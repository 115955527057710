import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { Store } from 'redux';
// @fenderdigital
import Cognito, { CognitoCredentials } from '@fenderdigital/custom-hooks/lib/cognito';
import isDomUsable from '@fenderdigital/utils/dom';
import { UserContextProvider } from '@fenderdigital/custom-hooks/contexts/UserContext';
// contexts
import ContextsProvider from '../contexts/Contexts';
import { ModalContextProvider } from '../contexts/ModalContext';
// components
import DeveloperConsole from './DeveloperConsole';
import Layout from './Layout';
import PlayAuthRedirectRoute from './PlayAuthRedirectRoute';
import Styles from './Styles';
// routes
import CollectionsDetail from '../routes/collections/CollectionsDetail';
import Pages from '../routes/pages/components/Pages';
import NotFound from '../routes/404/NotFound';
import routes from '../routes';
// config
import { AuthConfig, userApiConfig } from '../config';
// core
import bugsnagClient from '../core/bugsnag';
// store
import applyFeatureFlags from '../store/apply-feature-flags';

const getProviders = (): JSX.Element[] => {
  const providers: JSX.Element[] = [<ModalContextProvider />];
  return providers;
};

export interface AppProps {
  queryParams: URLSearchParams;
  store: Store;
}

const CognitoInstance = new Cognito(
  AuthConfig,
  `${process.env.REACT_APP_CLIENT_COGNITO_CLIENT_ID_LAUSD}`,
);
CognitoInstance.fetchCognitoCredentials().then(
  (response: CognitoCredentials | null): void => {
    let id: string | undefined;
    if (response) {
      id = response.userID;
      bugsnagClient.user = { id };
    } else {
      // Unauthed page track call;
      isDomUsable() && window.analytics.page();
    }
  },
  () => {},
);

const App: FC<AppProps> = ({ queryParams, store }) => {
  applyFeatureFlags();
  const state = store && store.getState();
  const intl = (state && state.intl) || {};
  const { locale, messages } = intl;
  const localeMessages = (messages && messages[locale]) || {};
  const PROVIDERS = getProviders();
  return (
    <IntlProvider
      initialNow={Date.now()}
      locale="en-US"
      messages={localeMessages}
      defaultLocale="en-US"
    >
      <UserContextProvider apiConfig={userApiConfig} cognito={CognitoInstance}>
        <ContextsProvider providers={PROVIDERS}>
          <DeveloperConsole>
            <Styles>
              <Layout>
                <Switch>
                  <Route
                    exact
                    path={['/lesson', '/lessons', '/course', '/courses', '/practice']}
                    render={(): React.ReactNode => <Redirect to="/" />}
                  />
                  <Route
                    exact
                    path="/artists"
                    render={(): React.ReactNode => <Redirect to="/guitar/songs" />}
                  />
                  <Route
                    exact
                    path={['/skills', '/skills/featured']}
                    render={(): React.ReactNode => <Redirect to="/guitar/skills" />}
                  />
                  <Route
                    exact
                    path="/:instrument(bass|guitar|ukulele)"
                    render={({ match }): React.ReactNode => (
                      <Redirect to={`/${match.params.instrument}/songs`} />
                    )}
                  />
                  <Route
                    exact
                    path="/:instrument(bass|guitar|ukulele)/artists/*"
                    render={({ match }): React.ReactNode => (
                      <Redirect to={`/${match.params.instrument}/songs`} />
                    )}
                  />
                  <Route
                    exact
                    path="/:instrument(bass|guitar|ukulele)/skills/all/catalog"
                    render={({ match }): React.ReactNode => (
                      <Redirect
                        to={`/search/results?query=&instrument=${match.params.instrument}&type=skill`}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/:instrument(bass|guitar|ukulele)/:type(songs|riffs)/:genre(catalog|rock
                    |country|pop|blues|folk|funk)"
                    render={({ match }): React.ReactNode => (
                      <Redirect
                        to={`/${match.params.instrument}/${match.params.type}/all/${match.params.genre}`}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/my-path"
                    render={(): React.ReactNode => <Redirect to="/path" />}
                  />
                  <Route
                    exact
                    path="/songs/catalog"
                    render={(): React.ReactNode => <Redirect to="/songs" />}
                  />
                  <Route
                    exact
                    path="/onboarding"
                    render={(): React.ReactNode => <Redirect to="/plans" />}
                  />
                  <Route
                    exact
                    path={['/onboarding/signin-reseller', '/onboarding/create-password']}
                    render={(): React.ReactNode => {
                      if (queryParams.has('email')) {
                        const redirSearchParams = new URLSearchParams();
                        redirSearchParams.append('email', queryParams.get('email') || '');
                        redirSearchParams.append('token', queryParams.get('token') || '');
                        return (
                          <Redirect
                            to={{ pathname: '/signin', search: redirSearchParams.toString() }}
                          />
                        );
                      }
                      return <Redirect to="/plans" />;
                    }}
                  />
                  <Route
                    exact
                    path="/onboarding/renew"
                    render={(): React.ReactNode => (
                      <Redirect to={{ pathname: '/plans', search: '?signin=1' }} />
                    )}
                  />
                  <Route
                    exact
                    path="/onboarding/:subroute"
                    render={(): React.ReactNode => <Redirect to="/plans" />}
                  />
                  <Route
                    exact
                    path="/:instrument(bass|guitar|ukulele)/collections/:collectionSlug"
                    render={(props): React.ReactNode => (
                      <CollectionsDetail slug={props.match.params.collectionSlug} />
                    )}
                  />
                  <PlayAuthRedirectRoute
                    path={[
                      '/lesson/:lessonSlug',
                      '/course/:courseSlug/lesson/:lessonSlug',
                      '/path/:pathSlug/:level/course/:courseSlug/lesson/:lessonSlug',
                      '/:instrument(bass|guitar|ukulele)/collections/:collectionSlug/course/:courseSlug/lesson/:lessonSlug',
                    ]}
                    key="lesson"
                  />
                  <PlayAuthRedirectRoute
                    path={[
                      '/practice/:practiceSlug',
                      '/course/:courseSlug/practice/:practiceSlug',
                      '/path/:pathSlug/:level/course/:courseSlug/practice/:practiceSlug',
                      '/:instrument(bass|guitar|ukulele)/collections/:collectionSlug/course/:courseSlug/practice/:practiceSlug',
                    ]}
                    key="practice"
                  />
                  <PlayAuthRedirectRoute
                    path={[
                      '/course/:courseSlug/feedback-mode',
                      '/path/:pathSlug/:level/course/:courseSlug/feedback-mode/',
                      '/:instrument(bass|guitar|ukulele)/collections/:collectionSlug/course/:courseSlug/feedback-mode',
                    ]}
                    key="feedback-mode"
                  />
                  <PlayAuthRedirectRoute
                    path={[
                      '/course/:courseSlug',
                      '/path/:pathSlug/:level/course/:courseSlug',
                      '/:instrument(bass|guitar|ukulele)/collections/:collectionSlug/course/:courseSlug',
                    ]}
                    key="course"
                  />
                  <PlayAuthRedirectRoute
                    exact
                    path="/bundles-ineligible"
                    key="bundles-ineligible"
                  />
                  <PlayAuthRedirectRoute exact path="/checkout" key="checkout" />
                  <PlayAuthRedirectRoute
                    exact
                    path={[
                      '/chord-challenge',
                      '/chord-challenge/transitions',
                      '/chord-challenge/session',
                      '/chord-challenge/session/:transitionKey',
                    ]}
                    key="chord-challenge"
                  />
                  <PlayAuthRedirectRoute path="/favorites" exact key="favorites" />
                  <PlayAuthRedirectRoute
                    path={[
                      '/my-progress',
                      '/my-progress/:type?',
                      '/my-progress/courses',
                      '/my-progress/:type',
                    ]}
                    exact
                    key="my-progress"
                  />
                  <PlayAuthRedirectRoute
                    path={['/path', '/path/:level?', '/paths/:path?']}
                    exact
                    key="path"
                  />
                  <PlayAuthRedirectRoute path="/plans/:plan?" exact key="plans" />
                  <PlayAuthRedirectRoute path="/quiz" exact key="quiz" />
                  <PlayAuthRedirectRoute
                    path={['/redeem', '/redeem/success', '/redeem/error']}
                    exact
                    key="redeem"
                  />
                  <PlayAuthRedirectRoute path="/tab-viewer" exact key="tab-viewer" />
                  <PlayAuthRedirectRoute path="/up-next" exact key="up-next" />
                  <PlayAuthRedirectRoute path="/welcome-screen" exact key="welcome-screen" />
                  <Route exact path="/pages/:slug" render={(): React.ReactNode => <Pages />} />
                  {routes.map(({ path, Component, exact }) => (
                    <PlayAuthRedirectRoute
                      key={path}
                      path={path}
                      Component={Component}
                      exact={exact}
                    />
                  ))}
                  <Route
                    exact
                    path="/:instrument(bass|guitar|ukulele)/:type"
                    render={({ match }): React.ReactNode => (
                      <Redirect to={`/${match.params.instrument}/songs`} />
                    )}
                  />
                  {/* Client Redirects */}
                  <Route
                    exact
                    path="/pages/streaks-announcement"
                    render={(): React.ReactNode => {
                      if (isDomUsable()) {
                        window.location.replace(
                          `${process.env.REACT_APP_CLIENT_FENDER_COM}/whats-new/streaks-updates`,
                        );
                      }
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/pages/affiliate-program"
                    render={(): React.ReactNode => {
                      if (isDomUsable()) {
                        window.location.replace(
                          `${process.env.REACT_APP_CLIENT_FENDER_COM}/en-US/affiliate-program.html`,
                        );
                      }
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/myaccount"
                    render={(): React.ReactNode => {
                      if (isDomUsable()) {
                        window.location.replace(
                          `${process.env.REACT_APP_CLIENT_FENDER_COM}/profile/info`,
                        );
                      }
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/guitar/skills/catalog"
                    render={(): React.ReactNode => <Redirect to="/guitar/skills/courses" />}
                  />
                  <Route
                    exact
                    path="/ukulele/skills/catalog"
                    render={(): React.ReactNode => <Redirect to="/ukulele/skills/courses" />}
                  />
                  <Route
                    exact
                    path="/password-update-article"
                    render={(): React.ReactNode => {
                      if (isDomUsable()) {
                        window.location.replace(
                          `https://play-support.fender.com/hc/en-us/articles/360021245212`,
                        );
                      }
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/password-update"
                    render={(): React.ReactNode => {
                      if (isDomUsable()) {
                        window.location.replace(
                          `${process.env.REACT_APP_CLIENT_FENDER_COM}/forgot-password`,
                        );
                      }
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/offerterms"
                    render={(): React.ReactNode => {
                      if (isDomUsable()) {
                        window.location.replace(
                          `https://play-support.fender.com/hc/en-us/articles/360015185312`,
                        );
                      }
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/tracks"
                    render={(): React.ReactNode => {
                      if (isDomUsable()) {
                        window.location.replace(`https://soundcloud.com/fenderplaytracks`);
                      }
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/announcements"
                    render={(): React.ReactNode => <Redirect to="/pages/announcements" />}
                  />
                  <Route
                    exact
                    path="/signin"
                    render={(): React.ReactNode => {
                      if (isDomUsable()) {
                        window.location.replace(`${process.env.REACT_APP_CLIENT_SSO_URL}/sign-in`);
                      }
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/signup"
                    render={(): React.ReactNode => {
                      if (isDomUsable()) {
                        window.location.replace(`${process.env.REACT_APP_CLIENT_SSO_URL}/sign-up`);
                      }
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/affiliate"
                    render={(): React.ReactNode => <Redirect to="/pages/affiliate-program" />}
                  />
                  <Route
                    exact
                    path="/support"
                    render={(): React.ReactNode => {
                      if (isDomUsable()) {
                        window.location.replace(`https://play-support.fender.com/hc/en-us`);
                      }
                      return null;
                    }}
                  />
                  <Route component={NotFound} />
                </Switch>
              </Layout>
            </Styles>
          </DeveloperConsole>
        </ContextsProvider>
      </UserContextProvider>
    </IntlProvider>
  );
  /* eslint-enable @typescript-eslint/explicit-function-return-type */
};

export default App;
