import React, { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import Markdown from 'react-markdown';
import Button from '@fenderdigital/ui/Button';
import Image, { getVariantUrls } from '@fenderdigital/react-ui-kit/image';
import Link from '../Link';
import { renderHeadline } from '../../utils/blocks-utils';

const messages = defineMessages({
  cta1: {
    id: 'blockHero.cta1',
    defaultMessage: '{cta1}',
  },
  cta2: {
    id: 'blockHero.cta2',
    defaultMessage: '{cta2}',
  },
  description: {
    id: 'blockHero.description',
    defaultMessage: '{description}',
  },
  headline: {
    id: 'blockHero.headline',
    defaultMessage: '{headline}',
  },
});

interface BlockImageTextProps {
  backgroundImageUrl?: string;
  cta1?: string;
  cta1Url?: string;
  cta2?: string;
  cta2Url?: string;
  containerStyles?: string;
  description?: string;
  headline?: string;
  headlineStyles?: string;
  imageAlign: string;
  imageUrl?: string;
  isFirstBlock: boolean;
  mobileImageUrl?: string;
  mobileBackgroundImageUrl?: string;
  theme?: string;
}

const BlockImageText: FC<BlockImageTextProps> = ({
  backgroundImageUrl = null,
  cta1 = null,
  cta1Url = null,
  cta2 = null,
  cta2Url = null,
  containerStyles = 'w-100 mb3 mb0-l ph3-ns',
  description = null,
  headline = null,
  headlineStyles = 'f1 futura-heavy ma0 mb1 tc tl-l',
  imageAlign = 'right',
  imageUrl = null,
  isFirstBlock = false,
  theme = 'light',
}) => {
  const themeStyles = `relative ${theme === 'dark' ? 'bg-black white' : 'bg-white black'}`;
  const images = getVariantUrls(imageUrl, null);

  const content: JSX.Element = (
    <div
      className={`flex-l items-center-l h-100 ${
        theme === 'dark' ? 'ph3 ph5-l' : 'ph5-l ph3'
      } ${containerStyles}`}
    >
      <div
        className={`w-50-l mw5 mw-100-l center tc flex-auto-l ${
          imageAlign === 'left' ? 'order-0-l' : 'order-1-l'
        }`}
      >
        {imageUrl && <Image bgColor="transparent" images={images} ratio={1 / 1} />}
      </div>
      <div
        className={`w-50-l center db-l flex flex-column items-center justify-center ${
          imageAlign === 'left' ? 'order-1-l' : 'order-0-l'
        }`}
      >
        {renderHeadline(headline, headlineStyles, isFirstBlock)}
        {description && <Markdown className="block-markdown lh-copy">{description}</Markdown>}
        <div className="dib pl3 pl0-l">
          {cta1 && cta1Url && (
            <Button as={Link} colorScheme="blue" to={cta1Url}>
              <FormattedMessage {...messages.cta1} values={{ cta1 }} />
            </Button>
          )}
          {cta2 && cta2Url && (
            <Button
              as={Link}
              hoverColor="nearWhite"
              variant="ghost"
              textColor={theme === 'dark' ? 'white' : 'black'}
            >
              <FormattedMessage {...messages.cta2} values={{ cta2 }} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className={`block-image-text ${themeStyles}`}>
      {backgroundImageUrl ? (
        <Image baseUrl={backgroundImageUrl} animate aspectRatio="wideScreen" variantType="large">
          {content}
        </Image>
      ) : (
        <div>{content}</div>
      )}
    </div>
  );
};

export default BlockImageText;
