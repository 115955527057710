import React from 'react';
import styled from 'styled-components';
import Flex from '@fenderdigital/ui/Flex';
import Spacer from '@fenderdigital/ui/Spacer';
import ScreenReaderOnly from '@fenderdigital/ui/ScreenReaderOnly';
import { Focus } from '@fenderdigital/ui/utils/css';
import { Lock } from '@fenderdigital/ui/Icons';
import Image, { getMapToRatios, getVariantUrls } from '@fenderdigital/react-ui-kit/image';
import LevelIndicator from '../LevelIndicator';
import Link from '../Link';

function getChords(display, chords, containerStyle, itemStyle) {
  if (!display) return null;
  return (
    <div className={containerStyle}>
      {chords ? (
        <div className={itemStyle}>
          <span className="gray mr2">{chords}</span>
        </div>
      ) : null}
    </div>
  );
}

function getContentImage(rowContent, styleObj) {
  const { image } = rowContent || {};
  const { aspectRatio, image: imgStyles, style } = styleObj || {};
  const images = getVariantUrls(image, aspectRatio, 'smallThumb');
  const ratio = getMapToRatios(aspectRatio);
  return (
    <div className={`${style} mr3`}>
      <div className={`${imgStyles}`}>
        <Image images={images} ratio={ratio} />
      </div>
    </div>
  );
}

function getContentText(display, content, style) {
  if (!display) return null;
  return <div className={style}>{content}</div>;
}

function getLessonsMessage(lessons) {
  return `${lessons} lesson${lessons && lessons > 1 ? 's' : ''}`;
}

function getStatsMessage(stats) {
  const statsMessage = [];
  for (const key in stats) {
    if (stats[key]) {
      if (statsMessage.length)
        statsMessage.push(
          <p key={`${stats[key]}`} className="ph1 f6 mb0">
            •
          </p>,
        );
      statsMessage.push(
        <p key={`${stats[key]}-${key}}`} className="mb0">{`${stats[key]} ${key}`}</p>,
      );
    }
  }
  return statsMessage;
}

function getLevelDisplay(display, level, style) {
  if (!display) return null;
  const levelNum = Number(level) || 1;
  return (
    <div className={style}>
      <LevelIndicator currentLevel={levelNum} levelFontSize="f5" completeColor="#666666" />
    </div>
  );
}

function getTitle(curriculumInfo) {
  const {
    title,
    url,
    trackEvent,
    onClick = () => {},
    style = '',
    isSearch,
    isLocked,
    isNonApprovedCountry,
  } = curriculumInfo;

  return (
    <Flex
      align="center"
      css={`
        a {
          ${Focus};
        }
      `}
    >
      <Link
        className={`w-100 source-sans black ${style}`}
        to={isLocked ? '/plans/upgrade' : url}
        trackEvent={trackEvent || null}
        onClick={e => {
          onClick(e);
        }}
      >
        {isLocked && (
          <>
            <Lock
              aria-hidden="true"
              data-id="locked-content"
              fontSize="1rem"
              css={`
                margin-top: 3px;
                margin-right: 3px;
              `}
            />
          </>
        )}
        {isSearch ? <span dangerouslySetInnerHTML={{ __html: title }} /> : title}
        {isLocked && <ScreenReaderOnly>. Locked. Upgrade to unlock content.</ScreenReaderOnly>}
      </Link>
    </Flex>
  );
}

function getContentTitle(title, isSearch) {
  return isSearch ? <span dangerouslySetInnerHTML={{ __html: title }} /> : title;
}

export {
  getChords,
  getContentImage,
  getContentText,
  getLessonsMessage,
  getStatsMessage,
  getLevelDisplay,
  getTitle,
  getContentTitle,
};
