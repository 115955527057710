import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import isDomUsable from '@fenderdigital/utils/dom';
import rootReducer from '../reducers';
import createHelpers from './createHelpers';
import applyFeatureFlags from './apply-feature-flags';

export default function configureStore(initialState, helpersConfig) {
  const helpers = createHelpers(helpersConfig);
  const middleware = [thunk.withExtraArgument(helpers)];

  let enhancer;
  const playAPI = process.env.REACT_APP_CLIENT_LIBRARY_API;

  if (
    isDomUsable() &&
    (process.env.NODE_ENV === 'development' ||
      playAPI.indexOf('active-play-api.fendergarage.com') > -1 ||
      playAPI.indexOf('qa-play-api.fendergarage.com') > -1)
  ) {
    // Freeze objects to prevent mutating state
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const freeze = require('redux-freeze'); // eslint-disable-line  global-require
    middleware.push(freeze);

    // https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
    let devToolsExtension = f => f;
    if (
      isDomUsable() &&
      process.env.REACT_APP_ENVIRONMENT !== 'production' &&
      // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION__
    ) {
      // eslint-disable-next-line no-underscore-dangle
      devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__();
    }

    enhancer = compose(applyMiddleware(...middleware), devToolsExtension);
  } else {
    enhancer = applyMiddleware(...middleware);
  }

  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  const store = createStore(rootReducer(), initialState, enhancer);
  store.asyncReducers = {};
  if (isDomUsable()) {
    window.fenderFlags = () => {
      console.table(store.getState().flags);
    };
  }

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('../reducers', () => store.replaceReducer(require('../reducers').default));
  }

  return store;
}
