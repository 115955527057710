import isDomUsable from '@fenderdigital/utils/dom';
import { setOverrideFlag } from '../lib/feature-flags';
/**
 * Pull feature flags from local storage and query params and set them in the app
 * eg `https://www.fender.com/play?ff_console=true&fsticky=true` will enable the console in prod for the user visiting
 * that url permanently until the next deploy to prod
 * @param store redux store
 */
export default function applyFeatureFlags(): void {
  const flagPrefix = 'ff_';
  const gitSHA = `${process.env.REACT_APP_GITSHA}`;
  const compoundPrefix = gitSHA + flagPrefix;
  function setFlag(value: string | null, key: string): void {
    if (key.startsWith(flagPrefix)) {
      // eslint-disable-next-line
      // @ts-ignore
      setOverrideFlag(key.substr(3), value);
    }
  }
  if (isDomUsable()) {
    for (let i = 0; i < window.localStorage.length; i++) {
      const key = window.localStorage.key(i);
      if (key && key.startsWith(compoundPrefix)) {
        setFlag(window.localStorage.getItem(key), key.slice(gitSHA.length));
      }
    }
    const params = new URLSearchParams(window.location.search);
    params.forEach(setFlag);
    if (params.get('fsticky')) {
      params.forEach((value, key): void => {
        if (key.startsWith(flagPrefix)) {
          // prefix git sha so we don't have flags sitcking around w/o knowing
          window.localStorage.setItem(gitSHA + key, value);
        }
      });
    }
  }
}
