import has from 'lodash/has';

export default function getTabs(list) {
  const flattenedTabs = [];

  if (list && has(list, 'chord-tab-items')) {
    list['chord-tab-items'].forEach(item => {
      if (has(item, 'tabs')) {
        item.tabs.forEach(tab => {
          flattenedTabs.push(tab);
        });
      }
    });
  }

  return flattenedTabs;
}
