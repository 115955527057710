import React from 'react';
import styled from 'styled-components';

const Label = styled.p<{ background?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, background }): string =>
    background ? theme.colors?.[background] : theme.colors.black};
  font-size: ${({ theme }): string => theme.fontSizes.f1};
  font-family: ${({ theme }): string => theme.fonts.futuraHeavy};
  color: ${({ theme }): string => theme.colors.white};
  line-height: 1;
  height: 22px;
  width: fit-content;
  padding: 0 6px;
  text-transform: uppercase;
  border-radius: 1px;
`;

export const FeedbackBadge: React.FC = () => <Label data-id="feedback-badge">feedback mode</Label>;

export const LastWatchedBadge: React.FC = () => <Label background="danger">Last Watched</Label>;
