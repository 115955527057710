/**
 * Based off https://github.com/kriasoft/react-starter-kit/blob/master/src/createFetch.js
 */

import fetch from 'isomorphic-fetch';

/**
 * Creates a wrapper function around the HTML5 Fetch API that provides
 * default arguments to fetch(...) and is intended to reduce the amount
 * of boilerplate code in the application.
 * https://developer.mozilla.org/docs/Web/API/Fetch_API/Using_Fetch
 */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
type PlayFetch = (url: string, options?: RequestInit) => ReturnType<typeof fetch>;

const createFetch = (): PlayFetch => {
  // NOTE: Tweak the default options to suite your application needs
  const defaults = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Client-Name': process.env.REACT_APP_CLIENT_SITE_ID || '',
    },
  };

  return (url, options): ReturnType<typeof fetch> =>
    fetch(url, {
      ...defaults,
      ...options,
      headers: {
        ...defaults.headers,
        ...(options && options.headers),
      },
    });
};

const defaultFetch = createFetch();
export default defaultFetch;
