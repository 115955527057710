import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import useBackHandler from '../BackButton/useBackHandler';

const TranslucentButton = styled.button`
  background: rgba(34, 34, 34, 0.41);
  border-radius: 22px;
  border: 1px solid rgb(255, 255, 255);
  height: 44px;
  width: 44px;
  padding-left: 0;
  padding-right: 0;
  flex-direction: column;
  justify-content: center;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${up('tablet-landscape')} {
    width: 117px;
    padding-left: 22px;
    padding-right: 28px;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BackIcon = styled.i`
  font-size: 1.2rem;
`;

const BackLabel = styled.span`
  color: white;
  font-family: SourceSansPro-Regular;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  display: none;

  ${up('tablet-landscape')} {
    display: block;
  }
`;

const CollectionBackButton: React.FC<{ className: string }> = ({ className }) => {
  const backHandler = useBackHandler();

  return (
    <TranslucentButton type="button" onClick={backHandler} className={className}>
      <BackIcon className="icon-arrow-left" />
      <BackLabel className="collection-back-button-label">BACK</BackLabel>
    </TranslucentButton>
  );
};

export default CollectionBackButton;
