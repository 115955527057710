import mirror from 'keymirror';
import Library from '../lib/library';

export const actionTypes = mirror({
  CLEAR_WATCHING: null,
  COURSES_GET_BY_ARTIST_ERROR: null,
  COURSES_GET_BY_ARTIST_IN_PROGRESS: null,
  COURSES_GET_BY_ARTIST_SUCCESS: null,
  COURSES_GET_ERROR: null,
  COURSES_GET_IN_PROGRESS: null,
  COURSES_GET_SUCCESS: null,
  NEXT_LESSON_IN_COURSE: null,
});

export const clearCourseWatching = () => ({
  type: actionTypes.CLEAR_WATCHING,
});

const coursesGetByArtistError = error => ({
  error,
  type: actionTypes.COURSES_GET_BY_ARTIST_ERROR,
});

const coursesGetByArtistInProgress = () => ({
  type: actionTypes.COURSES_GET_BY_ARTIST_IN_PROGRESS,
});

const coursesGetByArtistSuccess = results => ({
  results,
  type: actionTypes.COURSES_GET_BY_ARTIST_SUCCESS,
});

const coursesGetError = error => ({
  error,
  type: actionTypes.COURSES_GET_ERROR,
});

const coursesGetInProgress = () => ({
  type: actionTypes.COURSES_GET_IN_PROGRESS,
});

const coursesGetSuccess = results => ({
  results,
  type: actionTypes.COURSES_GET_SUCCESS,
});

export function getCourses(options, unseenPath = false, publicEndpoint = false) {
  return async (dispatch, getState) => {
    try {
      dispatch(coursesGetInProgress());
      const state = getState();
      const results = await Library.getCourses(options, unseenPath, publicEndpoint);
      dispatch(coursesGetSuccess(results));
    } catch (err) {
      dispatch(coursesGetError(err));
    }
  };
}

export function getCoursesByArtist(options) {
  return async dispatch => {
    dispatch(coursesGetByArtistInProgress());
    try {
      const courseArtists = await Library.getCoursesByArtist(options);
      dispatch(coursesGetByArtistSuccess(courseArtists));
    } catch (err) {
      dispatch(coursesGetByArtistError(err));
    }
  };
}

export function nextLessonInCourse(currentLessonSlug) {
  return async dispatch => {
    dispatch({
      type: actionTypes.NEXT_LESSON_IN_COURSE,
      currentLessonSlug,
    });
  };
}
