import styled from 'styled-components';

const LocaleTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  color: #000;
  font-family: SourceSansPro-Regular, -apple-system, system-ui, avenir next, avenir, Helvetica Neue,
    helvetica, ubuntu, roboto, noto, Segoe UI, Arial, sans-serif;
  p,
  a {
    font-size: 14px;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
  }
  span.flag {
    display: inline-block;
    margin: 0 10px 0 0;
    width: 16px;
    height: 11px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  td {
    padding: 0 0 8px;
    text-align: center;
    width: 12%;
    &:first-child {
      text-align: left;
      width: 35%;
      white-space: nowrap;
    }
  }
  td.first {
    width: 10%;
  }

  .zone-col {
    width: 64px;
    text-align: center;
    margin-bottom: 15px;
  }

  span.flag-icon-ad {
    background-image: url('/icons/flags/ad.svg');
  }

  span.flag-icon-at {
    background-image: url('/icons/flags/at.svg');
  }

  span.flag-icon-au {
    background-image: url('/icons/flags/au.svg');
  }

  span.flag-icon-be {
    background-image: url('/icons/flags/be.svg');
  }

  span.flag-icon-bg {
    background-image: url('/icons/flags/bg.svg');
  }

  span.flag-icon-ch {
    background-image: url('/icons/flags/ch.svg');
  }

  span.flag-icon-cy {
    background-image: url('/icons/flags/cy.svg');
  }

  span.flag-icon-cz {
    background-image: url('/icons/flags/cz.svg');
  }

  span.flag-icon-de {
    background-image: url('/icons/flags/de.svg');
  }

  span.flag-icon-dk {
    background-image: url('/icons/flags/dk.svg');
  }

  span.flag-icon-ee {
    background-image: url('/icons/flags/ee.svg');
  }

  span.flag-icon-es {
    background-image: url('/icons/flags/es.svg');
  }

  span.flag-icon-fi {
    background-image: url('/icons/flags/fi.svg');
  }

  span.flag-icon-fr {
    background-image: url('/icons/flags/fr.svg');
  }

  span.flag-icon-gb {
    background-image: url('/icons/flags/gb.svg');
  }

  span.flag-icon-gr {
    background-image: url('/icons/flags/gr.svg');
  }

  span.flag-icon-hr {
    background-image: url('/icons/flags/hr.svg');
  }

  span.flag-icon-hu {
    background-image: url('/icons/flags/hu.svg');
  }

  span.flag-icon-ie {
    background-image: url('/icons/flags/ie.svg');
  }

  span.flag-icon-it {
    background-image: url('/icons/flags/it.svg');
  }

  span.flag-icon-jp {
    background-image: url('/icons/flags/jp.svg');
  }

  span.flag-icon-li {
    background-image: url('/icons/flags/li.svg');
  }

  span.flag-icon-lt {
    background-image: url('/icons/flags/lt.svg');
  }

  span.flag-icon-lu {
    background-image: url('/icons/flags/lu.svg');
  }

  span.flag-icon-lv {
    background-image: url('/icons/flags/lv.svg');
  }

  span.flag-icon-mc {
    background-image: url('/icons/flags/mc.svg');
  }

  span.flag-icon-mt {
    background-image: url('/icons/flags/mt.svg');
  }

  span.flag-icon-nl {
    background-image: url('/icons/flags/nl.svg');
  }

  span.flag-icon-no {
    background-image: url('/icons/flags/no.svg');
  }

  span.flag-icon-pl {
    background-image: url('/icons/flags/pl.svg');
  }

  span.flag-icon-pt {
    background-image: url('/icons/flags/pt.svg');
  }

  span.flag-icon-ro {
    background-image: url('/icons/flags/ro.svg');
  }

  span.flag-icon-se {
    background-image: url('/icons/flags/se.svg');
  }

  span.flag-icon-sk {
    background-image: url('/icons/flags/sk.svg');
  }

  span.flag-icon-sl {
    background-image: url('/icons/flags/sl.svg');
  }

  span.flag-icon-us {
    background-image: url('/icons/flags/us.svg');
  }
`;

export default LocaleTable;
