import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Focus } from '@fenderdigital/ui/utils/css';
import Image, { getMapToRatios, getVariantUrls } from '@fenderdigital/react-ui-kit/image';
import Link from '../Link';

const LinkWrapper = styled.div`
  a {
    ${Focus}
  }
`;
class OverlayThumb extends PureComponent {
  render() {
    const {
      backgroundColor,
      aspectRatio,
      variantType,
      label,
      image,
      circle,
      labelColor,
      overlay,
      itemStyle,
      height: heightProp,
      labelUrl,
    } = this.props;
    const imageStyles = `absolute top-0 left-0 w-100 h-100 z-0 bg-${backgroundColor} w-100 h-100`;
    const labelStyles = `relative f3 z-1 white futura-heavy ${labelColor}`;
    const containerStyles =
      'overlay-thumb pointer flex justify-center items-center tc relative overflow-hidden ba b--black-10';
    const blendMode = overlay ? 'multiply' : 'normal';
    const circleStyle = circle ? 'br-100 bn' : '';
    const ratio = getMapToRatios(aspectRatio);
    const images = Array.isArray(image)
      ? image
      : getVariantUrls(image, aspectRatio, variantType || 'small');
    const height = aspectRatio === 'wideScreen' ? '135px' : heightProp;
    return (
      <div
        className={`overlay-thumb ${containerStyles} ${circleStyle} bg-${backgroundColor}`}
        style={{ height, ...itemStyle }}
      >
        <div
          className={imageStyles}
          style={{
            mixBlendMode: `${blendMode}`,
          }}
        >
          {images ? <Image images={images} ratio={ratio} /> : null}
        </div>
        {label ? (
          <>
            {labelUrl ? (
              <LinkWrapper>
                <Link to={labelUrl} className={labelStyles} onClick={e => e.stopPropagation()}>
                  {label}
                </Link>
              </LinkWrapper>
            ) : (
              <span className={labelStyles}>{label}</span>
            )}
          </>
        ) : null}
      </div>
    );
  }
}

OverlayThumb.propTypes = {
  aspectRatio: PropTypes.string,
  backgroundColor: PropTypes.string,
  circle: PropTypes.bool,
  itemStyle: PropTypes.shape({}),
  height: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.string,
  labelColor: PropTypes.string,
  labelUrl: PropTypes.string,
  overlay: PropTypes.bool,
  variantType: PropTypes.string,
};

OverlayThumb.defaultProps = {
  aspectRatio: 'square',
  backgroundColor: 'transparent',
  circle: false,
  itemStyle: { width: '10rem', minWidth: '10rem' },
  height: '10rem',
  image: null,
  label: null,
  labelColor: 'white',
  labelUrl: '',
  overlay: false,
  variantType: 'smallThumb',
};

export default OverlayThumb;
