import React from 'react';
import { getFlag } from '../../lib/feature-flags';

interface FlagProps {
  fallBack?: JSX.Element;
  name: string;
  children?: JSX.Element;
}

const Flag: React.FC<FlagProps> = ({ children = null, fallBack = null, name }) => {
  // eslint-disable-next-line
  // @ts-ignore
  const flagEnabled = getFlag(name);
  return flagEnabled ? children : fallBack;
};

export default Flag;
