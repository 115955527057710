import videojs, { VideoJsPlayer } from 'video.js';

export interface PlaybackRateButton extends videojs.MenuButton {
  rate: number;
}

export interface PlaybackRateMenuButton extends videojs.MenuButton {
  items: PlaybackRateButton[];
}

// eslint-disable-next-line import/prefer-default-export
export const getPlayBackRateButtons = (player: VideoJsPlayer): PlaybackRateButton[] | [] => {
  const playbackRateButton = player.controlBar.getChild(
    'PlaybackRateMenuButton',
  ) as PlaybackRateMenuButton;

  return playbackRateButton?.items || [];
};
