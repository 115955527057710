type AccessLevels = string[] | null | undefined;

/**
 * Checks for intersection between item access levels and user access levels.
 * An item is locked if there is no intersection.
 * @param itemAccessLevels {Array} - array of item access levels
 * @param userAccessLevels {Array} - array of user access levels
 */
export const isLocked = (
  itemAccessLevels: AccessLevels,
  userAccessLevels: AccessLevels = ['all-access'],
): boolean => {
  if (!itemAccessLevels || itemAccessLevels === null || userAccessLevels === null) {
    return false;
  }
  return (
    itemAccessLevels.filter((accessLevel: string): boolean =>
      userAccessLevels.includes(accessLevel),
    ).length === 0
  );
};

export interface Instrument {
  image?: string;
  instrument?: string;
  paths?: Path[];
  title?: string;
}

interface Path {
  id?: string;
  ['access-levels']?: AccessLevels;
}

/**
 * Checks if there are any unlocked paths within an instrument.
 * An instrument is locked if there aren't any unlocked paths.
 * @param instrumentPath {Instrument} - an instrument object
 * @param userAccessLevels {Array} - array of user access levels
 */
export const isInstrumentLocked = (
  instrument: Instrument,
  userAccessLevels: AccessLevels = ['all-access'],
): boolean => {
  if (!instrument.paths || userAccessLevels === null) {
    return false;
  }
  return !instrument.paths?.some(
    (instrumentPath: Path): boolean =>
      !isLocked(instrumentPath?.['access-levels'], userAccessLevels),
  );
};
