import { connect } from 'react-redux';
import SearchPanel from './SearchPanel';
import { instantSearch, updateFilters } from '../../actions/search';

const mapState = (state, props) => {
  return {
    ...props,
  };
};
const mapDispatch = { instantSearch, updateFilters };

export default connect(mapState, mapDispatch)(SearchPanel);
