import { getAuthenticationHeader } from '@fenderdigital/custom-hooks/lib/cognito';
import fetch from '../core/fetch';
import Cognito from './cognito';
import { parseResponse } from '../core/fetch/response';

export default class Skills {
  static async getSkills() {
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}/skills-lists`;
    let params = {};
    const token = await getAuthenticationHeader(true);
    if (token) {
      params = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
    }
    const response = await fetch(url, params);
    return parseResponse(response);
  }
}
