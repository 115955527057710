import React from 'react';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import Heading from '@fenderdigital/ui/Heading';
import BodyText from '@fenderdigital/ui/BodyText';
import Spacer from '@fenderdigital/ui/Spacer';
import { generateImageCSS } from '@fenderdigital/utils/image-css';
import { FeedbackModeCarouselData } from './CarouselData';

const ModalContentContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${up('tablet-portrait')} {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
  }
`;

interface ImageArtContainerProps {
  special: boolean;
  artwork?: string;
}

const ImageArtContainer = styled.div<ImageArtContainerProps>`
  display: flex;
  justify-content: center;
  width: 40%;
  margin: 65px 30% 30px;
  min-height: 105px;
  max-height: 105px;
  overflow: hidden;

  @media screen and (min-height: 600px) {
    min-height: 140px;
    max-height: 140px;
  }

  ${({ artwork }) =>
    generateImageCSS(
      artwork ?? '',
      undefined,
      css`
        background-size: contain;
        background-position: center;
      `,
      '.png',
    )}

  @media screen and (min-height: 700px) {
    width: 45%;
    margin: 4rem 28% 2.5rem;
    min-height: 235px;
    max-height: 235px;
  }

  ${up('tablet-portrait')} {
    padding-top: ${({ special }): string => (special ? '0' : '30px')};
    height: 320px;
    min-height: none;
    max-height: none;
    align-items: center;
    width: 25%;
    margin: 0 12.5%;
  }
`;

const ModalCopyContainer = styled.div`
  white-space: break-spaces;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 84%;

  @media screen and (min-height: 600px) {
    width: 75%;
  }
  ${up('phone')} {
    width: 79%;
  }
  @media screen and (min-height: 820px) {
    width: 72%;
  }
  ${up('tablet-portrait')} {
    width: 50%;
    padding-top: 2.75rem;
    padding-left: 0px;
  }
`;

interface SlideProps {
  headline: string;
  text: string;
  artwork?: FeedbackModeCarouselData['artwork'];
  special: boolean;
  tip: string | undefined;
}

const FeedbackModalContent: React.FC<SlideProps> = ({ headline, text, artwork, special, tip }) => {
  return (
    <ModalContentContainer data-id="feedback-modal-content-container">
      <ImageArtContainer
        data-id="feedback-modal-art-container"
        artwork={artwork}
        special={special}
      />
      <ModalCopyContainer data-id="feedback-modal-copy-container">
        <Heading
          alignment="left"
          as="h2"
          textSize={['f8', 'f9', 'f10']}
          width="100%"
          textColor="white"
          id="feedback-mode-modal"
          tracking="1.25px"
        >
          {headline}
        </Heading>
        <Spacer />
        <BodyText textSize={['f4', 'f5']} textColor="white" weight="variant" width="100%">
          {text}
        </BodyText>
        {tip && (
          <>
            <Spacer />
            <BodyText textSize={['f4', 'f5']} textColor="white" weight="variant" width="100%">
              {tip}
            </BodyText>
          </>
        )}
      </ModalCopyContainer>
    </ModalContentContainer>
  );
};

export default FeedbackModalContent;
