import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { addLocaleData } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import theme from '@fenderdigital/ui/theme';
import CSSReset from '@fenderdigital/ui/CSSReset';
import en from 'react-intl/locale-data/en';
import 'intersection-observer';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { decorateWindow, loadSegment } from './core/segment';
import loadMouseFlow from './core/mouseflow';
import { trackPerformanceMetrics } from './lib/analytics';
import { avoConfig as AvoConfig } from './core/avoConfig';
import App from './components/App';
import configureStore from './store/configureStore';
// import * as serviceWorker from './serviceWorker';
import bugsnagClient from './core/bugsnag';

trackPerformanceMetrics();
decorateWindow();
[en].forEach(addLocaleData);
AvoConfig.init();

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    ga: UniversalAnalytics.ga;
    _fender_perf: ['string', number][];
    APP_STATE: Record<string, any>;
    bouncex: any[];
    _mfq: any[];
  }
}

const store = configureStore(window.APP_STATE);

const ErrorBoundary = bugsnagClient.getPlugin('react');

const container = document.getElementById('app');
// Re-render the app when window.location changes
function main(): void {
  try {
    ReactDOM.hydrate(
      <ErrorBoundary>
        <HelmetProvider>
          <Provider store={store}>
            <CookiesProvider>
              <ThemeProvider theme={theme}>
                {/* TODO: remove the reset from our tachyons (css-utilities) */}
                <CSSReset />
                <Router basename={process.env.REACT_APP_CLIENT_PATH_PREFIX}>
                  <App queryParams={new URL(window.location.href).searchParams} store={store} />
                </Router>
              </ThemeProvider>
            </CookiesProvider>
          </Provider>
        </HelmetProvider>
      </ErrorBoundary>,
      container,
      () => {
        loadSegment();
        loadMouseFlow();
      },
    );
  } catch (error) {
    // Display the error in full-screen for development mode
    if (process.env.NODE_ENV === 'development') {
      throw error;
    }

    console.error(error); // eslint-disable-line no-console
  }
}

main();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// mbeeson commenting out 5/20:
// https://github.com/facebook/create-react-app/issues/8047#issuecomment-578523702

// serviceWorker.unregister();
