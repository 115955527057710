import { useEffect, useState, useCallback } from 'react';
import { useSearchSelection } from '../contexts/SearchSelectionContext';

/**
 * This hook is used to add keyboard functionality to an autocomplete search box
 *
 * @param selector {string} - A selector used to find the selectable options
 * @return resetOptions {function} - Fetch a new list of options from the provided selctor
 */
const useSelectSearchOption = (selector: string): { resetOptions: () => void } => {
  const [options, setOptions] = useState<string[] | null>(null);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(null);
  const { setSelectedOptionId } = useSearchSelection();

  const resetOptions = useCallback(() => {
    const allOptions = document.querySelectorAll(selector);
    const optionIds = [] as string[];
    allOptions.forEach(node => optionIds.push(node.id));
    setOptions(optionIds);
    setSelectedOptionIndex(null);
    setSelectedOptionId();
  }, [setSelectedOptionId, selector]);

  const highlightNextOption = useCallback(() => {
    if (options?.length) {
      if (selectedOptionIndex === null) {
        setSelectedOptionIndex((): number => {
          setSelectedOptionId(options[0]);
          return 0;
        });
      } else if (selectedOptionIndex < options.length - 1) {
        setSelectedOptionIndex(currentOption => {
          if (currentOption !== null) {
            setSelectedOptionId(options[currentOption + 1]);
            return currentOption + 1;
          }
          return null;
        });
      } else if (selectedOptionIndex === options.length - 1) {
        setSelectedOptionIndex(() => {
          setSelectedOptionId(options[0]);
          return 0;
        });
      }
    }
  }, [selectedOptionIndex, options, setSelectedOptionId]);

  const highlightPreviousOption = useCallback(() => {
    if (options?.length) {
      if (selectedOptionIndex === null) {
        setSelectedOptionIndex(() => {
          setSelectedOptionId(options[options.length - 1]);
          return options.length - 1;
        });
      } else if (selectedOptionIndex > 0) {
        setSelectedOptionIndex(currentOption => {
          if (currentOption !== null) {
            setSelectedOptionId(options[currentOption - 1]);
            return currentOption - 1;
          }
          return null;
        });
      } else if (selectedOptionIndex === 0) {
        setSelectedOptionIndex(() => {
          setSelectedOptionId(options[options.length - 1]);
          return options.length - 1;
        });
      }
    }
  }, [selectedOptionIndex, options, setSelectedOptionId]);

  useEffect(() => {
    const handleKeyDown = (e: any): void => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        highlightNextOption();
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        highlightPreviousOption();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [highlightNextOption, highlightPreviousOption]);

  return { resetOptions };
};

export default useSelectSearchOption;
