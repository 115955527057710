import React from 'react';

interface PromoContentProps {
  onClick: () => void;
  bannerOpen: boolean;
  promoColor: string;
}

const PromoContent: React.FC<PromoContentProps> = ({
  children,
  bannerOpen,
  promoColor,
  onClick,
}) => {
  const shouldShow = children && bannerOpen;
  if (!shouldShow) {
    return null;
  }

  return (
    <div className={`promo-div w-100 relative f6 v-mid white bb b--light-gray bg-${promoColor}`}>
      <div
        className="f6 fw1 futura-book tc-l tl pr4"
        style={{ padding: '0.85rem 1rem 0.8rem 1rem' }}
      >
        {children}
        <button
          type="button"
          className={
            'absolute right-0 pa2 pr3 no-underline button-reset ' +
            'bg-transparent outline-0 bn pointer'
          }
          style={{ top: '.3rem' }}
          onClick={onClick}
        >
          <span className="icon-x white" />
        </button>
      </div>
    </div>
  );
};

export default PromoContent;
