import React, { SyntheticEvent, useState } from 'react';
import has from 'lodash/has';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import Logo from '@fenderdigital/ui/Logo';
import { isHash } from './Header';
import SubMenu from './SubMenu';
import Link from '../Link/Link';
import { NavigationItem } from './NavTypes';

interface DesktopMenuProps {
  navItems: NavigationItem[] | null;
  userEmail: string;
  setSelectedSubMenu: (subMenu: string | null) => void;
  selectedSubMenu: string | null;
}

const LogoContainer = styled(Link)`
  padding-left: 1rem;

  ${up('tablet-landscape')} {
    padding-left: 2rem;
  }
`;

const StyledLogo = styled(Logo)`
  height: 2rem;

  ${up('tablet-landscape')} {
    margin-top: 0.5rem;
  }
`;

export const getCurrentMenu = (
  nav: NavigationItem[] | null,
  id: string | null,
): NavigationItem[] | null => {
  if (nav && id) {
    const filteredNav = nav.filter((n: NavigationItem) => n.id === id)[0];
    return has(filteredNav, 'items') ? filteredNav.items : null;
  }
  return null;
};

const DesktopMenu: React.FC<DesktopMenuProps> = ({
  navItems,
  selectedSubMenu,
  userEmail,
  setSelectedSubMenu,
}) => {
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);

  const toggleSubMenu = (id: any): void => {
    if (selectedSubMenu === id) {
      setSubMenuVisible(false);
    }
    if (!selectedSubMenu) {
      setSubMenuVisible(true);
    }
  };

  const handleCloseSubMenu = (event: SyntheticEvent): void => {
    // eslint-disable-next-line
    // @ts-ignore
    const isSubMenu = event.target.closest('a.is-sub-menu');
    // allows for submenu mouse events to propagate correctly before setting state
    // a bit of a hack IMO, but wasn't sure of a better way, perhaps future me(dja)
    // will figure it out
    if (!isSubMenu) {
      setTimeout(() => {
        setSubMenuVisible(false);
        setSelectedSubMenu(null);
      }, 150);
    }
  };

  const handleNavItemClick = (event: SyntheticEvent, navItem: any): void => {
    event.preventDefault();
    if (navItem.items) {
      setSelectedSubMenu(selectedSubMenu !== navItem.id ? navItem.id : null);
      toggleSubMenu(navItem.id);
    }
  };

  const currentMenu = getCurrentMenu(navItems, selectedSubMenu);

  return (
    <div className="flex items-center">
      <LogoContainer to="/">
        <StyledLogo appName="play" data-id="mega-nav-fender-logo-img" />
      </LogoContainer>
      <ul className="list pl0 ma0 ml3 flex-l dn items-center self-stretch">
        {navItems?.map(navItem => (
          <li
            className={`flex items-center self-stretch mh3 bb
            ${navItem.id === selectedSubMenu ? 'b--blue' : 'b--transparent'}`}
            key={navItem.id}
            style={{ borderWidth: '-0.3125rem' }}
            data-id={`mega-nav-label-${navItem.label.toLowerCase()}`}
          >
            {navItem.items || !navItem.url || isHash(navItem.url) ? (
              <a
                href={isHash(navItem?.url ?? '') ? navItem.url : ''}
                onClick={event => handleNavItemClick(event, navItem)}
                className={`db relative mt3 ttu f8 truncate dark-gray no-underline ${
                  navItem.items ? 'is-sub-menu pr3' : ''
                }
                ${navItem.id === selectedSubMenu ? 'blue' : 'dark-gray'}`}
              >
                <span className={`${navItem['class-names'] || ''}`}>{navItem.label}</span>
                {navItem.items && (
                  <span
                    className={`${navItem['class-names'] || ''} f6 pl2 absolute right-0`}
                    style={{ top: '-0.3125rem' }}
                  >
                    <i
                      className={
                        navItem.id === selectedSubMenu ? 'icon-triangle-up' : 'icon-triangle-down'
                      }
                    />
                  </span>
                )}
              </a>
            ) : (
              <Link
                className="db relative mt3 ttu f8 truncate dark-gray no-underline"
                target={navItem.target ? navItem.target : ''}
                to={!navItem.url ? '' : navItem.url}
              >
                <span className={`${navItem['class-names'] || ''}`}>{navItem.label}</span>
              </Link>
            )}
          </li>
        ))}
      </ul>
      <SubMenu
        email={userEmail}
        isVisible={isSubMenuVisible}
        menu={currentMenu}
        subMenuId={selectedSubMenu}
        onClickOutside={handleCloseSubMenu}
        onSubMenuClick={handleCloseSubMenu}
      />
    </div>
  );
};

export default DesktopMenu;
