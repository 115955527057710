import React from 'react';
import styled from 'styled-components';
import { Focus } from '@fenderdigital/ui/utils/css';

const CopyRightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  margin: 0 auto;
  border-top: 1px solid rgb(74, 74, 74);
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
  > span {
    color: rgb(153, 153, 153);
    font-size: 14px;
    font-weight: normal;
    @media screen and (max-width: 768px) {
      order: 2;
      margin-top: 25px;
      width: 50%;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      text-align: center;
    }
  }
  > div {
    @media screen and (max-width: 768px) {
      order: 1;
    }
    @media screen and (max-width: 480px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

const LegalLink = styled.a`
  ${Focus}
  margin-left: 24px;
  color: rgb(153, 153, 153);
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 24px;
  }
  @media screen and (max-width: 480px) {
    margin-left: 0;
    margin-right: 0;
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
`;

const FooterCopyright: React.FC = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <CopyRightContainer id="footer-legal-links">
      <span>
        Copyright ©<time id="footer-legal-copyright-year">{year}</time>. Fender Musical Instruments
        Corporation. All Rights Reserved.
      </span>
      <div>
        <LegalLink href="https://www.fender.com/en-US/privacy-portal.html">
          Privacy Policy
        </LegalLink>
        <LegalLink href="/pages/terms-of-use">Terms & Conditions</LegalLink>
        <LegalLink href="/pages/supply-chain-transparency">Supply Chain Transparency</LegalLink>
        <LegalLink href="https://shop.fender.com/en-US/anti-slavery-policy">
          Modern Slavery Act 2019
        </LegalLink>
      </div>
    </CopyRightContainer>
  );
};

export default FooterCopyright;
