import React, { FC, ReactNode } from 'react';

interface ContentPlacementProps {
  children: ReactNode;
  contentPlacement: string;
}

const ContentPlacement: FC<ContentPlacementProps> = ({
  children,
  contentPlacement = 'middle-center',
}) => {
  const contentInnerStyles = 'flex flex-auto flex-column h-100 pv3';
  const verticalStyle = contentPlacement.split('-')[0];
  const horizontalStyle = contentPlacement.split('-')[1];
  const styleObject: Record<string, string> = {
    upper: 'justify-start',
    middle: 'justify-center',
    lower: 'justify-end',
    left: 'items-start tl-l',
    center: 'items-center',
    right: 'items-end tl-l',
  };

  const placementStyles = `${styleObject[verticalStyle]} tc ${styleObject[horizontalStyle]}`;

  return <div className={`${contentInnerStyles} ${placementStyles}`}>{children}</div>;
};

export default ContentPlacement;
