import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { FeedbackGradient } from './common';

const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: black;
  height: 42.25px;
  margin-left: 12px;
  width: 70px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  color: white;
  border: 1px solid #ccc;

  ${up('phone')} {
    width: 82px;
    height: 47.25px;
  }
`;

const PrettyThumb = styled.div`
  ${FeedbackGradient}
  height: 35%;
  font-family: FuturaPT-HeavyObl;
  padding-top: 0.5px;
  padding-bottom: 0.5px;
  padding-left: 4px;
  font-size: 0.9rem;
  line-height: 0.9;

  ${up('phone')} {
    font-size: 1rem;
  }
`;

const FeatureCopy = styled.div`
  font-family: FuturaPT-CondExtraBold;
  padding-left: 4px;
  margin-top: 2px;
  font-size: 0.9rem;
  line-height: 0.9;

  ${up('phone')} {
    font-size: 1rem;
  }
`;

const FeedbackThumbnail: React.FC = () => {
  return (
    <ThumbnailContainer data-id="feedback-mode-thumbnail">
      <FeatureCopy>feedback mode</FeatureCopy>
      <PrettyThumb>beta</PrettyThumb>
    </ThumbnailContainer>
  );
};

export default FeedbackThumbnail;
