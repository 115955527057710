import capitalize from 'lodash/capitalize';

export function stringCharLimitTo(string = '', limitNumber = 120): string {
  if (string && string.length > limitNumber) {
    string = string.slice(0, limitNumber - 3);
    string += '...';
  }
  return string;
}

export const toTitleCase = (str = ''): string => {
  return str.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const toKebabCase = (string?: string): string => {
  if (!string) {
    return '';
  }
  return string.replace(/\s+/g, '-').toLowerCase();
};

export const jsonParse = (rawString: string): any => {
  if (!rawString) return;
  try {
    return JSON.parse(rawString);
  } catch (e) {
    console.error('JSON Parse error: ', e);
  }
};

export const representsTruthy = (str?: string | null): boolean =>
  !!str && str !== null && (str === '1' || str === 'true');

export const slugPageTitle = (string: string) => {
  // Example input: "easy-reading-chord-diagrams-and-tablature"
  if (!string) {
    return string;
  }

  return string
    .split('-')
    .map(s => s.charAt(0).toUpperCase() + s.substr(1))
    .join(' ');
};

export const getPathStyleLabel = (pathStyle: string): string => {
  switch (pathStyle) {
    case 'rnbsoul':
      return 'R&B/Soul';
    default:
      return capitalize(pathStyle);
  }
};
