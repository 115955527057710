import React from 'react';

const CircledQuestionMark: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle stroke="#888" cx={7.5} cy={7.5} r={7} />
        <path
          d="M7.892 8.748v-1.26a2.38 2.38 0 001.368-.672c.408-.408.612-.9.612-1.548 0-.804-.288-1.332-.624-1.656C8.744 3.12 8.12 3 7.496 3c-1.008 0-1.572.336-1.896.672-.336.36-.6.996-.6 1.62h1.56c0-.42.132-.66.264-.78.072-.072.264-.216.612-.216.3 0 .492.108.624.24.144.144.252.372.252.696 0 .324-.132.612-.312.804-.252.252-.66.408-1.128.408-.168 0-.324-.012-.468-.048v2.352h1.488zm-.744 2.616a.922.922 0 00.936-.936.922.922 0 00-.936-.936.922.922 0 00-.936.936c0 .528.408.936.936.936z"
          fill="#888"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default CircledQuestionMark;
