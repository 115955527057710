import { Cookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';

const sessionCookieName = process.env.REACT_APP_CLIENT_FC_COOKIE_SESSION;
const expirationCookieName = process.env.REACT_APP_CLIENT_FC_COOKIE_EXPIRATION_NAME;
const flagCookieName = process.env.REACT_APP_CLIENT_FC_COOKIE_FLAG;
const cookieDomain = process.env.REACT_APP_CLIENT_FC_COOKIE_DOMAIN;
const cookieExpiration = process.env.REACT_APP_CLIENT_FC_COOKIE_EXPIRATION;

const cookies = new Cookies();

interface CookieFormat {
  authenticated: {
    authenticator: string;
    token: string;
    userId: string;
  };
}

class Cookie {
  static cookieFormat(userID: string, token: string): CookieFormat {
    return {
      authenticated: {
        authenticator: 'authenticator:jwt',
        token,
        userId: userID,
      },
    };
  }

  static options(): CookieSetOptions {
    if (!cookieDomain || cookieDomain === 'localhost') {
      return {
        secure: false,
      };
    }
    return {
      path: '/',
      expires: new Date(Date.now() + Number(cookieExpiration) * 1000),
      maxAge: Number(cookieExpiration),
      secure: true,
      httpOnly: false,
      domain: cookieDomain,
    };
  }

  static setSession(userID: string, token: string): void {
    cookies.set(sessionCookieName, this.cookieFormat(userID, token), this.options());
    cookies.set(expirationCookieName, cookieExpiration, this.options());
    cookies.set(flagCookieName, true, this.options());
  }

  static getCountry(): string {
    return cookies.get('country_code_play') ?? 'US';
  }

  static setCountry(value = 'US'): Promise<string> {
    cookies.remove('country_code_play', this.options());
    return new Promise(resolve => {
      cookies.set('country_code_play', value, this.options());
      resolve(value);
    });
  }

  static getUtms(): Record<any, string> {
    const utms = cookies.get('fc_utm');
    const fcUtms = {};
    if (utms) {
      utms.split(';').forEach((value: string) => {
        const tmp = value.split('=');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fcUtms[tmp[0]] = tmp[1];
      });
    }
    return fcUtms;
  }

  static hasTrackingPrefs(): boolean {
    return !!cookies.get('tracking-preferences');
  }

  static getClickRef(): string {
    return cookies.get('clickref');
  }

  static getAffiliateId(): string {
    return cookies.get('aff_id');
  }

  // methods to get Iterable ids for tracking purchases
  static getCampaignId(): number | null {
    const campaign = cookies.get('iterableEmailCampaignId');
    return campaign ? parseInt(campaign, 10) : null;
  }

  static getTemplateId(): number | null {
    const template = cookies.get('iterableTemplateId');
    return template ? parseInt(template, 10) : null;
  }

  static clear(): void {
    cookies.remove('country_code_play', this.options());
    cookies.remove(sessionCookieName, this.options());
    cookies.remove(expirationCookieName, this.options());
    cookies.remove(flagCookieName, this.options());
  }
}

export default Cookie;
