import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const ResponsiveContainer = styled.div`
  padding-left: ${({ theme: th }): string => th.spacing.sm3};
  padding-right: ${({ theme: th }): string => th.spacing.sm3};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ${up('tablet-portrait')} {
    padding-left: ${({ theme: th }): string => th.spacing.md1};
    padding-right: ${({ theme: th }): string => th.spacing.md1};
    max-width: 959px;
  }
  ${up('tablet-landscape')} {
    padding-left: ${({ theme: th }): string => th.spacing.md3};
    padding-right: ${({ theme: th }): string => th.spacing.md3};
    max-width: 1279px;
  }
  ${up('laptop')} {
    padding-left: ${({ theme: th }): string => th.spacing.md4};
    padding-right: ${({ theme: th }): string => th.spacing.md4};
    max-width: 1919px;
  }
  ${up('desktop')} {
    padding-left: ${({ theme: th }): string => th.spacing.lg3};
    padding-right: ${({ theme: th }): string => th.spacing.lg3};
    max-width: 1920px;
  }
`;

export default ResponsiveContainer;
