import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ImageLazyLoader from '@fenderdigital/utils/image-lazy-loader';
import { Block } from './BlockTypes';
import BlockHero from './BlockHero';
import BlockHtml from './BlockHtml';
import BlockImageText from './BlockImageText';
import BlockText from './BlockText';
import BlockList from './BlockList';
import BlockSection from './BlockSection';
import useIsHome from '../../hooks/useIsHome';

const BlocksList = styled.div`
  margin: 0;
  padding-left: 0;
  /*
    don't like using classes in styled components, but
    needed to target them from the markdown renderer.
  */
  .block-markdown {
    ul,
    ol {
      margin-left: 1rem;
      padding-left: 0.25rem;

      li {
        text-align: left;
      }
    }

    &.upper-left,
    &.middle-left,
    &.bottom-left {
      ul,
      ol {
        margin-left: 1rem;
        padding-left: 0.25rem;
      }
    }
  }
`;

interface BlocksProps {
  blocks?: Block[];
}

const Blocks: FC<BlocksProps> = ({ blocks }) => {
  const blockListRef = useRef<HTMLDivElement | null>(null);
  const isHome = useIsHome();

  useEffect(() => {
    if (blocks && blockListRef.current) {
      const imageLazyLoader = new ImageLazyLoader();
      imageLazyLoader.init();
    }
  }, [blocks, blockListRef]);

  return (
    <BlocksList ref={blockListRef} className="blocks">
      {blocks?.map(
        (block, index): React.ReactNode => {
          switch (block.type) {
            case 'block-section-break':
              return (
                <section key={`block-section-break-${block.headline}`}>
                  <BlockSection
                    headline={block.headline}
                    headlineStyles={block['headline-size']}
                    theme={block.theme}
                    containerStyles={block['container-styles']}
                    isFirstBlock={index === 0}
                  />
                </section>
              );
            case 'block-image-3up':
              return (
                <section key={`block-image-3up-${JSON.stringify(block.items).slice(-20) + index}`}>
                  <BlockList
                    columns="3"
                    items={block.items}
                    containerStyles={block['container-styles']}
                    isFirstBlock={index === 0}
                  />
                </section>
              );

            case 'block-image-2up':
              return (
                <section key={`block-image-2up-${JSON.stringify(block.items).slice(-20) + index}`}>
                  <BlockList
                    columns="2"
                    items={block.items}
                    containerStyles={block['container-styles']}
                    isFirstBlock={index === 0}
                  />
                </section>
              );
            case 'block-hero':
              return (
                <section key={`block-hero-${JSON.stringify(block).slice(-20) + index}`}>
                  <BlockHero
                    theme={block.theme}
                    contentPlacement={block['content-placement']}
                    headline={block.headline}
                    headlineStyles={block['headline-size']}
                    subHeadline={block['sub-headline']}
                    subHeadlineStyles={block['sub-headline-size']}
                    description={block.description}
                    cta1={block.cta1}
                    cta1Url={block['cta1-url']}
                    cta1Styles={block['cta1-styles']}
                    cta2={block.cta2}
                    cta2Url={block['cta2-url']}
                    cta2Styles={block['cta2-styles']}
                    image={block['background-image-url']}
                    logoImageUrl={block['logo-image-url']}
                    mobileImage={block['mobile-background-image-url']}
                    logoImage={block['logo-image-url']}
                    mobileLogoImage={block['mobile-logo-image-url']}
                    containerStyles={block['container-styles']}
                    isFirstBlock={index === 0}
                  />
                </section>
              );
            case 'block-html':
              /**
               * Hides the first & second block on the unsubscribed homepage.
               */
              if ((index === 0 || index === 1) && isHome) return null;
              return (
                <section key={`block-html-${JSON.stringify(block.body).slice(-20) + index}`}>
                  {block.body && (
                    <BlockHtml body={block.body} containerStyles={block?.['container-styles']} />
                  )}
                </section>
              );
            case 'block-image-text':
              return (
                <section key={`block-image-text-${block['image-url']}`}>
                  <BlockImageText
                    theme={block.theme}
                    imageAlign={block['image-align'] || 'right'}
                    headline={block.headline}
                    headlineStyles={block['headline-size']}
                    description={block.description}
                    cta1={block.cta1}
                    cta1Url={block['cta1-url']}
                    cta2={block.cta2}
                    cta2Url={block['cta2-url']}
                    imageUrl={block['image-url']}
                    mobileImageUrl={block['mobile-image-url']}
                    backgroundImageUrl={block['background-image-url']}
                    mobileBackgroundImageUrl={block['mobile-background-image-url']}
                    containerStyles={block['container-styles']}
                    isFirstBlock={index === 0}
                  />
                </section>
              );
            case 'block-text':
              return (
                <section key={`block-text-${JSON.stringify(block.body).slice(-20) + index}`}>
                  <BlockText
                    theme={block.theme}
                    headline={block.headline}
                    headlineStyles={block['headline-size']}
                    subHeadline={block['sub-headline']}
                    subHeadlineStyles={block['sub-headline-size']}
                    body={block.body}
                    cta1={block.cta1}
                    cta1Url={block['cta1-url']}
                    cta1Styles={block['cta1-styles']}
                    cta2={block.cta2}
                    cta2Url={block['cta2-url']}
                    cta2Styles={block['cta2-styles']}
                    containerStyles={block['container-styles']}
                    isFirstBlock={index === 0}
                  />
                </section>
              );
            default:
              return null;
          }
        },
      )}
    </BlocksList>
  );
};

export default Blocks;
