import { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const FeedbackGradient = css`
  background: linear-gradient(
    -45deg,
    rgba(97, 48, 144, 1) 0%,
    rgba(206, 72, 74, 1) 70%,
    rgba(220, 95, 53, 1) 100%
  );
  color: white;
  text-transform: uppercase;
  box-shadow: 0px 2px 4px 0px rgba(179, 179, 179, 0.21);
`;
