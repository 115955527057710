/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useRef, useState, useEffect, FC, FormEvent, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { ButtonBase as SearchButton } from '@fenderdigital/ui/Button';
import { VisuallyHidden } from '@fenderdigital/ui/utils/css';
import { defineMessages, InjectedIntl, InjectedIntlProps, injectIntl } from 'react-intl';
import usePlayUser from '@fenderdigital/custom-hooks/hooks/usePlayUser';
import { CoreInstrumentType as Instrument } from '@fenderdigital/api-definitions/play';
import useDebounce from '../../utils/useDebounce';
import { clearFilters, getFieldStyles } from '../../utils/search-utils';
import { useSearchSelection } from '../../contexts/SearchSelectionContext';
import { trackEvent, EventNames } from '../../lib/analytics';
import './SearchFieldStyles.css';
import { RootState } from '../../redux-types/root.redux-types';
import { Facets, FilterData, Hit, InstrumentGroup } from '../../models/search';

const messages = defineMessages({
  label: {
    id: 'searchField.label',
    defaultMessage: 'Search',
  },
  placeholder: {
    id: 'searchField.placeholder',
    defaultMessage: 'Find songs, skills and more...',
  },
});

const SearchButtonContainer = styled.div<{ pill: boolean; small: boolean }>`
  button {
    max-height: none;
    min-width: auto;
    height: 100%;
    padding: ${({ theme: { spacing } }): string => spacing.sm3};
    font-size: ${({ theme: { fontSizes } }): string => fontSizes.f6};

    ${({ pill, theme: { fontSizes, spacing } }) =>
      pill &&
      css`
        padding: ${spacing.sm2};
        position: absolute;
        top: 25%;
        left: ${spacing.sm3};
        height: 50%;
        font-size: ${fontSizes.f5};
      `}

    ${({ pill, small, theme: { fontSizes, spacing } }) =>
      !pill &&
      !small &&
      css`
        ${up('tablet-landscape')} {
          padding: ${spacing.md1};
          font-size: ${fontSizes.f7};
        }
      `}
  }
`;

const ScreenReaderLabel = styled.label`
  ${VisuallyHidden}
`;
export type LocalResult = {
  hits: Hit[];
  facets: Facets;
  query: string;
};

export interface SearchFieldProps {
  filters?: FilterData;
  instantSearch: (query: string, isLocalSearch: boolean, instrument: Instrument) => LocalResult;
  intl?: InjectedIntl;
  onFocus?: () => void;
  onSubmit?: () => void;
  pill?: boolean;
  query?: string;
  small?: boolean;
  updateFilters: (clearedFilters: FilterData) => void;
  hasLocalSearch?: boolean;
  onMountFocus?: boolean;
  instrument?: Instrument | InstrumentGroup;
  setLocalResults?: (localResult: LocalResult) => void;
  localResults?: LocalResult;
  localResultsToRedux: (localResults: LocalResult) => void;
}

const selector = (state: RootState): FilterData => state.search.filters;

const SearchField: FC<SearchFieldProps & InjectedIntlProps> = ({
  hasLocalSearch = false,
  onMountFocus,
  query: queryParam,
  intl,
  pill = false,
  small = false,
  instrument = 'guitar',
  localResults,
  onFocus,
  updateFilters,
  localResultsToRedux,
  onSubmit,
  instantSearch,
  setLocalResults,
}) => {
  const searchInput = useRef<HTMLInputElement | null>(null);
  const history = useHistory();
  const [isMounted, setIsMounted] = useState(false);
  const [query, setQuery] = useState(queryParam || '');
  const { selectedOptionId } = useSearchSelection();
  const debouncedQuery = useDebounce(query, 500);
  const state = useSelector(selector);
  const { instrument: reduxInstrument = '' } = state;
  const { inputStyle } = getFieldStyles(pill, small);
  const { formatMessage } = intl;
  const { user } = usePlayUser();
  const isPanel = pill || small;

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setQuery(value);
    if (onFocus) onFocus();
  };

  const handleSearch = async (isLocalSearch = false): Promise<void> => {
    const results = await instantSearch(query, isLocalSearch, instrument);

    if (isLocalSearch && results && setLocalResults) {
      setLocalResults(results);
    } else {
      history.push(`/search?query=${query}`);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const { query: localQuery } = localResults || {};
    const isQueryInLocalState = localQuery === query;
    const params = new URLSearchParams(document.location.search);
    const urlParams = params.get('query');
    const trackingProperties = {
      fc_id: user?.userID,
      search_text: query,
    };

    trackEvent(EventNames.SEARCH_COMPLETED, trackingProperties);

    if (urlParams === query && reduxInstrument === instrument) return;
    const clearedFilters = clearFilters({ instrument: instrument || reduxInstrument });
    updateFilters && updateFilters(clearedFilters);
    if (isQueryInLocalState && localResultsToRedux && localResults) {
      localResultsToRedux(localResults);
      history.push(`/search?query=${query}`);
    } else {
      handleSearch();
    }
    onSubmit && onSubmit();
  };

  const getAriaExpandedStatus = () => {
    if (pill) {
      if (query) {
        return 'true';
      }
      return 'false';
    }
    return undefined;
  };

  useEffect(() => {
    if (isMounted) handleSearch(hasLocalSearch);
    setIsMounted(true);
  }, [hasLocalSearch, debouncedQuery]);

  useEffect(() => {
    const searchRefCurrent = searchInput.current as HTMLInputElement;
    if (onMountFocus) searchRefCurrent.focus();

    if (onFocus) searchRefCurrent.addEventListener('focus', onFocus);
    return (): void => {
      if (onFocus) searchRefCurrent.removeEventListener('focus', onFocus);
    };
  }, [onMountFocus, searchInput, onFocus]);

  return (
    <form
      className="search-field br2 flex justify-between items-stretch"
      data-id="search-field"
      method="POST"
      onSubmit={handleSubmit}
    >
      <ScreenReaderLabel
        htmlFor={`${isPanel ? 'nav' : 'page'}-search-input`}
        id={`${isPanel ? 'nav' : 'page'}-search-label`}
      >
        {formatMessage(messages.label)}
      </ScreenReaderLabel>
      <input
        ref={searchInput}
        className={inputStyle}
        onChange={handleChange}
        value={query}
        placeholder={formatMessage(messages.placeholder)}
        type="text"
        id={`${isPanel ? 'nav' : 'page'}-search-input`}
        aria-autocomplete="list"
        aria-controls={`${isPanel ? 'nav' : 'page'}-search-results`}
        aria-haspopup={isPanel ? 'listbox' : undefined}
        aria-expanded={getAriaExpandedStatus()}
        aria-describedby={isPanel ? 'search-instructions' : undefined}
        aria-activedescendant={selectedOptionId || ''}
      />
      <SearchButtonContainer small={small} pill={pill}>
        <SearchButton
          type="submit"
          aria-label="Search"
          colorScheme={pill ? 'transparent' : 'red'}
          bgAlpha={pill ? 0 : 100}
          hoverColor={pill ? 'transparent' : 'red'}
          textColor={pill ? 'silver' : 'white'}
        >
          <span className={`icon-search ${!pill ? 'white mh1' : ''}`} aria-hidden />
        </SearchButton>
      </SearchButtonContainer>
    </form>
  );
};

export default injectIntl(SearchField);
