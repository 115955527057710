import React, { FC } from 'react';
import CurriculumLarge from './CurriculumLarge';
import CurriculumSmall from './CurriculumSmall';
import { CurriculumTableProps } from './types';

const CurriculumTable: FC<CurriculumTableProps> = ({
  content = [],
  display = {
    chords: true,
    level: true,
  },
  small = false,
  isArtist = false,
  isFullSearchView = false,
  tableId,
}) => {
  const smallLayout = (
    <CurriculumSmall
      content={content}
      display={display}
      isArtist={isArtist}
      isFullSearchView={isFullSearchView}
      tableId={tableId}
    />
  );

  if (small) return smallLayout;
  return (
    <div data-id="curriculum-table">
      <div className="dn db-l pb2">
        <CurriculumLarge content={content} display={display} isFullSearchView={isFullSearchView} />
      </div>
      <div className="db dn-l">{smallLayout}</div>
    </div>
  );
};

export default CurriculumTable;
