import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import isDomUsable from '@fenderdigital/utils/dom';
import useHorizontalClickAndDrag from '../../hooks/useHorizontalClickAndDrag';

const Root = styled.div`
  width: 100%;
  padding-bottom: 16px;
`;

interface ContainerType {
  isWindows: boolean;
}

const Container = styled.div<ContainerType>`
  overflow-x: ${({ isWindows = false }): string => (isWindows ? 'auto' : 'hidden')};
  overflow-y: ${({ isWindows = false }): string => (isWindows ? 'visible' : 'hidden')};
  height: auto;
`;

const ScrollContainer = styled.div`
  overflow: auto;
  width: 100%;
`;

interface ScrollerProps {
  isScrollerEnabled?: boolean;
  contentClass?: string;
  enableMaxWidthClass?: boolean;
}

const Scroller: FC<ScrollerProps> = ({
  isScrollerEnabled = true,
  children,
  contentClass = 'content-start',
  enableMaxWidthClass = true,
}) => {
  const [isWindows, setIsWindows] = useState(false);
  const [scrollBuffer, setScrollBuffer] = useState(16);
  const scrollWrapperRef = useHorizontalClickAndDrag() as React.MutableRefObject<HTMLDivElement>;

  const canUseDOM = isDomUsable();
  const userAgent = canUseDOM ? window.navigator.userAgent : 'NO DOM';

  useEffect(() => {
    if (canUseDOM && userAgent.indexOf('Windows') > -1) {
      setIsWindows(true);
      setScrollBuffer(0);
    }
  }, [canUseDOM, userAgent]);

  if (!isScrollerEnabled) {
    return <div className="scroller-disabled">{children}</div>;
  }

  return (
    <Root className="scroller">
      {children ? (
        <Container isWindows={isWindows}>
          <ScrollContainer style={{ marginBottom: `-${scrollBuffer}px` }} ref={scrollWrapperRef}>
            <div
              className={`scroller-container flex ${contentClass} ${
                enableMaxWidthClass ? 'mw9 center' : ''
              }`}
              data-id="scroller-container"
            >
              {children}
            </div>
          </ScrollContainer>
        </Container>
      ) : null}
    </Root>
  );
};

export default Scroller;
