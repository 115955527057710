/* eslint-disable no-underscore-dangle */
import videojs from 'video.js';
import backward10s from '../../../../assets/player-icons/icon-back10s.svg';
import forward10s from '../../../../assets/player-icons/icon-forward10s.svg';
import { SeekButtonOptions, SeekMenuButtonOptions } from './seek-buttons.types';

const Button = videojs.getComponent('Button');

export interface CustomControlBar extends videojs.ControlBar {
  seekForward: videojs.Component;
  seekBack: videojs.Component;
}

const defaults = {
  forwardIndex: 1,
  backIndex: 1,
};

const onPlayerReady = (player: videojs.Player, options: SeekButtonOptions): void => {
  player.addClass('vjs-seek-buttons');

  const controlBar = player.controlBar as CustomControlBar;

  if (options.forward && options.forward > 0) {
    controlBar.seekForward = controlBar.addChild(
      'SeekButton',
      {
        direction: 'forward',
        seconds: options.forward,
        onClick: options.onSeekForward,
      },
      options.forwardIndex,
    );
  }

  if (options.back && options.back > 0) {
    controlBar.seekBack = controlBar.addChild(
      'SeekButton',
      {
        direction: 'back',
        seconds: options.back,
        onClick: options.onSeekBack,
      },
      options.backIndex,
    );
  }
};

function seekButtons(options: SeekButtonOptions): void {
  // @ts-expect-error: An outer value of 'this' is shadowed by this container.
  this.ready(() => {
    // @ts-expect-error: An outer value of 'this' is shadowed by this container.
    onPlayerReady(this, videojs.mergeOptions(defaults, options));
  });
}

const getIconImage = (direction: 'forward' | 'backward'): HTMLImageElement => {
  const img = document.createElement('img');
  img.src = direction === 'forward' ? forward10s : backward10s;
  img.alt = '';
  return img;
};

// https://videojs.com/guides/components/#creating-a-component
class SeekButton extends Button {
  constructor(player: videojs.Player, options: SeekMenuButtonOptions) {
    super(player, options as videojs.ComponentOptions);
    const { direction, seconds } = this.options_ as SeekMenuButtonOptions;

    if (direction === 'forward') {
      const icon = getIconImage('forward');
      this.el().appendChild(icon);
      this.controlText(
        this.localize('Seek forward {{seconds}} seconds').replace(
          '{{seconds}}',
          seconds.toString(),
        ),
      );
    } else if (direction === 'back') {
      const icon = getIconImage('backward');
      this.el().appendChild(icon);
      this.controlText(
        this.localize('Seek back {{seconds}} seconds').replace('{{seconds}}', seconds.toString()),
      );
    }
  }

  // Add class names, e.g. .vjs-seek-button .skip-forward .skip-10 .vjs-control .vjs-button
  buildCSSClass(): string {
    const { direction, seconds } = this.options_ as SeekMenuButtonOptions;
    return `vjs-seek-button skip-${direction} ` + `skip-${seconds} ${super.buildCSSClass()}`;
  }

  handleClick(): void {
    const { direction, seconds, onClick } = this.options_ as SeekMenuButtonOptions;
    const now = this.player_.currentTime();

    if (direction === 'forward') {
      let duration = this.player_.duration();

      // If live video, get current length
      if (this.player_.liveTracker && this.player_.liveTracker.isLive()) {
        duration = this.player_.liveTracker.seekableEnd();
      }

      const newTime = Math.min(now + seconds, duration);

      if (onClick) {
        onClick(now, newTime);
      }

      this.player_.currentTime(newTime);
    } else if (direction === 'back') {
      const newTime = Math.max(0, now - seconds);

      if (onClick) {
        onClick(now, newTime);
      }

      this.player_.currentTime(newTime);
    }
  }
}

videojs.registerComponent('SeekButton', SeekButton);
videojs.registerPlugin('seekButtons', seekButtons);

export default seekButtons;
