import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import { courses as defaultState } from './default.json';
import { actionTypes } from '../actions/courses';
import { getLesson } from '../lib/finder';

const handlers = {
  [actionTypes.COURSES_GET_SUCCESS]: (state, action) => {
    const { items } = action.results;
    const types = groupBy(items, 'type');
    const slugs = keyBy(items, 'slug');
    return {
      ...state,
      inProgress: false,
      results: {
        ...items,
      },
      slugs,
      types,
      meta: action.results.meta,
    };
  },
  [actionTypes.COURSES_GET_ERROR]: (state, action) => ({
    ...state,
    inProgress: false,
    error: action.error,
  }),
  [actionTypes.COURSES_GET_IN_PROGRESS]: state => ({
    ...state,
    inProgress: true,
  }),
  [actionTypes.COURSES_GET_BY_ARTIST_SUCCESS]: (state, action) => ({
    ...state,
    inProgress: false,
    results: {
      ...action.results.items,
    },
    meta: action.results.meta,
  }),
  [actionTypes.COURSES_GET_BY_ARTIST_ERROR]: (state, action) => ({
    ...state,
    inProgress: false,
    error: action.error,
  }),
  [actionTypes.COURSES_GET_BY_ARTIST_IN_PROGRESS]: state => ({
    ...state,
    inProgress: true,
  }),
  [actionTypes.NEXT_LESSON_IN_COURSE]: (state, action) => {
    const { currentLessonSlug } = action;
    const { currentLesson, nextLesson } = getLesson(
      state.watching.currentCourse,
      currentLessonSlug,
    );
    return {
      ...state,
      watching: {
        ...state.watching,
        currentLesson,
        nextLesson,
      },
    };
  },
  [actionTypes.CLEAR_WATCHING]: state => ({
    ...state,
    watching: {
      ...defaultState.watching,
    },
  }),
};

export default (state = defaultState, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};
