import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet-async';
import { v4 as uuid } from 'uuid';
import config from '../../config';

const parseCanonicalURL = (canonicalURL, metaURL, baseURL) => {
  if (canonicalURL && canonicalURL.indexOf('https://') > -1) {
    return canonicalURL;
  }
  return canonicalURL ? `${baseURL}/play/${canonicalURL}` : metaURL;
};

class Head extends PureComponent {
  static generateMetaData(
    title,
    description,
    metaURL,
    canonicalURL,
    schema,
    socialTitle,
    socialDescription,
    socialImage,
  ) {
    const baseURL = `${process.env.REACT_APP_CLIENT_FENDER_COM}`;
    const parsedURL = `${baseURL}/play${metaURL === 'home' || metaURL === '' ? '' : `/${metaURL}`}`;
    const parsedCanonicalURL = parseCanonicalURL(canonicalURL, parsedURL, baseURL);
    return {
      title,
      link: [{ rel: 'canonical', href: parsedCanonicalURL }],
      meta: [
        { name: 'title', content: title },
        { name: 'url', content: `${parsedURL}` },
        { name: 'fb:app_id', content: config.facebookAppID },
        { name: 'og:type', content: 'website' },
        { name: 'og:title', content: socialTitle || title },
        { name: 'description', content: description },
        { name: 'og:description', content: socialDescription || description },
        { name: 'og:url', content: `${parsedURL}` },
        { name: 'og:image', content: socialImage || config.ogImage },
        { name: 'og:image:url', content: socialImage || config.ogImage },
        { name: 'og:image:secure_url', content: socialImage || config.ogImage },
        { name: 'og:image:type', content: 'image/jpeg' },
        { property: 'twitter:card', content: 'summary' },
        { property: 'twitter:site:id', content: config.twitterSiteID },
        { property: 'twitter:site', content: '@Fender' },
        { property: 'twitter:title', content: socialTitle || title },
        { property: 'twitter:description', content: socialDescription || description },
      ],
      schema,
    };
  }

  render() {
    const { title, meta, link, script, schema } = this.props;
    return (
      <Helmet>
        <title>{title}</title>
        {meta.map(m => (
          <meta key={uuid()} {...m} />
        ))}
        {link.map(l => (
          <link key={uuid()} {...l} />
        ))}
        {script.map(s => (
          <script key={uuid()} type={s.type}>
            {s.script}
          </script>
        ))}
        {schema ? <script type="application/ld+json">{`${JSON.stringify(schema)}`}</script> : null}
        <script
          src="https://media.evolv.ai/asset-manager/releases/latest/webloader.min.js"
          data-evolv-environment={process.env.REACT_APP_CLIENT_EVOLV_ENVIRONMENT_ID}
          data-evolv-pushstate="true"
          data-evolv-use-cookies=".fender.com"
        ></script>
      </Helmet>
    );
  }
}

Head.propTypes = {
  title: PropTypes.string,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.string,
      media: PropTypes.string,
      id: PropTypes.string,
      className: PropTypes.string,
      itemprop: PropTypes.string,
    }),
  ),
  link: PropTypes.arrayOf(
    PropTypes.shape({
      rel: PropTypes.string,
      href: PropTypes.string,
    }),
  ),
  script: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      src: PropTypes.string,
      script: PropTypes.string,
    }),
  ),
  schema: PropTypes.shape({}),
};

Head.defaultProps = {
  meta: [],
  link: [],
  script: [],
  schema: null,
  title: 'Fender Play Guitar Lessons - Learn How to Play Guitar in 7 Min a Day',
};

export default Head;
