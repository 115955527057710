import { ApiConfig as UserApiConfig } from '@fenderdigital/custom-hooks/lib/apiConfig';

const userApiConfig: UserApiConfig = {
  clientName: `${process.env.REACT_APP_CLIENT_SITE_ID ?? 'play'}`,
  countryCodeCookieName: 'country_code_play',
  isFreemium: process.env.REACT_APP_FF_isFreemium === 'true',
  playApiUrl: `${process.env.REACT_APP_CLIENT_LIBRARY_API}`,
  profileApiUrl: `${process.env.REACT_APP_CLIENT_FC_PROFILE_API}`,
  subscriptionsApiUrl: `${process.env.REACT_APP_CLIENT_FC_SUBSCRIPTIONS_API}`,
};

export default userApiConfig;
