import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: ${({ theme }): string => theme.colors.lightGray};
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;

  &:first-of-type {
    padding-top: 0px;
  }
`;
