import { useState, useRef, useEffect, MutableRefObject } from 'react';

const useHorizontalClickAndDrag = (): MutableRefObject<HTMLDivElement | undefined | null> => {
  const [clickStartX, setClickStartX] = useState<number | null>();
  const [scrollStartX, setScrollStartX] = useState<number | undefined | null>();
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isMouseMoving, setIsMouseMoving] = useState(false);
  const scrollWrapperRef = useRef<HTMLDivElement | undefined>();

  useEffect(() => {
    if (scrollWrapperRef.current) {
      const handleDragStart = (e: MouseEvent): void => {
        setIsMouseDown(true);
        e.preventDefault();
        e.stopPropagation();
        setClickStartX(e.screenX);
        setScrollStartX(scrollWrapperRef?.current?.scrollLeft);
      };

      const handleDragMove = (e: MouseEvent): void => {
        if (isMouseDown) {
          e.preventDefault();
          setIsMouseMoving(true);
          if (
            clickStartX &&
            clickStartX > -1 &&
            typeof scrollStartX === 'number' &&
            scrollStartX > -1
          ) {
            const touchDelta = clickStartX - e.screenX;
            if (scrollWrapperRef?.current) {
              scrollWrapperRef.current.scrollLeft = scrollStartX + touchDelta;
            }
          }
        }
      };

      const handleDragEnd = (): void => {
        if (clickStartX && clickStartX > -1) {
          setClickStartX(null);
          setScrollStartX(null);
        }
      };

      const handleClick = (e: MouseEvent): void => {
        // prevents from registering a click when dragging components
        if (isMouseDown && isMouseMoving) e.preventDefault();
        setIsMouseDown(false);
        setIsMouseMoving(false);
      };

      // check to see if ontouch events exist before initializing custom mouse events.
      if (!scrollWrapperRef?.current?.ontouchstart) {
        scrollWrapperRef.current.onclick = handleClick;
        scrollWrapperRef.current.onmousedown = handleDragStart;
        scrollWrapperRef.current.onmousemove = handleDragMove;
        scrollWrapperRef.current.onmouseup = handleDragEnd;
        scrollWrapperRef.current.onmouseleave = handleDragEnd;
      }
    }
  });

  return scrollWrapperRef;
};

export default useHorizontalClickAndDrag;
