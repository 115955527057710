import merge from 'lodash/merge';
import fetch from '../core/fetch';
import { parseResponse } from '../core/fetch/response';
import AuthorizationHeader from './authorization-header';

function parseLessonType(typeStr) {
  switch (typeStr) {
    case 'chords':
      return 'chord';
    case 'exercises':
      return 'exercise';
    case 'techniques':
      return 'technique';
    default:
      return typeStr;
  }
}

function parseOptions(options, unseenPath) {
  const startingOptions = {
    offset: 0,
    limit: 20,
    sortField: 'start-at',
    sortOrder: 'asc',
  };
  if (unseenPath) {
    startingOptions.unseen = true;
    startingOptions.pathId = unseenPath;
    startingOptions.unseenLimit = options.limit || startingOptions.limit;
  }
  if (options.type) {
    options.type = parseLessonType(options.type); // eslint-disable-line no-param-reassign
  }
  if (options.ids && options.ids.length) {
    options.ids = options.ids.toString(); // eslint-disable-line no-param-reassign
  }
  if (options['course-style']) {
    options['course-style'] = options['course-style'].toLowerCase(); // eslint-disable-line no-param-reassign
  }
  const parsedOptions = merge({}, startingOptions, options);
  const params = Object.keys(parsedOptions)
    .map(key => `${key}=${parsedOptions[key]}`)
    .join('&');
  return params;
}

function parsePublicPath(endpointStr) {
  return `/public-${endpointStr}`;
}

export default class Library {
  static async request(url, opts = {}) {
    const headers = await AuthorizationHeader.getCognitoHeader();
    const response = await fetch(url, {
      method: 'GET',
      ...opts,
      headers: {
        ...headers.headers,
        ...opts.headers,
      },
    });
    return parseResponse(response);
  }

  static getPathForSlug(slug) {
    return `${process.env.REACT_APP_CLIENT_LIBRARY_API}/paths?slug=${slug}`;
  }

  static async getPathBySlug(slug) {
    const response = await Library.request(Library.getPathForSlug(slug));
    if (response.length === 0) {
      throw new Error('Return empty list of paths');
    }
    return response.items[0];
  }

  static async getPathList(tiny = false) {
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}/paths?skinny=true&tiny=${tiny}`;
    return Library.request(url);
  }

  static async getPathOnboarding() {
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}/paths?onboarding=true`;
    return Library.request(url);
  }

  static async getPathByID(id) {
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}/paths/${id}`;
    return Library.request(url);
  }

  static async getCourses(options, unseenPath = false) {
    const parsedOptions = parseOptions(options, unseenPath);
    const endpointStr = parsePublicPath('courses');
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}${endpointStr}?${parsedOptions}`;
    return Library.request(url);
  }

  static formatCoursesByType(coursesObj) {
    const typeList = [
      { label: 'Song', value: 'song' },
      { label: 'Riff', value: 'riff' },
    ];
    const courses = {};
    typeList.forEach((type, key) => {
      courses[type.value] = coursesObj[key];
    });
    return courses;
  }

  static async getCoursesByArtist(options) {
    const parsedOptions = parseOptions(options);
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}/courses/artists?${parsedOptions}`;
    const response = await Library.request(url);
    if (response.length === 0) {
      throw new Error('Return empty list of courses');
    }
    return response;
  }

  static async getLessons(options, publicEndpoint = false, unseenPath = false) {
    const parsedOptions = parseOptions(options, unseenPath);
    const endpointStr = parsePublicPath('lessons');
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}${endpointStr}?${parsedOptions}`;
    return Library.request(url);
  }

  static async getSongByID(id) {
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}/songs/${id}`;
    return Library.request(url);
  }

  static getAllCollections(instrument = 'guitar', offset = 0, limit = 20) {
    const endpoint = parsePublicPath('collections');
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}${endpoint}?instrument=${instrument}&limit=${limit}&offset=${offset}`;
    return Library.request(url);
  }

  static async getCollectionsEditorial(instrument = 'guitar') {
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}/collections-lists?instrument=${instrument}`;
    return Library.request(url);
  }

  static getCollectionById(id) {
    const endpoint = parsePublicPath('collections');
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}${endpoint}?ids=${id}`;
    return Library.request(url);
  }

  static getCollectionBySlug(slug, instrument) {
    const endpoint = parsePublicPath('collections');
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}${endpoint}?slug=${slug}&instrument=${instrument}`;
    return Library.request(url);
  }
}
