import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { Focus } from '@fenderdigital/ui/utils/css';

interface ContainerProps {
  desktopImage?: string;
  mobileImage?: string;
}

interface ProgressBarContainerProps {
  isSongWBackground?: boolean;
}

const getGradient = (image: string) => {
  return css`
    background: url("${image}");
  `;
};

export const Container = styled.article<ContainerProps>`
  display: flex
  flex-direction: column;
  width: 100%;
  max-width: 344px;
  border-radius: 10px;
  background-color: white;
  margin: 2rem auto 0;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
  }

  ${({ desktopImage, mobileImage }) =>
    desktopImage || mobileImage
      ? css`
          color: white;
        `
      : ''}

  ${({ mobileImage }) => (mobileImage ? getGradient(mobileImage) : '')}

  ${up('tablet-portrait')} {
    max-width: 1436px;
    ${({ desktopImage }) => (desktopImage ? getGradient(desktopImage) : '')}
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${up('tablet-portrait')} {
    padding: 32px;
    flex-direction: row;
    max-height: 270px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;

  ${up('tablet-portrait')} {
    max-width: 368px;
  }
`;

export const Title = styled.h2<ProgressBarContainerProps>`
  margin-bottom: 0px;
  > a {
    ${Focus}
    font-size: ${({ theme }): string => theme.fontSizes.f6};
    font-family: ${({ theme }): string => theme.fonts.futuraDemi};
    color: ${({ theme: { colors }, isSongWBackground }): string =>
      isSongWBackground ? colors.white : colors.black};


    ${up('tablet-landscape')} {
      font-size: ${({ theme }): string => theme.fontSizes.f8};
    }
  }
  > a:hover, a:focus {
    color: inherit;
  }
`;

export const MetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  justify-content: center;

  ${up('tablet-portrait')} {
    padding: 0 0 0 2rem;
  }

  ${up('laptop')} {
    justify-content: start;
  }

  .fade-white {
    color: rgba(255, 255, 255, 0.3);
  }
`;

export const CardMenuContainer = styled.div`
  max-height: 36px;
  min-height: 20px;
`;

export const Dot = styled.span`
  margin: 0 6px;
`;

export const ProgressBarContainer = styled.div<ProgressBarContainerProps>`
  width: 100%;

  ${up('tablet-portrait')} {
    max-width: 300px;
  }

  > div {
    background-color: ${({ isSongWBackground, theme }): string =>
      isSongWBackground ? 'rgba(255, 255, 255, 0.3)' : theme.colors.lightGray};
  }
`;
