import { Focus } from '@fenderdigital/ui/utils/css';
import styled, { css, SimpleInterpolation } from 'styled-components';
import { up } from 'styled-breakpoints';
import { BreakpointKeys } from '@fenderdigital/ui/system';

interface MobileContainerStyleProps {
  breakpoint?: BreakpointKeys;
}

export const MobileContainer = styled.div<MobileContainerStyleProps>`
  width: 100%;
  height: 1px;

  /* TODO Update OffCanvas component to accept custom height and width in order for
  focus trap to work properly */
  div:nth-child(1) {
    width: 100% !important;
  }

  div:nth-child(2) {
    width: 100%;
  }

  ${({ breakpoint }) => up(`${breakpoint}`)} {
    display: none;
  }
`;

export const MobileMenuContainer = styled.div`
  z-index: 999;
  width: 100%;
  height: 100vh;
  display: block;
  background-color: ${({ theme }): string => theme.colors.white};
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
`;

export const MobileMenuContent = styled.div`
  overflow: auto;
  height: 100%;
  padding-bottom: 6.25rem;
  -webkit-overflow-scrolling: touch;
  font-family: ${({ theme: { fonts } }): string => fonts.futuraHeavy};

  a,
  button {
    ${Focus}
  }
`;

interface MobileMenuListItemStyleProps {
  isCurrent?: boolean;
  primaryColor?: string;
}

export const MobileMenuListItem = styled.li<MobileMenuListItemStyleProps>`
  ${({ isCurrent, primaryColor }): SimpleInterpolation =>
    isCurrent &&
    css`
      border-left: 0.25rem solid ${primaryColor};
    `}
  button {
    font-family: ${({ theme: { fonts } }): string => fonts.futuraHeavy};
  }

  a,
  button {
    display: block;
    position: relative;
    width: 100%;
    color: ${({ isCurrent, primaryColor, theme: { colors } }): string =>
      isCurrent ? primaryColor : colors.darkGray};
    text-transform: uppercase;
    text-align: left;
    font-size: ${({ theme: { fontSizes } }): string => fontSizes.f2};
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const AccordionListItem = styled.li`
  div > h3 > button {
    font-family: ${({ theme: { fonts } }): string => fonts.futuraHeavy};
    border-bottom: 1px solid ${({ theme }): string => theme.colors.lightGray};

    &:focus,
    &:active {
      box-shadow: inset rgb(185, 205, 241) 0px 0px 0px 3px;
    }
  }
`;
