import mirror from 'keymirror';
import Search from '../lib/search';
import { parseFiltersToFacets, clearFilters, parseSectionsResults } from '../utils/search-utils';

export const actionTypes = mirror({
  SEARCH_GET_ERROR: null,
  SEARCH_GET_IN_PROGRESS: null,
  SEARCH_GET_SUCCESS: null,
  SEARCH_INSTANT_GET_ERROR: null,
  SEARCH_INSTANT_GET_IN_PROGRESS: null,
  SEARCH_INSTANT_GET_SUCCESS: null,
  SEARCH_UPDATE_FILTER_SUCCESS: null,
});

const searchGetError = error => ({
  error,
  type: actionTypes.SEARCH_GET_ERROR,
});

const searchGetInProgress = () => ({
  type: actionTypes.SEARCH_GET_IN_PROGRESS,
});

const searchGetSuccess = results => ({
  results,
  type: actionTypes.SEARCH_GET_SUCCESS,
});

const searchInstantGetError = error => ({
  error,
  type: actionTypes.SEARCH_INSTANT_ERROR,
});

const searchInstantGetInProgress = () => ({
  type: actionTypes.SEARCH_INSTANT_GET_IN_PROGRESS,
});

const searchInstantGetSuccess = results => ({
  results,
  type: actionTypes.SEARCH_INSTANT_GET_SUCCESS,
});

const searchUpdateFilterSuccess = filters => ({
  filters,
  type: actionTypes.SEARCH_UPDATE_FILTER_SUCCESS,
});

export const fullSearch = (query, page = 0, filters) => async (dispatch, getState) => {
  dispatch(searchGetInProgress());
  try {
    const state = getState();
    const filterObj = filters || state.search.filters;
    const params = { ...state.search.params, page };
    const searchResults = await Search.doSearch(query || '', params, filterObj);
    dispatch(searchGetSuccess(searchResults));
  } catch (err) {
    dispatch(searchGetError(err));
  }
};

export const instantSearch = (query, returnToComponent = false, instrumentParam) => async (
  dispatch,
  getState,
) => {
  if (!returnToComponent) dispatch(searchInstantGetInProgress());
  try {
    const state = getState();
    const rawSectionsResults = await Search.sectionsSearch(query, instrumentParam, state);
    const sectionsResults = parseSectionsResults(rawSectionsResults);
    return returnToComponent ? sectionsResults : dispatch(searchInstantGetSuccess(sectionsResults));
  } catch (err) {
    dispatch(searchInstantGetError(err));
  }
};

export const localResultsToRedux = localResults => dispatch => {
  dispatch(searchInstantGetSuccess(localResults));
};

export const updateFilters = filterObj => async (dispatch, getState) => {
  try {
    const state = getState();
    const currentFilters = state.search.filters;
    const currentInstrumentFilter = currentFilters.instrument;
    const newInstrumentFilter = filterObj.instrument || currentInstrumentFilter;
    const { style, type } = currentFilters;
    const existingFilters = style || type || currentFilters['artists.name'];
    const filters = { ...currentFilters, ...filterObj };
    dispatch(searchUpdateFilterSuccess(filters));
  } catch (err) {
    dispatch(searchGetError(err));
  }
};
