import { Navigation } from './NavTypes';

const CollectionLinks: { [key: string]: Navigation } = {
  guitar: {
    id: 'guitar-collections',
    items: [
      {
        id: 'guitar-collections-features',
        items: null,
        label: 'Featured',
        type: 'text',
        url: '/guitar/collections/',
        'class-names': 'blue source-sans-b',
      },
      {
        id: 'guitar-artist-spotlight-george-harrison',
        items: null,
        label: 'George Harrison',
        'label-prefix': 'New!',
        type: 'text',
        url: '/guitar/collections/artist-spotlight-george-harrison',
      },
      {
        id: 'guitar-artist-spotlight-albert-hammond-jr',
        items: null,
        label: 'Albert Hammond Jr (The Strokes)',
        type: 'text',
        url: '/guitar/collections/artist-spotlight-albert-hammond-jr',
      },
      {
        id: 'guitar-mastering-g-c-d-chords',
        items: null,
        label: 'Mastering G-C-D chords',
        type: 'text',
        url: '/guitar/collections/mastering-g-c-d-chords',
      },
      {
        id: 'guitar-alternate-tuning',
        items: null,
        label: 'Alternate Tuning',
        type: 'text',
        url: '/guitar/collections/alternate-tuning',
      },
      {
        id: 'guitar-essential-scales',
        items: null,
        label: 'Scales You Should Know',
        'label-prefix': 'Essential:',
        type: 'text',
        url: '/guitar/collections/scales-you-should-know',
      },
    ],
    label: 'Collections',
    type: 'text',
  },
  bass: {
    id: 'bass-collections',
    items: [
      {
        id: 'bass-collections-features',
        items: null,
        label: 'Featured',
        type: 'text',
        url: '/bass/collections/',
        'class-names': 'blue source-sans-b',
      },
      {
        id: 'bass-slap-bass',
        items: null,
        label: 'Slap Bass',
        'label-prefix': 'New!',
        type: 'text',
        url: '/bass/collections/slapbass',
      },
      {
        id: 'bass-scales-you-should-know',
        items: null,
        label: 'Scales You Should Know',
        'label-prefix': 'Popular!',
        type: 'text',
        url: '/bass/collections/bass-scales-you-should-know',
      },
      {
        id: 'bass-essential-get-to-know-the-fretboard',
        items: null,
        label: 'Get to Know the Fretboard',
        'label-prefix': 'Essential:',
        type: 'text',
        url: '/bass/collections/get-to-know-the-fretboard',
      },
      {
        id: 'bass-challenging-3-pick-songs',
        items: null,
        label: 'Challenging 3-Pick Songs',
        type: 'text',
        url: '/bass/collections/challenge-3-pick-songs',
      },
      {
        id: 'bass-got-5-minutes-song-and-riffs',
        items: null,
        label: 'Got 5 minutes? Songs & Riffs',
        type: 'text',
        url: '/bass/collections/got-5-minutes-songs-and-riffs-bass',
      },
    ],
    label: 'Collections',
    type: 'text',
  },
  ukulele: {
    id: 'ukulele-collections',
    items: [
      {
        id: 'ukulele-collections-features',
        items: null,
        label: 'Featured',
        type: 'text',
        url: '/ukulele/collections/',
        'class-names': 'blue source-sans-b',
      },
      {
        id: 'ukulele-billie-eilish-uke-collection',
        items: null,
        label: 'Billie Eilish',
        'label-prefix': 'New!',
        type: 'text',
        url: '/ukulele/collections/billie-eilish-uke-collection',
      },
      {
        id: 'ukulele-easy-1-pick-songs-ukulele',
        items: null,
        label: 'Easy 1-Pick Songs',
        type: 'text',
        url: '/ukulele/collections/easy-1-pick-songs-ukulele',
      },
      {
        id: 'ukulele-most-popular-uke-riffs',
        items: null,
        label: 'Most Popular Uke Riffs',
        'label-prefix': 'New!',
        type: 'text',
        url: '/ukulele/collections/most-popular-uke-riffs',
      },
      {
        id: 'ukulele-intro-to-fingerpicking',
        items: null,
        label: 'Intro to Fingerpicking',
        'label-prefix': 'Popular!',
        type: 'text',
        url: '/ukulele/collections/intro-to-fingerpicking-ukulele',
      },
      {
        id: 'ukulele-raise-the-barre-ukulele',
        items: null,
        label: 'Raise The Barre',
        type: 'text',
        url: '/ukulele/collections/raise-the-barre-ukulele',
      },
    ],
    label: 'Collections',
    type: 'text',
  },
};

export default CollectionLinks;
