import React, { useState, Suspense, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import BodyText from '@fenderdigital/ui/BodyText';
import { ImageBreakpointMap, generateImageBreakpointCSS } from '@fenderdigital/utils/image-css';
import { Focus } from '@fenderdigital/ui/utils/css';
import Spinner from '@fenderdigital/ui/Spinner';
import { useEscapeKey, useFocusTrap } from '@fenderdigital/custom-hooks';
import usePlayUser from '@fenderdigital/custom-hooks/hooks/usePlayUser';
import SwipeCarousel from '../SwipeCarousel';
import feedbackModeCarouselData from './CarouselData';
import FeedbackModalContent from './ModalContent';
import { ModalBackground } from '../../contexts/Modal-styled';
import { EventNames, trackEvent, trackPageEvents } from '../../lib/analytics';
import useFeedbackModeModal from './useFeedbackModal';

const SlideOneArt: ImageBreakpointMap = {
  'small-phone': 'https://stuff.fendergarage.com/images/y/I/S/woman-bg-gradient-phone.jpg',
  phone: 'https://stuff.fendergarage.com/images/y/I/S/woman-bg-gradient-phone.jpg',
  'tablet-portrait':
    'https://stuff.fendergarage.com/images/G/u/h/Feedback_Modal-Gradient-Slide_1-desktop@2x_09082021-103921.jpg',
  'tablet-landscape':
    'https://stuff.fendergarage.com/images/G/u/h/Feedback_Modal-Gradient-Slide_1-desktop@2x_09082021-103921.jpg',
  laptop:
    'https://stuff.fendergarage.com/images/G/u/h/Feedback_Modal-Gradient-Slide_1-desktop@2x_09082021-103921.jpg',
  desktop:
    'https://stuff.fendergarage.com/images/G/u/h/Feedback_Modal-Gradient-Slide_1-desktop@2x_09082021-103921.jpg',
};

const SlideTwoFourArt: ImageBreakpointMap = {
  'small-phone': 'https://stuff.fendergarage.com/images/U/4/p/bg-gradient-phone.jpg',
  phone: 'https://stuff.fendergarage.com/images/U/4/p/bg-gradient-phone.jpg',
  'tablet-portrait': 'https://stuff.fendergarage.com/images/h/V/T/bg-gradient-tablet-landscape.jpg',
  'tablet-landscape':
    'https://stuff.fendergarage.com/images/h/V/T/bg-gradient-tablet-landscape.jpg',
  laptop: 'https://stuff.fendergarage.com/images/N/L/I/bg-gradient-desktop.jpg',
  desktop: 'https://stuff.fendergarage.com/images/N/L/I/bg-gradient-desktop.jpg',
};

const centerImageCSS = css`
  background-position: top;
`;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(-225deg, #ff8030 0%, #ff5a52 50%, #613090 100%);
  position: relative;

  ${up('tablet-portrait')} {
    max-width: 800px;
    height: 450px;
  }
`;

const ModalBackgroundArt1Container = styled.div<{ slideState: number }>`
  height: 100%;
  width: 100%;
  position: absolute;
  display: ${({ slideState }): string => (slideState === 0 ? 'block' : 'none')};
  overflow: hidden;
`;

const modalArt1CSS = css`
  ${centerImageCSS};
  background-position: left;
  background-size: cover;
`;

const ModalBackgroundArt1 = styled.div`
  height: 100%;
  width: 100%;

  ${generateImageBreakpointCSS(SlideOneArt, 'phone', undefined, centerImageCSS, '.jpg')};

  ${up('tablet-portrait')} {
    ${generateImageBreakpointCSS(SlideOneArt, 'tablet-portrait', undefined, modalArt1CSS, '.jpg')}
    height: 100%;
    width: auto;
    overflow: hidden;
  }
  ${up('tablet-landscape')} {
    ${generateImageBreakpointCSS(SlideOneArt, 'tablet-landscape', undefined, modalArt1CSS, '.jpg')}
  }
  ${up('laptop')} {
    ${generateImageBreakpointCSS(SlideOneArt, 'laptop', undefined, modalArt1CSS, '.jpg')}
  }
`;

const ModalBackgroundArt2 = styled.div<{ slideState: number }>`
  height: 100%;
  width: 100%;
  position: absolute;
  display: ${({ slideState }): string => (slideState !== 0 ? 'block' : 'none')};

  ${generateImageBreakpointCSS(SlideTwoFourArt, 'phone', undefined, centerImageCSS, '.jpg')};

  ${up('tablet-portrait')} {
    ${generateImageBreakpointCSS(
      SlideTwoFourArt,
      'tablet-portrait',
      undefined,
      centerImageCSS,
      '.jpg',
    )}
  }
  ${up('tablet-landscape')} {
    ${generateImageBreakpointCSS(
      SlideTwoFourArt,
      'tablet-landscape',
      undefined,
      modalArt1CSS,
      '.jpg',
    )}
  }
  ${up('laptop')} {
    ${generateImageBreakpointCSS(SlideTwoFourArt, 'laptop', undefined, modalArt1CSS, '.jpg')}
  }
`;

const SwipeContainer = styled.div`
  padding-bottom: 37px;
  height: 100%;
  width: 100%;

  ${up('tablet-portrait')} {
    padding-bottom: 32px;
    padding-top: 32px;
  }
`;

const SkipAllButtonContainer = styled.button`
  ${Focus}
  position: absolute;
  top: 39px;
  right: 39px;
  z-index: 5;
  cursor: pointer;
  ${up('tablet-portrait')} {
    top: 24px;
    right: 32px;
  }
`;

interface FeedbackModalProps {
  onClose: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ onClose }) => {
  const { user } = usePlayUser();
  const [slideState, setSlideState] = useState<number>(0);
  const modalContainerRef = useEscapeKey<HTMLDivElement>(() => onClose());
  const modalRef = useFocusTrap(true, { focusSelector: '[data-id="swipe-carousel-button"]' });
  const { firstRunFeedbackModeModal } = useFeedbackModeModal();

  useEffect(() => {
    user?.userID &&
      trackPageEvents(EventNames.FEEDBACK_MODE_ONBOARDING, {
        first_run: firstRunFeedbackModeModal(),
        fc_id: user?.userID,
        step: slideState + 1,
        total_steps: feedbackModeCarouselData.length,
      });
  }, [firstRunFeedbackModeModal, slideState, user]);

  const closeModal = (): void => {
    const properties = {
      step: slideState + 1,
      total_steps: feedbackModeCarouselData.length,
      fc_id: user?.userID,
    };
    trackEvent(EventNames.FEEDBACK_MODE_ONBOARDING_COMPLETED, properties);
    onClose();
  };

  const escapeModal = (): void => {
    const properties = {
      step: slideState + 1,
      total_steps: feedbackModeCarouselData.length,
      fc_id: user?.userID,
    };
    trackEvent(EventNames.FEEDBACK_MODE_ONBOARDING_DISMISSED, properties);
    onClose();
  };

  const handleSlideChange = (slideNum: number): void => {
    const properties = {
      step: slideNum > slideState ? slideNum : slideNum + 2,
      total_steps: feedbackModeCarouselData.length,
      fc_id: user?.userID,
    };
    trackEvent(EventNames.FEEDBACK_MODE_ONBOARDING_ADVANCED, properties);
    setSlideState(slideNum);
  };

  const SpinnerContainer = styled.button`
    display: flex;
    height: 600px;
    width: 100vw;
    justify-content: center;
    align-items: center;
  `;

  return (
    <Suspense
      fallback={
        <SpinnerContainer>
          <Spinner strokeBaseColor="white" strokeColor="transparent" />
        </SpinnerContainer>
      }
    >
      <ModalBackground
        data-id="feedback-mode-modal-background"
        onClick={escapeModal}
        ref={modalContainerRef}
      />
      <ModalContainer
        ref={modalRef}
        data-id="feedback-mode-modal"
        role="dialog"
        aria-labelledby="feedback-mode-modal"
        aria-modal
      >
        <SkipAllButtonContainer
          data-id="feedback-mode-modal-close-button-container"
          aria-label="Close"
          type="button"
          onClick={escapeModal}
        >
          <BodyText
            textSize="f3"
            textColor="white"
            tracking="0.5px"
            dataId="feedback-mode-modal-skip-all"
          >
            Skip all
          </BodyText>
        </SkipAllButtonContainer>
        <ModalBackgroundArt1Container slideState={slideState}>
          <ModalBackgroundArt1 data-id="feedback-mode-modal-background-art-1" />
        </ModalBackgroundArt1Container>
        <ModalBackgroundArt2
          slideState={slideState}
          data-id="feedback-mode-modal-background-art-2-4"
        />
        <SwipeContainer>
          <SwipeCarousel
            hasCTA
            buttonTextColor="black"
            onClick={closeModal}
            onChange={handleSlideChange}
            feedbackMode
          >
            {feedbackModeCarouselData.map(({ artwork, headline, text, specialArt, tip }) => (
              <FeedbackModalContent
                artwork={artwork}
                headline={headline}
                text={text}
                special={specialArt}
                tip={tip}
              />
            ))}
          </SwipeCarousel>
        </SwipeContainer>
      </ModalContainer>
    </Suspense>
  );
};

export default FeedbackModal;
