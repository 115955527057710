/* eslint-disable import/prefer-default-export */

import mirror from 'keymirror';

export const actionTypes = mirror({
  SET_RUNTIME_VARIABLE: null,
});

export function setRuntimeVariable({ name, value }) {
  return {
    type: actionTypes.SET_RUNTIME_VARIABLE,
    payload: {
      name,
      value,
    },
  };
}
