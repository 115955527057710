import styled, { css } from 'styled-components';

export const BaseModalPosition = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
`;

export const ModalContainer = styled.div`
  ${BaseModalPosition};
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: auto;
`;

export const ModalBackground = styled.div`
  ${BaseModalPosition};
  z-index: 0;

  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.8);
`;
