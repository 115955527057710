import React, { FC } from 'react';
import ContentPlacement from './ContentPlacement';
import TextContent from './TextContent';

interface BlockHeroProps {
  containerStyles?: string;
  contentPlacement?: string;
  cta1?: string;
  cta1Url?: string;
  cta1Styles?: string;
  cta2?: string;
  cta2Url?: string;
  cta2Styles?: string;
  description?: string;
  headline?: string;
  headlineStyles?: string;
  image?: string;
  isFirstBlock: boolean;
  logoImage?: string;
  logoImageUrl?: string;
  mobileImage?: string;
  mobileLogoImage?: string;
  subHeadline?: string;
  subHeadlineStyles?: string;
  theme?: string;
}

const BlockHero: FC<BlockHeroProps> = ({
  headlineStyles,
  isFirstBlock,
  theme,
  contentPlacement = 'middle-center',
  containerStyles = 'cover bg-center w-100 pa4 pa0-ns mb4',
  cta1 = null,
  cta1Url = null,
  cta1Styles = null,
  cta2 = null,
  cta2Url = null,
  cta2Styles = null,
  description = null,
  headline = null,
  image = null,
  logoImage = null,
  mobileImage = null,
  mobileLogoImage = null,
  subHeadline = null,
  subHeadlineStyles = '',
}) => {
  const themeColor = theme === 'dark' ? 'bg-black white' : 'bg-white black';
  const aspectRatio = image ? 'aspect-ratio aspect-ratio--1x1 aspect-ratio--2x35-ns' : '';
  const baseStyles = `relative ${aspectRatio} ${themeColor}`;

  const renderTextContent = (): JSX.Element => {
    const contentStyles = image ? 'absolute top-0 left-0 w-100 h-100 z-1 overflow-visible' : '';

    return (
      <div className={`${image ? contentStyles : ''} pa4-ns`}>
        <ContentPlacement contentPlacement={contentPlacement}>
          <div>
            <TextContent
              contentPlacement={contentPlacement}
              cta1={cta1}
              cta1Url={cta1Url}
              cta1Styles={cta1Styles}
              cta2={cta2}
              cta2Url={cta2Url}
              cta2Styles={cta2Styles}
              description={description}
              isFirstBlock={isFirstBlock}
              logoImage={logoImage}
              mobileLogoImage={mobileLogoImage}
              playButtonAction={() => {}}
              headline={headline}
              headlineStyles={headlineStyles}
              subHeadline={subHeadline}
              subHeadlineStyles={subHeadlineStyles}
              theme={theme}
            />
          </div>
        </ContentPlacement>
      </div>
    );
  };

  return (
    <div className={`block-hero ${containerStyles}`}>
      <div
        className={`${baseStyles} dn db-l bg-center cover`}
        style={{ backgroundImage: `url(${image})` }}
      >
        {renderTextContent()}
      </div>
      <div
        className={`${baseStyles} dn-l bg-center cover`}
        style={{ backgroundImage: `url(${mobileImage})` }}
      >
        {renderTextContent()}
      </div>
    </div>
  );
};

export default BlockHero;
