function envToBool(envVal: string | boolean = false): boolean {
  if (typeof envVal === 'string') {
    return envVal === 'true';
  }
  return !!envVal;
}
if (!envToBool(process.env.REACT_APP_SANITY_CHECK)) {
  throw new Error('env vars not loading');
}
const flags = {
  // Don't get clever with trying to do an accessor off process.env
  // webpack is doing string matching to replace these so if you use an accessor
  // it will break
  console: envToBool(process.env.REACT_APP_FF_console),
  isRenewWithExistingCreditCardEnabled: envToBool(
    process.env.REACT_APP_FF_isRenewWithExistingCreditCardEnabled,
  ),
  isFreemiumPhase1: envToBool(process.env.REACT_APP_FF_isFreemiumPhase1),
  isMMS: envToBool(process.env.REACT_APP_FF_isMMS),
  isFreemiumPhase2: envToBool(process.env.REACT_APP_FF_isFreemiumPhase2),
  isHolidayOffer: envToBool(process.env.REACT_APP_FF_isHolidayOffer),
  isAutoplayWelcomeEnabled: envToBool(process.env.REACT_APP_FF_isAutoplayWelcomeEnabled),
  isWhatsNewUrlEnabled: envToBool(process.env.REACT_APP_FF_isWhatsNewUrlEnabled),
  isNotationEnabled: envToBool(process.env.REACT_APP_FF_isNotationEnabled),
  isZoomEnabled: envToBool(process.env.REACT_APP_FF_isZoomEnabled),
  isPracticeModeV2Enabled: envToBool(process.env.REACT_APP_FF_isPracticeModeV2Enabled),
  isFreemium: envToBool(process.env.REACT_APP_FF_isFreemium),
  isPlay5: envToBool(process.env.REACT_APP_FF_isPlay5),
  isNewVideoPlayer: envToBool(process.env.REACT_APP_FF_isNewVideoPlayer),
  isNewPlayHomeEnabled: envToBool(process.env.REACT_APP_FF_isNewPlayHomeEnabled),
  isSquierBundle: envToBool(process.env.REACT_APP_FF_SQUIER_BUNDLE),
  isFA15Bundle: envToBool(process.env.REACT_APP_FF_FA15_BUNDLE),
  isSquierElectricBundle: envToBool(process.env.REACT_APP_FF_SQUIER_ELECTRIC_BUNDLE),
  useAds: envToBool(process.env.REACT_APP_FF_useAds),

  env: process.env.REACT_APP_WINNING_ENV_FILE,
};

export default flags;
