import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import Logo from '@fenderdigital/ui/Logo';
import ScreenReaderOnly from '@fenderdigital/ui/ScreenReaderOnly';
import { Focus } from '@fenderdigital/ui/utils/css';
import { Facebook, Twitter, Instagram, Youtube } from '@fenderdigital/ui/Icons';
import FooterSignup from './FooterSignup';
import FooterLinks from './FooterLinks';
import FooterCopyright from './FooterCopyright';
import USFlag from './USFlag';
import ResponsiveContainer from '../ResponsiveContainer/index';
import parseFooterFeed from './parse-utils';
import FooterLocale from './FooterLocale';
import bugsnagClient from '../../core/bugsnag';

const Root = styled.footer`
  background: rgb(18, 18, 18);
  padding: 60px 0 45px;
  color: rgb(255, 255, 255);
`;

const Container = styled(ResponsiveContainer)`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
`;

const LogoContainer = styled.div`
  width: 15%;
  padding-left: 2rem;
  a {
    text-decoration: none;
    font-size: 3.2rem;
    color: white;
    line-height: 24px;
  }
  a > i {
    line-height: 44px;
  }
  @media screen and (max-width: 1280px) {
    width: 100%;
    padding-left: unset;
  }
`;

const StyledLogo = styled(Logo)`
  height: 3.25rem;

  ${up('laptop')} {
    height: 2.75rem;
  }
`;

const ContentContainer = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
  @media screen and (max-width: 1280px) {
    padding-right: 0;
    margin-top: 69px;
    width: 100%;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 75px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const LocaleSwitch = styled.button`
  ${Focus}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  border: 1px solid rgb(102, 102, 102);
  height: 44px;
  width: 225px;
  background-color: rgb(18, 18, 18);
  cursor: pointer;
  > span {
    &:last-child {
      text-transform: uppercase;
      margin-left: 14px;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme: { colors } }): string => colors.white};
      font-family: ${({ theme: { fonts } }): string => fonts.futuraHeavy};
    }
  }
`;

const IconContainer = styled.div`
  @media screen and (max-width: 480px) {
    margin-top: 45px;
  }
  a {
    ${Focus}
    margin-left: 25px;
    color: rgb(153, 153, 153);
    text-decoration: none;
    font-size: 34px;
    :hover {
      svg {
        fill: white;
      }
      i,
      img {
        color: white;
      }
    }
    @media screen and (max-width: 480px) {
      margin-left: 0px;
      &:not(:last-child) {
        margin-right: 25px;
      }
      &:last-child {
        font-size: 36px;
      }
    }
  }
`;

const Footer: React.FC<any> = () => {
  const [footerData, setFooterData] = useState<any>(null);
  const [showLocaleModal, setShowLocaleModal] = useState(false);
  const timeoutId = useRef<number>(0);

  useEffect(() => {
    const timer = (timeout = 1000): Promise<void> => {
      return new Promise((_, rej) => {
        timeoutId.current = window.setTimeout(rej, timeout, 'Footer fetch timeout');
      });
    };
    const getData = async (): Promise<void> => {
      const url = `/navigation/footer.json`;
      try {
        const data = await Promise.race([fetch(url), timer()]);
        timeoutId && clearTimeout(timeoutId.current);
        const body = await (data as Response).json();
        const parsed = parseFooterFeed(body);
        setFooterData(parsed);
      } catch (e) {
        timeoutId && clearTimeout(timeoutId.current);
        import('./footer.json').then(mod => {
          const parsed = parseFooterFeed(mod.default);
          setFooterData(parsed);
        });
        bugsnagClient.notify(e);
      }
    };
    getData();
  }, []);

  const onModalClose = (): void => {
    setShowLocaleModal(false);
  };

  return (
    <Root id="footer">
      <FooterLocale onClose={onModalClose} visible={showLocaleModal} />
      <Container>
        <LogoContainer>
          <a data-id="footer-logo" href="/play/">
            <StyledLogo logoColor="white" />
          </a>
        </LogoContainer>
        <ContentContainer>
          <FooterSignup />
          <FooterLinks nodes={footerData} />
          <SocialContainer>
            <LocaleSwitch
              onClick={(): void => {
                setShowLocaleModal(!showLocaleModal);
              }}
            >
              <USFlag />
              <span>UNITED STATES</span>
            </LocaleSwitch>
            <IconContainer>
              <a
                data-id="footer-social-facebook"
                href="https://www.facebook.com/Fender/"
                title="Facebook"
                css={`
                  margin-right: -18px;
                `}
              >
                <ScreenReaderOnly>Fender&apos;s Facebook page</ScreenReaderOnly>
                <Facebook fill="#939393" fontSize="2.6rem" aria-hidden="true" />
              </a>
              <a
                data-id="footer-social-twitter"
                href="https://www.twitter.com/fender"
                title="Twitter"
                css={`
                  margin-right: -8px;
                `}
              >
                <ScreenReaderOnly>Fender&apos;s Twitter page</ScreenReaderOnly>
                <Twitter fill="#939393" fontSize="2.9rem" aria-hidden="true" />
              </a>
              <a
                data-id="footer-social-instagram"
                href="https://www.instagram.com/fender"
                title="Instagram"
              >
                <ScreenReaderOnly>Fender&apos;s Instagram page</ScreenReaderOnly>
                <Instagram fill="#939393" fontSize="1.5rem" aria-hidden="true" />
              </a>
              <a
                data-id="footer-social-youtube"
                href="https://www.youtube.com/user/fendermusical"
                title="YouTube"
              >
                <ScreenReaderOnly>Fender&apos;s YouTube page</ScreenReaderOnly>
                <Youtube fill="#939393" fontSize="1.5rem" aria-hidden="true" />
              </a>
            </IconContainer>
          </SocialContainer>
        </ContentContainer>
      </Container>
      <ResponsiveContainer>
        <FooterCopyright />
      </ResponsiveContainer>
    </Root>
  );
};

export default Footer;
