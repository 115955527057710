/* eslint-disable import/prefer-default-export */

import mirror from 'keymirror';
import fetch from '../core/fetch';

export const actionTypes = mirror({
  SET_LOCALE_START: null,
  SET_LOCALE_SUCCESS: null,
  SET_LOCALE_ERROR: null,
});

export function setLocale({ locale }) {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_LOCALE_START,
      payload: {
        locale,
      },
    });

    try {
      let url = `/messages/${locale}.json`;
      if (!process.env.REACT_APP_BROWSER) {
        url = `http://localhost:${process.env.REACT_APP_PORT}${url}`;
      }
      const response = await fetch(url);
      const data = await response.json();
      if (Array.isArray(data)) {
        const messages = data.reduce((msgs, msg) => {
          msgs[msg.id] = msg.message; // eslint-disable-line no-param-reassign
          return msgs;
        }, {});
        dispatch({
          type: actionTypes.SET_LOCALE_SUCCESS,
          payload: {
            locale,
            messages,
          },
        });

        // remember locale for every new request
        if (process.env.REACT_APP_BROWSER) {
          const maxAge = 3650 * 24 * 3600; // 10 years in seconds
          document.cookie = `lang=${locale};path=/;max-age=${maxAge}`;
        }
      }
    } catch (error) {
      dispatch({
        type: actionTypes.SET_LOCALE_ERROR,
        payload: {
          locale,
          error,
        },
      });
      return false;
    }

    return true;
  };
}
