import isDomUsable from '@fenderdigital/utils/dom';
import { Cookies } from 'react-cookie';

export const PLAY_PUBLIC_URL = `${process.env.REACT_APP_CLIENT_FENDER_COM}/play`;
export const PLAY_AUTH_URL = `${process.env.REACT_APP_CLIENT_PLAY_AUTH_URL}`;
export const SSO_URL = `${process.env.REACT_APP_CLIENT_SSO_URL}`;

export interface CognitoCredentials {
  token: string;
  userID: string;
  email?: string;
  'first-name': string;
  'last-name': string;
}

// clear localstorage auth creds from legacy www.fender.com/connect
// connect.fender.com can't because of the domain difference
export const clearLocalStorage = (): void => window?.localStorage?.clear();

export const asyncClearLocalStorage = (): Promise<void> => {
  return new Promise(resolve => {
    window.localStorage.clear();
    resolve();
  });
};

export const getClientIdCookie = (): Promise<boolean> => {
  const cookies = new Cookies();
  return new Promise(resolve => {
    const clientId = !!cookies.get('lausd-client-id');
    resolve(clientId);
  });
};

export const getSignOutLink = (hasCookie = false): string => {
  return hasCookie
    ? `${process.env.REACT_APP_CLIENT_SSO_URL}/lausd/sign-out`
    : `${process.env.REACT_APP_CLIENT_SSO_URL}/sign-out?ref=${process.env.REACT_APP_CLIENT_FENDER_COM}/play`;
};

/**
 * Add session-started query param for avo event session start on login
 * @returns {string}
 */
export const getSignInUrlWithSession = (): string => {
  const params = isDomUsable() ? window.location.search : '';
  const parsedParams = params.replace('?clearStorage=true', '');
  const ref = `${PLAY_AUTH_URL}${parsedParams}`;

  if (ref.endsWith('?session-started=true')) {
    return `${SSO_URL}/sign-in?ref=${ref}`;
  }

  return `${SSO_URL}/sign-in?ref=${ref}?session-started=true`;
};

export const getSignUpUrlWithSession = (): string => {
  const params = isDomUsable() ? window.location.search : '';
  const parsedParams = params.replace('?clearStorage=true', '');
  const ref = `${PLAY_AUTH_URL}${parsedParams}`;

  if (ref.endsWith('?session-started=true')) {
    return `${SSO_URL}/sign-up?ref=${ref}`;
  }

  return `${SSO_URL}/sign-up?ref=${ref}?session-started=true`;
};

/**
 * Some URL's will pass along query params for analytics, tracking,
 * session-start, or to clear local storage, etc
 *
 * Get url with ref query params e.g. https://playapp.fender.com/welcome-screen?utm_medium=test&utm_campaign=test
 * @param path path to navigate to e.g. /welcome-screen
 * @returns {string} url with query params */
export const getUrlWithQueryParams = (path: string): string => {
  return `${PLAY_AUTH_URL}${path}${isDomUsable() ? window.location.search : ''}`;
};
