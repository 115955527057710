import React from 'react';
import PropTypes from 'prop-types';
import Head from '../Head';

export function getParsedURL(url) {
  if (url && typeof url === 'string') {
    const pathPrefix = url.substring(0, 5);
    const formattedUrl = pathPrefix === '/play' ? url.split(pathPrefix)[1] : url;
    return formattedUrl.charAt(0) === '/' ? formattedUrl.substring(1) : formattedUrl;
  }
  return '';
}

const LayoutMetaData = ({ pathname }) => {
  const description =
    'Fender Play is the complete app for learning to play acoustic and electric guitar, bass and ukulele. Get started with step-by-step lessons, expert instructors and 100s of your favorite songs. Start your free trial.';
  const title = 'Fender Play Online Guitar Lessons - Learn How to Play Guitar';
  const parsedURL = getParsedURL(pathname);
  const metaData = Head.generateMetaData(
    title,
    description,
    parsedURL,
    '',
    null,
    title,
    description,
    'https://stuff.fendergarage.com/images/O/C/w/fender-play-og.jpg',
  );
  return <Head {...metaData} />;
};

LayoutMetaData.propTypes = {
  url: PropTypes.string,
};

LayoutMetaData.defaultProps = {
  url: '',
};

export default LayoutMetaData;
