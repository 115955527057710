import { CourseStatus } from './types';

interface CourseMetadata {
  courseNumberLabel: string;
  courseStatus: CourseStatus;
}

const getCourseMetadata = (
  lessonsCompletedPercentage: number,
  courseNumber?: number,
): CourseMetadata => {
  const courseNumberLabel =
    courseNumber && courseNumber > 9 ? `${courseNumber}` : `0${courseNumber}`;
  let courseStatus: CourseStatus = CourseStatus.DEFAULT;
  if (lessonsCompletedPercentage > 0 && lessonsCompletedPercentage < 100) {
    courseStatus = CourseStatus.IN_PROGRESS;
  }
  if (lessonsCompletedPercentage === 100) {
    courseStatus = CourseStatus.COMPLETED;
  }
  return { courseNumberLabel, courseStatus };
};

export default getCourseMetadata;
