import React, { MouseEvent, SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Focus } from '@fenderdigital/ui/utils/css';
import ScreenReaderOnly from '@fenderdigital/ui/ScreenReaderOnly';
import usePlayUser from '@fenderdigital/custom-hooks/hooks/usePlayUser';
import Link from '../Link';
import { FeedbackThumbnail } from '../FeedbackMode';
import CircledQuestionMark from './CircledQuestionMark';
import useFeedbackModeModal from '../FeedbackModeModal/useFeedbackModal';
import { isLocked } from '../../utils/access-utils';

interface BetaCardProps {
  title: string;
  category: string;
  courseUrl?: string;
  mmsId: string;
  accessLevel?: string[] | null;
}

const CourseDetailItemContainer = styled.div`
  display: grid;
  grid-template-columns: 82px auto 83px;
  column-gap: 12px;
  padding: 15px 0;
  cursor: pointer;

  ${up('phone')} {
    grid-template-columns: 94px auto 83px;
  }

  ${up('tablet-portrait')} {
    grid-template-columns: 100px auto 100px 100px;
  }

  ${up('tablet-landscape')} {
    grid-template-columns: 100px auto 174px 100px;
  }

  &:hover {
    background: ${({ theme: { colors } }): string => colors.nearWhite};
  }
`;

const CustomSpacer = styled.div`
  align-items: center;
  height: 47.65px;
  ${up('tablet-portrait')} {
    min-width: 189.73px;
  }
  ${up('laptop')} {
    min-width: 297.73px;
  }
`;

const ActivityType = styled.div`
  width: 100%;
  max-width: 127px;
  font-size: 16px;
  font-family: SourceSansPro-Regular;
  color: #666666;
`;

const LearnMore = styled.button`
  ${Focus}
  width: 15px;
  height: 30px;
  z-index: 4;
  cursor: help;
  padding-bottom: 4px;
`;

const DetailContainerOuter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

const DetailContainerInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;

  ${up('laptop')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Title = styled.h2`
    margin-bottom: 0px;
    line-height: .8;

  a {
    ${Focus}
    font-size: ${({ theme: { fontSizes } }): string => fontSizes.f3};
    font-family: ${({ theme: { fonts } }): string => fonts.futuraDemi};
    color: ${({ theme: { colors } }): string => colors.nearBlack};

    ${up('phone')} {
      font-size: ${({ theme: { fontSizes } }): string => fontSizes.f4};
    }
  }


`;

const BetaCard: React.FC<BetaCardProps> = ({ title, category, courseUrl, accessLevel }) => {
  const { accessLevel: userAccessLevel } = usePlayUser();
  const isAvailable = !isLocked(accessLevel, userAccessLevel);
  const { showFeedbackModeModal } = useFeedbackModeModal();
  const history = useHistory();
  const link = `${courseUrl}/feedback-mode`;

  const handleOnClick = (): void => {
    if (isAvailable) return history.push(link);
    return history.push('/plans');
  };

  return (
    <CourseDetailItemContainer
      className="lesson-card-container"
      onClick={handleOnClick}
      data-id="activity-card-link"
    >
      <FeedbackThumbnail />
      <DetailContainerOuter>
        <DetailContainerInner>
          <Title>
            <Link
              to={isAvailable ? link : '/plans'}
              onClick={(e: SyntheticEvent): void => e.preventDefault()}
            >
              {`"${title}"`}
              <ScreenReaderOnly>
                {isAvailable ? `. ${category}` : '. Locked. Upgrade to unlock content.'}
              </ScreenReaderOnly>
            </Link>
          </Title>
        </DetailContainerInner>
        <ActivityType data-id="beta-activity-category">
          {category}{' '}
          <LearnMore
            data-id="lesson-card-feedback-mode-learn-more"
            aria-label="Learn more"
            onClick={(e: MouseEvent): void => {
              e.preventDefault();
              showFeedbackModeModal();
            }}
          >
            <CircledQuestionMark transform="translate(0 3)" aria-hidden />
          </LearnMore>
        </ActivityType>
      </DetailContainerOuter>
      <CustomSpacer />
    </CourseDetailItemContainer>
  );
};

export default BetaCard;
