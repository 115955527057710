import isDomUsable from '@fenderdigital/utils/dom';
import flags from '../flags';

export type AvailableFeatureFlags = keyof Omit<typeof flags, 'env'>;

export const getDefaultFlag = (flagKey: AvailableFeatureFlags): boolean => {
  return flags[flagKey];
};

export const getOverrideFlag = (flagKey: AvailableFeatureFlags): boolean | null => {
  const override = localStorage.getItem(`ff_${flagKey}`);
  switch (override) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
};

export const getFlag = (flagKey: AvailableFeatureFlags): boolean => {
  const defaultEnvFlag = getDefaultFlag(flagKey);
  if (!isDomUsable()) {
    return defaultEnvFlag ?? false;
  }

  const override = getOverrideFlag(flagKey);
  return override ?? defaultEnvFlag;
};

export const setOverrideFlag = (flagKey: AvailableFeatureFlags, enable: boolean): void => {
  if (!isDomUsable()) {
    return;
  }
  localStorage.setItem(`ff_${flagKey}`, String(enable));
};

export const clearOverrideFlags = (): void => {
  for (const key in localStorage) {
    if (key.startsWith('ff_')) {
      localStorage.removeItem(key);
    }
  }
};

export const getFlagState = (): typeof flags => {
  const nonFeatureFlags = ['console', 'env'];
  const flagNames = Object.keys(flags).filter(
    item => nonFeatureFlags.indexOf(item) === -1,
  ) as AvailableFeatureFlags[];
  return flagNames.reduce(
    (acc, flag) => {
      acc[flag] = getFlag(flag);
      return acc;
    },
    { ...flags },
  );
};
