import { actionTypes } from '../actions/song';
import { song as defaultState } from './default.json';
import getTabs from '../lib/song';

const handlers = {
  [actionTypes.SONG_CLEAR]: state => ({
    ...state,
    results: {
      ...defaultState.results,
    },
  }),
  [actionTypes.SONG_GET_SUCCESS]: (state, action) => ({
    ...state,
    inProgress: false,
    results: {
      ...action.results,
      tabs: getTabs(action.results),
    },
  }),
  [actionTypes.SONG_GET_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    inProgress: false,
  }),
  [actionTypes.SONG_GET_IN_PROGRESS]: state => ({
    ...state,
    inProgress: true,
  }),
};

export default (state = defaultState, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};
