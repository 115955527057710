import React, { FC, useState, memo, ReactNode, ReactElement, useRef } from 'react';
import classNames from 'classnames';
import { AuthIndicatorProps } from '@fenderdigital/react-ui-kit/auth-indicator';
import OffCanvas from '@fenderdigital/react-ui-kit/off-canvas';
import { BreakpointKeys } from '@fenderdigital/ui/system';
import ScreenReaderOnly from '@fenderdigital/ui/ScreenReaderOnly';
import Accordion from '@fenderdigital/ui/Accordion';
import { useEscapeKey, useFocusTrap } from '@fenderdigital/custom-hooks';
import { isHash } from '../Header/Header';
import { getCurrentMenu } from '../Header/DesktopMenu';
import Link from '../Link/Link';
import { CollapsibleContent, MobileMenuHeader, MenuList, MobileMenuItemContents } from './common';
import CollectionLinks from '../Header/CollectionLinks';
import { Navigation, NavigationItem } from '../Header/NavTypes';
import { toKebabCase } from '../../utils/string-utils';
import * as S from './UnSubscribedMenus-styled';

export interface Titles {
  site?: string;
  connect?: string;
  apps?: string;
}

export interface FenderConnectProfile {
  email?: string;
}

interface UnSubscribedMobileMenuProps {
  breakpoint?: BreakpointKeys;
  currentNav?: string;
  fenderConnect?: FC<AuthIndicatorProps> | ReactElement;
  fenderConnectProfile?: FenderConnectProfile | null;
  globalNavData: any;
  isVisible?: boolean;
  navItems?: NavigationItem[] | null;
  onClick?: (url?: string) => void;
  onCloseMenu: () => void;
  onSelectSubMenu?: (id: string | null) => void;
  selectedSubMenu?: string | null;
  titles?: Titles;
}

const UnSubscribedMobileMenu: FC<UnSubscribedMobileMenuProps> = ({
  breakpoint = 'tablet-landscape',
  currentNav = '',
  fenderConnect,
  fenderConnectProfile = null,
  globalNavData,
  isVisible = false,
  navItems = [],
  onClick = (): void => {},
  onCloseMenu,
  onSelectSubMenu = (): void => {},
  selectedSubMenu: selectedSubMenuFromProps = null,
  titles = {
    site: 'Fender',
    connect: 'Fender Connect',
    apps: 'Fender Apps',
  },
}) => {
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const [selectedSubMenu, setSelectedSubMenu] = useState<string | null>(selectedSubMenuFromProps);
  const modalRef = useFocusTrap(isVisible, {
    focusSelector: '[data-id="mobile-menu-close-button"]',
  });
  const subMenuRef = useFocusTrap(isSubMenuVisible, {
    focusSelector: '[data-id="mobile-menu-back-button"]',
  });
  const contentRef = useEscapeKey<HTMLDivElement>(() => closeMenu());
  const closeMenuButtonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (url?: string): void => {
    onClick(url);
  };

  const resetSubMenuState = (): void => {
    setIsSubMenuVisible(false);

    const duration = 250;
    setTimeout(() => {
      setSelectedSubMenu(null);

      if (onSelectSubMenu) {
        onSelectSubMenu(null);
      }

      if (closeMenuButtonRef?.current !== null) {
        closeMenuButtonRef.current.focus();
      }
    }, duration);
  };

  const closeSubMenu = (event?: MouseEvent): void => {
    if (event) {
      event.preventDefault();
    }
    resetSubMenuState();
  };

  const closeMenu = (): void => {
    const bodyEl = document.body;
    const htmlEl = document.documentElement;
    if (bodyEl.classList.contains('overflow-hidden')) {
      bodyEl.classList.remove('overflow-hidden');
      htmlEl.classList.remove('overflow-hidden');
      htmlEl.classList.remove('overflow-auto-l');
    }
    resetSubMenuState();
    onCloseMenu();
  };

  const selectSubMenu = (id: string): void => {
    setIsSubMenuVisible(true);
    setSelectedSubMenu(id);
    onSelectSubMenu(id || null);
  };

  const getMenuWithCollectionLinks = (
    menu: NavigationItem[] | null,
    navId: string,
  ): Navigation[] | null => {
    if (!menu) {
      return menu;
    }

    switch (navId) {
      case 'ukulele':
      case 'guitar':
      case 'bass':
        // eslint-disable-next-line
        // @ts-ignore
        return [menu[0], CollectionLinks[navId], menu[1]];
      default:
        return menu as Navigation[];
    }
  };

  interface CollapsibleContainerProps {
    email: string;
    items: NavigationItem[];
    id: string;
  }

  const CollapsibleContainer: FC<CollapsibleContainerProps> = ({ email, items, id }) => {
    if (id && items) {
      const menu = getCurrentMenu(items, id);
      const currentMenu = getMenuWithCollectionLinks(menu, id);
      const titleStyles = 'flex items-center no-underline bb bt-0 bl-0 br-0 b--light-gray pa3';
      const subTitleStyles = `${titleStyles} mid-gray bg-near-white b--dotted futura-heavy f8 ttu mb0`;
      return (
        <ul>
          {currentMenu
            ? Object.keys(currentMenu).map(menuItem => {
                const item = Number(menuItem);
                return (
                  <S.AccordionListItem className="accordion" key={`${currentMenu[item].label}`}>
                    <Accordion
                      isVisible
                      label={currentMenu[item].label}
                      textTransform="uppercase"
                      headingElement="h3"
                      headingTextSize="f2"
                      maxHeight="none"
                      dataId={`${toKebabCase(currentMenu[item].label)}-accordion`}
                    >
                      <CollapsibleContent
                        obj={{ email, list: currentMenu[item].items, styles: subTitleStyles }}
                        onClick={closeMenu}
                      />
                      <MenuList
                        obj={{
                          email,
                          list: currentMenu[item].items,
                          gutterSpacing: null,
                        }}
                        isMobile
                        onClick={closeMenu}
                      />
                    </Accordion>
                  </S.AccordionListItem>
                );
              })
            : null}
        </ul>
      );
    }
    return null;
  };

  const getDivider = (str: string): ReactNode => (
    <h3 className="bg-near-white pt4 pb2 ph3 bb b--black-10 ttu gray f8">{str}</h3>
  );
  const userEmail =
    fenderConnectProfile && fenderConnectProfile.email ? fenderConnectProfile.email : '';

  return (
    <S.MobileContainer breakpoint={breakpoint}>
      <OffCanvas isVisible={isVisible} placement="right">
        <S.MobileMenuContainer
          data-id="mobile-menu"
          role="dialog"
          aria-modal
          aria-labelledby="mobile-menu-title"
          ref={modalRef}
        >
          <ScreenReaderOnly id="mobile-menu-title">Menu</ScreenReaderOnly>
          <S.MobileMenuContent ref={contentRef}>
            <MobileMenuHeader
              title={titles.site}
              onCloseButtonClick={closeMenu}
              ref={closeMenuButtonRef}
            />
            <nav aria-labelledby="mobile-menu-nav-title">
              <ScreenReaderOnly as="h2" id="mobile-menu-nav-title">
                Main Navigation
              </ScreenReaderOnly>
              <ul>
                {navItems && Array.isArray(navItems)
                  ? navItems.map(navItem => {
                      return (
                        <S.MobileMenuListItem
                          key={`${navItem.label}`}
                          data-id={`mobile-menu-nav-item-${navItem.label}`}
                        >
                          {navItem.items || !navItem.url || isHash(navItem.url) ? (
                            <>
                              {navItem.url && isHash(navItem.url) ? (
                                <a
                                  href={navItem.url}
                                  onClick={(event): void => {
                                    event.preventDefault();
                                    if (navItem.items) {
                                      selectSubMenu(navItem.id);
                                    } else {
                                      handleClick(navItem.url);
                                    }
                                  }}
                                >
                                  <MobileMenuItemContents navItem={navItem} />
                                </a>
                              ) : (
                                <button
                                  type="button"
                                  onClick={(): void => {
                                    if (navItem.items) {
                                      selectSubMenu(navItem.id);
                                    } else {
                                      handleClick(navItem.url);
                                    }
                                  }}
                                >
                                  <MobileMenuItemContents navItem={navItem} />
                                </button>
                              )}
                            </>
                          ) : (
                            <Link
                              onClick={navItem.target ? null : closeMenu}
                              target={navItem.target ? navItem.target : ''}
                              to={!navItem.url ? '' : navItem.url}
                              dataId={`mobile-menu-nav-item-${navItem.label}`}
                            >
                              <span className={classNames(navItem['class-names'])}>
                                {navItem.label}
                              </span>
                            </Link>
                          )}
                        </S.MobileMenuListItem>
                      );
                    })
                  : null}
              </ul>
              {titles?.connect && getDivider(titles.connect)}
              {fenderConnect}
              {titles?.apps && getDivider(titles.apps)}
              {globalNavData && (
                <ul data-id="mobile-menu-global-nav">
                  {Object.keys(globalNavData).map(key => {
                    const isCurrent = key === currentNav;

                    return (
                      <S.MobileMenuListItem
                        key={key}
                        data-id={`mobile-menu-global-nav-${key}`}
                        isCurrent={isCurrent}
                        primaryColor={globalNavData[key].color}
                      >
                        <Link
                          to={globalNavData[key].url}
                          ariaCurrent={isCurrent ? 'page' : undefined}
                        >
                          {globalNavData[key].title}
                        </Link>
                      </S.MobileMenuListItem>
                    );
                  })}
                </ul>
              )}
              <S.MobileContainer breakpoint={breakpoint}>
                <OffCanvas isVisible={isSubMenuVisible} placement="right">
                  <S.MobileMenuContainer ref={subMenuRef}>
                    <S.MobileMenuContent>
                      {Array.isArray(navItems) && navItems && selectedSubMenu ? (
                        <div>
                          <MobileMenuHeader
                            title={selectedSubMenu}
                            heading="h2"
                            isBackButtonVisible
                            onBackButtonClick={closeSubMenu}
                            onCloseButtonClick={closeMenu}
                          />
                          <CollapsibleContainer
                            email={userEmail}
                            items={navItems}
                            id={selectedSubMenu}
                          />
                        </div>
                      ) : null}
                    </S.MobileMenuContent>
                  </S.MobileMenuContainer>
                </OffCanvas>
              </S.MobileContainer>
            </nav>
          </S.MobileMenuContent>
        </S.MobileMenuContainer>
      </OffCanvas>
    </S.MobileContainer>
  );
};

const MemoizedUnSubscribedMobileMenu = memo(UnSubscribedMobileMenu);
export default MemoizedUnSubscribedMobileMenu;
export { MemoizedUnSubscribedMobileMenu as UnSubscribedMobileMenu };
