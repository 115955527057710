import React from 'react';
import { Link } from 'react-router-dom';

const SearchSuggestions = () => {
  const suggestedArr = [
    'G chord',
    'Hammer-on',
    'Coldplay Clocks',
    'E minor pentatonic',
    'Pivot finger',
    'Foster The People',
  ];
  return (
    <div className="search-suggestions ph3 pv2">
      <h3 className="bb b--light-gray pb2 mb2 mt1 f5" data-id="search-suggestions-title">
        Suggested Searches
      </h3>
      <ul className="list pa0">
        {suggestedArr.map((suggestion, index) => (
          <li className="mb2" key={suggestion} data-id={`search-suggestions-item-${index}`}>
            <Link
              className="blue no-underline f5 source-sans"
              to={`/search?query=${escape(suggestion)}`}
            >
              {suggestion}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchSuggestions;
