import { Focus } from '@fenderdigital/ui/utils/css';
import React from 'react';
import styled from 'styled-components';

interface LinkProps {
  name: string;
  links?: {
    name: string;
    link: string;
  }[];
}

const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 32px;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 375px) {
    grid-column-gap: 16px;
  }
`;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgb(74, 74, 74);
  padding-top: 37px;
  /* margin-right: 32px; */
  @media screen and (max-width: 768px) {
    margin-top: 16px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 16px;
  }
  > div {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
  }
`;

const FooterLink = styled.a`
  ${Focus}
  color: rgb(204, 204, 204);
  text-decoration: none;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 36px;
  &:hover {
    color: #888;
  }
`;

const LinkHead = styled.span`
  font-family: FuturaPT-Heavy;
  font-size: 18px;
  font-weight: normal;
  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
`;

const FooterLinks: React.FC<{ nodes?: LinkProps[] }> = ({ nodes }) => {
  return (
    <LinksContainer data-id="footer-link-grid">
      {nodes?.map((node: LinkProps) => (
        <LinkContainer key={node.name}>
          <LinkHead>{node.name}</LinkHead>
          <div>
            {node.links?.map(link => (
              <FooterLink href={link.link} key={link.link}>
                {link.name}
              </FooterLink>
            ))}
          </div>
        </LinkContainer>
      ))}
    </LinksContainer>
  );
};

export default FooterLinks;
