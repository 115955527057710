import React, { useEffect } from 'react';
import { Course, Playlist, Recents, Collection } from '@fenderdigital/api-definitions/play';
import Spinner from '@fenderdigital/ui/Spinner';
import find from 'lodash/find';
import { useHistory } from 'react-router-dom';
import { ProgressListViewType } from './ProgressListViewType';
import CourseDetailsCard from './CourseDetailsCard';
import CourseList from './CourseList';

export interface ProgressListProps {
  courseNumber?: number | null;
  courses?: Course[] | null;
  collection?: Collection;
  currentCourse?: string | null;
  currentLesson?: string | null;
  currentPlaylist?: Playlist | null;
  flatLessons?: any[];
  pathLessons?: any[]; // same as flat lessons
  hasNavigated?: boolean;
  isCourseDetail?: boolean;
  loggedIn?: boolean;
  markCompleteData?: any;
  playlists?: Playlist[];
  recentlyViewed?: { [key: number]: Recents };
  url: string;
  view: ProgressListViewType;
  className?: string;
}

const ProgressList: React.FC<ProgressListProps> = ({
  courses = null,
  collection,
  currentLesson = null,
  currentPlaylist = null,
  flatLessons = [],
  hasNavigated = false,
  playlists = [],
  view,
  recentlyViewed,
  markCompleteData,
  url,
  courseNumber,
}) => {
  const history = useHistory();

  useEffect(() => {
    const currentLessonObject = find(flatLessons, { id: currentLesson });
    if (
      currentLessonObject?.isLastLessonInPlaylist &&
      currentPlaylist?.id === currentLessonObject.playlistID &&
      !currentLessonObject.isLastPlaylist &&
      !hasNavigated
    ) {
      const currentIndex = playlists.findIndex(
        playlist => playlist.id === currentLessonObject.playlistID,
      );
      const nextSlug = playlists[currentIndex + 1].slug;
      history.push(`/path/${nextSlug}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!courses) return <Spinner fullPage />;
  if (courses.length > 1 || view === 'collectionDetail')
    return (
      <CourseList
        courses={courses}
        collection={collection}
        recentlyViewed={recentlyViewed}
        url={url}
        view={view}
        markCompleteData={markCompleteData}
      />
    );
  return (
    <CourseDetailsCard
      course={courses}
      recentlyViewed={recentlyViewed}
      markCompleteData={markCompleteData}
      courseNumber={courseNumber}
    />
  );
};

export default ProgressList;
