import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import isDomUsable from '@fenderdigital/utils/dom';
import initSessionActivity, { getSessionData } from '@fenderdigital/utils/sessions';
import usePlayUser from '@fenderdigital/custom-hooks/hooks/usePlayUser';
import LayoutMetaData from './LayoutMetaData';
import { EventNames, trackEvent } from '../../lib/analytics';
import { asyncClearLocalStorage } from '../../lib/cognito';

const Layout: FC = ({ children }) => {
  const location = useLocation();
  const { user, hasCheckedSession } = usePlayUser();
  const [hasCheckedClearStorageParam, setHasCheckedClearStorageParam] = useState<boolean>(false);
  const params = new URLSearchParams(location.search);
  const shouldFireSessionStart = params.get('session-started') === 'true';
  const sessionData = isDomUsable() && getSessionData();
  const currentTime = new Date();
  const expireSessionTime = sessionData ? new Date(sessionData?.expiresAt) : new Date();
  const isSessionExpired = currentTime > expireSessionTime;
  const clearStorageParam = params.get('clearStorage') === 'true';

  isDomUsable() &&
    hasCheckedSession &&
    initSessionActivity(() => {
      trackEvent(EventNames.SESSION_STARTED, {
        fc_id: user?.userID,
      });
    });

  useEffect(() => {
    if (shouldFireSessionStart && user && hasCheckedSession && !isSessionExpired) {
      // Fire an additional SESSION_STARTED avo event once the user logs in
      // with a referrer query param of ?session-started=true.
      trackEvent(EventNames.SESSION_STARTED, {
        fc_id: user?.userID,
      });
    }
  }, [hasCheckedSession, shouldFireSessionStart, user, isSessionExpired]);

  useEffect(() => {
    /**
     * If a user was previously signed in on play-public but happens to sign out of
     * play-auth, they won't land on the unauth homepage as expected.
     * Play-auth will include a `clearStarage` param in the sign out URL, so play-public
     * will know to clear its local storage here.
     *
     * Lastly, the reason we have to do this is because play-auth is on a subdomain, so
     * clearing its localstorage within that app won't clear it in play-public.
     */
    const checkForClearStorageParam = async (): Promise<void> => {
      // checks for a local storage param
      if (clearStorageParam) {
        await asyncClearLocalStorage();
        setHasCheckedClearStorageParam(true);
      }
      setHasCheckedClearStorageParam(true);
    };
    checkForClearStorageParam();
  });

  return (
    <div className="layout">
      <LayoutMetaData pathname={location.pathname} />
      {hasCheckedClearStorageParam && <>{children}</>}
    </div>
  );
};

export default Layout;
