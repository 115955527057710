import { useRef } from 'react';
import { useModal } from '../../contexts/ModalContext';
import FeedbackModeModal from './FeedbackModeModal';

interface FeedbackModal {
  setFeedbackModeState: () => void;
  getFeedbackModeState: () => boolean;
  showFeedbackModeModal: () => void;
  closeFeedbackModeModal: () => void;
  firstRunFeedbackModeModal: () => boolean;
}

const useFeedbackModeModal = (): FeedbackModal => {
  const { showModal, hideModal } = useModal();
  const visibleRef = useRef({ feedbackModeVisible: false });

  const getFeedbackModeState = (): boolean => {
    const state = localStorage.getItem('hasSeenFeedbackModeModal');
    return state === 'true';
  };

  const setFeedbackModeState = (): void => {
    localStorage.setItem('hasSeenFeedbackModeModal', 'true');
  };

  const closeFeedbackModeModal = (finishedFlow = true): void => {
    if (!visibleRef.current.feedbackModeVisible) {
      return;
    }

    hideModal();
    visibleRef.current.feedbackModeVisible = false;
    finishedFlow && setFeedbackModeState();
  };

  const firstRunFeedbackModeModal = (): boolean => {
    const hasSeenFeedbackModeModal = getFeedbackModeState();
    return !hasSeenFeedbackModeModal;
  };

  const showFeedbackModeModal = (): void => {
    showModal(FeedbackModeModal, { onClose: closeFeedbackModeModal });
    visibleRef.current.feedbackModeVisible = true;
  };

  return {
    setFeedbackModeState,
    getFeedbackModeState,
    showFeedbackModeModal,
    closeFeedbackModeModal,
    firstRunFeedbackModeModal,
  };
};

export default useFeedbackModeModal;
