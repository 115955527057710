/* eslint-disable @typescript-eslint/no-explicit-any */
type ResponseError = Error & { code: number };

// eslint-disable-next-line import/prefer-default-export
export const parseResponse = async (response: Response): Promise<any> => {
  const payload = await response.json();
  if (response.status >= 200 && response.status < 300) {
    return payload;
  }
  const error = new Error(JSON.stringify(payload)) as ResponseError;
  error.code = response.status;
  throw error;
};
