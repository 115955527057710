import { defineMessages } from 'react-intl';

const messages = defineMessages({
  site: {
    id: 'layout.site',
    defaultMessage: 'Play',
  },
  connect: {
    id: 'layout.connect',
    defaultMessage: 'Fender Connect',
  },
  apps: {
    id: 'layout.apps',
    defaultMessage: 'Fender Apps',
  },
  cookies: {
    id: 'layout.cookies',
    defaultMessage: 'Fender uses cookies to ensure you get the best experience on our website.',
  },
  learnMore: {
    id: 'layout.learnMore',
    defaultMessage: 'Learn more',
  },
  modalTitle: {
    id: 'layout.modal.title',
    defaultMessage: 'Sign in to your account.',
  },
  signIn: {
    id: 'layout.cta.signin',
    defaultMessage: 'Sign In',
  },
  signOut: {
    id: 'layout.cta.signout',
    defaultMessage: 'Sign Out',
  },
  welcome: {
    id: 'layout.cta.title',
    defaultMessage: 'Hi, {name}',
  },
  cta: {
    id: 'header.cta',
    defaultMessage: 'GET STARTED',
  },
});

export default messages;
