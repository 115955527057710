import { CourseType } from '@fenderdigital/api-definitions/play';

export enum ImageOrigin {
  COURSE_DETAILS = 'courseDetails',
  MY_PATH = 'myPath',
  COLLECTIONS = 'collections',
}

export enum CourseStatus {
  COMPLETED = 'completed',
  DEFAULT = 'default',
  IN_PROGRESS = 'inProgress',
}

export interface CourseCardImageProps {
  courseNumber?: number;
  isCourseDetail?: boolean;
  courseTitle?: string;
  courseType: CourseType;
  imagePath: string;
  isOnPath: boolean;
  isTablet?: boolean;
  lessonsCompletedPercentage: number;
  origin: ImageOrigin;
}

export interface StyleProps {
  courseNumber?: number;
  courseStatus?: CourseStatus;
  courseType?: CourseType;
  imageOrigin?: ImageOrigin;
  imagePath?: string;
  isOnPath?: boolean;
  isTablet?: boolean;
}
