import { Collection } from '@fenderdigital/api-definitions/play';

const getStatMetaString = (statName: string, statCount: number): string | undefined => {
  if (statCount === 0) {
    return undefined;
  }
  const isPlural = statCount > 1;
  return `${statCount} ${statName}${isPlural ? 's' : ''}`;
};

const getStatsMetaStringArr = (collection: Collection): string[] => {
  const { riffs, skills, songs } = collection.stats;
  const statsArr = [
    getStatMetaString('Skill', skills),
    getStatMetaString('Song', songs),
    getStatMetaString('Riff', riffs),
  ];

  return statsArr.filter(stat => stat !== undefined) as string[];
};

const getStatsMetaString = (collection: Collection): string => {
  return getStatsMetaStringArr(collection).join(' • ');
};

// eslint-disable-next-line
export { getStatsMetaString };
