import React, { createContext, useContext, useState } from 'react';

interface SearchSelectionContextProps {
  selectedOptionId?: string;
  setSelectedOptionId: (selectedOptionId?: string) => void;
}

const SearchSelectionContext = createContext({} as SearchSelectionContextProps);

function useSearchSelection(): SearchSelectionContextProps {
  const context = useContext(SearchSelectionContext);

  if (!context) {
    throw new Error(`useSearchSelection must be used within a SearchSelectionProvider`);
  }
  return context;
}

const SearchSelectionProvider: React.FC = ({ children }) => {
  const [selectedOptionId, setSelectedOptionId] = useState<string | undefined>();

  return (
    <SearchSelectionContext.Provider value={{ selectedOptionId, setSelectedOptionId }}>
      {children}
    </SearchSelectionContext.Provider>
  );
};

export { SearchSelectionProvider, useSearchSelection };
