import React, { FC, useState, useEffect, useCallback, useRef, SyntheticEvent } from 'react';
import styled, { css, SimpleInterpolation } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Focus } from '@fenderdigital/ui/utils/css';
import usePlayUser from '@fenderdigital/custom-hooks/hooks/usePlayUser';
import {
  getChords,
  getContentImage,
  getContentText,
  getLessonsMessage,
  getStatsMessage,
  getLevelDisplay,
  getTitle,
  getContentTitle,
} from './CurriculumContent';
import { stylesLarge } from './CurriculumStyles';
import { isElementVisible } from '../../utils/dom-utils';
import { useSearchSelection } from '../../contexts/SearchSelectionContext';
import {
  CurriculumTableProps,
  RowContent as RowContentType,
  RowDisplayInfo,
  RowItem,
  TrackEvent,
} from './types';
import { trackEvent as avoTrackEvent, EventNames } from '../../lib/analytics';

const getImageStyle = (objectType: string, isArtist: boolean): 'circle' | 'wide' | 'square' => {
  if (isArtist) return 'circle';
  if (objectType === 'collection') return 'wide';
  return 'square';
};

interface RowContentProps {
  display: RowDisplayInfo;
  isSearch: boolean;
  item: RowItem;
  itemHeightStyle: string;
  itemIsLocked: boolean;
  onClick: (e: SyntheticEvent) => void;
  title: string;
  trackEvent: TrackEvent;
  url: string;
}

const RowContent: FC<RowContentProps> = ({
  display,
  isSearch,
  item,
  itemHeightStyle,
  itemIsLocked,
  title,
  onClick,
  trackEvent,
  url,
}) => {
  return (
    <div className="w-100 h-100 flex justify-between">
      <div className="flex flex-column justify-center justify-start-ns f7 f6-ns">
        {getTitle({
          title,
          url,
          trackEvent,
          itemHeightStyle,
          isSearch,
          style: 'pr2',
          isLocked: itemIsLocked,
          onClick,
        })}
        {item.title && (
          <div className="mid-gray db source-sans ttc pt1 pr2">
            {getContentTitle(item.title, isSearch)}
          </div>
        )}
        {getChords(display.chords, item.chords, `db tl w-60 ${itemHeightStyle}`, 'pr1')}
        {getContentText(display.type, item.type, 'ttc pt1 mid-gray')}
        {getContentText(display.description, item.description, `${itemHeightStyle} source-sans`)}
        {getContentText(display.lessons, getLessonsMessage(item.lessons), itemHeightStyle)}
        {getContentText(display.minutes && item.minutes, `${item.minutes} minutes`, `gray pt1`)}
        {getContentText(display.stats, getStatsMessage(item.stats), `flex ttc pt1 mid-gray`)}
      </div>
      {display.level ? (
        <div className="h-100 flex justify-center items-center mid-gray">
          {getLevelDisplay(display.level, item.level, 'flex items-start')}
        </div>
      ) : null}
    </div>
  );
};

interface RowContentItemStyleProps {
  isSelected: boolean;
}

const RowContentItem = styled.li<RowContentItemStyleProps>`
  display: flex;
  padding-top: ${({ theme: { spacing } }): string => spacing.sm3};
  padding-bottom: ${({ theme: { spacing } }): string => spacing.sm3};
  cursor: pointer;

  a {
    ${Focus}

    ${({ isSelected }): SimpleInterpolation =>
      isSelected &&
      css`
        box-shadow: rgb(185, 205, 241) 0px 0px 0px 3px;
        transition: box-shadow 0.15s ease;
      `}
  }
`;

const CurriculumItems: FC<CurriculumSmallProps> = ({
  content,
  display,
  isArtist,
  isFullSearchView,
  tableId,
}) => {
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState<RowContentType | Record<string, any> | null>(
    null,
  );
  const { user } = usePlayUser();

  const handleOnClick = useCallback(
    (item: RowContentType | Record<string, any>): void | boolean => {
      if (item) {
        if (item.trackEvent) {
          const { eventType, properties } = item.trackEvent;
          properties.fc_id = user?.userID ?? '';
          avoTrackEvent(eventType, properties);
          if (properties.objectType === 'lesson') {
            properties.is_locked = false;
            avoTrackEvent(EventNames.LESSON_CLICKED, properties);
          }
        }

        return history.push(item.url);
      }
    },
    [history, user],
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent): void => {
      if (selectedItem && e.key === 'Enter') {
        e.preventDefault();
        handleOnClick(selectedItem);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleOnClick, selectedItem]);

  return (
    <>
      {content.map(rowContent => {
        return (
          <CurriculumItem
            key={`table-${tableId}-item-${rowContent.objectID}`}
            rowContent={rowContent}
            handleOnClick={handleOnClick}
            display={display}
            isArtist={isArtist}
            isFullSearchView={isFullSearchView}
            tableId={tableId}
            setSelectedItem={setSelectedItem}
          />
        );
      })}
    </>
  );
};

interface CurriculumItemProps extends Omit<CurriculumSmallProps, 'content'> {
  handleOnClick: (item: RowContentType | Record<string, any>) => void;
  rowContent: RowContentType | Record<string, any>;
  setSelectedItem: (item: RowContentType | Record<string, any>) => void;
}

const CurriculumItem: FC<CurriculumItemProps> = ({
  display,
  handleOnClick,
  isArtist,
  isFullSearchView,
  rowContent,
  setSelectedItem,
  tableId,
}) => {
  const { items, objectType, title, url, trackEvent, isSearch, objectID } = rowContent;
  const { type: skillType } = items[0];
  const { selectedOptionId } = useSearchSelection();
  const styleObj = stylesLarge[getImageStyle(objectType, isArtist)];
  const { height } = styleObj;
  const itemHeightStyle = `${height} flex items-start`;
  const isCollection = objectType === 'collection';
  const collectionDisplay = { level: false, type: false, stats: true };
  const displayInfo = {
    ...display,
    ...(isCollection ? collectionDisplay : { level: skillType !== 'Skill' }),
  };
  const itemRef = useRef<HTMLLIElement>(null);
  const isSelected = selectedOptionId === `table-${tableId}-item-${objectID}`;

  if (isSelected) {
    setSelectedItem(rowContent);
    // Scroll selected item into view if navigating by keyboard
    if (itemRef.current && !isElementVisible(itemRef.current)) {
      itemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  const handleTitleClick = (e: SyntheticEvent): void => {
    e.stopPropagation();
  };

  return (
    <RowContentItem
      onClick={(): void => handleOnClick(rowContent)}
      role="option"
      id={`table-${tableId}-item-${objectID}`}
      ref={itemRef}
      isSelected={isSelected}
    >
      <div className="flex justify-center" style={{ minWidth: isFullSearchView ? '128px' : '' }}>
        {getContentImage(rowContent, styleObj)}
      </div>
      {!isArtist ? (
        <div className="w-100 flex flex-column justify-start f7 f6-ns">
          {items.map((item: RowItem) => (
            <RowContent
              key={`${tableId}-${item.objectID}`}
              display={displayInfo}
              isSearch={isSearch}
              item={item}
              itemHeightStyle={itemHeightStyle}
              itemIsLocked={false}
              onClick={handleTitleClick}
              title={title}
              trackEvent={trackEvent}
              url={url}
            />
          ))}
        </div>
      ) : (
        <div className="w-100 flex flex-column justify-center">
          {getTitle({
            title,
            url,
            trackEvent,
            itemHeightStyle,
            isSearch,
            isLocked: false,
            onClick: handleTitleClick,
          })}
        </div>
      )}
    </RowContentItem>
  );
};

type CurriculumSmallProps = Omit<CurriculumTableProps, 'small'>;

const CurriculumSmall: FC<CurriculumSmallProps> = ({
  content,
  display,
  isArtist,
  isFullSearchView,
  tableId,
}) => {
  if (!content) {
    return null;
  }
  return (
    <CurriculumItems
      content={content}
      display={display}
      isArtist={isArtist}
      isFullSearchView={isFullSearchView}
      tableId={tableId}
    />
  );
};

export default CurriculumSmall;
