import isDomUsable from '@fenderdigital/utils/dom';
import { getAuthenticationHeader } from '@fenderdigital/custom-hooks/lib/cognito';
import { AvoInspector } from 'avo-inspector';
import Avo, { AvoEnv, CustomDestination } from '../Avo';
import { version } from '../../package.json';
import postDirectlyToActivityStream from '../lib/activity-stream';

export const customDestination: CustomDestination = {
  make: (env: AvoEnv): void => {
    console.log('AVO: make called', env);
  },
  logEvent: async (eventName: string, eventProperties: any): Promise<void> => {
    console.log('AVO: logEvent called', eventName, eventProperties);
    try {
      const token = await getAuthenticationHeader(true);
      if (token) postDirectlyToActivityStream(`fender:play:${eventName}`, eventProperties);
    } catch (e) {
      console.log('AVO: catch error for not post to activity stream, for unauthed user.', e);
    }
  },
  identify: (userId: string): void => {
    console.log(`AVO: identify called, ${userId}`);
  },
  logPage: (pageName: string, eventProperties: Record<string, any>): void => {
    console.log(`AVO: logPage called, ${pageName} ${eventProperties}`);
  },
  setUserProperties: () => {},
};

type EnvForAvo = keyof typeof AvoEnv;
const appEnvironment = `${process.env.REACT_APP_ENVIRONMENT}`;
const getEnvForAvo = (): EnvForAvo => (appEnvironment === 'prod' ? 'Prod' : 'Dev');

const getAvoInspector = async (): Promise<AvoInspector | null> => {
  if (isDomUsable()) {
    const env = getEnvForAvo();
    const parseVersion = version.replace('v', '');
    // because the avo-inspector module references the window object,
    // we have to do a dynamic import. if we don't do it this way, it'll
    // cause the server tests to fail.
    const inspector = await import(/* webpackChunkName: "Inspector" */ 'avo-inspector');
    return new inspector.AvoInspector({
      apiKey: Avo.avoInspectorApiKey,
      env: inspector.AvoInspectorEnv[env],
      version: parseVersion,
      appName: 'Play Web',
    });
  }
  return null;
};

class AvoConfig {
  public inspector: AvoInspector | null;
  private env: EnvForAvo;
  constructor() {
    this.env = getEnvForAvo();
    this.inspector = null;
  }

  public async init(): Promise<void> {
    const isWebDebuggerEnabled = !!(appEnvironment === 'qa');
    const inspector = await getAvoInspector();
    this.inspector = inspector;
    if (inspector) {
      inspector.enableLogging(false);
      Avo.initAvo(
        { webDebugger: isWebDebuggerEnabled, env: Avo.AvoEnv[this.env], inspector },
        {},
        customDestination,
      );
    }
  }
}

const avoConfig = new AvoConfig();
export { avoConfig };
