import styled from 'styled-components';
import { SwipeCarouselContentProps, SwipeCarouselEasing as Easing } from './types';

export const SwipeCarouselContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
`;

export const SwipeCarouselBody = styled.div`
  position: relative;
  z-index: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;
`;

export const SwipeCarouselContent = styled.div<SwipeCarouselContentProps>`
  display: flex;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: ${({ w: width }): string => `${width}px`};
  height: 100%;

  transition: all 0.25s ${({ easing = 'ease-in-out' }): Easing => easing};
  transform: ${({ xPos = 0 }): string => `translate3d(${xPos}px, 0, 0)`};
`;
