import React, { FC } from 'react';
import { defineMessages, InjectedIntl, injectIntl } from 'react-intl';
import Heading from '@fenderdigital/ui/Heading';
import ThumbList from '../ThumbList';
import CurriculumTable from '../CurriculumTable';
import { formatTableData, isCustomCategory } from '../../utils/search-utils';
import Link from '../Link';
import { AspectRatio, Item } from '../ThumbList/types';

export type Summary = {
  title?: string;
  url?: string;
  items?: Item[];
  total?: number;
  type?: string;
};

interface SearchSummaryProps {
  intl: InjectedIntl;
  isScrollerEnabled?: boolean;
  onClick?: () => void;
  query?: string;
  small?: boolean;
  summaryArr?: Summary[];
  pill?: boolean;
}

const messages = defineMessages({
  seeAll: { id: 'searchSummary.seeAll', defaultMessage: 'See {total} {type}' },
});

const SearchSummary: FC<SearchSummaryProps> = ({
  intl,
  isScrollerEnabled = false,
  onClick,
  query = '',
  small = false,
  summaryArr = null,
  pill = false,
}) => {
  if (!summaryArr) {
    return null;
  }
  const { formatMessage } = intl;
  const isPanel = pill || small;
  let styles = {
    curriculum: 'dn-ns',
    header: 'f3-ns',
    link: 'f5-ns',
    thumbs: 'db-ns',
    titleContainer: 'mb3 bb b--moon-gray ',
  };
  if (small) {
    styles = { curriculum: '', header: '', link: '', thumbs: '', titleContainer: '' };
  }
  return (
    <ul
      role="listbox"
      aria-labelledby={`${isPanel ? 'nav' : 'page'}-search-label`}
      id={`${isPanel ? 'nav' : 'page'}-search-results`}
    >
      {summaryArr?.map((summaryItem: Summary, index: number): JSX.Element | null => {
        const { items = [], title = '', total = 0, type, url } = summaryItem || {};
        const customCategory = isCustomCategory(title);
        const isCollections = title === 'Collections';
        const isSkills = title === 'Skills';
        const content = formatTableData(items, title, query, false);
        const display = {
          chords: false,
          description: customCategory,
          level: !isCollections && !isSkills,
          stats: isCollections,
        };
        const getImageStyle = (imageTitle: string): AspectRatio => {
          switch (imageTitle) {
            case 'Artists':
              return 'circle';
            case 'Collections':
              return 'wideScreen';
            default:
              return 'square';
          }
        };
        if (!summaryItem || !items.length) return null;
        return (
          <li
            className="mb4"
            data-id={`search-summary-item-${index}`}
            key={`summary-item-${type}-${url}`}
            role="group"
            aria-labelledby={`${isPanel ? 'nav' : 'page'}-search-summary-item-${index}-heading`}
          >
            <div
              className={`pb1 mb1 bb b--light-gray flex items-center justify-between ${styles.titleContainer}`}
            >
              <Heading
                as="h2"
                textSize={small ? 'f6' : ['f6', 'f7']}
                textTransform="capitalize"
                id={`${isPanel ? 'nav' : 'page'}-search-summary-item-${index}-heading`}
              >
                {title}
              </Heading>
              {url && total > 5 && (
                <Link
                  data-id={`search-summary-see-all-${title}`}
                  className={`bg-transparent bn red no-underline f6 source-sans pointer ${styles.link}`}
                  to={url}
                >
                  {formatMessage(messages.seeAll, { total, type: title })}
                </Link>
              )}
            </div>
            <div className={`dn ${styles.thumbs}`} data-id={`search-summary-thumblist-${index}`}>
              <ThumbList
                aspectRatio={getImageStyle(title)}
                circle={title === 'Artists'}
                isScrollerEnabled={isScrollerEnabled}
                itemClasses="mr2 mr3-l"
                itemArr={items || []}
                onClick={onClick}
                showDescription={customCategory}
                variantType="smallThumb"
                isCollections={isCollections}
                isSkills={isSkills}
                pathColor="#666666"
              />
            </div>
            <ul
              className={`db ${styles.curriculum}`}
              data-id={`search-summary-table-${index}`}
              role="listbox"
              aria-labelledby={`${
                pill || small ? 'nav' : 'page'
              }-search-summary-item-${index}-heading`}
            >
              <CurriculumTable
                content={content}
                display={display}
                onClick={onClick}
                isArtist={title === 'Artists'}
                small
                tableId={index}
              />
            </ul>
          </li>
        );
      })}
    </ul>
  );
};

export default injectIntl(SearchSummary);
