import React, { FC, useState } from 'react';
import styled from 'styled-components';
import trim from 'lodash/trim';
import Auth from '@aws-amplify/auth';
import ContentTabs from '@fenderdigital/react-ui-kit/content-tabs';
import isDomUsable from '@fenderdigital/utils/dom';
import { Focus } from '@fenderdigital/ui/utils/css';
import { ArrowDown, ArrowUp } from '@fenderdigital/ui/Icons';
import Login from './Login';
import FeatureFlags from './FeatureFlags';
import { clearLocalStorage } from '../../lib/cognito';

const ExpandButton = styled.button`
  ${Focus};
  cursor: pointer;

  margin: ${({ theme: { spacing } }): string => spacing.sm3};
  padding: ${({ theme: { spacing } }): string => spacing.sm2};
`;

// the ContentTabs component uses a custom attribute for its
// div children called label
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    label?: string;
  }
}

const handleSignIn = (event: React.FormEvent<HTMLFormElement>): void => {
  const username = event.currentTarget.loginName.value;
  const password = event.currentTarget.loginPassword.value;
  Auth.signIn(trim(username.toLowerCase()), trim(password)).then(() => {
    if (isDomUsable()) {
      window.location.reload();
    }
  });
};

const handleSignOut = (): void => {
  Auth.signOut().then(() => {
    if (isDomUsable()) {
      clearLocalStorage();
      window.location.reload();
    }
  });
};

const DeveloperConsoleContent: FC = () => {
  const [isMinimized, setIsMinimized] = useState(true);
  let consoleStyles =
    'br3 br--top br--right bottom-0 bg-near-black light-gray fixed shadow-1 z-9999';
  if (!isMinimized) {
    consoleStyles = `${consoleStyles} w-100 w-50-l mw7 left-0 overflow-auto`;
  }
  return (
    <section className="console">
      <div
        className={consoleStyles}
        style={{
          maxHeight: '75vh',
          backfaceVisibility: 'hidden',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        <header className="flex items-center justify-end w-100" data-id="console-header">
          <ExpandButton
            data-id="console"
            onClick={(): void => setIsMinimized(() => !isMinimized)}
            aria-label="Toggle Dev Console"
          >
            {isMinimized ? <ArrowUp fill="#fff" /> : <ArrowDown fill="#fff" />}
          </ExpandButton>
        </header>
        <div className={`${isMinimized ? 'dn' : 'db'} ph3 pb3`}>
          <ContentTabs
            bgColor="bg-transparent"
            borderColor="dark-gray"
            defaultTab="Login"
            selectedBackgroundColor="bg-transparent"
            selectedTextColor="light-gray"
            textColor="light-gray"
          >
            <div label="Login">
              <Login onSignIn={handleSignIn} onSignOut={handleSignOut} />
            </div>
            <div label="Feature Flags">
              <FeatureFlags />
            </div>
          </ContentTabs>
        </div>
      </div>
    </section>
  );
};

export default DeveloperConsoleContent;
