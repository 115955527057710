import React, { MouseEvent } from 'react';
import Button from '@fenderdigital/ui/Button';
import Link from '../components/Link';

// TextContent Utils
export type StyleCTA = {
  message1: string;
  message2: string;
  url1: string;
  url2: string;
  style1: string;
  style2: string;
};

export const renderPlayButton = (
  themeColor = 'white',
  playButtonAction?: (e: MouseEvent<HTMLButtonElement>) => void | null,
): JSX.Element => (
  <button
    type="button"
    className={`anti-aliased inline-flex items-center justify-center f-headline
                  mb3 bg-transparent pointer ba ${themeColor} b--${themeColor} w3 bw2 h3 br-100`}
    onClick={playButtonAction}
  >
    <i
      className="icon-triangle-right"
      style={{
        lineHeight: 0.35,
        marginLeft: '.3rem',
        height: '1.5rem',
      }}
    />
  </button>
);

export const renderStyleCTA = (obj: StyleCTA): JSX.Element => (
  <>
    <Button as={Link} to={obj.url1}>
      {obj.message1}
    </Button>
    {obj.style2 && <Button>{obj.message2}</Button>}
  </>
);

export const renderCTA = (
  message: string | null,
  url: string | null,
  theme = 'light',
  secondary = false,
  btnSize = 'large',
): JSX.Element | null => {
  if (!message || !url) return null;

  if (!secondary) {
    return (
      <Button as={Link} colorScheme="blue" to={url} size={btnSize === 'large' ? 'large' : 'medium'}>
        {message}
      </Button>
    );
  }

  return (
    <Button
      as={Link}
      colorScheme="transparent"
      size={btnSize === 'large' ? 'large' : 'medium'}
      textColor={theme === 'dark' ? 'white' : 'black'}
      to={url}
    >
      {message}
    </Button>
  );
};

export const renderHeadline = (
  headline: string | null,
  headlineStyles: string,
  isFirstBlock: boolean,
): JSX.Element | null => {
  if (headline && isFirstBlock) {
    return <h1 className={headlineStyles}>{headline}</h1>;
  } else if (headline) {
    return (
      <h2 className={headlineStyles} style={{ fontSize: '2em' }}>
        {headline}
      </h2>
    );
  }

  return null;
};
