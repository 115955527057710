import React, { ComponentType, ReactElement } from 'react';

/**
 * Returns a new React component, ready to be instantiated.
 * Note the closure here protecting Component, and providing a unique
 * instance of Component to the static implementation of `load`.
 */
export default function generateAsyncRouteComponent<
  P = Record<string, unknown>,
  T extends ComponentType<P> = ComponentType<P>
>({
  loader,
  Placeholder,
}: {
  loader: () => Promise<T>;
  Placeholder?: ComponentType<P>;
}): ComponentType<P> & { load: () => Promise<void> } {
  let Component: ComponentType<P> | T;

  return class AsyncRouteComponent extends React.Component<P, { Component: ComponentType<P> | T }> {
    /**
     * Static so that you can call load against an uninstantiated version of
     * this component. This should only be called one time outside of the
     * normal render path.
     */
    static async load(): Promise<void> {
      Component = await loader();
    }

    constructor(props: P) {
      super(props);
      this.state = {
        Component,
      };
    }

    componentDidMount(): void {
      AsyncRouteComponent.load().then(this.updateState);
    }

    updateState = (): void => {
      // Only update state if we don't already have a reference to the
      // component, this prevent unnecessary renders.
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state.Component !== Component) {
        this.setState({
          Component,
        });
      }
    };

    render(): ReactElement | null {
      const { Component: ComponentFromState } = this.state;
      if (ComponentFromState) {
        // As this is an abstract class prop spreading here is legit
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <ComponentFromState {...this.props} />;
      }
      if (Placeholder) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Placeholder {...this.props} />;
      }
      return null;
    }
  };
}
