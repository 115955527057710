import React, { FC } from 'react';
import { renderHeadline } from '../../utils/blocks-utils';

interface BlockSectionProps {
  containerStyles?: string;
  headline?: string;
  headlineStyles?: string;
  isFirstBlock: boolean;
  theme?: string;
}

const BlockSection: FC<BlockSectionProps> = ({
  theme,
  containerStyles = 'mb4 pa3',
  headline = null,
  headlineStyles = 'futura-heavy tc mb2 f2',
  isFirstBlock = false,
}) => {
  const baseStyles = `${
    theme === 'dark' ? 'bg-black white ' : 'bg-white black'
  } ${containerStyles}`;
  const hrStyles = `bt-0 bb ma0 ${theme === 'dark' ? 'b--white' : 'b--light-gray'}`;

  return (
    <section className={`block-section ${baseStyles}`}>
      {renderHeadline(headline, headlineStyles, isFirstBlock)}
      <hr className={hrStyles} />
    </section>
  );
};

export default BlockSection;
