import { connect } from 'react-redux';
import SearchField, { SearchFieldProps } from './SearchField';
import {
  fullSearch,
  instantSearch,
  localResultsToRedux,
  updateFilters,
} from '../../actions/search';
import { RootState } from '../../redux-types/root.redux-types';

const mapState = (state: RootState, props: SearchFieldProps): SearchFieldProps => ({
  ...props,
  filters: state.search.filters,
});
const mapDispatch = { fullSearch, instantSearch, localResultsToRedux, updateFilters };

export default connect(mapState, mapDispatch)(SearchField);
