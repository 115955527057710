import mirror from 'keymirror';
import has from 'lodash/has';
import Library from '../lib/library';
import { getSongByID } from './song';

export const actionTypes = mirror({
  CLEAR_WATCHING: null,
  LESSONS_GET_ERROR: null,
  LESSONS_GET_IN_PROGRESS: null,
  LESSONS_GET_SUCCESS: null,
});

export const clearWatching = () => ({
  type: actionTypes.CLEAR_WATCHING,
});

const lessonsGetInProgress = () => ({
  type: actionTypes.LESSONS_GET_IN_PROGRESS,
});

const lessonsGetError = error => ({
  type: actionTypes.LESSONS_GET_ERROR,
  error,
});

const lessonsGetSuccess = results => ({
  type: actionTypes.LESSONS_GET_SUCCESS,
  results,
});

export function getLessons(options, unseenPath = false, publicEndpoint = false) {
  return async (dispatch, getState) => {
    try {
      dispatch(lessonsGetInProgress());
      const state = getState();
      const results = await Library.getLessons(options, publicEndpoint, unseenPath);
      const hasItems = has(results, 'items');
      if (hasItems) {
        const hasSongID = has(results.items[0], 'song');
        const songID = hasSongID ? results.items[0].song : null;
        await getSongByID(songID)(dispatch, getState);
      }
      dispatch(lessonsGetSuccess(results));
      return results;
    } catch (err) {
      dispatch(lessonsGetError(err));
    }
  };
}
