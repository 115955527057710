import isDomUsable from '@fenderdigital/utils/dom';
import { track } from '@fenderdigital/utils/analytics';
import { dispatchSessionTimer, getSessionData } from '@fenderdigital/utils/sessions';
import { getAuthenticationHeader } from '@fenderdigital/custom-hooks/lib/cognito';
import ttiPolyfill from 'tti-polyfill';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import segment from '../core/segment';
import Cookie from './cookie';
import { getUTMData } from './payments';
import getSubscriptionStatus from './subscription-status';
import { getProductRates } from './plans';
import { AlgoliaEventType, AlgoliaFilters } from '../models/search';
import {
  articlesList,
  activityStarted,
  activityCompleted,
  activityDismissed,
  carouselAdvanced,
  checkout,
  checkoutStarted,
  chordChallengeDetail,
  chordChallengeList,
  chordChallengePicker,
  chordChallengeTour,
  chordsList,
  collectionClicked,
  collectionCourseClicked,
  collectionDetail,
  collectionsList,
  completedOrder,
  courseClicked,
  courseCompleted,
  courseDetail,
  courseMarkedAsComplete,
  courseUnmarkedAsComplete,
  ctaClicked,
  downloadClicked,
  emailSignup,
  favoriteAdded,
  favoriteRemoved,
  favoritesAlert,
  favoritesList,
  favoritesListFilterPicker,
  feedbackMode,
  feedbackModeScoreReceived,
  feedbackModeOnboarding,
  feedbackModeOnboardingAdvanced,
  feedbackModeOnboardingCompleted,
  feedbackModeOnboardingDismissed,
  home,
  homeArticleClicked,
  homeArticlesViewallClicked,
  homeCollectionClicked,
  homeCollectionsViewallClicked,
  homeFeaturedClicked,
  homePathLastClicked,
  homePathNextClicked,
  homePathStartClicked,
  homeHeroCompleted,
  homeHeroContinued,
  homeHeroPaused,
  homeHeroStarted,
  homeProductClicked,
  homeProductsViewallClicked,
  homeRiffClicked,
  homeRiffsViewallClicked,
  homeSongClicked,
  homeSongsViewallClicked,
  instructorClicked,
  landing,
  lessonClicked,
  lessonChordsClicked,
  lessonDetail,
  lessonFeedbackClicked,
  lessonInfoClicked,
  lessonMarkedAsComplete,
  lessonRelatedClicked,
  lessonTablatureClicked,
  lessonToneClicked,
  lessonToolsClicked,
  lessonUnmarkedAsComplete,
  levelClicked,
  markAsCompleteModal,
  modalDismissed,
  modalViewed,
  miniPlayerToggled,
  myPath,
  navigationClicked,
  navigationDismissed,
  navigationOpened,
  onboardingInstrumentPicker,
  onboardingStylePicker,
  onboardingQuizGenre,
  onboardingQuizGenreClicked,
  onboardingQuizGenreSkipped,
  onboardingQuizAbility,
  onboardingQuizAbilityClicked,
  onboardingQuizInstrument,
  onboardingQuizInstrumentClicked,
  onboardingQuizGoalClicked,
  onboardingQuizGoal,
  onboardingTour,
  pathChanged,
  planSelection,
  planAnnualClicked,
  planMonthlyClicked,
  practiceMode,
  practiceModeExit,
  practiceModeLaunched,
  practiceModeNextLessonClicked,
  practiceModePlaybackCheckpointReached,
  presetDownloaded,
  promotionCheckout,
  searchCompleted,
  searchResultClicked,
  searchResults,
  sessionMilestoneCompleted,
  signInLinkClicked,
  sessionStarted,
  skillsList,
  songsList,
  streakIconClicked,
  subscriptionAdBannerClicked,
  subscriptionAdModal,
  subscriptionAdModalAccepted,
  subscriptionAdModalDeclined,
  subscriptionBundle,
  subscriptionLastChanceOffer,
  subscriptionLastChanceOfferAnnualClicked,
  subscriptionLastChanceOfferDeclined,
  subscriptionOffer,
  subscriptionOfferAnnualClicked,
  subscriptionOfferDeclined,
  subscriptionPromotionAnnualClicked,
  subscriptionPromotionBundleClicked,
  subscriptionPromotionMonthlyClicked,
  subscriptionResubscribe,
  subscriptionResubscribeAnnualClicked,
  subscriptionResubscribeDismissed,
  subscriptionResubscribeMonthlyClicked,
  subscriptionUpgrade,
  subscriptionUpgradeAnnualClicked,
  subscriptionUpgradeMonthlyClicked,
  subscriptionUpgradeDismissed,
  tablatureCollapsed,
  tablatureExpanded,
  tablatureLabelChordClicked,
  tablatureZoomChanged,
  tooltipDismissed,
  tooltipViewed,
  trialStarted,
  videoPlaybackBufferCompleted,
  videoPlaybackCompleted,
  videoPlaybackPaused,
  videoPlaybackResumed,
  videoPlaybackStarted,
  videoPlayerBack10,
  videoPlayerForward10,
  videoPlayerScrubBackward,
  videoPlayerScrubForward,
  videoPlayerSpeedChanged,
  videoQualityUpdated,
  watchedVideo,
} from '../Avo';

import packageJson from '../../package.json';

export const siteId = 'play_web_unauth';
const appVersion = packageJson.version.slice(1);

export enum EventNames {
  ARTICLE_LIST = 'Article List',
  ACTIVITY_STARTED = 'Activity Started',
  ACTIVITY_COMPLETED = 'Activity Completed',
  ACTIVITY_DISMISSED = 'Activity Dismissed',
  CAROUSEL_ADVANCED = 'Carousel Advanced',
  CHECKOUT = 'Checkout',
  CHECKOUT_STARTED = 'Checkout Started',
  CHORDS_LIST = 'Chords List',
  CHORD_CHALLENGE_DETAIL = 'Chord Challenge Detail',
  CHORD_CHALLENGE_LIST = 'Chord Challenge List',
  CHORD_CHALLENGE_PICKER = 'Chord Challenge Picker',
  CHORD_CHALLENGE_TOUR = 'Chord Challenge Tour',
  COLLECTION_CLICKED = 'Collection Clicked',
  COLLECTION_COURSE_CLICKED = 'Collection Course Clicked',
  COLLECTIONS_LIST = 'Collections List',
  COLLECTION_DETAIL = 'Collection Detail',
  COMPLETED_ORDER = 'Completed Order',
  COURSE_CLICKED = 'Course Clicked',
  COURSE_COMPLETED = 'Course Completed',
  COURSE_DETAIL = 'Course Detail',
  COURSE_MARKED_AS_COMPLETE = 'Course Marked as Complete',
  COURSE_UNMARKED_AS_COMPLETE = 'Course Unmarked as Complete',
  CTA_CLICKED = 'CTA Clicked',
  DOWNLOAD_CLICKED = 'Download Clicked',
  EMAIL_SIGNUP = 'Email Signup',
  FAVORITE_ADDED = 'Favorite Added',
  FAVORITE_REMOVED = 'Favorite Removed',
  FAVORITES_ALERT = 'Favorites Alert',
  FAVORITES_LIST = 'Favorites List',
  FAVORITES_LIST_FILTER_PICKER = 'Favorites List Filter Picker',
  FEEDBACK_MODE = 'Feedback Mode',
  FEEDBACK_MODE_SCORE_RECEIVED = 'Feedback Mode Score Received',
  FEEDBACK_MODE_ONBOARDING = 'Feedback Mode Onboarding',
  FEEDBACK_MODE_ONBOARDING_ADVANCED = 'Feedback Mode Onboarding Advanced',
  FEEDBACK_MODE_ONBOARDING_COMPLETED = 'Feedback Mode Onboarding Completed',
  FEEDBACK_MODE_ONBOARDING_DISMISSED = 'Feedback Mode Onboarding Dismissed',
  HOME = 'Home',
  HOME_ARTICLE_CLICKED = 'Home Article Clicked',
  HOME_ARTICLES_VIEWALL_CLICKED = 'Home Articles Viewall Clicked',
  HOME_COLLECTION_CLICKED = 'Home Collection Clicked',
  HOME_COLLECTIONS_VIEWALL_CLICKED = 'Home Collections Viewall Clicked',
  HOME_FEATURED_CLICKED = 'Home Featured Clicked',
  HOME_HERO_COMPLETED = 'Home Hero Completed',
  HOME_HERO_CONTINUED = 'Home Hero Continued',
  HOME_HERO_PAUSED = 'Home Hero Paused',
  HOME_HERO_STARTED = 'Home Hero Started',
  HOME_PRODUCT_CLICKED = 'Home Product Clicked',
  HOME_PRODUCT_VIEW_ALL_CLICKED = 'Home Product View All Clicked',
  HOME_RIFF_CLICKED = 'Home Riff Clicked',
  HOME_RIFFS_VIEWALL_CLICKED = 'Home Riffs Viewall Clicked',
  HOME_SONG_CLICKED = 'Home Song Clicked',
  HOME_PATH_LAST_CLICKED = 'Home Path Last Clicked',
  HOME_PATH_NEXT_CLICKED = 'Home Path Next Clicked',
  HOME_PATH_START_CLICKED = 'Home Path Start Clicked',
  HOME_SONGS_VIEWALL_CLICKED = 'Home Songs Viewall Clicked',
  INSTRUCTOR_CLICKED = 'Instructor Clicked',
  LANDING = 'Landing',
  LESSON_CLICKED = 'Lesson Clicked',
  LESSON_CHORDS_CLICKED = 'Lesson Chords Clicked',
  LESSON_FEEDBACK_CLICKED = 'Lesson Feedback Clicked',
  LESSON_INFO_CLICKED = 'Lesson Info Clicked',
  LESSON_RELATED_CLICKED = 'Lesson Related Clicked',
  LESSON_TABLATURE_CLICKED = 'Lesson Tablature Clicked',
  LESSON_TONE_CLICKED = 'Lesson Tone Clicked',
  LESSON_TOOLS_CLICKED = 'Lesson Tools Clicked',
  LESSON_DETAIL = 'Lesson Detail',
  LESSON_MARKED_AS_COMPLETE = 'Lesson Maked as Complete',
  LESSON_UNMARKED_AS_COMPLETE = 'Lesson Unmarked as Complete',
  LEVEL_CLICKED = 'Level Clicked',
  MARK_AS_COMPLETE_MODAL = 'Mark as Complete Modal',
  MODAL_DISMISSED = 'Modal Dismissed',
  MODAL_VIEWED = 'Modal Viewed',
  MINI_PLAYER_TOGGLED = 'Mini Player Toggled',
  MY_PATH = 'My Path',
  NAVIGATION_CLICKED = 'Navigation Clicked',
  NAVIGATION_DISMISSED = 'Navigation Dismissed',
  NAVIGATION_OPENED = 'Navigation Opened',
  ONBOARDING_INSTRUMENT_PICKER = 'Onboarding Instrument Picker',
  ONBOARDING_STYLE_PICKER = 'Onboarding Style Picker',
  ONBOARDING_QUIZ_GENRE = 'Onboarding Quiz Genre',
  ONBOARDING_QUIZ_GENRE_CLICKED = 'Onboarding Quiz Genre Clicked',
  ONBOARDING_QUIZ_GENRE_SKIPPED = 'Onboarding Quiz Genre Skipped',
  ONBOARDING_QUIZ_ABILITY = 'Onboarding Quiz Ability',
  ONBOARDING_QUIZ_ABILITY_CLICKED = 'Onboarding Quiz Ability Clicked',
  ONBOARDING_QUIZ_INSTRUMENT = 'Onboarding Quiz Instrument',
  ONBOARDING_QUIZ_INSTRUMENT_CLICKED = 'Onboarding Quiz Instrument Clicked',
  ONBOARDING_QUIZ_GOAL = 'Onboarding Quiz Goal',
  ONBOARDING_QUIZ_GOAL_CLICKED = 'Onboarding Quiz Goal Clicked',
  ONBOARDING_TOUR = 'Onboarding Tour',
  PATH_CHANGED = 'Path Changed',
  PLAN_SELECTION = 'Plan Selection',
  PLAN_ANNUAL_CLICKED = 'Plan Annual Clicked',
  PLAN_MONTHLY_CLICKED = 'Plan Monthly Clicked',
  PRACTICE_MODE = 'Practice Mode',
  PRACTICE_MODE_EXIT = 'Practice Mode Exit',
  PRACTICE_MODE_LAUNCHED = 'Practice Mode Launched',
  PRACTICE_MODE_NEXT_LESSON_CLICKED = 'Practice Mode Next Lesson Clicked',
  PRACTICE_MODE_PLAYBACK_CHECKPOINT_REACHED = 'Practice Mode Playback Checkpoint Reached',
  PRESET_DOWNLOADED = 'Preset Downloaded',
  PROMOTION_CHECKOUT = 'Promotion Checkout',
  SEARCH_COMPLETED = 'Search Completed',
  SEARCH_RESULTS = 'Search Results',
  SEARCH_RESULT_CLICKED = 'Search Result Clicked',
  SESSION_MILES_STONE_COMPLETED = 'Session Milestone Completed',
  SIGN_IN_LINK_CLICKED = 'Sign In Link Clicked',
  SESSION_STARTED = 'Session Started',
  SKILLS_LIST = 'Skills List',
  SONGS_LIST = 'Songs List',
  STREAK_ICON_CLICKED = 'Streak Icon Clicked',
  SUBSCRIPTION_AD_BANNER_CLICKED = 'Subscription Ad Banner Clicked',
  SUBSCRIPTION_AD_MODAL = 'Subscription Ad Modal',
  SUBSCRIPTION_AD_MODAL_ACCEPTED = 'Subscription Ad Modal Accepted',
  SUBSCRIPTION_AD_MODAL_DECLINED = 'Subscription Ad Modal Declined',
  SUBSCRIPTION_BUNDLE = 'Subscription Bundle',
  SUBSCRIPTION_LAST_CHANCE_OFFER = 'Subscription Last Chance Offer',
  SUBSCRIPTION_LAST_CHANCE_OFFER_ANNUAL_CLICKED = 'Subscription Last Chance Offer Annual Clicked',
  SUBSCRIPTION_LAST_CHANCE_OFFER_DECLINED = 'Subscription Last Chance Offer Declined',
  SUBSCRIPTION_OFFER = 'Subscription Offer',
  SUBSCRIPTION_OFFER_ANNUAL_CLICKED = 'Subscription Offer Annual Clicked',
  SUBSCRIPTION_OFFER_DECLINED = 'Subscription Offer Declined',
  SUBSCRIPTION_PROMOTION_ANNUAL_CLICKED = 'Subscription Promotion Annual Clicked',
  SUBSCRIPTION_PROMOTION_BUNDLE_CLICKED = 'Subscription Promotion Bundle Clicked',
  SUBSCRIPTION_PROMOTION_MONTHLY_CLICKED = 'Subscription Promotion Monthly Clicked',
  SUBSCRIPTION_RESUBSCRIBE = 'Subscription Resubscribe',
  SUBSCRIPTION_RESUBSCRIBE_ANNUAL_CLICKED = 'Subscription Resubscribe Annual Clicked',
  SUBSCRIPTION_RESUBSCRIBE_DISMISSED = 'Subscription Resubscribe Dismissed',
  SUBSCRIPTION_RESUBSCRIBE_MONTHLY_CLICKED = 'Subscription Resubscribe Monthly Clicked',
  SUBSCRIPTION_UPGRADE = 'Subscription Upgrade',
  SUBSCRIPTION_UPGRADE_ANNUAL_CLICKED = 'Subscription Upgrade Annual Clicked',
  SUBSCRIPTION_UPGRADE_MONTHLY_CLICKED = 'Subscription Upgrade Monthly Clicked',
  SUBSCRIPTION_UPGRADE_DISMISSED = 'Subscription Upgrade Dismissed',
  TABLATURE_COLLAPSED = 'Tablature Collapsed',
  TABLATURE_EXPANDED = 'Tablature Expanded',
  TABLATURE_LABEL_CHORD_CLICKED = 'Tablature Label Chord Clicked',
  TABLATURE_ZOOM_CHANGED = 'Tablature Zoom Changed',
  TOOLTIP_DISMISSED = 'Tooltip Dismissed',
  TOOLTIP_VIEWED = 'Tooltip Viewed',
  TRIAL_STARTED = 'Trial Started',
  VIDEO_PLAYBACK_BUFFER_COMPLETED = 'Video Playback Buffer Completed',
  VIDEO_PLAYBACK_COMPLETED = 'Video Playback Completed',
  VIDEO_PLAYBACK_PAUSED = 'Video Playback Paused',
  VIDEO_PLAYBACK_RESUMED = 'Video Playback Resumed',
  VIDEO_PLAYBACK_STARTED = 'Video Playback Started',
  VIDEO_PLAYER_BACK_10 = 'Video Player Back 10',
  VIDEO_PLAYER_FORWARD_10 = 'Video Player Forward 10',
  VIDEO_PLAYER_SCRUB_BACKWARD = 'Video Player Scrub Backward',
  VIDEO_PLAYER_SCRUB_FORWARD = 'Video Player Scrub Forward',
  VIDEO_PLAYER_SPEED_CHANGED = 'Video Player Speed Changed',
  VIDEO_QUALITY_UPDATED = 'Video Quality Updated',
  WATCHED_VIDEO = 'Watched Video',
}

// lodash's camelCase doesn't preserve consecutive uppercase letters,
// which breaks the following (e.g. returning objectId instead of objectID)
const retainKeys = ['objectID', 'queryID'];
// avo functions expect properties to be camelCase
const formatProperties = (obj: Record<any, unknown>): any =>
  mapKeys(obj, (_, key) => {
    if (retainKeys.includes(key)) return key;
    let newKey = key;
    if (key.startsWith('$')) {
      newKey = key.slice(1);
    }
    return camelCase(newKey);
  });

// const prependDollarSymbol = (obj: Record<any, unknown>): any =>
//   mapKeys(obj, (_, key) => {
//     return `$${key}`;
//   });

export const validateTrackingData = (type: EventNames, properties: Record<any, unknown>): any => {
  const avoFuncMap = {
    [EventNames.ARTICLE_LIST]: articlesList,
    [EventNames.ACTIVITY_STARTED]: activityStarted,
    [EventNames.ACTIVITY_COMPLETED]: activityCompleted,
    [EventNames.ACTIVITY_DISMISSED]: activityDismissed,
    [EventNames.CAROUSEL_ADVANCED]: carouselAdvanced,
    [EventNames.CHECKOUT]: checkout,
    [EventNames.CHECKOUT_STARTED]: checkoutStarted,
    [EventNames.CHORDS_LIST]: chordsList,
    [EventNames.CHORD_CHALLENGE_DETAIL]: chordChallengeDetail,
    [EventNames.CHORD_CHALLENGE_LIST]: chordChallengeList,
    [EventNames.CHORD_CHALLENGE_PICKER]: chordChallengePicker,
    [EventNames.CHORD_CHALLENGE_TOUR]: chordChallengeTour,
    [EventNames.COLLECTION_CLICKED]: collectionClicked,
    [EventNames.COLLECTION_COURSE_CLICKED]: collectionCourseClicked,
    [EventNames.COLLECTIONS_LIST]: collectionsList,
    [EventNames.COLLECTION_DETAIL]: collectionDetail,
    [EventNames.COMPLETED_ORDER]: completedOrder,
    [EventNames.COURSE_CLICKED]: courseClicked,
    [EventNames.COURSE_COMPLETED]: courseCompleted,
    [EventNames.COURSE_DETAIL]: courseDetail,
    [EventNames.COURSE_MARKED_AS_COMPLETE]: courseMarkedAsComplete,
    [EventNames.COURSE_UNMARKED_AS_COMPLETE]: courseUnmarkedAsComplete,
    [EventNames.CTA_CLICKED]: ctaClicked,
    [EventNames.DOWNLOAD_CLICKED]: downloadClicked,
    [EventNames.EMAIL_SIGNUP]: emailSignup,
    [EventNames.FAVORITE_REMOVED]: favoriteRemoved,
    [EventNames.FAVORITE_ADDED]: favoriteAdded,
    [EventNames.FAVORITES_ALERT]: favoritesAlert,
    [EventNames.FAVORITES_LIST]: favoritesList,
    [EventNames.FAVORITES_LIST_FILTER_PICKER]: favoritesListFilterPicker,
    [EventNames.FEEDBACK_MODE]: feedbackMode,
    [EventNames.FEEDBACK_MODE_SCORE_RECEIVED]: feedbackModeScoreReceived,
    [EventNames.FEEDBACK_MODE_ONBOARDING]: feedbackModeOnboarding,
    [EventNames.FEEDBACK_MODE_ONBOARDING_ADVANCED]: feedbackModeOnboardingAdvanced,
    [EventNames.FEEDBACK_MODE_ONBOARDING_COMPLETED]: feedbackModeOnboardingCompleted,
    [EventNames.FEEDBACK_MODE_ONBOARDING_DISMISSED]: feedbackModeOnboardingDismissed,
    [EventNames.HOME]: home,
    [EventNames.HOME_ARTICLES_VIEWALL_CLICKED]: homeArticlesViewallClicked,
    [EventNames.HOME_ARTICLE_CLICKED]: homeArticleClicked,
    [EventNames.HOME_COLLECTIONS_VIEWALL_CLICKED]: homeCollectionsViewallClicked,
    [EventNames.HOME_COLLECTION_CLICKED]: homeCollectionClicked,
    [EventNames.HOME_FEATURED_CLICKED]: homeFeaturedClicked,
    [EventNames.HOME_PATH_LAST_CLICKED]: homePathLastClicked,
    [EventNames.HOME_PATH_NEXT_CLICKED]: homePathNextClicked,
    [EventNames.HOME_PATH_START_CLICKED]: homePathStartClicked,
    [EventNames.HOME_HERO_COMPLETED]: homeHeroCompleted,
    [EventNames.HOME_HERO_CONTINUED]: homeHeroContinued,
    [EventNames.HOME_HERO_PAUSED]: homeHeroPaused,
    [EventNames.HOME_HERO_STARTED]: homeHeroStarted,
    [EventNames.HOME_RIFFS_VIEWALL_CLICKED]: homeRiffsViewallClicked,
    [EventNames.HOME_RIFF_CLICKED]: homeRiffClicked,
    [EventNames.HOME_SONGS_VIEWALL_CLICKED]: homeSongsViewallClicked,
    [EventNames.HOME_SONG_CLICKED]: homeSongClicked,
    [EventNames.HOME_PRODUCT_CLICKED]: homeProductClicked,
    [EventNames.HOME_PRODUCT_VIEW_ALL_CLICKED]: homeProductsViewallClicked,
    [EventNames.INSTRUCTOR_CLICKED]: instructorClicked,
    [EventNames.LANDING]: landing,
    [EventNames.LESSON_CLICKED]: lessonClicked,
    [EventNames.LESSON_DETAIL]: lessonDetail,
    [EventNames.LESSON_MARKED_AS_COMPLETE]: lessonMarkedAsComplete,
    [EventNames.LESSON_UNMARKED_AS_COMPLETE]: lessonUnmarkedAsComplete,
    [EventNames.LESSON_CHORDS_CLICKED]: lessonChordsClicked,
    [EventNames.LESSON_FEEDBACK_CLICKED]: lessonFeedbackClicked,
    [EventNames.LESSON_INFO_CLICKED]: lessonInfoClicked,
    [EventNames.LESSON_RELATED_CLICKED]: lessonRelatedClicked,
    [EventNames.LESSON_TABLATURE_CLICKED]: lessonTablatureClicked,
    [EventNames.LESSON_TONE_CLICKED]: lessonToneClicked,
    [EventNames.LESSON_TOOLS_CLICKED]: lessonToolsClicked,
    [EventNames.LEVEL_CLICKED]: levelClicked,
    [EventNames.MARK_AS_COMPLETE_MODAL]: markAsCompleteModal,
    [EventNames.MODAL_DISMISSED]: modalDismissed,
    [EventNames.MODAL_VIEWED]: modalViewed,
    [EventNames.MINI_PLAYER_TOGGLED]: miniPlayerToggled,
    [EventNames.NAVIGATION_CLICKED]: navigationClicked,
    [EventNames.NAVIGATION_DISMISSED]: navigationDismissed,
    [EventNames.NAVIGATION_OPENED]: navigationOpened,
    [EventNames.ONBOARDING_INSTRUMENT_PICKER]: onboardingInstrumentPicker,
    [EventNames.ONBOARDING_STYLE_PICKER]: onboardingStylePicker,
    [EventNames.ONBOARDING_TOUR]: onboardingTour,
    [EventNames.ONBOARDING_QUIZ_GENRE]: onboardingQuizGenre,
    [EventNames.ONBOARDING_QUIZ_GENRE_CLICKED]: onboardingQuizGenreClicked,
    [EventNames.ONBOARDING_QUIZ_GENRE_SKIPPED]: onboardingQuizGenreSkipped,
    [EventNames.ONBOARDING_QUIZ_ABILITY]: onboardingQuizAbility,
    [EventNames.ONBOARDING_QUIZ_ABILITY_CLICKED]: onboardingQuizAbilityClicked,
    [EventNames.ONBOARDING_QUIZ_INSTRUMENT]: onboardingQuizInstrument,
    [EventNames.ONBOARDING_QUIZ_INSTRUMENT_CLICKED]: onboardingQuizInstrumentClicked,
    [EventNames.ONBOARDING_QUIZ_GOAL]: onboardingQuizGoal,
    [EventNames.ONBOARDING_QUIZ_GOAL_CLICKED]: onboardingQuizGoalClicked,
    [EventNames.PATH_CHANGED]: pathChanged,
    [EventNames.PLAN_SELECTION]: planSelection,
    [EventNames.PLAN_ANNUAL_CLICKED]: planAnnualClicked,
    [EventNames.PLAN_MONTHLY_CLICKED]: planMonthlyClicked,
    [EventNames.PRACTICE_MODE]: practiceMode,
    [EventNames.PRACTICE_MODE_EXIT]: practiceModeExit,
    [EventNames.PRACTICE_MODE_LAUNCHED]: practiceModeLaunched,
    [EventNames.PRACTICE_MODE_NEXT_LESSON_CLICKED]: practiceModeNextLessonClicked,
    [EventNames.PRACTICE_MODE_PLAYBACK_CHECKPOINT_REACHED]: practiceModePlaybackCheckpointReached,
    [EventNames.PRESET_DOWNLOADED]: presetDownloaded,
    [EventNames.PROMOTION_CHECKOUT]: promotionCheckout,
    [EventNames.SEARCH_COMPLETED]: searchCompleted,
    [EventNames.SEARCH_RESULT_CLICKED]: searchResultClicked,
    [EventNames.STREAK_ICON_CLICKED]: streakIconClicked,
    [EventNames.SEARCH_RESULTS]: searchResults,
    [EventNames.SESSION_MILES_STONE_COMPLETED]: sessionMilestoneCompleted,
    [EventNames.SIGN_IN_LINK_CLICKED]: signInLinkClicked,
    [EventNames.SESSION_STARTED]: sessionStarted,
    [EventNames.SKILLS_LIST]: skillsList,
    [EventNames.SONGS_LIST]: songsList,
    [EventNames.SUBSCRIPTION_AD_BANNER_CLICKED]: subscriptionAdBannerClicked,
    [EventNames.SUBSCRIPTION_AD_MODAL]: subscriptionAdModal,
    [EventNames.SUBSCRIPTION_AD_MODAL_ACCEPTED]: subscriptionAdModalAccepted,
    [EventNames.SUBSCRIPTION_AD_MODAL_DECLINED]: subscriptionAdModalDeclined,
    [EventNames.SUBSCRIPTION_BUNDLE]: subscriptionBundle,
    [EventNames.SUBSCRIPTION_PROMOTION_ANNUAL_CLICKED]: subscriptionPromotionAnnualClicked,
    [EventNames.SUBSCRIPTION_PROMOTION_BUNDLE_CLICKED]: subscriptionPromotionBundleClicked,
    [EventNames.SUBSCRIPTION_PROMOTION_MONTHLY_CLICKED]: subscriptionPromotionMonthlyClicked,
    [EventNames.SUBSCRIPTION_LAST_CHANCE_OFFER]: subscriptionLastChanceOffer,
    [EventNames.SUBSCRIPTION_LAST_CHANCE_OFFER_ANNUAL_CLICKED]: subscriptionLastChanceOfferAnnualClicked,
    [EventNames.SUBSCRIPTION_LAST_CHANCE_OFFER_DECLINED]: subscriptionLastChanceOfferDeclined,
    [EventNames.SUBSCRIPTION_OFFER]: subscriptionOffer,
    [EventNames.SUBSCRIPTION_OFFER_ANNUAL_CLICKED]: subscriptionOfferAnnualClicked,
    [EventNames.SUBSCRIPTION_OFFER_DECLINED]: subscriptionOfferDeclined,
    [EventNames.SUBSCRIPTION_RESUBSCRIBE]: subscriptionResubscribe,
    [EventNames.SUBSCRIPTION_RESUBSCRIBE_ANNUAL_CLICKED]: subscriptionResubscribeAnnualClicked,
    [EventNames.SUBSCRIPTION_RESUBSCRIBE_DISMISSED]: subscriptionResubscribeDismissed,
    [EventNames.SUBSCRIPTION_RESUBSCRIBE_MONTHLY_CLICKED]: subscriptionResubscribeMonthlyClicked,
    [EventNames.SUBSCRIPTION_UPGRADE]: subscriptionUpgrade,
    [EventNames.SUBSCRIPTION_UPGRADE_ANNUAL_CLICKED]: subscriptionUpgradeAnnualClicked,
    [EventNames.SUBSCRIPTION_UPGRADE_MONTHLY_CLICKED]: subscriptionUpgradeMonthlyClicked,
    [EventNames.SUBSCRIPTION_UPGRADE_DISMISSED]: subscriptionUpgradeDismissed,
    [EventNames.TABLATURE_COLLAPSED]: tablatureCollapsed,
    [EventNames.TABLATURE_EXPANDED]: tablatureExpanded,
    [EventNames.TABLATURE_LABEL_CHORD_CLICKED]: tablatureLabelChordClicked,
    [EventNames.TABLATURE_ZOOM_CHANGED]: tablatureZoomChanged,
    [EventNames.TOOLTIP_DISMISSED]: tooltipDismissed,
    [EventNames.TOOLTIP_VIEWED]: tooltipViewed,
    [EventNames.TRIAL_STARTED]: trialStarted,
    [EventNames.VIDEO_PLAYBACK_BUFFER_COMPLETED]: videoPlaybackBufferCompleted,
    [EventNames.VIDEO_PLAYBACK_COMPLETED]: videoPlaybackCompleted,
    [EventNames.VIDEO_PLAYBACK_PAUSED]: videoPlaybackPaused,
    [EventNames.VIDEO_PLAYBACK_RESUMED]: videoPlaybackResumed,
    [EventNames.VIDEO_PLAYBACK_STARTED]: videoPlaybackStarted,
    [EventNames.VIDEO_PLAYER_SCRUB_BACKWARD]: videoPlayerScrubBackward,
    [EventNames.VIDEO_PLAYER_SCRUB_FORWARD]: videoPlayerScrubForward,
    [EventNames.VIDEO_PLAYER_SPEED_CHANGED]: videoPlayerSpeedChanged,
    [EventNames.VIDEO_QUALITY_UPDATED]: videoQualityUpdated,
    [EventNames.VIDEO_PLAYER_BACK_10]: videoPlayerBack10,
    [EventNames.VIDEO_PLAYER_FORWARD_10]: videoPlayerForward10,
    [EventNames.WATCHED_VIDEO]: watchedVideo,
    [EventNames.MY_PATH]: myPath,
  };

  if (avoFuncMap?.[type]) {
    dispatchSessionTimer();
    return avoFuncMap[type](formatProperties(properties));
  }
  console.error(`event type ${type} not found`);
};

const defaultAttributions = [
  'web_campaign_source_first',
  'web_campaign_source_last',
  'web_campaign_name_first',
  'web_campaign_name_last',
  'web_campaign_medium_first',
  'web_campaign_medium_last',
];

export const getPersonaTraits = async (
  userId: string,
  traits = defaultAttributions,
): Promise<any> => {
  const PERSONAS_API = process.env.REACT_APP_PERSONAS_API;
  const clientName = process.env.REACT_APP_CLIENT_SITE_ID || 'play';
  const Authorization = await getAuthenticationHeader();
  const traitsEncoded = encodeURIComponent(traits.join(','));

  try {
    const resp = await fetch(`${PERSONAS_API}?id=${userId}&include=${traitsEncoded}`, {
      method: 'GET',
      headers: {
        Authorization,
        'Content-Type': 'application/json',
        'X-Client-Name': clientName,
      },
    });
    return resp.json();
  } catch (e) {
    console.error(e);
  }
};

export const trackPageEvents = (
  eventName: EventNames,
  properties = {},
  legacyProperties = {},
): void => {
  const trackingProperties = {
    ...properties,
    name: eventName,
    site_id: siteId,
  };
  const avoProps = {
    customDestinationPageName_: eventName,
  };

  const { fcSessionId: fc_session_id } = getSessionData() || {};
  validateTrackingData(eventName, { ...trackingProperties, ...avoProps, fc_session_id });
  segment.pageTrack('', eventName, { ...trackingProperties, ...legacyProperties, fc_session_id });
};

export const trackEvent = (
  eventName: EventNames,
  properties = {},
  legacyProperties = {},
  withCountry = false,
  withAppVersion = false,
): void => {
  const trackingProperties = {
    ...properties,
    site_id: siteId,
    ...(withCountry && { country_code: Cookie.getCountry() }),
    ...(withAppVersion && { appversion: appVersion }),
  };

  const { fcSessionId: fc_session_id } = getSessionData() || {};
  validateTrackingData(eventName, { ...trackingProperties, fc_session_id });
  segment.event(eventName, { ...trackingProperties, ...legacyProperties, fc_session_id });
};

export const trackEventAndUpdate = (
  userID: string | unknown,
  eventName: EventNames,
  properties: Record<any, unknown>,
  userProperties: Record<any, unknown>,
  legacyProperties = {},
): void => {
  const trackingProperties = {
    ...properties,
    site_id: siteId,
  };

  const { fcSessionId: fc_session_id } = getSessionData() || {};

  validateTrackingData(eventName, { ...trackingProperties, ...userProperties, fc_session_id });
  segment.event(eventName, { ...trackingProperties, ...legacyProperties, fc_session_id });
  segment.updateUser(userID, userProperties);
};

export const trackAndUpdateMyPath = (
  userId: string,
  eventProperties: Record<any, unknown>,
  userProperties: Record<any, unknown>,
  legacyProperties = {},
): void => {
  const eventName = EventNames.MY_PATH;

  const trackingProperties = {
    ...eventProperties,
    site_id: siteId,
    name: eventName,
    ...getAlgoliaTracking('view'),
  };

  const { fcSessionId: fc_session_id } = getSessionData() || {};

  validateTrackingData(eventName, { ...trackingProperties, ...userProperties, fc_session_id });
  segment.pageTrack('', eventName, { ...trackingProperties, fc_session_id });
  segment.updateUser(userId, { ...userProperties, ...legacyProperties });
};

export function getPracticeTrackEvent(eventType: any, lessonObj: any, path = ''): any {
  const { id, title } = lessonObj || {};
  return {
    eventType,
    properties: {
      practice_sheet_id: id,
      practice_sheet_title: title,
      referrer: path,
    },
  };
}

export function getSearchTrackEvent(
  hit: any,
  query = '',
  index = 0,
  queryID = '',
  filters: AlgoliaFilters = [],
): any {
  if (!hit) return null;
  const { objectType, slug, type, objectID, title } = hit;
  return {
    eventType: EventNames.SEARCH_RESULT_CLICKED,
    properties: {
      lesson_type: type,
      lesson_type1: type,
      lesson_id: objectID,
      lesson_name: title,
      objectType,
      search_location: isDomUsable() ? window.location.pathname : '',
      search_text: query,
      selection_location: index,
      lesson_slug: slug,
      slug,
      ...getAlgoliaTracking('click', objectID, index, queryID, filters),
    },
  };
}

export const getAlgoliaTracking = (
  eventType: AlgoliaEventType,
  objectID?: string,
  position?: any,
  queryID?: string,
  filters?: AlgoliaFilters,
): any => ({
  index: `${process.env.REACT_APP_CLIENT_ALGOLIA_APP_NAME}`,
  eventType,
  objectID,
  position,
  queryID,
  filters,
});

function getUserTracking(userID?: string, signedUp = false): any {
  if (!userID) return {};
  return {
    cart_id: userID,
    fcId: userID,
    order_id: userID,
    signedUp,
  };
}

function getPlanTracking(plan?: any): any {
  if (!plan) return {};
  const { name, pricing } = plan;
  const { currency, price } = pricing || {};
  const sku = plan ? plan['product-rate-plan-sku'] : '0001';
  const parsedPrice = price ? parseFloat(price) : 0.0;
  return {
    currency,
    revenue: parsedPrice,
    sku,
    total: parsedPrice,
    products: [
      {
        quantity: 1,
        name: `Fender Play ${name}`,
        sku,
        id: sku,
        product_id: sku,
        price: parsedPrice,
      },
    ],
  };
}

export function getSessionTracking(): any {
  const utm = getUTMData();
  return {
    affiliation: Cookie.getAffiliateId(),
    clickRef: Cookie.getClickRef(),
    country_code: Cookie.getCountry(),
    campaignId: Cookie.getCampaignId(),
    templateId: Cookie.getTemplateId(),
    ...utm,
  };
}

function getPaymentTracking(payment?: any): any {
  if (!payment) return {};
  const { paymentError, paymentMethod, redemptionCode } = payment;
  return {
    payment_method: paymentMethod,
    payment_error: paymentError,
    promo_code: redemptionCode,
  };
}

function getLocationTracking(type: string): any {
  const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();
  return type === EventNames.COMPLETED_ORDER ? { time_zone_identifier: timeZone, locale } : {};
}

export function trackOrder(
  type = 'Track Order',
  userID: string,
  selectedPlan: any,
  payment: any,
  properties?: any,
): void {
  const { signedUp } = payment || {};
  const trackProperties = {
    ...properties,
    ...getUserTracking(userID, signedUp),
    ...getPaymentTracking(payment),
    ...getPlanTracking(selectedPlan),
    ...getSessionTracking(),
    ...getLocationTracking(type),
  };
  segment.event(type, trackProperties);
}

export const trackCheckoutStarted = (userID?: string): void => {
  const eventName = EventNames.CHECKOUT_STARTED;
  const session = getSessionTracking();

  const legacyProperties = {
    ...getUserTracking(userID),
    ...getPaymentTracking(),
    ...getPlanTracking(),
    ...getSessionTracking(),
    ...getLocationTracking(eventName),
  };

  const { fcSessionId: fc_session_id } = getSessionData() || {};
  // all the product related properties are not available at the time this is triggered
  const trackProperties = {
    fc_id: userID,
    site_id: siteId,
    utm_medium: session?.['utm-medium'],
    utm_source: session?.['utm-source'],
    utm_campaign: session?.['utm-campaign'],
    $revenueType: null,
    currency: null,
    currency_code: null,
    order_id: userID,
    total: null,
    sku: null,
    products: null,
    campaignId: session?.campaignId,
    country_code: session?.country_code,
    templateId: session?.templateId,
  };

  validateTrackingData(eventName, { ...trackProperties, fc_session_id });
  segment.event(eventName, { ...trackProperties, ...legacyProperties, fc_session_id });
};

export const FreemiumSKU = 'Fender_FreemiumTrial_Web';

export const trackTrialStart = async (userID: string, sku: string): Promise<void> => {
  const eventName = EventNames.TRIAL_STARTED;
  const { country_code } = getSessionTracking() ?? {};
  const SkusWithTrials = [FreemiumSKU];

  const trackProperties = {
    fc_id: userID,
    site_id: siteId,
    value: 0,
    sku,
  };

  const { fcSessionId: fc_session_id } = getSessionData() || {};

  try {
    const plans = await getProductRates(country_code);
    if (plans) {
      plans.items.forEach(plan => {
        if (plan?.['free-trial-days'] > 0) {
          SkusWithTrials.push(plan?.['product-rate-plan-sku']);
        }
      });
    }
    if (!SkusWithTrials.includes(sku)) return;

    const { traits } = (await getPersonaTraits(userID)) ?? {};
    const traitsMap = {
      utm_source_first: traits?.web_campaign_source_first,
      utm_source_last: traits?.web_campaign_source_last,
      utm_medium_first: traits?.web_campaign_medium_first,
      utm_medium_last: traits?.web_campaign_medium_last,
      utm_campaign_first: traits?.web_campaign_name_first,
      utm_campaign_last: traits?.web_campaign_name_last,
    };
    validateTrackingData(eventName, { ...trackProperties, ...traitsMap, fc_session_id });
    segment.event(eventName, { ...trackProperties, ...traitsMap, fc_session_id });
  } catch (e) {
    console.error(e);
    validateTrackingData(eventName, { ...trackProperties, fc_session_id });
    segment.event(eventName, { ...trackProperties, fc_session_id });
  }
};

export const trackUserIdentity = async (userId?: string): Promise<void> => {
  if (!userId) return;
  try {
    const payload = await getSubscriptionStatus(userId);
    const { play } = payload;
    const traits = {
      play_sub_created: play?.['created-at'],
      play_sub_iap: play?.['is-iap'],
      play_sub_payment_source: play?.['payment-source'],
      play_sub_sku: play?.['product-rate-plan-sku'],
      play_sub_status: play?.status,
    };
    segment.updateUser(userId, traits);
  } catch (e) {
    console.error(e);
  }
};

export function trackNewOnPlay(properties: any): void {
  const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();
  const { userID } = properties || {};
  const { fcSessionId: fc_session_id } = getSessionData() || {};

  track('New on Play', {
    locale,
    timezone: timeZone,
    fc_id: userID,
    fc_session_id,
    site_id: siteId,
  });
}

function onGAReady(ga: any): void {
  ttiPolyfill.getFirstConsistentlyInteractive().then(tti => {
    ga('send', {
      hitType: 'timing',
      timingCategory: 'Performance Metrics',
      timingVar: 'TTI',
      timingValue: tti,
    });
  });

  // set in index.html
  // eslint-disable-next-line no-underscore-dangle
  if (Array.isArray(window._fender_perf)) {
    // eslint-disable-next-line no-underscore-dangle
    window._fender_perf.forEach(([metricName, time]) => {
      ga('send', {
        hitType: 'timing',
        timingCategory: 'Performance Metrics',
        timingVar: metricName,
        timingValue: time,
      });
    });
  }
}

export function trackPerformanceMetrics(): void {
  let attempts = 0;
  // using this instead of segment.ready as anything blocked by adblock will
  // cause ready to not fire. twitter ads for example
  const gaReadyInterval = setInterval(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.ga || attempts > 100) {
      clearInterval(gaReadyInterval);
    }

    if (window.ga) {
      onGAReady(window.ga);
    }
    attempts++;
  }, 250);
}
