import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Course, Recents, Collection } from '@fenderdigital/api-definitions/play';
import { ProgressListViewType } from '../ProgressListViewType';
import PathCard from '../PathCard';

interface CourseListProps {
  courses: Course[];
  collection?: Collection;
  recentlyViewed?: { [key: number]: Recents };
  url: string;
  view: ProgressListViewType;
  markCompleteData?: any;
}

interface ProgressLayoutWrapperProps {
  isCourseDetail?: boolean | null;
}

const Container = styled.div<ProgressLayoutWrapperProps>`
  padding: 0px 1rem;
  margin: auto auto 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  list-style-type: none;

  ${up('tablet-portrait')} {
    padding: 0px 2rem;
    justify-content: flex-start;
  }
`;

const CourseList: FC<CourseListProps> = ({
  courses,
  collection,
  recentlyViewed,
  url,
  view,
  markCompleteData,
}) => {
  return (
    <Container data-id="progress-list">
      {courses?.map(
        (courseData: Course, courseIndex: number): ReactElement => {
          const { id, slug } = courseData;
          const { 'course-id': recentlyViewedCourseId } = recentlyViewed?.[0] || {};
          const indexOfCourse = courseIndex + 1;
          const recentlyViewedCourse = recentlyViewedCourseId === id;
          const routeBase = 'courses';
          const toURL = `${
            url.indexOf('course') < 0 || view === 'collectionDetail' ? `/${routeBase}/${slug}` : ''
          }`;
          return (
            <PathCard
              collectionId={collection?.id}
              collectionTitle={collection?.title}
              courseData={courseData}
              courseNumber={indexOfCourse ?? 0}
              recentlyViewedCourse={recentlyViewedCourse}
              percentCompleted={0}
              markCompleteData={markCompleteData}
              toURL={toURL}
              key={id}
            />
          );
        },
      )}
    </Container>
  );
};

export default CourseList;
