import fetch from '../core/fetch';
import { parseResponse } from '../core/fetch/response';

export default class SongsLists {
  static async getSongsLists(instrument, featured = false) {
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}/songs-lists${
      instrument ? `?instruments=${instrument}` : ''
    }&featured=${featured}`;
    let params = {};
    params = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(url, params);
    return parseResponse(response);
  }
}
