import React, { FC } from 'react';
import Markdown from 'react-markdown';
import Image, { getVariantUrls } from '@fenderdigital/react-ui-kit/image';
import {
  renderCTA,
  renderHeadline,
  renderPlayButton,
  renderStyleCTA,
  StyleCTA,
} from '../../utils/blocks-utils';

interface TextContentProps {
  contentPlacement: string;
  cta1: string | null;
  cta1Url: string | null;
  cta1Styles?: string | null;
  cta2?: string | null;
  cta2Url?: string | null;
  cta2Styles?: string | null;
  description: string | null;
  headline: string | null;
  headlineStyles?: string;
  isFirstBlock: boolean;
  logoImage: string | null;
  mobileLogoImage: string | null;
  playButtonAction: (e: any) => void | null;
  subHeadline: string | null;
  subHeadlineStyles?: string;
  theme?: string;
}

const TextContent: FC<TextContentProps> = ({
  contentPlacement = 'middle-center',
  cta1 = null,
  cta1Url = null,
  cta1Styles = null,
  cta2 = null,
  cta2Url = null,
  cta2Styles = null,
  description = null,
  headline = null,
  headlineStyles = 'f1 futura-heavy ma0',
  isFirstBlock = false,
  logoImage = null,
  mobileLogoImage = null,
  playButtonAction = null,
  subHeadline = null,
  subHeadlineStyles = 'f5 f4-l lh-title futura-book ma0 mb2',
  theme = 'light',
}) => {
  const themeColor = `${theme === 'dark' ? 'white' : 'black'}`;
  const descriptionStyles = `block-markdown ${contentPlacement} f5 f6-l ${
    cta1 ? 'mb3' : ''
  } mh0 mv2 lh-copy tl`;
  const ctaAlignment = contentPlacement.indexOf('center') > 1 ? 'tc' : 'tl';
  const styleCTA = {
    message1: cta1,
    message2: cta2,
    url1: cta1Url,
    url2: cta2Url,
    style1: cta1Styles,
    style2: cta2Styles,
  } as StyleCTA;

  return (
    <div className="text-content mw7-l">
      {playButtonAction && renderPlayButton(themeColor, playButtonAction)}
      {logoImage && (
        <div className="mv3 dn db-l">
          <Image bgColor="transparent" images={getVariantUrls(logoImage, null)} />
        </div>
      )}
      {mobileLogoImage && (
        <div className="mv3 dn-l">
          <Image bgColor="transparent" images={getVariantUrls(mobileLogoImage, null)} />
        </div>
      )}
      {subHeadline && <h5 className={subHeadlineStyles}>{subHeadline}</h5>}
      {renderHeadline(headline, headlineStyles, isFirstBlock)}
      {description && <Markdown className={descriptionStyles}>{description}</Markdown>}
      <div className={`pl0 mt3-l ${ctaAlignment}`}>
        <div>
          {cta1Styles ? (
            renderStyleCTA(styleCTA)
          ) : (
            <>
              {renderCTA(cta1, cta1Url, theme)}
              {renderCTA(cta2, cta2Url, theme, true)}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TextContent;
