import reduce from 'lodash/reduce';
import uniqBy from 'lodash/uniqBy';
import includes from 'lodash/includes';
import truncate from 'lodash/truncate';
import AlgoliaSearch from 'algoliasearch';
import { getSearchTrackEvent } from '../lib/analytics';
import {
  FilterItem,
  SummaryItem,
  FilterData,
  AlgoliaInstrumentMap,
  AlgoliaTypeMap,
  Facets,
  SummarySections,
  ArtistInSearch,
  InstrumentGroup,
  AlgoliaObjectType,
  AlgoliaType,
  AlgoliaFacetFilters,
  AlgoliaFilters,
  Hit,
  SearchUserData,
  HitOverlay,
} from '../models/search';
import { Item } from '../components/ThumbList/types';

const defaultFacets = [
  'artists.name',
  'instrument',
  'lessonCount',
  'level',
  'objectType',
  'style',
  'type',
];
const filterKeyArr = {
  instrument: 'Instrument',
  objectType: 'objectType',
  type: 'Type',
  level: 'level',
  style: 'Genre',
  'artists.name': 'Artists',
};
const skillTypes = [
  'basics',
  'chord',
  'exercise',
  'glossary',
  'skill',
  'technique',
  'theory',
  'tone',
];
const guitarTypes = ['acoustic-guitar', 'electric-guitar'];

type FacetFilter = {
  current: string | null;
  items: FilterItem[];
  label: string;
};

const generateFilterItem = (
  id: string,
  subTitle: string | number,
  title: string,
  url: string,
): FilterItem => ({ id, subTitle, title, url });

const generateSummaryItem = (
  items: [],
  title: string,
  total: number | string = 0,
  url = '',
): SummaryItem => ({
  items,
  title,
  total,
  url,
});

const searchBasePath = '/search/results';

function clearFilters(filterObj: FilterData): FilterData {
  const filters: { [key: string]: string | null } = {};
  Object.keys(filterKeyArr).forEach((key: string) => {
    filters[key] = null;
  });
  return { ...filters, ...filterObj };
}

function getFacetsTotal(
  facets?: AlgoliaInstrumentMap | AlgoliaTypeMap,
  keyArr: string[] = [],
): number | string {
  if (!facets) return 0;
  return reduce(
    facets,
    (sum, value, key) => {
      if (keyArr.includes(key)) {
        return sum + value;
      }
      return sum;
    },
    0,
  );
}

function getSummaryTitles(query = '', facets: Facets, instrument = 'guitar'): SummarySections {
  const facetTypes = facets?.type;
  const { collection } = facets?.objectType || {};
  const skillTotal = getFacetsTotal(facetTypes, skillTypes);
  return {
    collections: generateSummaryItem(
      [],
      'Collections',
      collection || 0,
      `${searchBasePath}?query=${query}&instrument=${instrument}&objectType=collection`,
    ),
    song: generateSummaryItem(
      [],
      'Songs',
      facetTypes?.song || 0,
      `${searchBasePath}?query=${query}&instrument=${instrument}&type=song`,
    ),
    riff: generateSummaryItem(
      [],
      'Riffs',
      facetTypes?.riff || 0,
      `${searchBasePath}?query=${query}&instrument=${instrument}&type=riff`,
    ),
    artists: generateSummaryItem([], 'Artists'),
    skill: generateSummaryItem(
      [],
      'Skills',
      skillTotal || 0,
      `${searchBasePath}?query=${query}&instrument=${instrument}&type=skill`,
    ),
  };
}

function getArtistSummaryItems(
  hit: Hit = {},
  artists: ArtistInSearch[],
  query: string,
): Record<string, unknown> {
  const artist = artists?.length ? artists[0] : { name: '', image: '' };
  const { image, name } = artist;
  const { instrument = '' } = hit;
  const formattedInstrument = instrument.indexOf('guitar') > -1 ? 'guitar' : instrument;
  // We do not want to include access-levels in an artist summary item
  const { 'access-levels': accessLevels, ...hitProperties } = hit;
  return {
    ...hitProperties,
    artists: null,
    image,
    level: null,
    title: name,
    type: 'artist',
    url: `${searchBasePath}?query=${query}&instrument=${formattedInstrument}&artist=${encodeURIComponent(
      name,
    )}`,
  };
}

function getFieldStyles(pill: boolean, small: boolean): { inputStyle: string } {
  const smallStyle = small ? '' : 'pa3-l f3-l';
  const inputNormal = `ba br-0 b--moon-gray br2 br--left pa2 f6 source-sans ${smallStyle} w-100 inputSelectedRed`;
  const inputPill = `ba br-pill outline-0 pa2 pl4 bg-transparent w-100 b--silver black`;
  const inputStyle = `${pill ? inputPill : inputNormal}`;
  return { inputStyle };
}

function parseUserDataObject(dataObj: SearchUserData, query: string): SearchUserData {
  const hit = {
    ...dataObj,
    slug: dataObj.url,
    type: 'custom-search',
  };
  return {
    ...dataObj,
    trackEvent: getSearchTrackEvent(hit, query),
  };
}

function parseHitOverlay(type: string, title = ''): HitOverlay {
  return {
    overlayColor: type === 'chord' ? 'blue' : '',
    overlayTitle: type === 'chord' ? title.split(' ')[0] : '',
  };
}

function addHitToCategory(summaryTitles: SummarySections, hit: Hit, category: string): void {
  // eslint-disable-next-line
  // @ts-ignore
  if (!summaryTitles[category]) {
    // eslint-disable-next-line
    // @ts-ignore
    summaryTitles[category] = generateSummaryItem([], category, 0);
  }
  // eslint-disable-next-line
  // @ts-ignore
  summaryTitles[category].items.push(hit);
}

function addHitToSummary(summaryTitles: SummarySections, hit: Hit, query: string): number | void {
  const { artists, category, title, type, objectType } = hit;
  if (artists) {
    summaryTitles.artists.items.push(getArtistSummaryItems(hit, artists, query));
  }
  if (category) {
    return addHitToCategory(summaryTitles, hit, category);
  }
  if (type === 'song' || type === 'riff') {
    // eslint-disable-next-line
    // @ts-ignore
    return summaryTitles[type].items.push(hit);
  }
  if (objectType === 'collection') {
    return summaryTitles.collections.items.push(hit);
  }
  return summaryTitles.skill.items.push({
    ...hit,
    ...parseHitOverlay(type, title),
    type,
  });
}

function formatSummary(
  hits: Hit[],
  facets: Facets,
  query: string,
  instrument: InstrumentGroup,
  queryID = '',
  filters = {},
  hitsNum = 6,
): Record<string, unknown>[] | [] {
  if (!hits) return [];
  const summaryTitles = getSummaryTitles(query, facets, instrument);
  const activeFilters = formatFiltersForAlgolia(filters);
  hits.forEach((hit, index) => {
    const parsedHit = {
      ...hit,
      trackEvent: getSearchTrackEvent(hit, query, index, queryID, activeFilters),
    };
    addHitToSummary(summaryTitles, parsedHit, query);
  });
  summaryTitles.artists.items = uniqBy(summaryTitles.artists.items, 'title');
  return Object.keys(summaryTitles).map(key => {
    // eslint-disable-next-line
    // @ts-ignore
    const summary = summaryTitles[key];
    return { ...summary, items: summary.items.slice(0, hitsNum) };
  });
}

function getParsedArtist(hit: Item): string | null {
  const { _highlightResult = {} } = hit;
  const { artists } = _highlightResult;
  if (!Array.isArray(artists)) {
    if (process.env.ENVIRONMENT !== 'prod') {
      // eslint-disable-next-line
      console.warn('artist field is missing in search hit', hit);
    }
    return null;
  }

  if (artists.length > 0 && artists?.[0]?.name) {
    return artists[0].name.value;
  }

  return null;
}

function getTableItemTitle(hit: Item = {}): string {
  const { artists, objectType, type = '' } = hit;
  if (type === 'artist') return '';
  if (type === 'song' || type === 'riff') {
    const artist = artists ? artists[0] : { name: '' };
    const artistName = getParsedArtist(hit) || truncate(artist.name);
    return artistName;
  }
  return objectType === 'course' ? 'Course' : type;
}

function getTableItemType(type: string): string {
  if (type !== 'artist' && type !== 'song' && type !== 'riff') {
    return 'Skill';
  }
  return type;
}

function getParsedTitle(hit: Item, title: string): string {
  const isArtist = title === 'Artists';
  // eslint-disable-next-line no-underscore-dangle
  if (hit._highlightResult?.title && !isArtist) {
    // eslint-disable-next-line no-underscore-dangle
    return hit?._highlightResult.title.value;
  }
  return hit?.title || '';
}

const getUrlPrifix = (
  objectType: AlgoliaObjectType | string,
  hasSubscription: boolean,
  instrument: InstrumentGroup | string,
): string => {
  switch (objectType) {
    case 'lesson':
      const lessonPrefix = hasSubscription ? 'lesson' : 'lessons';
      return `/${lessonPrefix}`;
    case 'course':
      const coursePrefix = hasSubscription ? 'course' : 'courses';
      return `/${coursePrefix}`;
    case 'collection':
      const isGuitar = instrument.indexOf('guitar') > -1;
      const formattedInstrument = isGuitar ? 'guitar' : instrument;
      return `/${formattedInstrument}/collections`;
    default:
      return '';
  }
};

const formatFiltersForAlgolia = (filters: FilterData): AlgoliaFilters => {
  const activeFilters = [];
  for (const [type, value] of Object.entries(filters)) {
    if (value) {
      activeFilters.push({ type, value });
    }
  }
  return activeFilters;
};

function formatTableData(
  hits: Item[],
  title: string,
  query = '',
  hasSubscription: boolean,
  queryID = '',
  filters = {},
): Record<string, unknown>[] {
  if (!hits || !hits.length) return [];
  const activeFilters = formatFiltersForAlgolia(filters);
  return hits.map((hit: Item, index: number) => {
    const { objectType = '', slug, url, instrument = '', type = '' } = hit;
    const urlPrefix = getUrlPrifix(objectType, hasSubscription, instrument);
    const itemUrl = url || `${urlPrefix}/${slug}`;
    const trackEvent = getSearchTrackEvent(hit, query, index, queryID, activeFilters);
    return {
      ...hit,
      trackEvent,
      url: itemUrl,
      title: getParsedTitle(hit, title),
      isSearch: true,
      items: [
        {
          ...hit,
          title: getTableItemTitle(hit),
          trackEvent,
          type: getTableItemType(type),
          url: itemUrl,
        },
      ],
    };
  });
}

function getInstrumentFacetFilter(
  current: string,
  facetInstruments?: AlgoliaInstrumentMap,
  query?: string,
): FacetFilter | null {
  if (!facetInstruments) return null;
  const instrumentObj = {
    guitar: generateFilterItem(
      'guitar',
      getFacetsTotal(facetInstruments, guitarTypes) || 0,
      'Guitar',
      `${searchBasePath}?query=${query}&instrument=guitar`,
    ),
    ukulele: generateFilterItem(
      'ukulele',
      facetInstruments.ukulele || 0,
      'Ukulele',
      `${searchBasePath}?query=${query}&instrument=ukulele`,
    ),
    bass: generateFilterItem(
      'bass',
      facetInstruments.bass || 0,
      'Bass',
      `${searchBasePath}?query=${query}&instrument=bass`,
    ),
  };
  return {
    current,
    label: filterKeyArr.instrument,
    // eslint-disable-next-line
    // @ts-ignore
    items: Object.keys(instrumentObj).map(key => instrumentObj[key]),
  };
}

const formatSearchParamas = (currentObj = {}, key = '', facetLabel: string): string => {
  // eslint-disable-next-line
  // @ts-ignore
  const currentCategory = { [key]: currentObj[key] === facetLabel ? null : facetLabel };
  const newFilterObj = { ...currentObj, ...currentCategory };
  let queryParams = '';
  for (const category in newFilterObj) {
    // eslint-disable-next-line
    // @ts-ignore
    if (newFilterObj[category]) {
      const formattedCategory = category === 'artists.name' ? 'artist' : category;
      // eslint-disable-next-line
      // @ts-ignore
      queryParams += `&${formattedCategory}=${encodeURIComponent(newFilterObj[category])}`;
    }
  }
  return queryParams;
};

function getFacetFilterItems(
  facets: FilterData,
  key: string,
  query: string,
  currentObj: FilterData,
): FilterItem[] {
  // eslint-disable-next-line
  // @ts-ignore
  const facetKeys = Object.keys(facets[key]);
  let items = facetKeys.map(facetLabel => {
    return generateFilterItem(
      facetLabel,
      // eslint-disable-next-line
      // @ts-ignore
      facets[key][facetLabel],
      facetLabel,
      `${searchBasePath}?query=${query}${formatSearchParamas(currentObj, key, facetLabel)}`,
    );
  });
  if (items.length > 10) items = items.slice(0, 10);
  return items;
}

const getObjectTypeFacetFilter = (
  facetTypes: AlgoliaObjectType,
  query: string,
  currentObj: FilterData,
): FacetFilter => {
  const items = getFacetFilterItems({ objectType: facetTypes }, 'objectType', query, currentObj);
  return {
    label: 'Category',
    current: currentObj.objectType || null,
    items,
  };
};

const getLevelFacetFilter = (
  facetTypes: AlgoliaType,
  query: string,
  currentObj: FilterData,
): FacetFilter => {
  // eslint-disable-next-line
  // @ts-ignore
  if (facetTypes['4']) delete facetTypes['4'];
  const items = getFacetFilterItems({ level: facetTypes }, 'level', query, currentObj);
  return {
    label: 'Difficulty Level',
    current: currentObj.level || null,
    items,
  };
};

function getTypeFacetFilter(
  facetTypes: AlgoliaTypeMap,
  query: string,
  current: AlgoliaType,
  currentObj: FilterData,
): FacetFilter {
  const typeObj = {
    song: generateFilterItem(
      'song',
      facetTypes.song || 0,
      'Songs',
      `${searchBasePath}?query=${query}${formatSearchParamas(currentObj, 'type', 'song')}`,
    ),
    riff: generateFilterItem(
      'riff',
      facetTypes.riff || 0,
      'Riffs',
      `${searchBasePath}?query=${query}${formatSearchParamas(currentObj, 'type', 'riff')}`,
    ),
    skill: generateFilterItem(
      'skill',
      getFacetsTotal(facetTypes, skillTypes),
      'Skills',
      `${searchBasePath}?query=${query}${formatSearchParamas(currentObj, 'type', 'skill')}`,
    ),
  };
  // eslint-disable-next-line
  // @ts-ignore
  const mapped = Object.keys(typeObj).map(key => typeObj[key]);
  const filtered = mapped.filter(item => item.subTitle !== 0);
  return { current, label: filterKeyArr.type, items: filtered };
}

function facetsIntoFilterArr(
  facets: Record<string, any>,
  query: string,
  currentObj: Record<string, AlgoliaType>,
): (FacetFilter | null)[] {
  if (!facets) return [];
  const filterArr = [
    getInstrumentFacetFilter(currentObj.instrument || '', facets.instrument || {}, query),
  ];
  if (facets.objectType) {
    filterArr.push(getObjectTypeFacetFilter(facets.objectType || {}, query, currentObj));
  }
  if (facets.type) {
    filterArr.push(getTypeFacetFilter(facets.type || {}, query, currentObj.type, currentObj));
  }
  if (facets.level) {
    filterArr.push(getLevelFacetFilter({ ...facets.level } || {}, query, currentObj));
  }
  Object.keys(filterKeyArr).forEach(key => {
    if (
      facets[key] &&
      key !== 'instrument' &&
      key !== 'objectType' &&
      key !== 'type' &&
      key !== 'level'
    ) {
      // eslint-disable-next-line
      // @ts-ignore
      const label = filterKeyArr[key];
      const items = getFacetFilterItems(facets, key, query, currentObj);
      filterArr.push({
        current: currentObj[unescape(key)] || null,
        label,
        items,
      });
    }
  });
  return filterArr;
}

type FilterFacets = { [facetName: string]: { [facetValue: string]: number } };

function parseFacets(
  filterFacets: FilterFacets | undefined,
  totalFacets: FilterFacets | undefined = {},
  filterObj: { instrument: string },
): Record<string, unknown> {
  const parsed = {};
  Object.keys(totalFacets).forEach(facetKey => {
    // eslint-disable-next-line
    // @ts-ignore
    parsed[facetKey] = filterObj[facetKey] ? totalFacets[facetKey] : filterFacets[facetKey];
  });
  return parsed;
}

function parseSearchFilter(filterObj: FilterData): string {
  if (!filterObj) return '';
  const instrument =
    filterObj.instrument === 'guitar'
      ? '(instrument:electric-guitar OR instrument:acoustic-guitar)'
      : `instrument:${filterObj.instrument}`;
  const stringArr = [instrument];
  Object.keys(filterObj).forEach(key => {
    const value = filterObj[key as keyof FilterData];
    if (key === 'type' && value === 'skill') {
      const lessonSkills = skillTypes.map(skill => `type:${skill}`);
      const skillFilterStr = lessonSkills.join(' OR ');
      stringArr.push(` AND (${skillFilterStr})`);
    } else if (value && key !== 'instrument') {
      stringArr.push(` AND ${key}:"${value}"`);
    }
  });
  return stringArr.join('');
}

function isCustomCategory(title = ''): boolean {
  // eslint-disable-next-line
  // @ts-ignore
  const titles = getSummaryTitles();
  // eslint-disable-next-line
  // @ts-ignore
  const titlesArr = Object.keys(titles).map(key => titles[key].title);
  return !includes(titlesArr, title);
}

function parseFiltersToFacets(filterObj: FilterData): AlgoliaFacetFilters {
  const facetFilters = [];
  // eslint-disable-next-line
  for (const facet in filterObj) {
    // eslint-disable-next-line
    // @ts-ignore
    const facetValue = filterObj[facet];
    if (facetValue === 'guitar') {
      facetFilters.push(['instrument:electric-guitar', 'instrument:acoustic-guitar']);
    } else if (Array.isArray(facetValue)) {
      const facetArray: string[] = [];
      facetValue.forEach(value => facetArray.push(`${facet}:${value}`));
      facetFilters.push(facetArray);
    } else if (facetValue) {
      facetFilters.push([`${facet}:${facetValue}`]);
    }
  }
  return facetFilters;
}

const parseSectionsResults = (
  rawSectionsResults: AlgoliaSearch.Response[],
): AlgoliaSearch.Response => {
  const [
    {
      // eslint-disable-next-line
      // @ts-ignore
      facets: { instrument },
    },
    allFacetsResults,
    { hits: collectionHits },
    { hits: songHits },
    { hits: riffHits },
    { hits: skillHits },
  ] = rawSectionsResults || [];
  const { facets: allFacets } = allFacetsResults;
  return {
    ...allFacetsResults,
    facets: { ...allFacets, instrument },
    hits: [...collectionHits, ...songHits, ...riffHits, ...skillHits],
  };
};

export {
  defaultFacets,
  clearFilters,
  filterKeyArr,
  facetsIntoFilterArr,
  formatSummary,
  formatTableData,
  generateFilterItem,
  getArtistSummaryItems,
  getFacetsTotal,
  getFieldStyles,
  getInstrumentFacetFilter,
  getTypeFacetFilter,
  getSummaryTitles,
  guitarTypes,
  isCustomCategory,
  parseFacets,
  parseFiltersToFacets,
  parseSearchFilter,
  parseUserDataObject,
  searchBasePath,
  skillTypes,
  parseSectionsResults,
};
