const attributesArr = ['aspectRatio', 'height', 'image', 'style'];
const stylesArr = ['circle', 'round', 'square', 'wide'];

export interface CurriculumStyle {
  aspectRatio: string;
  height: string;
  image: string;
  style: string;
}

interface CurriculumStyles {
  [key: string]: CurriculumStyle;
}

const stylesLarge: CurriculumStyles = {
  circle: {
    aspectRatio: 'square',
    height: 'h3',
    image: 'w3 br-100 overflow-hidden',
    style: 'w3 pr2',
  },
  round: {
    aspectRatio: 'square',
    height: 'h2',
    image: 'w2 br-100',
    style: 'w2 h2 pr2 br-100 overflow-hidden',
  },
  square: {
    aspectRatio: 'square',
    height: 'h3 pb1 pb0',
    image: 'w3',
    style: 'w3 pr2',
  },
  wide: {
    aspectRatio: 'wideScreen',
    height: 'h3',
    image: 'w4',
    style: 'w4 h3 pr2 mb2',
  },
};

const stylesSmall: CurriculumStyles = {
  ...stylesLarge,
  round: {
    aspectRatio: 'square',
    height: '',
    image: 'w2 br-100',
    style: 'w2 h2 pr2 br-100 overflow-hidden',
  },
  square: {
    aspectRatio: 'square',
    height: 'pb1 pb0-1',
    image: 'w3',
    style: 'w3 pr2',
  },
  wide: {
    aspectRatio: 'wideScreen',
    height: '',
    image: 'w3',
    style: 'w3 pr2 mb2',
  },
};

export { attributesArr, stylesArr, stylesLarge, stylesSmall };
