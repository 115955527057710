import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import config from '../config';
import segment from '../core/segment';

export function checkForLessonError(lesson) {
  if (!lesson) {
    const errorProperties = {
      category: 'Error - Page Load Error ',
    };
    segment.event('Error', errorProperties);
  }
}
export function getCurriculumImage(obj) {
  if (!obj) return '';
  return (
    obj.image ||
    obj['hero-image'] ||
    obj['thumbnail-image'] ||
    obj['song-album-artwork'] ||
    obj['artist-thumbnail-image'] ||
    ''
  );
}
export function getGenreHeaderImage(instrument = 'guitar', pathStyle = '') {
  const { genres } = config;
  if (genres[pathStyle] && genres[pathStyle].headerImage) {
    const { headerImage } = genres[pathStyle];
    const parsedInstrument = toLower(instrument).indexOf('-guitar') > -1 ? 'guitar' : instrument;
    return headerImage[parsedInstrument] || null;
  }
  return null;
}
export function getBannerImage(instrument, pathStyle) {
  const { offPathCourse } = config;
  const headerImage = getGenreHeaderImage(instrument, pathStyle);
  if (headerImage) {
    return headerImage;
  }
  if (offPathCourse && offPathCourse[instrument] && offPathCourse[instrument].image) {
    return offPathCourse[instrument].image;
  }
  return null;
}
export function getEndscreenProps(lesson, props) {
  const { nextCourse, nextLesson, playlist } = props;
  let nextVideoTitle = null;
  let nextVideoPoster = null;
  let nextVideoType = '';
  let currentVideoTitle = lesson.title || null;
  let currentVideoType = 'lesson';
  if (nextLesson) {
    const { title, 'activity-type': activityType } = nextLesson || {};
    currentVideoTitle = null;
    currentVideoType = null;
    nextVideoType = 'lesson';
    nextVideoTitle = title;
    nextVideoPoster = getCurriculumImage(nextLesson);
    if (activityType === 'practice-sheet') nextVideoType = 'practice-sheet';
  } else if (nextCourse) {
    nextVideoType = 'course';
    nextVideoTitle = nextCourse.title;
    nextVideoPoster = getCurriculumImage(nextCourse);
  } else if (playlist) {
    currentVideoTitle = playlist.title;
    currentVideoType = 'playlist';
  }
  return { currentVideoTitle, currentVideoType, nextVideoTitle, nextVideoPoster, nextVideoType };
}
export function getCourseCardProps(content, isNextCourse, instrument) {
  if (!content) return null;
  const isSongRiff = content.type === 'song' || content.type === 'riff';
  const showImage = isNextCourse || isSongRiff;
  const { artists, id, image, lessons, level, rank, title, type } = content;
  const description = content['description-long'] || content['description-short'];
  const imageUrl = image || content['thumbnail-image'] || content['song-album-artwork'];
  return {
    artists,
    buyUrl: content['apple-affiliate-link'] || null,
    description: !isNextCourse ? description : null,
    imageUrl,
    instrument,
    key: id,
    lessons,
    level,
    rank,
    showImage,
    title,
    type,
  };
}
export function getVideoSource(clip) {
  const { sources } = clip || {};
  let videoSource = '';
  if (sources && sources.length) {
    videoSource = sources[0].src;
    sources.forEach(source => {
      const { src, type } = source;
      if (type.indexOf('mp4') > -1) videoSource = src;
    });
  }
  return videoSource;
}
export function getCompletedLessons(lessonsInCourse, lessonsInProgress = {}) {
  let completed = 0;
  if (Array.isArray(lessonsInCourse) && lessonsInProgress) {
    const lessonIDsInProgress = Object.keys(lessonsInProgress);
    lessonsInCourse.forEach(l => {
      if (lessonIDsInProgress.indexOf(l.id) > -1) completed += 1;
    });
  }
  return completed;
}
export function getTabLevel(course, lesson) {
  if (lesson && lesson.level) return lesson.level;
  if (course && course.level) return course.level;
  return 1;
}
export function isSkillLesson(lessonType) {
  const skillsArr = [
    'basics',
    'chord',
    'exercise',
    'glossary',
    'skill',
    'technique',
    'theory',
    'tone',
  ];
  if (lessonType) return includes(skillsArr, lessonType);
  return null;
}
export function flattenLessonItems(lesson, key) {
  if (lesson && lesson.items && lesson.items[0] && lesson.items[0][key]) {
    return lesson.items[0][key];
  }
  return null;
}
export function getPathColor(pathStyle) {
  return pathStyle ? `${config.genres[pathStyle].color}` : 'blue';
}
export function getInstrumentFromContent(content = {}) {
  const { instrument, lessons } = content;
  if (instrument) return instrument;
  if (lessons && lessons.length && lessons[0].instrument) return lessons[0].instrument;
  return 'guitar';
}
export function getThumbnailPreviewListProps(content, type) {
  if (content && type) {
    const getTitle = () => {
      if (type === 'lesson') {
        return `More ${content.type.charAt(0).toUpperCase()}${content.type.slice(1)} Lessons`;
      }
      return 'Related Courses';
    };
    return {
      className: 'mv2 ph3',
      exclude: content.slug,
      getRelated: true,
      isScrollerEnabled: false,
      itemType: `${type}s`,
      instrument: getInstrumentFromContent(content),
      limit: type === 'lesson' ? '5' : '3',
      title: getTitle(),
      type: content.type,
    };
  }
  return null;
}
export function getVideoPlayerPropsDeprecated(obj) {
  if (obj) {
    const { lesson, pause, startAtTime, volume } = obj;
    const { duration } = lesson['activity-lesson-video'];
    const parsedStartAtTime = duration === startAtTime ? 0 : startAtTime;
    return {
      chords: lesson['activity-lesson-video'].chords || [],
      clip: {
        cuepoints: lesson['activity-lesson-video']['chapter-cuepoints'],
        sources: [
          {
            type: 'application/x-mpegurl',
            src: lesson['activity-lesson-video']['hls-playlist'],
          },
          {
            type: 'video/mp4',
            src: lesson['activity-lesson-video'].mp4720p,
          },
        ],
      },
      duration: lesson['activity-lesson-video'].duration,
      glossary: lesson['activity-glossary-videos'] || [],
      isResumingFrom: parsedStartAtTime > 0,
      lowerThird: lesson['activity-lesson-video']['timed-annotations'] || [],
      startAtTime: parsedStartAtTime,
      thumbnail: lesson['activity-lesson-video']['thumb-generated-base'],
      title: lesson['activity-lesson-video'].title,
      volume,
      pause,
      poster: lesson['activity-lesson-video'].poster,
    };
  }
  return null;
}

export function getVideoPlayerProps(obj) {
  if (obj) {
    const { lesson } = obj;
    return {
      poster: lesson['activity-lesson-video'].poster,
      src: lesson['activity-lesson-video']['hls-playlist'],
      thumbnailBaseUrl: lesson['activity-lesson-video']['thumb-generated-base'],
      type: 'application/x-mpegurl',
    };
  }
  return null;
}

export function getTimeForProgress(videoTime = 0, videoWatchedSeconds = 0) {
  const parsedTime = Math.round(parseFloat(videoTime));
  if (parsedTime === 0 && videoWatchedSeconds > 0) {
    return videoWatchedSeconds;
  }
  return parsedTime;
}

export function isVideoLoaded(lesson) {
  return lesson && lesson['activity-lesson-video'] ? lesson['activity-lesson-video'] : null;
}
export function updateURL(obj, type, history, silent = true) {
  const { courseSlug, lesson, nextCourse, nextLesson, pathSlug, playlistSlug } = obj;
  const getPrefixedURL = () => {
    if (type === 'playlist') {
      const nextCourseSlug = nextCourse ? nextCourse.slug : courseSlug;
      return `/paths/${pathSlug}/playlists/${playlistSlug}/courses/${nextCourseSlug}`;
    }
    return `/courses/${courseSlug}`;
  };
  let updatedURL = null;
  if (nextLesson?.slug || lesson?.slug) {
    const lessonSlug = nextLesson ? nextLesson.slug : lesson.slug;
    updatedURL = `${getPrefixedURL()}/lessons/${lessonSlug}`;
  }
  const fullUpdatedURL = updatedURL;
  // Check if browser back button was selected - no need for update
  if (history.location.pathname !== fullUpdatedURL) {
    history.push(fullUpdatedURL, { silent });
  }
}
export const curriculumImageShape = skillType => {
  if (skillType === 'courses') return 'wide';
  if (skillType === 'chords') return 'round';
  return 'square';
};
