import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import { useEscapeKey } from '@fenderdigital/custom-hooks';
import Spacer from '@fenderdigital/ui/Spacer';
import ButtonGroup from '@fenderdigital/ui/ButtonGroup';
import Button from '@fenderdigital/ui/Button';
import SearchSummary from '../SearchSummary';
import SearchPanelScreenReaderInstructions from './SearchPanelScreenReaderInstructions';
import { getInstrumentFacetFilter } from '../../utils/search-utils';
import useSelectSearchOption from '../../hooks/useSelectSearchOption';
import { useSearchSelection } from '../../contexts/SearchSelectionContext';
import messages from './messages';
import './searchPanelStyles.css';

const ButtonGroupContainer = styled.section`
  display: flex;
  justify-content: center;

  button {
    min-height: auto;
    min-width: auto;
    font-family: ${({ theme: { fonts } }) => fonts.sourceSans};
    font-size: ${({ theme: { fontSizes } }) => fontSizes.f3};
    text-transform: none;
    padding: 0.5rem 0.25rem 0.75rem 0.25rem;
  }
`;

function getInstrumentButtons(
  instruments,
  query,
  instantSearch,
  handleInstrument,
  setLocalResults,
  resetOptions,
) {
  const handleClick = async instrumentId => {
    handleInstrument(instrumentId);
    const results = await instantSearch(query, true, instrumentId);
    setLocalResults(results);
    resetOptions();
  };

  const getAriaLabel = instrument => {
    const usePlural = Number(instrument.subTitle) > 1;
    return `Filter by ${instrument.title} (${instrument.subTitle} ${
      usePlural ? 'results' : 'result'
    } available)`;
  };

  if (!instruments) return null;

  return (
    <>
      <Spacer />
      <ButtonGroupContainer>
        <ButtonGroup>
          {instruments.current
            ? instruments.items.map(instrument => (
                <Button
                  key={instrument.id}
                  dataId={`search-panel-results-${instrument.id}`}
                  onClick={() => handleClick(instrument.id)}
                  size="small"
                  ariaLabel={getAriaLabel(instrument)}
                  ariaCurrent={instrument.id === instruments.current ? 'true' : undefined}
                  colorScheme={instrument.id === instruments.current ? 'red' : 'lightGray'}
                  textColor={instrument.id === instruments.current ? 'white' : 'midGray'}
                  variant={instrument.id === instruments.current ? 'solid' : 'outline'}
                  variantBgColor={instrument.id === instruments.current ? 'white' : 'nearWhite'}
                >
                  {instrument.title} ({instrument.subTitle})
                </Button>
              ))
            : null}
        </ButtonGroup>
      </ButtonGroupContainer>
      <Spacer />
    </>
  );
}

const SearchPanelResults = ({
  instrument,
  localResults,
  instantSearch,
  intl,
  handleInstrument,
  setLocalResults,
  closeSearchPanel,
  pill,
}) => {
  const [options, setOptions] = useState(null);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const { setSelectedOptionId } = useSearchSelection();
  const { hits, query, facets } = localResults;
  const { formatMessage } = intl;
  const facetInstruments = facets ? facets.instrument : {};
  const instruments = getInstrumentFacetFilter(instrument, facetInstruments, query);
  const panelRef = useEscapeKey(() => closeSearchPanel());
  const { resetOptions } = useSelectSearchOption('#nav-search-results [role="option"]');

  const instrumentButtons = getInstrumentButtons(
    instruments,
    query,
    instantSearch,
    handleInstrument,
    setLocalResults,
    resetOptions,
  );

  useEffect(() => {
    resetOptions();
  }, [query, resetOptions]);

  if (hits.length) {
    return (
      <div data-id="search-panel-results" className="searchPanelResultsGrow ph3" ref={panelRef}>
        {instrumentButtons}
        <SearchSummary localResults={localResults} instrument={instrument} small pill={pill} />
        <SearchPanelScreenReaderInstructions aria-live="assertive">
          {formatMessage(messages.resultsFound, { length: hits.length })}
        </SearchPanelScreenReaderInstructions>
      </div>
    );
  }
  if (!instruments) {
    return (
      <div className="tc source-sans vh-50 pv3" data-id="search-panel-results-none">
        {formatMessage(messages.noResults)}
        <SearchPanelScreenReaderInstructions>
          {formatMessage(messages.noResults)}
        </SearchPanelScreenReaderInstructions>
      </div>
    );
  }
  return (
    <div className="tc source-sans vh-50" data-id="search-panel-results-no-instrument">
      {instrumentButtons}
      {formatMessage(messages.noInstrumentResults, { instrument: instruments.current })}
      <SearchPanelScreenReaderInstructions>
        {formatMessage(messages.noInstrumentResults, { instrument: instruments.current })}
      </SearchPanelScreenReaderInstructions>
    </div>
  );
};

SearchPanelResults.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.shape({})),
  instantSearch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  query: PropTypes.string,
  pill: PropTypes.bool,
  // updateFilters: PropTypes.func.isRequired,
};

SearchPanelResults.defaultProps = {
  hits: null,
  query: '',
  pill: false,
};

export default injectIntl(SearchPanelResults);
