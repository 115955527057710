import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { up } from 'styled-breakpoints';
import { generateImageCSS } from '@fenderdigital/utils/image-css';

interface ImageProps {
  image?: string | null;
  hasBackground?: boolean;
  isCourse?: boolean;
}

interface CourseNumberProps {
  courseNumber?: number;
}

export const Container = styled.div<ImageProps>`
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
`;

const ImageStyle = css`
  background-position: center;
`;

export const Image = styled.div<ImageProps>`
  position: absolute;
  width: 100%;
  padding-bottom: 56.25%;
  ${({ image }) => generateImageCSS(image ?? '', '', ImageStyle)};
`;

export const AlbumContainer = styled.div`
  position: absolute;
  width: 50%;
  right: 0px;
  top: 10%;

  ${up('tablet-portrait')} {
    width: 49%;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const AlbumSubContainer = styled.div`
  position: relative;
  width: 90%;
  padding-bottom: 90%;

  ${up('tablet-portrait')} {
    width: 100%;
    padding-bottom: 100%;
    margin: 0;
  }
`;

export const AlbumCover = styled.div<ImageProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  right: ${({ isCourse }): string => (isCourse ? '0' : '50%')};

  ${({ image }) => generateImageCSS(image ?? '', '', ImageStyle)};

  ${up('tablet-portrait')} {
    right: ${({ isCourse }): string => (isCourse ? '12px' : '50%')};
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Spotlight = styled.div`
  position: absolute;
  inset: 0;
  background: radial-gradient(ellipse, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
`;

export const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
`;

export const MetaContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0px;
  left: 20px;
`;

export const Title = styled.h2`
  font-family: ${({ theme }): string => theme.fonts.futuraHeavy};
  font-size: ${({ theme }): string => theme.fontSizes.f4};
  line-height: 1;
  color: ${({ theme }): string => theme.colors.white};
  letter-spacing: 0.06em;
  margin-bottom: 0;
`;

export const CourseNumber = styled.h3<CourseNumberProps>`
  font-family: ${({ theme }): string => theme.fonts.futuraHeavy};
  font-size: 81px;
  line-height: 1;
  color: ${({ theme }): string => theme.colors.white};
  margin-bottom: 0;
  margin-left: ${({ courseNumber }) => (courseNumber && courseNumber > 9 ? '-9px' : '0')};
`;

export const BaseImage = styled.div<ImageProps>`
  position: absolute;
  width: 100%;
  padding-top: 56%;
  background-image: url('${props => props.image}');
  background-position: 20% 20%;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const BlurImage = styled.div<ImageProps>`
  position: absolute;
  width: 100%;
  padding-top: 56%;
  ${({ image }): FlattenSimpleInterpolation => generateImageCSS(image ?? '')}
  background-image: url('${({ image }): string => image ?? ''}')
  background-position: top center;
  background-size: cover;
  filter: blur(8px);
  transform: scale(1.5);
`;
