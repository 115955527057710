import React, { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import Markdown from 'react-markdown';
import { renderStyleCTA, renderCTA, renderHeadline, StyleCTA } from '../../utils/blocks-utils';

const messages = defineMessages({
  body: {
    id: 'blockText.body',
    defaultMessage: '{body}',
  },
  cta1: {
    id: 'blockText.cta1',
    defaultMessage: '{cta1}',
  },
  cta2: {
    id: 'blockText.cta2',
    defaultMessage: '{cta2}',
  },
  headline: {
    id: 'blockText.headline',
    defaultMessage: '{headline}',
  },
  subHeadline: {
    id: 'blockText.subHeadline',
    defaultMessage: '{subHeadline}',
  },
});

interface BlockTextProps {
  body?: string;
  containerStyles?: string;
  cta1?: string;
  cta1Url?: string;
  cta1Styles?: string;
  cta2?: string;
  cta2Url?: string;
  cta2Styles?: string;
  headline?: string;
  headlineStyles?: string;
  isFirstBlock: boolean;
  subHeadline?: string;
  subHeadlineStyles?: string;
  theme?: string;
}

const BlockText: FC<BlockTextProps> = ({
  body = null,
  containerStyles = 'pa3 mb3',
  cta1 = null,
  cta1Url = null,
  cta1Styles = null,
  cta2 = null,
  cta2Url = null,
  cta2Styles = null,
  headline = null,
  headlineStyles = 'f1 futura-heavy ma0 mb1 tc',
  isFirstBlock,
  subHeadline = null,
  subHeadlineStyles = 'f3 futura-book ma0 mb1 tc',
  theme = 'light',
}) => {
  const baseStyles = `relative w-100 ${theme === 'dark' ? 'bg-black white' : 'bg-white black'}`;
  const contentStyles = !containerStyles
    ? 'w-50-l center db-l flex flex-column items-center justify-center'
    : '';
  const styleCTA = {
    message1: cta1,
    message2: cta2,
    url1: cta1Url,
    url2: cta2Url,
    style1: cta1Styles,
    style2: cta2Styles,
  } as StyleCTA;

  return (
    <div className={`block-text ${baseStyles} ${containerStyles}`}>
      <div className="flex-l items-center-l h-100">
        <div className={`${contentStyles} w-100`}>
          {subHeadline && (
            <h2 className={subHeadlineStyles}>
              <FormattedMessage {...messages.subHeadline} values={{ subHeadline }} />
            </h2>
          )}
          {renderHeadline(headline, headlineStyles, isFirstBlock)}
          {body && (
            <Markdown className="block-markdown lh-copy mb3" allowDangerousHtml>
              {body}
            </Markdown>
          )}
          <div className="tc center cb">
            <div>
              {cta1Styles ? (
                renderStyleCTA(styleCTA)
              ) : (
                <>
                  {renderCTA(cta1, cta1Url, theme, false, 'small')}
                  {renderCTA(cta2, cta2Url, theme, true, 'small')}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockText;
