export default [
  {
    id: 'home',
    label: 'Home',
    url: '/',
    items: null,
    type: 'text',
  },
  {
    id: '7-day-free-trial',
    label: '7-Day Free Trial',
    url: '/plans',
    items: null,
    type: 'text',
    'class-names': 'red',
  },
  {
    id: 'my-path',
    label: 'My Path',
    url: '/my-path',
    items: null,
    type: 'text',
  },
  {
    id: 'guitar',
    label: 'Guitar',
    items: [
      {
        id: 'guitar-songs-riffs',
        label: 'Songs & Riffs',
        items: [
          {
            id: 'guitar-featured',
            label: 'Featured',
            url: '/guitar/songs',
            items: null,
            type: 'text',
            'class-names': 'blue source-sans-b',
          },
          {
            id: 'guitar-just-added',
            label: 'Just Added',
            url: '/guitar/songs/all/catalog?sort=start-at',
            items: null,
            type: 'text',
          },
          {
            id: 'guitar-rock',
            label: 'Rock',
            url: '/guitar/songs/all/rock',
            items: null,
            type: 'text',
          },
          {
            id: 'guitar-pop',
            label: 'Pop',
            url: '/guitar/songs/all/pop',
            items: null,
            type: 'text',
          },
          {
            id: 'guitar-blues',
            label: 'Blues',
            url: '/guitar/songs/all/blues',
            items: null,
            type: 'text',
          },
          {
            id: 'guitar-country',
            label: 'Country',
            url: '/guitar/songs/all/country',
            items: null,
            type: 'text',
          },
          {
            id: 'guitar-folk',
            label: 'Folk',
            url: '/guitar/songs/all/folk',
            items: null,
            type: 'text',
          },
          {
            id: 'guitar-rnbsoul',
            label: 'R&B/Soul',
            url: '/guitar/songs/all/rnbsoul',
            items: null,
            type: 'text',
          },
          {
            id: 'all-guitar-songs',
            label: 'All Songs',
            url: '/guitar/songs/all/catalog',
            items: null,
            type: 'text',
            'class-names': 'underline',
          },
          {
            id: 'all-guitar-riffs',
            label: 'All Riffs',
            url: '/guitar/riffs/all/catalog',
            items: null,
            type: 'text',
            'class-names': 'underline',
          },
        ],
        type: 'text',
      },
      {
        id: 'guitar-skills',
        label: 'skills',
        items: [
          {
            id: 'guitar-lessons',
            label: 'Lessons',
            items: [
              {
                id: 'guitar-featured',
                label: 'Featured',
                url: '/guitar/skills',
                items: null,
                type: 'text',
                'class-names': 'blue source-sans-b',
              },
              {
                id: 'guitar-basics',
                label: 'Basics',
                url: '/guitar/skills/basics',
                items: null,
                type: 'text',
              },
              {
                id: 'guitar-chords',
                label: 'Chords',
                url: '/guitar/skills/chords',
                items: null,
                type: 'text',
              },
              {
                id: 'guitar-exercises',
                label: 'Exercises',
                url: '/guitar/skills/exercises',
                items: null,
                type: 'text',
              },
              {
                id: 'guitar-glossary',
                label: 'Glossary',
                url: '/guitar/skills/glossary',
                items: null,
                type: 'text',
              },
              {
                id: 'guitar-techniques',
                label: 'Techniques',
                url: '/guitar/skills/techniques',
                items: null,
                type: 'text',
              },
              {
                id: 'guitar-theory',
                label: 'Theory',
                url: '/guitar/skills/theory',
                items: null,
                type: 'text',
              },
              {
                id: 'guitar-tone',
                label: 'Tone',
                url: '/guitar/skills/tone',
                items: null,
                type: 'text',
              },
            ],
            type: 'text',
          },
          {
            id: 'guitar-courses',
            label: 'Courses',
            items: [
              {
                id: 'show-off-lead-guitar-em',
                label: 'Show Off: Lead guitar in Em',
                url:
                  '/courses/course-show-off-hammer-ons-pull-offs-and-the-em-pentatonic-scale/lessons/show-me-lead-guitar-hammer-ons',
                items: null,
                'label-prefix': 'Popular!',
                type: 'text',
              },
              {
                id: 'triple-stops',
                label: 'Triple Stops',
                url: '/courses/triple-stops',
                items: null,
                'label-prefix': 'New:',
                type: 'text',
              },
              {
                id: 'why-are-about-capo',
                label: 'Why Care About a Capo?',
                url: '/courses/why-care-about-a-capo',
                items: null,
                'label-prefix': 'Essential:',
                type: 'text',
              },
              {
                id: 'abcs',
                label: 'ABCs. Know Your Notes',
                url: '/courses/abcs-know-your-notes',
                items: null,
                type: 'text',
              },
              {
                id: 'blues-styling',
                label: 'Blues Styling: First Shuffle',
                url: '/courses/course-blues-styling-first-shuffle',
                items: null,
                type: 'text',
              },
              {
                id: 'all-guitar-skills-courses',
                label: 'All Skills Courses',
                url: '/search/results?query=&instrument=guitar&type=skill&objectType=course',
                items: null,
                type: 'text',
                'class-names': 'underline',
              },
              {
                id: 'how-it-works-guitar',
                label: 'HOW IT WORKS',
                url: '/pages/guitar-lessons',
                items: null,
                type: 'text',
                'class-names':
                  'nl3 ml0-l ttu ma0 futura-heavy red w-100 f8 b--dotted-l mt3-l dib pb2-l bb-l bt-0 bl-0 br-0 b--light-gray',
              },
              {
                id: 'how-it-works-guitar-url',
                label: 'Learn to play guitar with step-by-step lessons',
                url: '/pages/guitar-lessons',
                items: null,
                type: 'text',
                'class-names': 'mt2-l',
              },
            ],
            type: 'text',
          },
        ],
        type: 'text',
      },
    ],
    type: 'text',
  },
  {
    id: 'bass',
    label: 'Bass',
    items: [
      {
        id: 'bass-songs-riffs',
        label: 'Songs & Riffs',
        items: [
          {
            id: 'bass-featured',
            label: 'Featured',
            url: '/bass/songs',
            items: null,
            type: 'text',
            'class-names': 'blue source-sans-b',
          },
          {
            id: 'bass-just-added',
            label: 'Just Added',
            url: '/bass/songs/all/catalog?sort=start-at',
            items: null,
            type: 'text',
          },
          {
            id: 'bass-rock',
            label: 'Rock',
            url: '/bass/songs/all/rock',
            items: null,
            type: 'text',
          },
          {
            id: 'bass-funk',
            label: 'Funk',
            url: '/bass/songs/all/funk',
            items: null,
            type: 'text',
          },
          {
            id: 'bass-all-songs',
            label: 'All Songs',
            url: '/bass/songs/all/catalog',
            items: null,
            type: 'text',
            'class-names': 'underline',
          },
          {
            id: 'bass-all-riffs',
            label: 'All Riffs',
            url: '/bass/riffs/all/catalog',
            items: null,
            type: 'text',
            'class-names': 'underline',
          },
        ],
        type: 'text',
      },
      {
        id: 'bass-skills',
        label: 'bass',
        items: [
          {
            id: 'bass-lessons',
            label: 'Lessons',
            items: [
              {
                id: 'bass-skills-featured',
                label: 'Featured',
                url: '/bass/skills',
                items: null,
                type: 'text',
                'class-names': 'blue source-sans-b',
              },
              {
                id: 'bass-basics',
                label: 'Basics',
                url: '/bass/skills/basics',
                items: null,
                type: 'text',
              },
              {
                id: 'bass-exercises',
                label: 'Exercises',
                url: '/bass/skills/exercises',
                items: null,
                type: 'text',
              },
              {
                id: 'bass-glossary',
                label: 'Glossary',
                url: '/bass/skills/glossary',
                items: null,
                type: 'text',
              },
              {
                id: 'bass-techniques',
                label: 'Techniques',
                url: '/bass/skills/techniques',
                items: null,
                type: 'text',
              },
              {
                id: 'bass-theory',
                label: 'Theory',
                url: '/bass/skills/theory',
                items: null,
                type: 'text',
              },
            ],
            type: 'text',
          },
          {
            id: 'bass-courses',
            label: 'Courses',
            items: [
              {
                id: 'essential-slap-bass',
                label: 'Slap Bass: Thumb',
                url: '/courses/slap-bass-thumb',
                items: null,
                'label-prefix': 'Essential!',
                type: 'text',
              },
              {
                id: 'the-walking-bass',
                label: 'The Walking Bass',
                url: '/courses/the-walking-bass',
                items: null,
                'label-prefix': 'New!',
                type: 'text',
              },
              {
                id: 'root-notes',
                label: 'Root Notes: How to Jam With a Guitarist',
                url: '/courses/root-notes-how-to-jam-with-a-guitarist',
                items: null,
                'label-prefix': 'Popular!',
                type: 'text',
              },
              {
                id: 'abs-how-to-play-blues',
                label: 'ABCs and How To Play the Blues',
                url: '/courses/bass-abcs-how-to-play-the-blues',
                items: null,
                type: 'text',
              },
              {
                id: 'bassline-basics',
                label: 'Bassline Basics: Roots and Fifths',
                url: '/courses/bassline-basics-roots-and-fifths',
                items: null,
                type: 'text',
              },
              {
                id: 'all-bass-skills-courses',
                label: 'All Skills Courses',
                url: '/search/results?query=&instrument=bass&type=skill&objectType=course',
                items: null,
                type: 'text',
                'class-names': 'underline',
              },
              {
                id: 'how-it-works-bass',
                label: 'HOW IT WORKS',
                url: '/pages/bass-lessons',
                items: null,
                type: 'text',
                'class-names':
                  'nl3 ml0-l ttu ma0 futura-heavy red w-100 f8 b--dotted-l mt3-l dib pb2-l bb-l bt-0 bl-0 br-0 b--light-gray',
              },
              {
                id: 'how-it-works-bass-url',
                label: 'Learn to play bass with step-by-step lessons',
                url: '/pages/bass-lessons',
                items: null,
                type: 'text',
                'class-names': 'mt2-l',
              },
            ],
            type: 'text',
          },
        ],
        type: 'text',
      },
    ],
    type: 'text',
  },
  {
    id: 'ukulele',
    label: 'Ukulele',
    items: [
      {
        id: 'ukulele-songs-riffs',
        label: 'Songs & Riffs',
        items: [
          {
            id: 'ukulele-featured',
            label: 'Featured',
            url: '/ukulele/songs',
            items: null,
            type: 'text',
            'class-names': 'blue source-sans-b',
          },
          {
            id: 'ukulele-just-added',
            label: 'Just Added',
            url: '/ukulele/songs/all/catalog?sort=start-at',
            items: null,
            type: 'text',
          },
          {
            id: 'ukulele-all-songs',
            label: 'All Songs',
            url: '/ukulele/songs/all/catalog',
            items: null,
            type: 'text',
            'class-names': 'underline',
          },
          {
            id: 'ukulele-all-riffs',
            label: 'All Riffs',
            url: '/ukulele/riffs/all/catalog',
            items: null,
            type: 'text',
            'class-names': 'underline',
          },
        ],
        type: 'text',
      },
      {
        id: 'ukulele-skills',
        label: 'skills',
        items: [
          {
            id: 'ukulele-lessons',
            label: 'Lessons',
            items: [
              {
                id: 'ukulele-skills-featured',
                label: 'Featured',
                url: '/ukulele/skills',
                items: null,
                type: 'text',
                'class-names': 'blue source-sans-b',
              },
              {
                id: 'ukulele-basics',
                label: 'Basics',
                url: '/ukulele/skills/basics',
                items: null,
                type: 'text',
              },
              {
                id: 'ukulele-chords',
                label: 'Chords',
                url: '/ukulele/skills/chords',
                items: null,
                type: 'text',
              },
              {
                id: 'ukulele-exercises',
                label: 'Exercises',
                url: '/ukulele/skills/exercises',
                items: null,
                type: 'text',
              },
              {
                id: 'ukulele-glossary',
                label: 'Glossary',
                url: '/ukulele/skills/glossary',
                items: null,
                type: 'text',
              },
              {
                id: 'ukulele-techniques',
                label: 'Techniques',
                url: '/ukulele/skills/techniques',
                items: null,
                type: 'text',
              },
              {
                id: 'ukulele-theory',
                label: 'Theory',
                url: '/ukulele/skills/theory',
                items: null,
                type: 'text',
              },
            ],
            type: 'text',
          },
          {
            id: 'ukulele-courses',
            label: 'Courses',
            items: [
              {
                id: 'fingerstyle-montuno-rhythm',
                label: 'Fingerstyle: Montuno Rhythm',
                url: '/courses/fingerstyle-montuno-rhythm',
                items: null,
                'label-prefix': 'New!',
                type: 'text',
              },
              {
                id: 'level-up-backbeat-strum',
                label: 'Level Up: Backbeat Strum',
                url: '/courses/ukulele-level-up-backbeat-strum',
                items: null,
                'label-prefix': 'New!',
                type: 'text',
              },
              {
                id: 'play-and-sing-happy-birthday',
                label: 'Play and Sing: “Happy Birthday”',
                url: '/courses/play-and-sing-happy-birthday',
                items: null,
                'label-prefix': 'Popular!',
                type: 'text',
              },
              {
                id: 'exploring-the-barre',
                label: 'Exploring the Barre Chord',
                url: '/courses/exploring-the-barre',
                items: null,
                type: 'text',
              },
              {
                id: 'calypso-strumming',
                label: 'Calypso Strumming',
                url: '/courses/calypso-strumming',
                items: null,
                type: 'text',
              },
              {
                id: 'all-ukulele-skills-courses',
                label: 'All Skills Courses',
                url: '/search/results?query=&instrument=ukulele&type=skill&objectType=course',
                items: null,
                type: 'text',
                'class-names': 'underline',
              },
              {
                id: 'how-it-works-uke',
                label: 'HOW IT WORKS',
                url: '/pages/ukulele-lessons',
                items: null,
                type: 'text',
                'class-names':
                  'nl3 ml0-l ttu ma0 futura-heavy red w-100 f8 b--dotted-l mt3-l dib pb2-l bb-l bt-0 bl-0 br-0 b--light-gray',
              },
              {
                id: 'how-it-works-uke-url',
                label: 'Learn to play ukulele with step-by-step lessons',
                url: '/pages/ukulele-lessons',
                items: null,
                type: 'text',
                'class-names': 'mt2-l',
              },
            ],
            type: 'text',
          },
        ],
        type: 'text',
      },
    ],
    type: 'text',
  },
  {
    id: 'songs',
    label: 'Songs',
    url: '/songs',
    items: null,
    type: 'text',
  },
  {
    id: 'toolkit',
    label: 'Toolkit',
    items: [
      {
        id: 'fender-tune',
        label: 'Fender Tune',
        items: [
          {
            id: 'fender-tune-app-iphone',
            label: 'Fender Tune app for iPhone',
            url: 'https://itunes.apple.com/us/app/fender-tune/id1107017950?mt=8',
            items: null,
            type: 'text',
            target: '_blank',
          },
          {
            id: 'fender-tune-app-android',
            label: 'Fender Tune app for Android',
            url: 'https://play.google.com/store/apps/details?id=com.fender.tuner',
            items: null,
            type: 'text',
            target: '_blank',
          },
          {
            id: 'online-tuner-bass-guitar',
            label: 'Online tuner for guitar, bass and ukulele.',
            url: 'https://www.fender.com/online-guitar-tuner/',
            items: null,
            type: 'text',
            target: '_blank',
          },
        ],
        type: 'text',
      },
      {
        id: 'fender-tone',
        label: 'Fender Tone',
        items: [
          {
            id: 'fender-tone-iphone',
            label: 'Fender Tone for iPhone',
            url: 'https://itunes.apple.com/us/app/fender-tone/id1174113426?mt=8',
            items: null,
            type: 'text',
            target: '_blank',
          },
          {
            id: 'fender-tone-android',
            label: 'Fender Tone for Android',
            url: 'https://play.google.com/store/apps/details?id=com.fender.tone',
            items: null,
            type: 'text',
            target: '_blank',
          },
          {
            id: 'get-information-fender-tone',
            label: 'Get more information about Fender Tone',
            url: 'https://www.fender.com/apps/tone-app-amp-controller/',
            items: null,
            type: 'text',
            target: '_blank',
          },
        ],
        type: 'text',
      },
    ],
    type: 'text',
  },
  {
    id: 'articles',
    label: 'Articles',
    url: '/articles',
    items: null,
    type: 'text',
    'class-names': 'articles-link',
  },
  {
    id: 'support',
    label: 'Support',
    url: 'https://play-support.fender.com/hc/en-us',
    items: null,
    type: 'text',
    target: '_blank',
    'class-names': 'support-link',
  },
];
