import { combineReducers } from 'redux';
import { reducers as collections } from '@fenderdigital/business/collections';
import courses from './courses';
import lessons from './lessons';
import intl from './intl';
import relatedItems from './related-items';
import runtime from './runtime';
import search from './search';
import skills from './skills';
import skillsLists from './skills-lists';
import song from './song';
import songsLists from './songs-lists';

const makeRootReducer = asyncReducers =>
  combineReducers({
    ...asyncReducers,
    collections,
    courses,
    lessons,
    intl,
    relatedItems,
    runtime,
    search,
    skills,
    skillsLists,
    song,
    songsLists,
  });

export const injectAsyncReducer = (store, key, reducer) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;
  /* eslint-disable no-param-reassign */
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
