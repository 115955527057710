import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Collection } from '@fenderdigital/api-definitions/play';
import ProgressList from '../ProgressList';

const CollectionCoursesContainer = styled.div`
  width: 100%;
  padding-top: 32px;
  flex-grow: 1;

  ${up('tablet-landscape')} {
    padding-bottom: 128px;
  }
  ${up('laptop')} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const CollectionContentContainer = styled.div`
  max-width: 1376px;
  width: 100%;
`;

const CollectionHeader = styled.div`
  padding-left: 16px;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  font-family: FuturaPT-Demi;
  color: black;

  ${up('tablet-landscape')} {
    padding-left: 32px;
    font-size: 32px;
    line-height: 40px;
  }

  ${up('laptop')} {
    font-size: 36px;
    line-height: 48px;
  }
`;

interface CollectionCoursesProps {
  collection: Collection;
}

const CollectionCourses: React.FC<CollectionCoursesProps> = ({ collection }) => {
  const { courses } = collection;
  const { instrument, collectionSlug } = useParams<{
    instrument: string;
    collectionSlug: string;
  }>();
  const url = `/${instrument}/collections/${collectionSlug}`;
  return (
    <CollectionCoursesContainer className="collection-detail-courses-list-container">
      <CollectionContentContainer>
        <CollectionHeader className="collection-detail-courses-list-header">
          Courses
        </CollectionHeader>
        <ProgressList
          url={url}
          className="collection-detail-courses-list-progress-list"
          courses={courses}
          collection={collection}
          view="collectionDetail"
        />
      </CollectionContentContainer>
    </CollectionCoursesContainer>
  );
};

export default CollectionCourses;
