import React, { FC } from 'react';

const IconPointerLeft: FC = () => {
  return (
    <svg width={9} height={16} viewBox="0 0 9 16">
      <path
        d="M8.4.89a.9.9 0 01-.23.59L2.4 8l5.78 6.52c.3.38.26.91-.1 1.23a.9.9 0 01-1.25-.05L0 8 6.82.3a.9.9 0 011-.24.9.9 0 01.58.83z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default IconPointerLeft;
