import React from 'react';
import styled from 'styled-components';
import Button from '@fenderdigital/ui/Button';
import Spacer from '@fenderdigital/ui/Spacer';
import { ButtonReset } from '@fenderdigital/ui/utils/css';
import { Colors } from '@fenderdigital/ui/system';
import IconPointerLeft from './icons/PointerLeft';
import IconPointerRight from './icons/PointerRight';

export interface SwipeCarouselButtonProps {
  isDisabled?: boolean;
}

export interface SwipeCarouselDotProps {
  isCurrent: boolean;
}

const SwipeCarouselFlexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;

  @media screen and (min-width: 700px) {
    align-items: flex-end;
  }
`;

const FeedbackModeCarouselControlsContainer = styled.section`
  width: 100%;
  max-width: 375px;

  padding-left: 3rem;
  padding-right: 3rem;

  @media screen and (min-width: 568px) {
    max-width: none;
    width: 50%;

    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media screen and (min-width: 700px) {
    padding-left: 0rem;
    padding-right: 3.5rem;
  }
`;

export const SwipeCarouselNav = styled.nav`
  position: relative;
  z-index: 2;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  & + * {
    margin-top: 1rem;
  }
`;

const SwipeCarouselButton = styled.button<SwipeCarouselButtonProps>`
  ${ButtonReset};

  width: 1.75rem;
  height: 1rem;

  opacity: ${({ isDisabled }): string => (isDisabled ? '0.30' : '100%')};
  cursor: ${({ isDisabled }): string => (isDisabled ? 'not-allowed' : 'pointer')};
`;

const SwipeCarouselList = styled.ul`
  display: flex;
  align-items: center;

  margin: 0;
  margin-top: -3px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-left: 0;

  list-style: none;
`;

export const SwipeCarouselListItem = styled.li`
  margin-left: 0.25rem;
  margin-right: 0.25rem;
`;

export const SwipeCarouselDot = styled.button<SwipeCarouselDotProps>`
  ${ButtonReset};

  border-radius: 100%;

  width: 0.5rem;
  height: 0.5rem;

  cursor: pointer;

  background-color: ${({ isCurrent }): string =>
    `rgba(255, 255, 255, ${isCurrent ? '100' : '0.5'})`};
`;

interface CarouselControlsProps {
  index: number;
  length: number;
  onPrevious: () => void;
  onNext: () => void;
  onNavDotClick: (index: number) => void;
  onNavComplete: () => void;
  buttonVisible: boolean;
  buttonTextColor: keyof Colors;
  className?: string;
}

const generateKey = (label: string, idx: number): string => `${label}-${idx}`;

export const FeedbackModeCarouselControls: React.FC<CarouselControlsProps> = ({
  buttonVisible,
  buttonTextColor,
  index,
  length,
  className,
  onPrevious,
  onNext,
  onNavDotClick,
  onNavComplete,
}) => {
  const buttonLabel = index === length - 1 ? 'try it out!' : 'next';

  const handleNavDotClick = (idx: number): (() => void) => {
    return (): void => onNavDotClick(idx);
  };

  const handleButtonClick = (): void => {
    if (buttonLabel === 'next') {
      onNext();
    }
    if (buttonLabel === 'try it out!') {
      onNavComplete();
    }
  };

  return (
    <SwipeCarouselFlexContainer>
      <FeedbackModeCarouselControlsContainer className={className}>
        {buttonVisible && (
          <Button
            isFluid
            shape="pill"
            colorScheme="nearWhite"
            textColor={buttonTextColor}
            onClick={handleButtonClick}
            ariaLabel="Next Slide"
            dataId="swipe-carousel-button"
          >
            <span>{buttonLabel}</span>
          </Button>
        )}
        <Spacer spacing={[null, 'md1', 'md3']} />
        <SwipeCarouselNav data-id="swipe-carousel-nav">
          <SwipeCarouselButton
            isDisabled={index === 0}
            onClick={onPrevious}
            aria-label="Previous Slide"
          >
            <IconPointerLeft />
          </SwipeCarouselButton>
          <SwipeCarouselList>
            {Array.from({ length }, (_, idx) => (
              <SwipeCarouselListItem key={generateKey('swipe-carousel-list-item', idx)}>
                <SwipeCarouselDot
                  isCurrent={index === idx}
                  onClick={handleNavDotClick(idx)}
                  role="group"
                  aria-label={`Slide ${idx + 1} of ${length}`}
                />
              </SwipeCarouselListItem>
            ))}
          </SwipeCarouselList>
          <SwipeCarouselButton
            isDisabled={index === length - 1}
            onClick={onNext}
            aria-label="Next Slide"
          >
            <IconPointerRight />
          </SwipeCarouselButton>
        </SwipeCarouselNav>
      </FeedbackModeCarouselControlsContainer>
    </SwipeCarouselFlexContainer>
  );
};

export default FeedbackModeCarouselControls;
