import React, {
  createContext,
  useState,
  useContext,
  ComponentType,
  ComponentClass,
  useRef,
} from 'react';
import { ModalContainer } from './Modal-styled';

interface ModalContextUIState {
  modalComponent: ComponentType<any> | null;
  props?: any;
  visible: boolean;
}

interface ModalContextValue {
  showModal: <P extends Record<string, unknown>>(component: ComponentType<P>, props?: P) => void;
  hideModal: () => void;
  clearModals: () => void;
}

const ModalContext = createContext<ModalContextValue>({
  clearModals: () => {},
  showModal: () => {},
  hideModal: () => {},
});

/**
 * React hook which gives functionality to trigger a modal from anywhere in the app
 * If more than one modal is triggered while another is open, it will be added to a queue.
 * ALWAYS clear queue onCTAClick, in order to avoid side effects.
 * @returns Modal Context Object containing showModal, hideModal, clearModals functions
 */
export const useModal = (): ModalContextValue => {
  const modalContext = useContext(ModalContext);
  return modalContext;
};

/**
 * HOC to provide a component with the showModal and hideModal functions from the
 * modal context service, where converting a class component to a hook is not possible
 * or feasible.
 * @param Component - React Class component to be injected with the modal context
 */
export const withModal = <P extends Record<string, unknown>>(
  Component: ComponentClass<P & ModalContextValue>,
): React.FC<P> => {
  return (props): ReturnType<React.FC<P>> => {
    const { showModal, hideModal, clearModals } = useModal();
    return (
      <Component showModal={showModal} hideModal={hideModal} clearModals={clearModals} {...props} />
    );
  };
};

/**
 * Context Provider for the Modal Context service. Needed to use the withModal HOC
 * or the useModal hook. This enforces the idea that while you may have multiple modal
 * instances or layouts, only one modal may be present at any given time.
 */
export const ModalContextProvider: React.FC = ({ children }) => {
  const [modalUIState, setModalUIState] = useState<ModalContextUIState>({
    modalComponent: null,
    props: null,
    visible: false,
  });

  const modalQueueRef = useRef<ModalContextUIState[]>([]);
  const modalQueue = modalQueueRef.current;

  const showModal = <P extends Record<string, unknown>>(
    modalComponent: ComponentType<P>,
    props?: P,
  ): void => {
    if (
      modalComponent === modalUIState.modalComponent &&
      JSON.stringify(props) === JSON.stringify(modalUIState.props)
    ) {
      return;
    }

    modalQueue.push({
      modalComponent,
      props,
      visible: true,
    });

    setModalUIState(prevModal => {
      if (!prevModal.visible) return modalQueue.shift() as ModalContextUIState;
      return prevModal;
    });
  };

  const hideModal = (): void => {
    if (modalQueue.length) {
      setModalUIState(modalQueue.shift() as ModalContextUIState);
    } else {
      setModalUIState({
        modalComponent: null,
        props: null,
        visible: false,
      });
    }
  };

  const clearModals = (): void => {
    modalQueue.length = 0;
    setModalUIState({
      modalComponent: null,
      props: null,
      visible: false,
    });
  };

  const contextValue = {
    clearModals,
    showModal,
    hideModal,
  };

  const { modalComponent: Component, props, visible } = modalUIState;

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {Component !== null && visible && (
        <ModalContainer className="modal-context-container">
          <Component {...props} />
        </ModalContainer>
      )}
    </ModalContext.Provider>
  );
};
