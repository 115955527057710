/* eslint-disable react/jsx-one-expression-per-line */
import React, { FC } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import LocaleTable from './LocaleTable';

const LocaleOverlay = styled.div<{ visible: boolean }>`
  ${(props): string => (props.visible ? '' : 'pointer-events: none;')}
  position: fixed;
  background-color: ${(props): string => (props.visible ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)')};
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  padding: 0.5rem;
  transition: background-color 0.15s linear;
  z-index: 999;
  overflow: auto;
`;

const LocaleContainer = styled.div`
  position: relative;
  height: 100%;
  width: auto;
  border-radius: 3px;
  background-color: white;
  opacity: 1;
  z-index: 999;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  overflow: auto;

  text-transform: none;
  color: #000;
  text-align: left;
  font-size: 14px;

  .col-item {
    float: left;
    width: 50%;
    margin: 0 0 15px;
    padding: 0 40px 0 0;
    box-sizing: border-box;
  }

  ${up('tablet-landscape')} {
    max-width: 500px;
    margin: 1.75rem auto;
  }
`;

const CloseButton = styled.button`
  color: #ccc;
  font-family: SourceSansPro-Bold, -apple-system, system-ui, avenir next, avenir, Helvetica Neue,
    helvetica, ubuntu, roboto, noto, Segoe UI, Arial, sans-serif;
  font-weight: 400;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  right: 13px;
  top: -4px;
  opacity: 1;
  z-index: 999;
  float: right;
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
  text-shadow: 0 1px 0 #fff;
`;

const LocaleHeadline = styled.h3`
  clear: left;
  text-transform: uppercase;
  border-bottom: 1px solid #e0e0e0;
  margin: 0 0 15px;
  font-size: 23px;
  padding: 0 0 5px;
  line-height: normal;
  font-family: FuturaPT-Heavy, -apple-system, system-ui, avenir next, avenir, Helvetica Neue,
    helvetica, ubuntu, roboto, noto, Segoe UI, Arial, sans-serif;
  color: #666;
`;

const CountryLabel = styled.h3`
  color: #000;
  padding: 7px 0 3px;
  font-family: SourceSansPro-Regular, -apple-system, system-ui, avenir next, avenir, Helvetica Neue,
    helvetica, ubuntu, roboto, noto, Segoe UI, Arial, sans-serif;
  font-weight: 400;
  font-size: 10px;
  border: 0;
  margin: 0;
  line-height: normal;
  text-transform: uppercase;
`;

type FooterLocaleProps = {
  onClose: () => void;
  visible: boolean;
};

const FooterLocale: FC<FooterLocaleProps> = ({ onClose, visible }) => {
  return (
    <>
      <LocaleOverlay visible={visible}>
        <div>
          {visible && (
            <LocaleContainer>
              <CloseButton onClick={onClose}>×</CloseButton>
              <LocaleHeadline>Choose your country</LocaleHeadline>
              <LocaleHeadline>Asia Pacific</LocaleHeadline>
              <div className="col-item">
                <LocaleTable cellPadding="0" cellSpacing="0" className="locale-list">
                  <tbody>
                    <tr>
                      <td>
                        <span className="flag flag-icon-au" />
                        Australia
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=AU&amp;l=en"
                          data-test="ftr-intl-en-AU"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </LocaleTable>
              </div>
              <div className="col-item">
                <LocaleTable cellPadding="0" cellSpacing="0" className="locale-list">
                  <tbody>
                    <tr>
                      <td>
                        <span className="flag flag-icon-jp" />
                        Japan
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=JP&amp;l=ja"
                          data-test="ftr-intl-ja-JP"
                        >
                          JA
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </LocaleTable>
              </div>
              <LocaleHeadline>Europe</LocaleHeadline>
              <div className="col-item">
                <LocaleTable cellPadding="0" cellSpacing="0" className="locale-list">
                  <tbody>
                    <tr>
                      <td>
                        <span className="flag flag-icon-ad" />
                        Andorra
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=AD&amp;l=en"
                          data-test="ftr-intl-en-AD"
                        >
                          EN
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=AD&amp;l=es"
                          data-test="ftr-intl-es-AD"
                        >
                          ES
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=AD&amp;l=fr"
                          data-test="ftr-intl-fr-AD"
                        >
                          FR
                        </a>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-be" />
                        Belgium
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=BE&amp;l=en"
                          data-test="ftr-intl-en-BE"
                        >
                          EN
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=BE&amp;l=de"
                          data-test="ftr-intl-de-BE"
                        >
                          DE
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=BE&amp;l=fr"
                          data-test="ftr-intl-fr-BE"
                        >
                          FR
                        </a>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-hr" />
                        Croatia
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=HR&amp;l=en"
                          data-test="ftr-intl-en-HR"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-cz" />
                        Czech Republic
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=CZ&amp;l=en"
                          data-test="ftr-intl-en-CZ"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-ee" />
                        Estonia
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=EE&amp;l=en"
                          data-test="ftr-intl-en-EE"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-fr" />
                        France
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=FR&amp;l=en"
                          data-test="ftr-intl-en-FR"
                        >
                          EN
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=FR&amp;l=fr"
                          data-test="ftr-intl-fr-FR"
                        >
                          FR
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-de" />
                        Germany
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=DE&amp;l=en"
                          data-test="ftr-intl-en-DE"
                        >
                          EN
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=DE&amp;l=de"
                          data-test="ftr-intl-de-DE"
                        >
                          DE
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-it" />
                        Italy
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=IT&amp;l=en"
                          data-test="ftr-intl-en-IT"
                        >
                          EN
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=IT&amp;l=it"
                          data-test="ftr-intl-it-IT"
                        >
                          IT
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-lv" />
                        Latvia
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=LV&amp;l=en"
                          data-test="ftr-intl-en-LV"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-lt" />
                        Lithuania
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=LT&amp;l=en"
                          data-test="ftr-intl-en-LT"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-mt" />
                        Malta
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=MT&amp;l=en"
                          data-test="ftr-intl-en-MT"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-nl" />
                        Netherlands
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=NL&amp;l=en"
                          data-test="ftr-intl-en-NL"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-pl" />
                        Poland
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=PL&amp;l=en"
                          data-test="ftr-intl-en-PL"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-ro" />
                        Romania
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=RO&amp;l=en"
                          data-test="ftr-intl-en-RO"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-sk" />
                        Slovakia
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=SK&amp;l=en"
                          data-test="ftr-intl-en-SK"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-se" />
                        Sweden
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=SE&amp;l=en"
                          data-test="ftr-intl-en-SE"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-gb" />
                        United Kingdom
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=GB&amp;l=en"
                          data-test="ftr-intl-en-GB"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </LocaleTable>
              </div>
              <div className="col-item">
                <LocaleTable cellPadding="0" cellSpacing="0" className="locale-list">
                  <tbody>
                    <tr>
                      <td>
                        <span className="flag flag-icon-at" />
                        Austria
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=AT&amp;l=en"
                          data-test="ftr-intl-en-AT"
                        >
                          EN
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=AT&amp;l=de"
                          data-test="ftr-intl-de-AT"
                        >
                          DE
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-bg" />
                        Bulgaria
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=BG&amp;l=en"
                          data-test="ftr-intl-en-BG"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-cy" />
                        Cyprus
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=CY&amp;l=en"
                          data-test="ftr-intl-en-CY"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-dk" />
                        Denmark
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=DK&amp;l=en"
                          data-test="ftr-intl-en-DK"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-fi" />
                        Finland
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=FI&amp;l=en"
                          data-test="ftr-intl-en-FI"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-gr" />
                        Greece
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=GR&amp;l=en"
                          data-test="ftr-intl-en-GR"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-hu" />
                        Hungary
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=HU&amp;l=en"
                          data-test="ftr-intl-en-HU"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-ie" />
                        Ireland
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=IE&amp;l=en"
                          data-test="ftr-intl-en-IE"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-li" />
                        Liechtenstein
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=LI&amp;l=en"
                          data-test="ftr-intl-en-LI"
                        >
                          EN
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=LI&amp;l=de"
                          data-test="ftr-intl-de-LI"
                        >
                          DE
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-lu" />
                        Luxembourg
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=LU&amp;l=en"
                          data-test="ftr-intl-en-LU"
                        >
                          EN
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=LU&amp;l=de"
                          data-test="ftr-intl-de-LU"
                        >
                          DE
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=LU&amp;l=fr"
                          data-test="ftr-intl-fr-LU"
                        >
                          FR
                        </a>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-mc" />
                        Monaco
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=MC&amp;l=en"
                          data-test="ftr-intl-en-MC"
                        >
                          EN
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=MC&amp;l=fr"
                          data-test="ftr-intl-fr-MC"
                        >
                          FR
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-no" />
                        Norway
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=NO&amp;l=en"
                          data-test="ftr-intl-en-NO"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-pt" />
                        Portugal
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=PT&amp;l=en"
                          data-test="ftr-intl-en-PT"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-sl" />
                        Slovenia
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=SI&amp;l=en"
                          data-test="ftr-intl-en-SI"
                        >
                          EN
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-es" />
                        Spain
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=ES&amp;l=en"
                          data-test="ftr-intl-en-ES"
                        >
                          EN
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=ES&amp;l=es"
                          data-test="ftr-intl-es-ES"
                        >
                          ES
                        </a>
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="flag flag-icon-ch" />
                        Switzerland
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=CH&amp;l=en"
                          data-test="ftr-intl-en-CH"
                        >
                          EN
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=CH&amp;l=de"
                          data-test="ftr-intl-de-CH"
                        >
                          DE
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=CH&amp;l=fr"
                          data-test="ftr-intl-fr-CH"
                        >
                          FR
                        </a>
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=CH&amp;l=it"
                          data-test="ftr-intl-it-CH"
                        >
                          IT
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </LocaleTable>
              </div>
              <LocaleHeadline>North America</LocaleHeadline>
              <div className="col-item">
                <LocaleTable cellPadding="0" cellSpacing="0" className="locale-list">
                  <tbody>
                    <tr>
                      <td>
                        <span className="flag flag-icon-us" />
                        United States
                      </td>
                      <td>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=US&amp;l=en"
                          data-test="ftr-intl-en-US"
                        >
                          EN
                        </a>
                      </td>

                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </LocaleTable>
              </div>
              <LocaleHeadline>Choose your region</LocaleHeadline>
              <p>Don’t see your country listed above? Please choose your region below</p>
              &nbsp;
              <LocaleTable cellPadding="0" cellSpacing="0" className="regions-list">
                <tbody>
                  <tr>
                    <td align="left" className="first">
                      <div className="zone-col">
                        <img
                          alt=""
                          src="https://shop.fender.com/on/demandware.static/-/Library-Sites-Fender-Shared/en/dw2474854c/africa.png"
                          title=""
                        />
                        <CountryLabel>Africa</CountryLabel>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=ZA&amp;l=en"
                          data-test="ftr-intl-en-ZA"
                        >
                          En
                        </a>{' '}
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=SN&amp;l=fr"
                          data-test="ftr-intl-fr-SN"
                        >
                          Fr
                        </a>
                      </div>
                    </td>
                    <td align="center">
                      <div className="zone-col">
                        <img
                          alt=""
                          src="https://shop.fender.com/on/demandware.static/-/Library-Sites-Fender-Shared/en/dwb4b4358a/americas.png"
                          title=""
                        />
                        <CountryLabel>Americas</CountryLabel>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=CA&amp;l=en"
                          data-test="ftr-intl-en-CA"
                        >
                          En
                        </a>{' '}
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=VE&amp;l=es"
                          data-test="ftr-intl-es-VE"
                        >
                          Es
                        </a>
                      </div>
                    </td>
                    <td align="center">
                      <div className="zone-col">
                        <img
                          alt=""
                          src="https://shop.fender.com/on/demandware.static/-/Library-Sites-Fender-Shared/default/dwb9201bbe/asia-pacific.png"
                          title=""
                        />
                        <CountryLabel>Asia Pacific</CountryLabel>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=NZ&amp;l=en"
                          data-test="ftr-intl-en-NZ"
                        >
                          En
                        </a>
                      </div>
                    </td>
                    <td align="center">
                      <div className="zone-col">
                        <img
                          alt=""
                          src="https://shop.fender.com/on/demandware.static/-/Library-Sites-Fender-Shared/en/dw52dc96b8/europe.png"
                          title=""
                        />
                        <CountryLabel>Europe</CountryLabel>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=RU&amp;l=en"
                          data-test="ftr-intl-en-RU"
                        >
                          En
                        </a>
                      </div>
                    </td>
                    <td align="right" className="last">
                      <div className="zone-col zone-col_last">
                        <img
                          alt=""
                          src="https://shop.fender.com/on/demandware.static/-/Library-Sites-Fender-Shared/en/dw77f18bc4/middle-east.png"
                          title=""
                        />
                        <CountryLabel>Middle East</CountryLabel>
                        <a
                          href="https://shop.fender.com/en-US/redirect?c=SA&amp;l=en"
                          data-test="ftr-intl-en-SA"
                        >
                          En
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </LocaleTable>
            </LocaleContainer>
          )}
        </div>
      </LocaleOverlay>
    </>
  );
};

export default FooterLocale;
