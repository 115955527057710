import React, { FC } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Course, Recents } from '@fenderdigital/api-definitions/play';
import LessonsList from '../LessonsList';
import CardHeader from '../CardHeader';

interface CourseDetailsCard {
  course: Course[];
  recentlyViewed?: { [key: number]: Recents };
  markCompleteData?: any;
  courseNumber?: number | null;
}

const Container = styled.div`
  position: relative;
  max-width: 1376px;
  margin: auto;

  ${up('tablet-portrait')} {
    padding-top: 2rem;
  }

  ${up('laptop')} {
    padding-top: 2rem;
  }
`;

const SubContainer = styled.div`
  padding-bottom: 2rem;
  background: white;
  min-height: calc(100vh - 105px);

  ${up('tablet-portrait')} {
    margin: '0 2rem';
    border-radius: 10px;
    overflow: hidden;
    min-height: auto;
  }
`;

const CourseDetailsCard: FC<CourseDetailsCard> = ({ course, recentlyViewed, courseNumber }) => {
  const courseData = course?.[0];
  const { lessons, slug, id, 'mms-id': mmsId } = courseData ?? {};

  return (
    <Container>
      <SubContainer>
        <CardHeader
          courseData={courseData}
          courseNumber={courseNumber ?? 0}
          percentCompleted={0}
          key={id}
        />
        <LessonsList
          lessons={lessons}
          url={slug}
          mmsId={mmsId}
          courseData={courseData}
          recentlyViewed={recentlyViewed}
        />
      </SubContainer>
    </Container>
  );
};

export default CourseDetailsCard;
