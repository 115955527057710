import React, { useState, FormEvent } from 'react';
import styled from 'styled-components';
import { Focus } from '@fenderdigital/ui/utils/css';
import Heading from '@fenderdigital/ui/Heading';
import BodyText from '@fenderdigital/ui/BodyText';
import { trackEvent, EventNames } from '../../lib/analytics';

const isEmail = (email: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const SignupContainer = styled.div`
  display: flex;
  margin-bottom: 80px;
  flex-wrap: wrap;
  @media screen and (max-width: 480px) {
    margin-bottom: 60px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  @media screen and (max-width: 768px) {
    flex: unset;
    width: 100%;
  }
  h1 {
    font-family: FuturaPT-Heavy;
    font-size: 33px;
    font-weight: normal;
    line-height: 36px;
    margin-top: 0;
    margin-bottom: 16px;
  }
  span {
    width: 90%;
    margin-top: 15px;
    color: rgb(170, 170, 170);
    font-size: 20px;
    font-weight: normal;
    line-height: 25px;
  }
`;

const FormContainer = styled.div`
  flex: 3;
  display: flex;
  align-items: flex-end;
  > span {
    margin: 0 auto 10px;
  }
`;

const InputContainer = styled.form`
  display: flex;
  border: 1px solid rgb(151, 151, 151);
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 100%;
  padding: 16px;
  margin-left: 12px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-top: 36px;
    width: 100%;
  }
  > input {
    ${Focus}
    height: 80%;
    width: 75%;
    background-color: inherit;
    outline: none;
    border-style: none;
    font-family: FuturaPT-Heavy;
    font-size: 18px;
    font-weight: 100;
    text-transform: uppercase;
    color: rgb(204, 204, 204);

    &::placeholder {
      color: rgb(204, 204, 204);
    }
  }
  > button {
    ${Focus}
    display: inline-block;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0.05em;
    margin: 0;
    border: 0;
    background-color: #d52b1e;
    font-family: FuturaPT-Heavy;
    height: 40px;
    width: 127px;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.89px;
    padding: unset;
    border-radius: unset;
  }
`;

const EmailInput = styled.input`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FooterSignup: React.FC = () => {
  const [email, setEmail] = useState('');
  const [signupMsg, setSignupMsg] = useState('');

  const handleEmailChange = (e: { target: { value: string } }): void => {
    setEmail(e.target.value);
  };

  const signupSubmitAPI = async (): Promise<void> => {
    try {
      await fetch(`${process.env.REACT_APP_CLIENT_LEADS_API}/leads`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'omit',
        body: JSON.stringify({ email, locale: 'en-US' }),
      });
      setSignupMsg('Thanks for signing up!');
      trackEvent(
        EventNames.EMAIL_SIGNUP,
        {
          cta_location: 'footer',
        },
        {},
        true,
      );
    } catch (e) {
      setSignupMsg('Please try again later - we are experiencing technical difficulties.');
    }
  };

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    if (!isEmail(email)) {
      return;
    }
    await signupSubmitAPI();
  };
  return (
    <SignupContainer data-id="footer-email-capture-input">
      <TextContainer>
        <Heading as="h2" textColor="white" marginBottom="md1" textSize="f6">
          Don&apos;t miss out!
        </Heading>
        <BodyText textColor="white">
          Be the first to know about new products, featured content, exclusive offers and giveaways.
        </BodyText>
      </TextContainer>
      <FormContainer onSubmit={handleSubmit}>
        {signupMsg ? (
          <span data-id="footer-email-capture-message">{signupMsg}</span>
        ) : (
          <InputContainer>
            <EmailInput
              placeholder="Enter email address"
              type="text"
              value={email}
              onChange={handleEmailChange}
            />
            <button data-id="button-footer-email-capture" type="submit" className="button">
              SIGN UP
            </button>
          </InputContainer>
        )}
      </FormContainer>
    </SignupContainer>
  );
};

export default FooterSignup;
