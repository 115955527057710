import React, { FC } from 'react';
import styled from 'styled-components';
import { VisuallyHidden } from '@fenderdigital/ui/utils/css';

const StyledScreenReaderInstructions = styled.p`
  ${VisuallyHidden}
`;

const ScreenReaderSearchInstructions: FC = ({ children, ...props }) => {
  return (
    <StyledScreenReaderInstructions id="search-instructions" {...props}>
      {children}
    </StyledScreenReaderInstructions>
  );
};

export default ScreenReaderSearchInstructions;
