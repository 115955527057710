/* eslint-disable max-len */
import { ComponentType, FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import generateAsyncRouteComponent from '../components/AsyncComponent';

interface DefaultModule<T> {
  default: T;
}
const getDefaultExport = <T>(module: DefaultModule<T>): T => module.default;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncRouteComponent = ComponentType<any> & {
  load: () => Promise<void>;
};

export interface Route {
  path: string;
  Component: AsyncRouteComponent;
  exact?: boolean;
}

export enum InstrumentKind {
  bass = 'bass',
  guitar = 'guitar',
  ukulele = 'ukulele',
}

export function isInstrumentKind(instStr?: string | null): instStr is InstrumentKind {
  return !!instStr && instStr in InstrumentKind;
}

export interface Instrument {
  instrument: InstrumentKind;
}

export interface BrowseSkillsParams extends Instrument {
  skillType: string;
  offset: string;
}

export interface BrowseSongsParams extends Instrument {
  type: string;
  genre: string;
}

interface InjectedIntlFake {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intl: any;
}

export type InstrumentSkillsProps = RouteComponentProps<Instrument> & InjectedIntlFake;
export type InstrumentSongsProps = InstrumentSkillsProps;
export type InstrumentCollectionsProps = InstrumentSkillsProps;

export type BrowseSkillsRouteProps = RouteComponentProps<BrowseSkillsParams>;
export type BrowseSongsRouteProps = RouteComponentProps<BrowseSongsParams>;

// The top-level routes
const routes: Route[] = [
  {
    path: '/',
    exact: true,
    Component: generateAsyncRouteComponent({
      loader: (): Promise<ComponentType> =>
        import(/* webpackChunkName: "HomeRoute" */ './home').then(getDefaultExport),
    }),
  },
  {
    path: '/songs',
    exact: true,
    Component: generateAsyncRouteComponent({
      loader: (): Promise<ComponentType> =>
        import(/* webpackChunkName: "SongsRoute" */ './songs/components/SongsContainer').then(
          getDefaultExport,
        ),
    }),
  },

  {
    path: '/articles',
    Component: generateAsyncRouteComponent({
      loader: (): Promise<ComponentType> =>
        import(/* webpackChunkName: "ArticlesRoute" */ './articles/components/ArticlesFeed').then(
          getDefaultExport,
        ),
    }),
  },
  // TODO: Singularize this route for play-auth
  {
    path: '/courses/:courseSlug',
    Component: generateAsyncRouteComponent({
      loader: (): Promise<ComponentType> =>
        import(/* webpackChunkName: "PublicCourseRoute" */ './public-courses').then(
          getDefaultExport,
        ),
    }),
  },

  {
    path: '/:instrument(bass|guitar|ukulele)/collections',
    exact: true,
    Component: generateAsyncRouteComponent<
      InstrumentCollectionsProps,
      FC<InstrumentCollectionsProps>
    >({
      loader: (): Promise<FC<InstrumentCollectionsProps>> =>
        import(
          /* webpackChunkName: "InstrumentCollectionsRoute" */ './collections/CollectionsLanding'
        ).then(getDefaultExport),
    }),
  },
  {
    path: '/:instrument(bass|guitar|ukulele)/skills',
    exact: true,
    Component: generateAsyncRouteComponent<InstrumentSkillsProps, FC<InstrumentSkillsProps>>({
      loader: (): Promise<FC<InstrumentSkillsProps>> =>
        import(/* webpackChunkName: "InstrumentSkillsRoute" */ './instrument/Skills').then(
          getDefaultExport,
        ),
    }),
  },
  {
    path: '/:instrument(bass|guitar|ukulele)/songs',
    exact: true,
    Component: generateAsyncRouteComponent<InstrumentSongsProps, FC<InstrumentSongsProps>>({
      loader: (): Promise<FC<InstrumentSongsProps>> =>
        import(/* webpackChunkName: "InstrumentSongsRoute" */ './instrument/Songs').then(
          getDefaultExport,
        ),
    }),
  },
  {
    path: '/:instrument(bass|guitar|ukulele)/skills/:skillType?/:offset?',
    Component: generateAsyncRouteComponent<BrowseSkillsRouteProps, FC<BrowseSkillsRouteProps>>({
      loader: (): Promise<FC<BrowseSkillsRouteProps>> =>
        import(
          /* webpackChunkName: "instrumentSkillsTypeRoute" */ './instrument/BrowseSkills'
        ).then(getDefaultExport),
    }),
  },
  {
    path: '/:instrument(bass|guitar|ukulele)/:type/:level/:genre?/:offset?',
    Component: generateAsyncRouteComponent<BrowseSongsRouteProps, FC<BrowseSongsRouteProps>>({
      loader: (): Promise<FC<BrowseSongsRouteProps>> =>
        import(
          /* webpackChunkName: "instrumentArtistsTypeRoute" */ './instrument/BrowseSongs'
        ).then(getDefaultExport),
    }),
  },
  // TODO: Singularize this route for play-auth
  {
    path: '/lessons/:lessonSlug',
    Component: generateAsyncRouteComponent({
      loader: (): Promise<ComponentType> =>
        import(/* webpackChunkName: "PublicLessonRoute" */ './public-lessons').then(
          getDefaultExport,
        ),
    }),
  },
  {
    path: '/search',
    exact: true,
    Component: generateAsyncRouteComponent<RouteComponentProps, ComponentType<RouteComponentProps>>(
      {
        loader: (): Promise<ComponentType<RouteComponentProps>> =>
          import(/* webpackChunkName: "searchRoute" */ './search/search-wrapper').then(
            getDefaultExport,
          ),
      },
    ),
  },
  {
    path: '/search/results',
    exact: true,
    Component: generateAsyncRouteComponent<RouteComponentProps, ComponentType<RouteComponentProps>>(
      {
        loader: (): Promise<ComponentType<RouteComponentProps>> =>
          import(/* webpackChunkName: "searchResultsRoute" */ './search/results-wrapper').then(
            getDefaultExport,
          ),
      },
    ),
  },
];

export default routes;
