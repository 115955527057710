import React, { PureComponent, ReactNode, SyntheticEvent } from 'react';
// eslint-disable-next-line
// @ts-ignore
import onClickOutside from 'react-onclickoutside';
import { MenuTitle, MenuList } from '../UnSubscribedMenus';
import CollectionLinks from './CollectionLinks';
import { Navigation } from './NavTypes';

interface SubMenuProps {
  children: ReactNode;
  email: string;
  isVisible: boolean;
  isTest: boolean;
  menu: Navigation[] | null;
  subMenuId: string;
  onClickOutside: (event: SyntheticEvent) => void;
  onSubMenuClick: (event: SyntheticEvent) => void;
}

class SubMenu extends PureComponent<SubMenuProps> {
  static defaultProps = {
    email: null,
    isVisible: null,
    isTest: false,
    menu: null,
    onClickOutside: (): void => {},
    onSubMenuClick: (): void => {},
  };

  handleClickOutside = (event: SyntheticEvent): void => {
    this.props.onClickOutside(event);
  };

  handleSubMenuClick = (event: SyntheticEvent): void => {
    this.props.onSubMenuClick(event);
  };

  getMenuWithCollectionLinks = (): Navigation[] | null => {
    const { menu, subMenuId } = this.props;

    if (!menu) {
      return menu;
    }

    switch (subMenuId) {
      case 'ukulele':
      case 'guitar':
      case 'bass':
        return [menu[0], CollectionLinks[subMenuId], menu[1]];
      default:
        return menu;
    }
  };

  render(): ReactNode {
    const { email, isVisible, isTest } = this.props;
    const subMenuStyle = `${
      isTest ? 'db' : `${isVisible ? 'dn db-l' : 'dn'}`
    } absolute left-0 z-5 ph4 w-100 bg-white bb b--black-10`;
    const styleObj = {
      marginTop: '0.0625rem',
      top: isTest ? '0' : '100%',
    };

    const menu = this.getMenuWithCollectionLinks();

    if (!menu) {
      return null;
    }

    return (
      <div className={`sub-menu ${subMenuStyle}`} style={styleObj}>
        <div className="mw9 center pv3">
          <div className="flex nl4 nr4">
            {menu.map((menuItem: Navigation, index: number) => (
              <section
                key={`mega-nav-sub-menu-section-${menuItem.label}`}
                className={`${menu.length === 3 && index <= 1 ? 'w-25' : 'w-50'} ph4`}
                data-id={`mega-nav-sub-menu-section-${menuItem.label}`}
              >
                <MenuTitle
                  obj={{
                    label: menuItem.label,
                    secondaryLabel: menuItem['secondary-label'],
                    entity: menuItem.entity,
                  }}
                />
                <MenuList
                  obj={{ email, list: menuItem.items, gutterSpacing: null }}
                  onClick={this.handleSubMenuClick}
                />
                <div className="w-100 flex">
                  {menuItem.items
                    ? menuItem.items.map((secondaryItem: any, index: number) => (
                        <div
                          key={`secondary-item ${secondaryItem.label}`}
                          className={`
                        ${!secondaryItem.items ? 'dn' : ''}
                        ${index !== menuItem.items.length - 1 ? 'pr4' : ''} w-50`}
                        >
                          {secondaryItem.items ? (
                            <div>
                              <MenuTitle
                                obj={{
                                  label: secondaryItem.label,
                                  secondaryLabel: secondaryItem['secondary-label'],
                                  entity: secondaryItem.entity,
                                }}
                                isSubTitle
                              />

                              <MenuList
                                obj={{
                                  email,
                                  list: secondaryItem.items,
                                  gutterSpacing: null,
                                }}
                                onClick={this.handleSubMenuClick}
                              />
                            </div>
                          ) : null}
                        </div>
                      ))
                    : null}
                </div>
              </section>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default onClickOutside(SubMenu);
