import React, { ReactNode } from 'react';
import { css } from 'styled-components';
import SkinnyNav, { SkinnyNavPaths } from '@fenderdigital/ui/SkinnyNav';
import usePlayUser from '@fenderdigital/custom-hooks/hooks/usePlayUser';
import useIsHome from '../../hooks/useIsHome';
import PromoContent from './PromoContent';
import { clearLocalStorage, getSignInUrlWithSession } from '../../lib/cognito';
import segment from '../../core/segment';

const { event } = segment;

interface DotComHeader {
  bannerOpen: boolean;
  isHome: boolean;
  promoColor: string;
  promoContent: ReactNode;
  promoOnClick: () => void;
}

const getSiteTitle = (link: string): string => {
  switch (link) {
    case SkinnyNavPaths.COM:
      return 'FENDER.COM';
    case SkinnyNavPaths.PLAY:
      return 'PLAY';
    case SkinnyNavPaths.TUNE:
      return 'TUNE';
    case SkinnyNavPaths.BEGINNERS:
      return 'BEGINNERS';
    default:
      return '';
  }
};

const handleSegmentEvent = (link: string): void => {
  const trackEvent = {
    eventType: 'Click Global Navigation',
    properties: {
      navigation_core: getSiteTitle(link),
    },
  };
  event(trackEvent.eventType, trackEvent.properties);
};

const handleSignOutClick = (): void => {
  clearLocalStorage();
};

const DotComHeader: React.FC<DotComHeader> = ({
  bannerOpen,
  promoColor,
  promoContent,
  promoOnClick,
}) => {
  const { user } = usePlayUser();
  const isLoggedIn = !!user;
  const isHome = useIsHome();
  const signInURL = getSignInUrlWithSession();
  return (
    <div id="above-header">
      <SkinnyNav
        currentPath="/play"
        onLinkClick={handleSegmentEvent}
        onSignOutClick={handleSignOutClick}
        signInUrl={!isLoggedIn ? signInURL : undefined}
        signOutUrl={
          isLoggedIn
            ? `${process.env.REACT_APP_CLIENT_SSO_URL}/sign-out?ref=${process.env.REACT_APP_CLIENT_FENDER_COM}/play`
            : undefined
        }
        dataId="skinny-nav"
        userFirstName={isLoggedIn ? user?.['first-name'] : undefined}
        userProfileUrl={
          isLoggedIn ? `${process.env.REACT_APP_CLIENT_SSO_URL}/profile/info` : undefined
        }
        navListHidden={false}
        css={css`
          ${isHome &&
          `& > ul:last-child {
            display: none;
          }`}
        `}
      />
      <PromoContent onClick={promoOnClick} bannerOpen={bannerOpen} promoColor={promoColor}>
        {promoContent}
      </PromoContent>
    </div>
  );
};

export default DotComHeader;
