import mirror from 'keymirror';
import SongsLists from '../lib/songs-lists';

export const actionTypes = mirror({
  SONGS_LISTS_GET_ERROR: null,
  SONGS_LISTS_GET_IN_PROGRESS: null,
  SONGS_LISTS_GET_SUCCESS: null,
});

const songsListsGetError = error => ({
  error,
  type: actionTypes.SONGS_LISTS_GET_ERROR,
});

const songsListsGetInProgress = () => ({
  type: actionTypes.SONGS_LISTS_GET_IN_PROGRESS,
});

const songsListsGetSuccess = results => ({
  results,
  type: actionTypes.SONGS_LISTS_GET_SUCCESS,
});

export const getSongsLists = (instrument, featured) => async dispatch => {
  dispatch(songsListsGetInProgress());
  try {
    const lists = await SongsLists.getSongsLists(instrument, featured);
    dispatch(songsListsGetSuccess(lists));
  } catch (err) {
    dispatch(songsListsGetError(err));
  }
};
