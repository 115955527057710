import React, { FC } from 'react';
import * as S from './CardImage-styled';

interface CardImageProps {
  image: string;
  courseNumber?: number;
  isSong?: boolean;
  hasBackgroundImage?: boolean;
}

const CardImage: FC<CardImageProps> = ({ image, courseNumber, isSong, hasBackgroundImage }) => {
  const courseNumLabel = courseNumber && courseNumber > 9 ? `${courseNumber}` : `0${courseNumber}`;

  const transformBaseImageUrl = (url: string): string => {
    const base = url?.split('.jpg')[0];
    const resolution = isSong ? '200x200' : '400x225';
    return encodeURI(`${base}_${resolution}.jpg`);
  };

  return (
    <S.Container data-id="course-card-image-container">
      {isSong && !hasBackgroundImage && (
        <>
          <S.BaseImage image={transformBaseImageUrl(image)} />
          <S.Overlay />
        </>
      )}
      {isSong ? (
        <S.AlbumContainer>
          <S.AlbumSubContainer>
            <S.AlbumCover
              image={transformBaseImageUrl(image)}
              hasBackground={hasBackgroundImage}
              isCourse={!!courseNumber}
            />
          </S.AlbumSubContainer>
        </S.AlbumContainer>
      ) : (
        <>
          <S.Image image={transformBaseImageUrl(image)} />
          <S.Spotlight />
        </>
      )}
      {!!courseNumber && (
        <S.MetaContainer>
          <S.Title>COURSE</S.Title>
          <S.CourseNumber courseNumber={courseNumber}>{courseNumLabel}</S.CourseNumber>
        </S.MetaContainer>
      )}
    </S.Container>
  );
};

export default CardImage;
