import React from 'react';
import styled from 'styled-components';
import Flex from '@fenderdigital/ui/Flex';
import { FeedbackGradient } from './common';

const Label = styled.div<{ absolute?: boolean }>`
  font-weight: 200;
  background: black;
  color: white;
  text-transform: uppercase;
  max-width: fit-content;
  white-space: nowrap;
  padding: 4px 6px;
  font-size: 14px;
  font-family: FuturaPT-Heavy;
  letter-spacing: 0.3px;
  line-height: 1;
`;

const PrettyBadge = styled.div`
  ${FeedbackGradient}
  max-width: fit-content;
  padding: 4px;
  font-size: 14px;
  font-family: FuturaPT-HeavyObl;
  letter-spacing: 0.3px;
  line-height: 1;
`;

interface BadgeProps {
  label?: string;
  prettyLabel?: string;
  absolute?: boolean;
}

const FeedbackBadge: React.FC<BadgeProps> = ({ label = 'feedback mode', prettyLabel = 'beta' }) => {
  return (
    <Flex dataId="feedback-badge">
      <Label>{label}</Label>
      {prettyLabel && <PrettyBadge>{prettyLabel}</PrettyBadge>}
    </Flex>
  );
};

export default FeedbackBadge;
