import React, { FC } from 'react';
import Alert from '@fenderdigital/ui/Alert';
import Button from '@fenderdigital/ui/Button';
import TextField from '@fenderdigital/ui/TextField';
import Spacer from '@fenderdigital/ui/Spacer';

import usePlayUser from '@fenderdigital/custom-hooks/hooks/usePlayUser';

interface LoginProps {
  onSignIn: (event: React.FormEvent<HTMLFormElement>) => void;
  onSignOut: () => void;
}

const Login: FC<LoginProps> = ({ onSignIn, onSignOut }) => {
  const { user, subscriptionStatus } = usePlayUser();
  return (
    <div className="developer-console-login" data-id="cognitoLogin">
      {user?.email ? (
        <dl className="mb3 mt0 tl">
          <dt className="source-sans-b ttc mb2">Email</dt>
          <dd className="ml0 mb3">{user.email}</dd>
          <dt className="source-sans-b ttc mb2">Name</dt>
          <dd className="ml0 mb3">
            {user['first-name']} {user['last-name']}
          </dd>
          {subscriptionStatus?.play?.status && (
            <>
              <dt className="source-sans-b ttc mb2">Subscription Status</dt>
              <dd className="ml0">{subscriptionStatus?.play?.status}</dd>
            </>
          )}
        </dl>
      ) : null}
      <form
        className="bn"
        onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
          event.preventDefault();
          onSignIn(event);
        }}
        method="POST"
      >
        {!user?.email ? (
          <fieldset className="bn pa0 ma0">
            <TextField
              autoComplete="on"
              dataId="loginName-input"
              id="loginName-input"
              fieldError={<Alert>Email is required.</Alert>}
              label="email"
              name="loginName"
              required
              type="text"
            />
            <Spacer />
            <TextField
              autoComplete="off"
              dataId="loginPassword-input"
              id="loginPassword-input"
              fieldError={<Alert>Password is required.</Alert>}
              label="password"
              name="loginPassword"
              required
              type="password"
            />
            <Spacer />
          </fieldset>
        ) : null}
        {!user?.email ? (
          <Button dataId="developer-console-login" size="small" type="submit">
            Login
          </Button>
        ) : null}
        {user?.email ? (
          <Button dataId="developer-console-logout" size="small" onClick={(): void => onSignOut()}>
            Logout
          </Button>
        ) : null}
      </form>
    </div>
  );
};

export default Login;
