const isRouteMatch = (routeStr?: string, routes: string[] = []): boolean => {
  let isMatch = false;
  if (routeStr) {
    const playSplit = routeStr.split('/play/');
    const afterPlay = playSplit.length > 1 ? playSplit[1] : playSplit[0];
    const slashSplit = afterPlay.split('/');
    const rootPath = slashSplit[0] === '' ? slashSplit[1] : slashSplit[0];
    routes.forEach(str => {
      if (rootPath === str) {
        isMatch = true;
      }
    });
  }
  return isMatch;
};

export default isRouteMatch;
