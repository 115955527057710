import React, { useEffect } from 'react';
import isDomUsable from '@fenderdigital/utils/dom';

const useScrollToTop = (): void => {
  useEffect(() => {
    if (isDomUsable()) {
      window.scrollTo(0, 0);
    }
  }, []);
};

export const ScrollToTop: React.FC = () => {
  useScrollToTop();
  return null;
};

export default useScrollToTop;
