export interface AuthorizationHeaders {
  headers: {
    'Content-Type': string;
    Accept: string;
    Authorization?: string;
  };
}

export default class AuthorizationHeader {
  static async getCognitoHeader(version = '3.1.0'): Promise<AuthorizationHeaders> {
    const authorizationHeader: AuthorizationHeaders = {
      headers: {
        'Content-Type': 'application/json',
        Accept: `application/json; version=${version}`,
      },
    };
    return authorizationHeader;
  }
}
