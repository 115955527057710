import fetch from '../core/fetch';
import { parseResponse } from '../core/fetch/response';

export default class SkillsLists {
  static async getSkillsLists(instruments = 'guitar,ukulele,bass') {
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}/skills-lists?instruments=${instruments}`;
    let params = {};
    params = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(url, params);
    return parseResponse(response);
  }
}
