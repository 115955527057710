import Cookie from './cookie';

interface UTMData {
  'affiliate-id': string;
  clickref: string;
  'utm-campaign': string;
  'utm-source': string;
  'utm-medium': string;
}
export const getUTMData = (): UTMData => {
  const utm = Cookie.getUtms();
  const clickref = Cookie.getClickRef();
  const affiliateId = Cookie.getAffiliateId();
  return {
    'affiliate-id': affiliateId,
    clickref,
    'utm-campaign': utm.utm_campaign,
    'utm-medium': utm.utm_medium,
    'utm-source': utm.utm_source,
  };
};

export default getUTMData;
