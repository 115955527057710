/* eslint-disable no-underscore-dangle */
import videojs from 'video.js';
import {
  createThumbnailImage,
  createThumbnailImageContainer,
  mergeThumbnailElements,
  addThumbnailToPlayer,
  updateOnHover,
} from './video-thumbnails-helpers';
import './video-thumbnails.css';
import { ControlBar, Thumbnails, VideoThumbnailOptions } from './video-thumbnails.types';

const defaults = {};

const onPlayerReady = (player: videojs.Player, options: VideoThumbnailOptions): void => {
  player.addClass('vjs-video-thumbnails');

  player.on('loadedmetadata', () => {
    const thumbnails = prepareThumbnails(player.duration(), options);

    initializeThumbnails(thumbnails, player);
  });
};

function videoThumbnails(options: VideoThumbnailOptions): void {
  // @ts-expect-error: An outer value of 'this' is shadowed by this container.
  this.ready(() => {
    // @ts-expect-error: An outer value of 'this' is shadowed by this container.
    onPlayerReady(this, videojs.mergeOptions(defaults, options));
  });
}

const getThumbnailUrl = (base: string, iteration: number): string => {
  const padding = '0000000';
  // always 7 chars long, e.g. 0000001 or 0000025
  const time = padding.substring(0, padding.length - iteration.toString().length) + iteration;
  return base.replace('{count}', time);
};

const prepareThumbnails = (videoDuration: number, options: VideoThumbnailOptions): Thumbnails => {
  let currentTime = 0;
  let currentIteration = 0;
  const { interval, thumbnailBaseUrl } = options;

  const thumbnails: Thumbnails = {
    0: {
      src: getThumbnailUrl(thumbnailBaseUrl, currentIteration),
    },
  };

  while (currentTime <= videoDuration) {
    currentTime += interval;
    ++currentIteration;
    thumbnails[currentTime] = {
      src: getThumbnailUrl(thumbnailBaseUrl, currentIteration),
    };
  }
  return thumbnails;
};

const initializeThumbnails = (thumbnails: Thumbnails, player: videojs.Player): void => {
  const controlBar = player.controlBar as ControlBar;
  const thumbnailImage = createThumbnailImage(thumbnails);
  let thumbnailContainer = createThumbnailImageContainer();

  thumbnailContainer = mergeThumbnailElements(thumbnailContainer, thumbnailImage);
  addThumbnailToPlayer(controlBar, thumbnailContainer);

  updateOnHover(controlBar, thumbnails, thumbnailImage, player);
};

videojs.registerPlugin('videoThumbnails', videoThumbnails);

export default videoThumbnails;
