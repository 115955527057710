import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { generateImageCSS } from '@fenderdigital/utils/image-css';
import { CourseCardImageProps, CourseStatus, StyleProps } from './types';
import getCourseMetadata from './utils';

const Wrapper = styled.div<StyleProps>`
  width: 100%;
  position: relative;

  ${up('tablet-portrait')} {
    width: 50%;
    max-width: 368px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  ${up('laptop')} {
    min-width: 0%;
    margin-right: 32px;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  ${up('tablet-portrait')} {
    border-radius: 4px;
  }
`;

const TextContainer = styled.div`
  bottom: 0;
  position: absolute;
  left: 20px;
  z-index: 4;
`;

const CourseLabel = styled.h2<StyleProps>`
  margin: 0px;
  font-size: 16px;
  letter-spacing: 2px;
  font-family: FuturaPT-Heavy;
  color: ${(props: StyleProps): string =>
    props.courseStatus === CourseStatus.COMPLETED ? 'rgb(81, 194, 139)' : 'white'};
`;

const CourseDataStack = styled.div<StyleProps>`
  ${props =>
    props.isOnPath
      ? css`
          align-items: center;
          display: flex;
          flex-direction: row;
        `
      : ''}
`;

const CourseNumber = styled.h3<StyleProps>`
  color: ${(props: StyleProps): string =>
    props.courseStatus === CourseStatus.COMPLETED ? 'rgb(81, 194, 139)' : 'white'};
  font-size: 78px;
  font-family: FuturaPT-Heavy;
  height: 80px;
  line-height: 80px;
  margin: 0;
  margin-left: ${(props: StyleProps): string =>
    props.courseNumber && props.courseNumber > 9 ? '-10px' : '-1px'};
`;

const ImageOverlay = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  background-image: linear-gradient(50deg, rgba(11, 106, 59, 0.8) 0%, rgba(42, 194, 120, 0.6) 80%);
  background-blend-mode: multiply;
  ${(props: StyleProps): string => {
    switch (props.courseStatus) {
      case CourseStatus.COMPLETED:
        return `
        `;
      default:
        return '';
    }
  }};
  z-index: 3;
`;

const BaseOverlay = styled.div`
  background: linear-gradient(50deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 80%);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
`;

const BaseImage = styled.div<StyleProps>`
  width: 100%;
  padding-top: 56%;
  ${({ imagePath }) => generateImageCSS(imagePath ?? '')}
  background-image: url('${props => props.imagePath}')
  background-position: top center;
  background-size: cover;
  z-index: 1;
  filter: ${({ courseStatus, courseType }) =>
    `${courseStatus === CourseStatus.COMPLETED ? 'grayscale(100%)' : ''} ${
      courseType === 'skill' ? '' : 'blur(6px)'
    }`};

  ${({ courseType }) =>
    courseType === 'skill'
      ? ''
      : css`
          transform: scale(1.5);
        `};
`;

const CoverArt = styled.div<StyleProps>`
  width: 46%;
  padding-top: calc(56% - 32px);
  position: absolute;
  top: 16px;
  right: 16px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.6);
  z-index: 3;

  ${({ imagePath }) => generateImageCSS(imagePath ?? '')}

  ${({ isOnPath }) =>
    !isOnPath
      ? css`
          left: 0;
          margin: auto;
          right: 0;
        `
      : ''};
`;

const transformBaseImageUrl = (url: string, isAlbumArt: boolean): string => {
  const base = url?.split('.jpg')[0];
  const resolution = isAlbumArt ? '200x200' : '400x225';
  return encodeURI(`${base}_${resolution}.jpg`);
};

const CourseCardImage: FC<CourseCardImageProps> = ({
  courseNumber,
  courseType,
  imagePath,
  isOnPath,
  lessonsCompletedPercentage,
}) => {
  const { courseNumberLabel, courseStatus } = getCourseMetadata(
    lessonsCompletedPercentage,
    courseNumber,
  );

  const imageUrl = transformBaseImageUrl(imagePath, courseType !== 'skill');

  return (
    <Wrapper courseType={courseType} data-id="course-card-image-container">
      <Container>
        <TextContainer>
          {isOnPath && <CourseLabel courseStatus={courseStatus}>COURSE</CourseLabel>}
          <CourseDataStack isOnPath={isOnPath}>
            {isOnPath && (
              <CourseNumber courseNumber={courseNumber} courseStatus={courseStatus}>
                {courseNumberLabel}
              </CourseNumber>
            )}
          </CourseDataStack>
        </TextContainer>
        {courseStatus === CourseStatus.COMPLETED && <ImageOverlay />}
        <BaseOverlay data-id="course-card-image-overlay" />
        <BaseImage
          className="course-card-image"
          courseStatus={courseStatus}
          courseType={courseType}
          imagePath={imageUrl}
        />
      </Container>
      {courseType !== 'skill' && (
        <CoverArt courseStatus={courseStatus} isOnPath={isOnPath} imagePath={imageUrl} />
      )}
    </Wrapper>
  );
};

export default CourseCardImage;
