import { Collection } from '@fenderdigital/api-definitions/play';
import React, { useState, ReactElement } from 'react';
import Markdown from 'react-markdown';
import Truncate from 'react-truncate';
import { up } from 'styled-breakpoints';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import {
  generateImageBreakpointCSS,
  ImageBreakpointMap,
  generateImageBreakpointMapFromResolutionMap,
} from '@fenderdigital/utils/image-css';
import { BreakpointKeys as breakpoints } from '@fenderdigital/ui/system';
import { Focus } from '@fenderdigital/ui/utils/css';
import CollectionBackButton from '../CollectionBackButton';
import { HeroContainer, HeroContentContainer, HeroDescription, HeroTitle } from './common';
import { getStatsMetaString } from '../../utils/collections-utils';

interface CollectionsHeroProps {
  collection: Collection;
}

interface HeroTruncationProps {
  canTruncate: boolean;
}

interface HeroImageProps {
  imageMap: ImageBreakpointMap;
}

const HeroResolutions: ImageBreakpointMap = {
  'small-phone': '400x400',
  phone: '400x400',
  'tablet-portrait': '800x340',
  'tablet-landscape': '1400x613',
  laptop: '1400x613',
  desktop: '1400x613',
};

type ImageUrlGenerator = (props: HeroImageProps) => FlattenSimpleInterpolation;
const ImageBackgroundCSSFactory = (breakpoint: breakpoints): ImageUrlGenerator => (
  props: HeroImageProps,
): FlattenSimpleInterpolation =>
  generateImageBreakpointCSS(
    props.imageMap,
    breakpoint,
    'linear-gradient(-180deg, rgba(0, 0, 0, 0) 5%, rgb(0, 0, 0) 100%)',
  );

const HeroContainerBottomPaddingFactory = (defaultPadding: number) => {
  return (props: HeroTruncationProps): FlattenSimpleInterpolation => {
    return css`
      padding-bottom: ${props.canTruncate ? 40 : defaultPadding}px;
    `;
  };
};

const DetailHeroContainer = styled(HeroContainer)<HeroTruncationProps>`
  min-height: 340px;
  padding-top: 48px;
  ${HeroContainerBottomPaddingFactory(62)};
  ${up('tablet-portrait')} {
    min-height: 296px;
    padding-top: 64px;
  }
  ${up('tablet-landscape')} {
    ${HeroContainerBottomPaddingFactory(80)};
    padding-top: 80px;
  }
  ${up('laptop')} {
    min-height: 314px;
    padding-top: 66px;
    ${HeroContainerBottomPaddingFactory(71)};
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  width: 100%;
  display: flex;
  ${up('tablet-portrait')} {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
  }
  ${up('tablet-landscape')} {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 24px;
  }
  ${up('laptop')} {
    padding-left: 142px;
    padding-right: 142px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const ButtonFlexContainer = styled.div`
  ${up('laptop')} {
    width: 100%;
    max-width: 1376px;
  }
`;

const HeroImage = styled.div<HeroImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 340px;
  ${ImageBackgroundCSSFactory('phone')}
  ${up('tablet-portrait')} {
    height: 296px;
    ${ImageBackgroundCSSFactory('tablet-portrait')}
  }
  ${up('tablet-landscape')} {
    ${ImageBackgroundCSSFactory('tablet-landscape')}
  }
  ${up('laptop')} {
    height: 314px;
    ${ImageBackgroundCSSFactory('laptop')}
  }
`;

const HeroStats = styled.div`
  font-family: ${({ theme }): string => theme.fonts.sourceSansIt};
  font-weight: it;
  font-size: ${({ theme: { fontSizes } }): string => fontSizes.f5};
  line-height: 24px;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 32px;
  ${up('tablet-portrait')} {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

const ShowMoreButtonContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

const ShowMoreButton = styled.button`
${Focus}
  color: ${({ theme }): string => theme.colors.white};
  background: transparent;
  border-color: transparent;
  font-family: ${({ theme }): string => theme.fonts.futuraBook};
  font-weight: 300;
  font-size: ${({ theme: { fontSizes } }): string => fontSizes.f2};
  line-height: 28px;
  text-transform: uppercase;
  height: 28px;
  cursor: pointer;
  &:hover {
    opacity: 60%;
  }
`;

const CollectionDetailHero: React.FC<CollectionsHeroProps> = ({ collection }) => {
  const [truncated, setTruncated] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const canTruncate = truncated || expanded;

  const handleSeeMore = (): void => setExpanded(!expanded);
  const handleTruncate = (newTruncated: boolean): void => setTruncated(newTruncated);

  const { title, 'description-long': description, 'hero-image': image } = collection;
  const imageMap: ImageBreakpointMap = generateImageBreakpointMapFromResolutionMap(
    image,
    HeroResolutions,
  );

  const markdown = (
    <Markdown
      renderers={{
        paragraph: ({ children }): ReactElement => <p className="mb4 f6">{children}</p>,
      }}
    >
      {description}
    </Markdown>
  );

  return (
    <DetailHeroContainer className="collection-detail-hero" canTruncate={canTruncate}>
      <HeroImage className="collection-detail-hero-image" imageMap={imageMap} />
      <ButtonContainer className="collection-back-button-container">
        <ButtonFlexContainer className="collection-back-button-flex-container">
          <CollectionBackButton className="collection-detail-hero-back-button" />
        </ButtonFlexContainer>
      </ButtonContainer>
      <HeroContentContainer className="collection-detail-hero-content-container">
        <HeroTitle className="collection-detail-hero-title">{title}</HeroTitle>
        <HeroStats className="collection-detail-hero-stats">
          {getStatsMetaString(collection)}
        </HeroStats>
        <HeroDescription
          className="collection-detail-hero-description"
          textAlign={canTruncate ? 'left' : 'center'}
        >
          <Truncate
            className="collection-detail-hero-truncator"
            lines={!expanded && 2}
            onTruncate={handleTruncate}
            data-id={`collection-detail-hero-truncator${truncated ? '-truncated' : ''}`}
          >
            {markdown}
          </Truncate>
        </HeroDescription>
        {(truncated || expanded) && (
          <ShowMoreButtonContainer>
            <ShowMoreButton
              type="button"
              onClick={handleSeeMore}
              className="collection-detail-hero-show-more-btn"
            >
              show {expanded ? 'less' : 'more'}
              <i
                style={{ paddingLeft: 8 }}
                className={expanded ? 'icon-pointer-up' : 'icon-pointer-down'}
              />
            </ShowMoreButton>
          </ShowMoreButtonContainer>
        )}
      </HeroContentContainer>
    </DetailHeroContainer>
  );
};

export default CollectionDetailHero;
