import React, { FC, ReactNode, useEffect, useRef, useState, useCallback } from 'react';
import { Colors } from '@fenderdigital/ui/system';
import { useSwipeable } from 'react-swipeable';
import SwipeCarouselControls from './CarouselControls';
import {
  SwipeCarouselBody,
  SwipeCarouselContainer,
  SwipeCarouselContent,
} from './SwipeCarouselStyled';
import { SwipeCarouselEasing as Easing } from './types';
import FeedbackModeCarouselControls from './FeedbackModeCarouselControls';

interface SwipeCarouselProps {
  children: ReactNode;
  easing?: Easing;
  hasCTA: boolean;
  onClick: () => void;
  onChange?: (state: number) => void;
  transitionDuration?: string;
  buttonTextColor?: keyof Colors;
  feedbackMode?: boolean;
}

// this still needs some work to be truly reusable.
// it's about 80% there.
const SwipeCarousel: FC<SwipeCarouselProps> = ({
  children,
  easing,
  hasCTA,
  onClick,
  onChange = (): void => {},
  transitionDuration,
  buttonTextColor = 'red',
  feedbackMode = false,
}) => {
  // https://github.com/FormidableLabs/react-swipeable#props--config-options
  const handlers = useSwipeable({
    onSwipedRight: () => handlePrev(),
    onSwipedLeft: () => handleNext(),
    delta: 50,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  const total = React.Children.count(children);
  const [currentIdx, setCurrentIdx] = useState<number>(0);
  const [totalWidth, setTotalWidth] = useState<number | undefined>(undefined);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [xPos, setXPos] = useState<number>(0);
  const target = useRef<HTMLDivElement | null>(null);
  const setDimensions = useCallback(() => {
    if (target.current) {
      // each carousel item x the total
      setTotalWidth(target.current?.offsetWidth * total);
      if (totalWidth) {
        const generatedWidth = Math.ceil(totalWidth / total);
        setWidth(generatedWidth);
        if (width) {
          setXPos(-(currentIdx * width));
        }
      }
    }
  }, [currentIdx, target, total, totalWidth, width]);

  const handleNext = (): void => {
    if (currentIdx < total - 1) {
      setCurrentIdx(nextIdx => {
        const next = nextIdx + 1;
        onChange(next);
        return next;
      });
    }
  };

  const handlePrev = (): void => {
    if (currentIdx > 0) {
      setCurrentIdx(prevIdx => {
        const next = prevIdx - 1;
        onChange(next);
        return next;
      });
    }
  };

  const handleNavClick = (idx: number): void => {
    setCurrentIdx(idx);
    onChange(idx);
    if (width) {
      const x = -(idx * width);
      setXPos(x);
    }
  };

  const handleResize = (): void => {
    setDimensions();
  };

  useEffect(() => {
    setDimensions();
  }, [setDimensions]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <SwipeCarouselContainer {...handlers} ref={target} className="swipe-carousel">
      <SwipeCarouselBody>
        <SwipeCarouselContent
          easing={easing}
          w={totalWidth}
          xPos={xPos}
          transitionDuration={transitionDuration}
        >
          {children}
        </SwipeCarouselContent>
      </SwipeCarouselBody>
      {feedbackMode ? (
        <FeedbackModeCarouselControls
          buttonVisible={hasCTA}
          buttonTextColor={buttonTextColor}
          onNavComplete={onClick}
          onNext={handleNext}
          onPrevious={handlePrev}
          onNavDotClick={handleNavClick}
          length={total}
          index={currentIdx}
        />
      ) : (
        <SwipeCarouselControls
          buttonVisible={hasCTA}
          buttonTextColor={buttonTextColor}
          onNavComplete={onClick}
          onNext={handleNext}
          onPrevious={handlePrev}
          onNavDotClick={handleNavClick}
          length={total}
          index={currentIdx}
        />
      )}
    </SwipeCarouselContainer>
  );
};

export default SwipeCarousel;
