import React, { FC } from 'react';
import { Course } from '@fenderdigital/api-definitions/play';
import Text from '@fenderdigital/ui/BodyText';
import ProgressBar from '@fenderdigital/ui/ProgressBar';
import Flex from '@fenderdigital/ui/Flex';
import Spacer from '@fenderdigital/ui/Spacer';
import { toTitleCase } from '../../../utils/string-utils';
import { getFlag } from '../../../lib/feature-flags';
import { FeedbackBadge } from '../Labels';
import CardImage from './CardHeaderImage';
import LevelIndicator from '../../LevelIndicator';
import * as S from './CardHeader-styled';

interface CourseData extends Course {
  'desktop-background-image'?: string;
  'mobile-background-image'?: string;
}

interface CardHeaderProps {
  courseData: CourseData;
  courseNumber: number;
  percentCompleted: number;
  toURL?: string;
}

const CardHeader: FC<CardHeaderProps> = ({ courseData, courseNumber, percentCompleted }) => {
  const {
    title,
    image,
    type,
    lessons,
    level,
    artists,
    'mms-id': mmsId,
    'mobile-background-image': mobileImage,
    'description-long': description,
  } = courseData ?? {};
  const isSong = type === 'song' || type === 'riff';
  const showFeebackBadge = mmsId && getFlag('isMMS');
  const activityCount = lessons?.length;
  const percentage = Math.round(percentCompleted * 100);

  const transformBaseImageUrl = (url: string): string => {
    const base = url?.split('.jpg')[0];
    const resolution = '@2x';
    return encodeURI(`${base}${resolution}.jpg`);
  };

  const newMobileImage = mobileImage ? transformBaseImageUrl(mobileImage) : '';
  const DotDivider = <S.Dot>&#8226;</S.Dot>;

  return (
    <S.Container>
      <S.SubContainer>
        <S.ImageContainer>
          <CardImage
            image={image}
            courseNumber={courseNumber}
            isSong={isSong}
            backgroundImage={newMobileImage}
          />
        </S.ImageContainer>
        <S.MetadataContainer>
          {showFeebackBadge && (
            <>
              <Flex height="22px">
                <FeedbackBadge />
              </Flex>
              <Spacer spacing="sm4" />
            </>
          )}
          <S.Title data-id="course-title">{title}</S.Title>
          <Spacer spacing={[null, 'sm3', 'sm4']} />
          {artists && (
            <>
              <Text textColor="black" textSize="f3" dataId="artist">
                {artists[0]?.name}
              </Text>
              <Spacer spacing={[null, 'sm3', 'sm4']} />
            </>
          )}
          <Text textColor="midGray" textSize="f3" dataId="activity-count">
            {`${toTitleCase(type)} Course`}
            {DotDivider}
            {`${activityCount} ${activityCount > 1 ? 'Activities' : 'Activity'}`}
            {level && isSong && (
              <>
                {DotDivider}
                <LevelIndicator
                  currentLevel={parseInt(level, 10)}
                  levelFontSize="f6"
                  completeColor="#666666"
                  incompleteColor="moon-gray"
                />
              </>
            )}
          </Text>
          <Spacer spacing="md1" />
          <S.ProgressBarContainer>
            <ProgressBar
              dataId="course-card-progress-fill"
              percentComplete={percentage}
              size="small"
              barColor="black"
              screenReaderDescription={`You have completed ${Math.round(
                percentage,
              )}% of the course.`}
            />
          </S.ProgressBarContainer>
          <Spacer spacing="md1" />
          <Text textColor="black" textSize="f3">
            {description}
          </Text>
        </S.MetadataContainer>
      </S.SubContainer>
    </S.Container>
  );
};

export default CardHeader;
