import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Lesson, Course, Playlist, Recents } from '@fenderdigital/api-definitions/play';
import { getFlag } from '../../../lib/feature-flags';
import LessonCard from '../LessonCard';
import BetaCard from '../BetaCard';

interface LessonsListProps {
  lessons?: Lesson[];
  courseData: Course;
  mmsId?: string;
  currentPlaylist?: Playlist;
  markCompleteData?: any;
  isCourseDetail?: boolean;
  url: string;
  recentlyViewed?: { [key: number]: Recents };
}

export const ActivityTableContainer = styled.ul`
  position: relative;
  padding-top: 0;
  padding-left: 0;
  list-style-type: none;
  display: block;
  background-color: white;
  border-top: 1px solid rgb(224, 224, 224);
  border-bottom: 1px solid rgb(224, 224, 224);

  ${up('tablet-portrait')} {
    margin: 0 2rem;
  }
`;

export const VerticalLine = styled.div`
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 46px;
  bottom: 0;
  background-color: rgb(204, 204, 204);
  z-index: 1;

  ${up('phone')} {
    left: 53px;
  }
`;

const LessonsList: FC<LessonsListProps> = ({
  lessons,
  courseData,
  markCompleteData,
  mmsId,
  url,
  recentlyViewed,
}) => {
  const list = lessons?.map(
    (lessonData: Lesson): ReactElement => {
      const recentlyViewedLessonId = recentlyViewed?.[0]?.['lesson-id'] ?? false;
      const lessonId = lessonData?.id;
      const isPracticeMode = lessonData['activity-type'] === 'practice-sheet';
      return (
        <li
          data-id={`course-list-item${isPracticeMode ? '-practice-mode' : ''}`}
          key={lessonData?.id}
        >
          <LessonCard
            lessonData={lessonData}
            courseData={courseData}
            markCompleteData={markCompleteData}
            url={url}
            isRecentlyViewedLesson={recentlyViewedLessonId === lessonId}
            progress={0}
          />
        </li>
      );
    },
  );
  return (
    <ActivityTableContainer data-id="course-list">
      <VerticalLine />
      {list}
      {getFlag('isMMS') && mmsId && (
        <li data-id="course-list-item" key={courseData?.id}>
          <BetaCard
            category="Feedback Mode"
            title={courseData?.title}
            courseUrl={url}
            mmsId={mmsId}
            accessLevel={courseData?.['access-levels']}
          />
        </li>
      )}
    </ActivityTableContainer>
  );
};

export default LessonsList;
