type FooterLink = {
  name: string;
  link: string;
};

type FooterData = {
  name: string;
  links: FooterLink[];
};

const parseFooterFeed = (data: any): FooterData[] => {
  return Object.keys(data).map(footerDataKey => {
    const footerLinks = data[footerDataKey];

    const links = Object.keys(footerLinks).map(footerLinkKey => {
      const footerLink = footerLinks[footerLinkKey];
      return {
        name: footerLinkKey,
        link: footerLink,
      };
    });
    const link = {
      name: footerDataKey,
      links,
    };
    return link;
  });
};

export default parseFooterFeed;
