import React, { SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { Focus } from '@fenderdigital/ui/utils/css';
import Image, { getMapToRatios, getVariantUrls } from '@fenderdigital/react-ui-kit/image';
import { Course } from '@fenderdigital/api-definitions/play';
import ProgressBar from '@fenderdigital/ui/ProgressBar';
import Spacer from '@fenderdigital/ui/Spacer';
import Heading from '@fenderdigital/ui/Heading';
import usePlayUser from '@fenderdigital/custom-hooks/hooks/usePlayUser';
import practiceModeImage from '../../../assets/player-icons/Practice-Mode-thumbnail.png';
import practiceModeImageLarge from '../../../assets/player-icons/Practice-Mode-thumbnail@2x.png';
import Link from '../../Link';
import { getCurriculumImage } from '../../../utils/curriculum-utils';
import { LastWatchedBadge } from '../Labels';
import { trackEvent, EventNames } from '../../../lib/analytics';
import { isLocked } from '../../../utils/access-utils';
import { getArtistNames } from '../progress-list-utils';

interface LessonCardProps {
  isRecentlyViewedLesson: boolean;
  progress: number;
  lessonData: any;
  courseData?: Course;
  markCompleteData?: any;
  url: string;
}

interface ImageOutlineProps {
  isAlbumArt: boolean;
}

const NewLessonCardContainer = styled.div`
  display: grid;
  grid-template-columns: 82px auto 83px;
  column-gap: 12px;
  padding: 15px 0;
  cursor: pointer;

  ${up('phone')} {
    grid-template-columns: 94px auto 83px;
  }

  ${up('tablet-portrait')} {
    grid-template-columns: 100px auto 100px 100px;
  }

  ${up('tablet-landscape')} {
    grid-template-columns: 100px auto 174px 100px;
  }

  &:hover {
    background: ${({ theme: { colors } }): string => colors.nearWhite};
  }
`;

const ImageContainer = styled.div`
  margin-left: 12px;
  width: 70px;
  position: relative;
  z-index: 2;
  align-self: center;

  ${up('phone')} {
    width: 82px;
  }
`;

const ImageOutline = styled.div<ImageOutlineProps>`
  border: ${({ isAlbumArt, theme: { colors } }): string =>
    isAlbumArt ? '' : `1px solid ${colors.moonGray}`};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
`;

const ProgressBarContainer = styled.div`
  width: 72px;
  flex-shrink: 0;
  align-self: center;
  margin: auto;

  ${up('tablet-portrait')} {
    display: block;
    margin: 0 auto 0 0;
  }

  ${up('tablet-landscape')} {
    display: block;
    margin: auto;
  }
`;

const DetailContainerOuter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

const DetailContainerInner = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;

  ${up('tablet-landscape')} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
  width: content-fit;
`;

const LessonCard: React.FC<LessonCardProps> = ({
  progress,
  isRecentlyViewedLesson,
  lessonData,
  courseData,
  url,
}) => {
  const { title, type, slug, 'activity-type': activityType } = lessonData;

  const { artists } = courseData ?? {};
  const isPracticeMode = activityType === 'practice-sheet';
  const baseImage = getCurriculumImage(lessonData).split('.jpg')[0];
  const resolution = type === 'riff' || type === 'song' ? '200x200' : '200x112';
  const image = `${baseImage}_${resolution}.jpg`;
  let images = getVariantUrls(image, 'smallThumb');
  const ratio = getMapToRatios('wideScreen');
  const linkToActivity = isPracticeMode ? `${url}/practice/${slug}` : `${url}/lesson/${slug}`;
  const percentage = Math.round(progress * 100);
  const { user, accessLevel: userAccessLevel } = usePlayUser();
  const isTabletUp = useBreakpoint(up('tablet-portrait'));
  const history = useHistory();
  const lessonAccess = lessonData['access-levels'] ?? ['all_access'];
  const isAvailable = !isLocked(lessonAccess, userAccessLevel);
  const artistContent = getArtistNames(artists);

  if (isPracticeMode) {
    images = [practiceModeImage, practiceModeImageLarge];
  }
  const handleOnClick = (): void => {
    if (isPracticeMode) {
      trackEvent(
        EventNames.PRACTICE_MODE_LAUNCHED,
        {
          fc_id: user?.userID,
          practice_sheeet_id: lessonData?.id,
          practice_sheet_slug: lessonData?.slug,
          practice_sheet_title: title,
          referrer: courseData?.slug,
        },
        {},
        false,
        true,
      );
    }

    const lessonProperties = {
      fc_id: user?.userID,
      lesson_id: lessonData?.id,
      lesson_slug: lessonData?.slug,
      lesson_type_1: lessonData?.type,
      lesson_name: lessonData?.title,
      is_locked: !isAvailable,
    };

    const songProperties = {
      song_name: lessonData?.title,
      artist_name: courseData?.artists?.[0]?.name,
    };

    const trackingProperties = {
      ...lessonProperties,
      ...(artistContent && songProperties),
    };

    trackEvent(EventNames.LESSON_CLICKED, trackingProperties);

    if (!isAvailable) return history.push('/plans');
    return history.push(linkToActivity);
  };

  return (
    <NewLessonCardContainer
      data-id="lesson-card-lesson-link"
      onClick={
        isAvailable
          ? handleOnClick
          : (): void => {
              history.push('/plans');
            }
      }
    >
      <ImageContainer>
        <ImageOutline isAlbumArt={artistContent !== null && !isPracticeMode} />
        {artistContent && !isPracticeMode ? (
          <div className="w-60 center">
            <Image
              images={images}
              ratio={1}
              transition="opacity .3s ease"
              style={{ position: 'absolute' }}
            />
          </div>
        ) : (
          <Image
            images={images}
            ratio={ratio}
            width="82px"
            transition="opacity .3s ease"
            style={{ position: 'absolute' }}
          />
        )}
      </ImageContainer>
      <DetailContainerOuter>
        <DetailContainerInner>
          <Heading
            forwardedAs="h2"
            marginBottom="sp0"
            textSize={['f3', 'f4']}
            textColor="nearBlack"
            css={css`
              line-height: 0.8;
              font-family: ${({ theme: { fonts } }): string => fonts.futuraDemi};
              margin-right: 12px;

              a {
                ${Focus}
                color: currentColor;
                font-size: inherit;
                font-family: inherit;
              }
            `}
          >
            <Link
              to={isAvailable ? linkToActivity : '/plans'}
              data-id="activity-card-link"
              onClick={(e: SyntheticEvent): void => e.preventDefault()}
            >
              {title}
            </Link>
          </Heading>
          <Spacer spacing="sm3" direction={isTabletUp ? 'vertical' : 'horizontal'} />
          {isRecentlyViewedLesson && <LastWatchedBadge />}
        </DetailContainerInner>
      </DetailContainerOuter>
      {isTabletUp && (
        <ProgressContainer>
          <ProgressBarContainer>
            <ProgressBar
              dataId="lesson-progress-bar"
              percentComplete={percentage}
              size="small"
              barColor="black"
              screenReaderDescription={`You have completed ${Math.round(
                percentage,
              )}% of the lesson.`}
            />
          </ProgressBarContainer>
        </ProgressContainer>
      )}
    </NewLessonCardContainer>
  );
};

export default LessonCard;
