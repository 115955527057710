import React from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import BodyText from '@fenderdigital/ui/BodyText';
import Spacer from '@fenderdigital/ui/Spacer';

export const getArtistNames = (artists, { weight = 'normal' } = {}) => {
  if (!artists) return null;
  const names = artists.map(({ name }) => name).join(', ');
  return (
    <>
      <Spacer spacing={['sm2', null, null, 'sm3']} />
      <BodyText
        textSize={['f1', null, null, 'f2']}
        bottomSpacing={['sm2', null, null, 'sm3']}
        weight={weight}
        dataId="artist-name"
      >
        {names}
      </BodyText>
    </>
  );
};

export const getLessonPercent = (lessonId, lessonProgress) => {
  const progress = (lessonProgress || {})[lessonId];
  const { completed, videoWatchedPercentage } = progress || {};
  if (completed) return 1;
  return videoWatchedPercentage / 100 || 0;
};

export const getPercentCompleted = (lessons, lessonProgress) => {
  if (!lessons) return;
  const total = lessons?.length;
  let completed = 0;
  lessons.forEach(lesson => {
    const { id } = lesson || {};
    completed += getLessonPercent(id, lessonProgress);
  });
  return completed === 0 ? 0 : completed / total;
};

export const getCoursePayload = course => {
  if (!course) return {};
  const { id, type } = course;
  return {
    'course-id': id,
    'course-type': type,
    type,
  };
};

export function findPreviousViewedlessonId(recentlyViewed, courses) {
  if (recentlyViewed && '0' in recentlyViewed && recentlyViewed[0]['lesson-id']) {
    const { 'course-id': recentlyViewedCourseId, 'lesson-id': recentlyViewedLessonId } =
      recentlyViewed[0] || {};
    const foundCourse = courses.find(({ id: courseId }) => courseId === recentlyViewedCourseId);
    if (!foundCourse) return '';
    const { lessons } = foundCourse;
    const foundLesson = lessons.find(({ id: lessonId }) => lessonId === recentlyViewedLessonId);
    if (foundLesson) return foundLesson.id;
  }
  return '';
}

export function findFirstUncompletedLesson(courses, coursesProgress, lessonProgress) {
  if (!coursesProgress) return '';
  let firstUncompleteLesson = {};
  const firstUncompleteCourse = courses.find(({ id: courseId }) => {
    const { completed = false, lessonsCompletedPercent = 0 } = coursesProgress[courseId] || {};
    return !completed && lessonsCompletedPercent < 100;
  });
  if (firstUncompleteCourse && firstUncompleteCourse.lessons) {
    const { lessons } = firstUncompleteCourse;
    firstUncompleteLesson =
      lessons.find(({ id: lessonId }) => {
        const { completed = false, videoWatchedPercentage = 0 } = lessonProgress[lessonId] || {};
        return !completed && videoWatchedPercentage < 100;
      }) || {};
  }
  return firstUncompleteLesson.id || '';
}

export const getCurrentLessonId = (recentlyViewed, courses, coursesProgress, lessonProgress) => {
  const previousViewedlessonId = findPreviousViewedlessonId(recentlyViewed, courses);
  if (previousViewedlessonId) return previousViewedlessonId;
  const firstUncompletedLesson = findFirstUncompletedLesson(
    courses,
    coursesProgress,
    lessonProgress,
  );
  if (firstUncompletedLesson) return firstUncompletedLesson;
  return '';
};

// Function can be deleted after backend updates course % complete logic
export function getLevelCompletedPercentage(myPath, myProgress) {
  const { id: myPathId, playlists = [] } = myPath || {};
  const { level, paths } = myProgress || {};
  const currentPlaylist = playlists.find(playlist => playlist.slug === level);
  const { id: playListId, courses = [] } = currentPlaylist || {};
  let myProgressCurrentPlaylistCourses = {};
  if (
    paths &&
    paths[myPathId] &&
    paths[myPathId].playlists &&
    paths[myPathId].playlists[playListId] &&
    paths[myPathId].playlists[playListId].courses
  ) {
    myProgressCurrentPlaylistCourses = paths[myPathId].playlists[playListId].courses;
  }
  const lessonsTotals = Object.values(courses).reduce(
    (
      { 'lessons-total': lessonsTotal, 'lessons-completed': lessonsCompleted },
      { id: courseId, lessons: courseLessons },
    ) => {
      if (!myProgressCurrentPlaylistCourses[courseId])
        return {
          'lessons-total': lessonsTotal + courseLessons.length,
          'lessons-completed': lessonsCompleted,
        };
      const {
        completed = false,
        'lessons-total': courseLessonsTotal,
        'lessons-completed': courseLessonsCompleted,
      } = myProgressCurrentPlaylistCourses[courseId];
      if (completed)
        return {
          'lessons-total': lessonsTotal + courseLessonsTotal,
          'lessons-completed': lessonsCompleted + courseLessonsTotal,
        };
      if (courseLessonsTotal)
        return {
          'lessons-total': lessonsTotal + courseLessonsTotal,
          'lessons-completed': lessonsCompleted + courseLessonsCompleted,
        };
      return { 'lessons-total': lessonsTotal, 'lessons-completed': lessonsCompleted };
    },
    {
      'lessons-completed': 0,
      'lessons-total': 0,
    },
  );
  const { 'lessons-total': lessonsTotal, 'lessons-completed': lessonsCompleted } = lessonsTotals;
  const lessonsCompletedPercent = !lessonsCompleted
    ? 0
    : Math.floor((lessonsCompleted / lessonsTotal) * 100);
  lessonsTotals['lessons-completed-percent'] = lessonsCompletedPercent;
  return lessonsTotals;
}
