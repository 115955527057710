import isDomUsable from '@fenderdigital/utils/dom';
import { useHistory } from 'react-router-dom';

type BackHandler = () => void;

const useBackHandler = (clickHandler?: () => void): BackHandler => {
  const history = useHistory();

  const backHandler = (): void => {
    if (isDomUsable()) {
      if (history.length === 1 || history.action === 'REPLACE') {
        history.push('/');
      } else {
        history.goBack();
      }
    }
    clickHandler && clickHandler();
  };

  return backHandler;
};

export default useBackHandler;
