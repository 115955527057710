import React, { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import Markdown from 'react-markdown';
import Button from '@fenderdigital/ui/Button';
import Image, { getMapToRatios, getVariantUrls } from '@fenderdigital/react-ui-kit/image';
import Link from '../Link';
import { renderHeadline } from '../../utils/blocks-utils';

const messages = defineMessages({
  cta1: {
    id: 'blockFlag.cta1',
    defaultMessage: '{cta1}',
  },
  cta2: {
    id: 'blockFlag.cta2',
    defaultMessage: '{cta2}',
  },
  headline: {
    id: 'blockFlag.headline',
    defaultMessage: '{headline}',
  },
  subHeadline: {
    id: 'blockFlag.subHeadline',
    defaultMessage: '{subHeadline}',
  },
});

interface BlockFlagProps {
  aspectRatio: string;
  columns: string;
  cta1: string;
  cta1Url: string;
  cta2: string;
  cta2Url: string;
  description: string;
  descriptionFontSize?: string;
  headline: string;
  headlineStyles?: string;
  image: string;
  isFirstBlock: boolean;
  theme?: string;
}

// TODO: Check to see if we even need this component.
const BlockFlag: FC<BlockFlagProps> = ({
  aspectRatio,
  columns,
  description,
  headline,
  image,
  cta1 = null,
  cta1Url = null,
  cta2 = null,
  cta2Url = null,
  descriptionFontSize = 'f6',
  headlineStyles = 'f4 tc futura-heavy ph3 ph0-ns ma0 mb1',
  isFirstBlock = false,
  theme = 'light',
}) => {
  const baseStyles = `tc ${theme === 'dark' ? 'bg-black white' : 'bg-white black'}`;
  const descriptionStyles = `block-markdown ${descriptionFontSize} ${
    cta1 || cta2 ? 'mb2' : ''
  } ph3 ph0-ns lh-copy tl`;
  const images = getVariantUrls(image, null);

  return (
    <div className={`block-flag ${baseStyles}`}>
      <div className={`mb3 ${columns === '3' ? 'mw4 center' : ''} relative z-0`}>
        <Link to={cta1Url || '#'}>
          <Image bgColor="transparent" images={images} ratio={getMapToRatios(aspectRatio)} />
        </Link>
      </div>
      {renderHeadline(headline, headlineStyles, isFirstBlock)}
      {description && <Markdown className={descriptionStyles}>{description}</Markdown>}
      <div className="dib pl3 pl0-l">
        {cta1 && cta1Url && (
          <Button as={Link} colorScheme="blue" to={cta1Url}>
            <FormattedMessage {...messages.cta1} values={{ cta1 }} />
          </Button>
        )}
        {cta2 && cta2Url && (
          <Button
            as={Link}
            to={cta2Url}
            variant="ghost"
            textColor={theme === 'dark' ? 'white' : 'blue'}
          >
            <FormattedMessage {...messages.cta2} values={{ cta2 }} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default BlockFlag;
