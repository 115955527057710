import React, { FC, useEffect, useReducer, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import styled from 'styled-components';
import Button from '@fenderdigital/ui/Button';
import isDomUsable from '@fenderdigital/utils/dom';
import Modal from '@fenderdigital/ui/Modal';
import { getVariantUrls } from '@fenderdigital/react-ui-kit/image';
import Link from '../Link';
import VideoJSPlayer from '../VideoJSPlayer';

interface BlockHtmlProps {
  body: string;
  containerStyles?: string;
}

interface BlockHtmlState {
  buttonLabel?: string | null;
  buttonStyles?: string | null;
  buttonUrl?: string | null;
  videoImage?: string | null;
  videoUrlHls?: string | null;
  videoUrlMP4?: string | null;
  youtubeUrl?: string | null;
}

type ActionType = 'OPEN_MODAL' | 'CLOSE_MODAL';
interface Action extends BlockHtmlState {
  type: ActionType;
  videoUrl?: string;
}
type BlockHtmlReducer = (state: BlockHtmlState, action: Action) => BlockHtmlState;

const initialState: BlockHtmlState = {
  buttonLabel: null,
  buttonStyles: null,
  buttonUrl: null,
  videoImage: null,
  videoUrlHls: null,
  videoUrlMP4: null,
  youtubeUrl: null,
};

const blockHtmlReducer: BlockHtmlReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      let youtubeUrl = null;
      if (action.videoUrl) {
        youtubeUrl = action?.videoUrl.indexOf('youtube') > -1 ? action.videoUrl : null;
      }
      return {
        ...state,
        buttonLabel: action.buttonLabel || 'Start Your Free Trial',
        buttonStyles: action.buttonStyles || 'bn br2 ph3 pv2 h2',
        buttonUrl: action.buttonUrl || 'https://www.fender.com/play/plans',
        videoImage: action.videoImage || null,
        videoUrlHls: action.videoUrlHls || null,
        videoUrlMP4: action.videoUrl || null,
        youtubeUrl,
      };
    case 'CLOSE_MODAL':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const BlockHtmlContainer = styled.section`
  p {
    margin-bottom: 0;
  }
`;

const BlockHtml: FC<BlockHtmlProps> = ({ body, containerStyles }) => {
  // using a reducer to manage the internal state better
  const [data, dispatch] = useReducer(blockHtmlReducer, initialState);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const blockRef = useRef<HTMLDivElement | null>(null);
  const poster = getVariantUrls(data?.videoImage || '', 'wideScreen', 'large');
  const videoSrc = data.videoUrlHls ?? data.videoUrlMP4 ?? null;
  const videoType = data.videoUrlHls ? 'application/x-mpegurl' : 'video/mp4';
  const handleCloseModal = (): void => {
    setIsModalOpen(false);
    dispatch({ type: 'CLOSE_MODAL' });
  };
  const handleOpenModal = (event: Event): void => {
    // cast `currentTarget`, so i'd get asscess to dataset
    const buttonData = (event.currentTarget as HTMLButtonElement).dataset;
    dispatch({ type: 'OPEN_MODAL', ...buttonData });
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (isDomUsable() && blockRef.current) {
      // eslint-disable-next-line max-len
      const buttonElements: HTMLCollectionOf<HTMLButtonElement> = blockRef.current.getElementsByTagName(
        'button',
      );
      if (buttonElements) {
        for (const buttonElement of buttonElements)
          buttonElement.addEventListener('click', handleOpenModal, false);
      }
    }
  }, [blockRef]);
  return (
    <BlockHtmlContainer className={`block-html ${containerStyles}`} ref={blockRef}>
      {body && (
        <Markdown className="block-markdown" allowDangerousHtml>
          {body}
        </Markdown>
      )}
      {isModalOpen && (
        <Modal onClose={handleCloseModal} colorScheme="dark">
          <>
            {/* added a min-height, if basic player renders */}
            <div className="relative" style={{ minHeight: '200px' }}>
              {data.youtubeUrl ? (
                <div className="flex flex-column aspect-ratio aspect-ratio--2x35">
                  <iframe
                    className="absolute top-0 h-100 w-100 bn"
                    frameBorder="0"
                    src={data.youtubeUrl}
                    title="Fender Play Youtube Video"
                  />
                </div>
              ) : (
                <>
                  {/*
                  using this to hook into for cypress testing.
                  when running e2e tests, on localhost, flowplayer
                  does not load causing test fails. I just want insure
                  the video url is getting passed here.
                */}
                  <div data-video-container={data.videoUrlHls || data.videoUrlMP4}>
                    {videoSrc && (
                      <VideoJSPlayer poster={poster} videoSrc={videoSrc} videoType={videoType} />
                    )}
                  </div>
                </>
              )}
            </div>
            {data.buttonUrl && data.buttonLabel && (
              <div className="ma0 pt3 pb2 tc">
                <Button as={Link} colorScheme="blue" hoverColor="blue" to={data.buttonUrl}>
                  {data.buttonLabel}
                </Button>
              </div>
            )}
          </>
        </Modal>
      )}
    </BlockHtmlContainer>
  );
};

export default BlockHtml;
