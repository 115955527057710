import AuthConfig from './authConfig';
import userApiConfig from './userApiConfig';
// using var given for some reason webpack doesn't compile const to var for externalRootPath
// eslint-disable-next-line no-var, import/no-mutable-exports
var externalRootPath = !process.env.REACT_APP_CLIENT_PATH_PREFIX
  ? '/'
  : process.env.REACT_APP_CLIENT_PATH_PREFIX;
// eslint-disable-next-line import/no-unresolved
export { default } from './config';
export { AuthConfig, externalRootPath, userApiConfig };
