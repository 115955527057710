import { AWSAuthConfig } from '@fenderdigital/custom-hooks/lib/cognito';
import LocalANDCookieStorage from '@fenderdigital/custom-hooks/lib/LocalAndCookieStorage';
import cookieConfig from './cookieConfig';

const storage = new LocalANDCookieStorage(cookieConfig);
const AuthConfig: AWSAuthConfig = {
  Auth: {
    region: `${process.env.REACT_APP_CLIENT_COGNITO_REGION}`,
    userPoolId: `${process.env.REACT_APP_CLIENT_COGNITO_USER_POOL_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_CLIENT_COGNITO_CLIENT_ID}`,
    storage,
  },
};

export default AuthConfig;
