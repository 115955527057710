import React, { FC } from 'react';
import { getFlag } from '../../lib/feature-flags';
import DeveloperConsoleContent from './DeveloperConsoleContent';

const DeveloperConsole: FC = ({ children }) => {
  const isEnabled = getFlag('console');
  return (
    <div className="console-wrapper">
      {children}
      {isEnabled && <DeveloperConsoleContent />}
    </div>
  );
};

export default DeveloperConsole;
