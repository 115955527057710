import React from 'react';
import Button from '@fenderdigital/ui/Button';

export interface CTA {
  text: string;
  className?: string;
  dataId?: string;
  onClick: () => void;
  wrapperClassName?: string;
}

interface CTAButtonProps {
  cta?: CTA | null;
}

const CTAButton: React.FC<CTAButtonProps> = ({ cta }) => {
  if (!cta) {
    return null;
  }

  return (
    <div className={`cta-button ph3 ${cta.wrapperClassName ?? ''}`}>
      <Button dataId={cta.dataId} onClick={cta.onClick} size="small">
        {cta.text}
      </Button>
    </div>
  );
};

export default CTAButton;
