import AlgoliaSearch from 'algoliasearch';
import { FilterData, InstrumentGroup } from '../models/search';
import {
  defaultFacets,
  filterKeyArr,
  parseFacets,
  parseSearchFilter,
  parseFiltersToFacets,
} from '../utils/search-utils';

let client: AlgoliaSearch.Client | undefined;
let index: AlgoliaSearch.Index | undefined;
let fetchingLib = false;
let libPromise: any = null;

function renameKey(obj: any, oldKey: string, newKey: string): any {
  const clonedObj = { ...obj };
  const value = clonedObj[oldKey];
  delete clonedObj[oldKey];
  clonedObj[newKey] = value;
  return clonedObj;
}

function convertKeys(data: any): any {
  return {
    ...data,
    hits: data.hits.map((item: any) => renameKey(item, 'accessLevels', 'access-levels')),
  };
}

export default class Search {
  static async getSearchIndex(): Promise<AlgoliaSearch.Index | undefined> {
    if (!client) {
      if (!fetchingLib) {
        fetchingLib = true;
        libPromise = import('algoliasearch').then(({ default: algoliasearch }) => {
          fetchingLib = false;
          client = algoliasearch(
            process.env.REACT_APP_CLIENT_ALGOLIA_APP_ID || '',
            process.env.REACT_APP_CLIENT_ALGOLIA_API_KEY || '',
          );
        });
      }
      await libPromise;
    }
    if (!index && client) {
      index = client.initIndex(process.env.REACT_APP_CLIENT_ALGOLIA_APP_NAME || '');
    }
    return index;
  }

  static async doSearch(
    query: string,
    params: AlgoliaSearch.QueryParameters,
    filterObj: FilterData,
  ): Promise<any> {
    const searchIndex = await Search.getSearchIndex();
    if (!searchIndex) {
      return null;
    }
    const filters = parseSearchFilter(filterObj);
    const facets = Object.keys(filterKeyArr);
    const facetsPromise: Promise<AlgoliaSearch.Response> = searchIndex.search({
      facets,
      hitsPerPage: 1,
      page: 0,
      query,
    });
    const resultsPromise: Promise<AlgoliaSearch.Response> = searchIndex.search({
      ...params,
      query,
      filters,
      facets,
      clickAnalytics: true,
    });
    const [facetResults, results] = await Promise.all([facetsPromise, resultsPromise]);
    const data = {
      ...results,
      facets: parseFacets(results.facets, facetResults.facets, {
        instrument: filterObj.instrument || 'guitar',
      }),
    };
    // Algolia API is currently returning 'access-levels' as 'accessLevels'.
    // We can remove this function once keys are corrected on the platform side.
    return convertKeys(data);
  }

  static async sectionsSearch(
    query: string,
    instrument: InstrumentGroup,
    state?: any,
  ): Promise<Array<AlgoliaSearch.Response> | null> {
    const searchIndex = await Search.getSearchIndex();
    if (!searchIndex) {
      return null;
    }
    const filters = { instrument: instrument || state.search.filters.instrument };
    const params = { hitsPerPage: 10, page: 0, clickAnalytics: true };
    const searchCategories = [
      { objectType: 'collection' },
      { type: 'song' },
      { type: 'riff' },
      { type: ['basics', 'chord', 'exercise', 'glossary', 'skill', 'technique', 'theory', 'tone'] },
    ];

    const instrumentsFacetsPromise = searchIndex.search({
      query,
      facets: defaultFacets,
      hitsPerPage: 1,
      page: 0,
      clickAnalytics: true,
    });

    const facetsPromise = searchIndex.search({
      query,
      facets: defaultFacets,
      facetFilters: parseFiltersToFacets({ ...filters }),
      hitsPerPage: 1,
      page: 0,
      clickAnalytics: true,
    });

    const categoriesPromise = searchCategories.map(category =>
      searchIndex.search({
        query,
        facets: defaultFacets,
        // eslint-disable-next-line
        // @ts-ignore
        facetFilters: parseFiltersToFacets({ ...filters, ...category }),
        ...params,
      }),
    );

    const results = await Promise.all([
      instrumentsFacetsPromise,
      facetsPromise,
      ...categoriesPromise,
    ]);
    // Algolia API is currently returning 'access-levels' as 'accessLevels'.
    // We can remove this function once keys are corrected on the platform side.
    return results.map(result => convertKeys(result));
  }
}
