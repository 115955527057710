import mirror from 'keymirror';
import RelatedItems from '../lib/related-items';

export const actionTypes = mirror({
  RELATED_ITEMS_RESET: null,
  RELATED_ITEMS_CLEAR: null,
  RELATED_ITEMS_GET_ERROR: null,
  RELATED_ITEMS_GET_IN_PROGRESS: null,
  RELATED_ITEMS_GET_SUCCESS: null,
});

export const resetRelatedItems = () => ({
  type: actionTypes.RELATED_ITEMS_RESET,
});

export const clearRelatedItems = key => ({
  type: actionTypes.RELATED_ITEMS_CLEAR,
  key,
});

const relatedItemsGetError = error => ({
  type: actionTypes.RELATED_ITEMS_GET_ERROR,
  error,
});

const relatedItemsGetInProgress = () => ({
  type: actionTypes.RELATED_ITEMS_GET_IN_PROGRESS,
});

const relatedItemsGetSuccess = (results, key) => ({
  type: actionTypes.RELATED_ITEMS_GET_SUCCESS,
  results,
  key,
});

export const getRelatedItems = (
  itemType,
  type,
  limit,
  exclude,
  instrument,
  key = null,
) => async dispatch => {
  try {
    dispatch(relatedItemsGetInProgress());
    const results = await RelatedItems.getRelatedItems(itemType, type, limit, exclude, instrument);
    dispatch(relatedItemsGetSuccess(results, key));
  } catch (err) {
    dispatch(relatedItemsGetError(err));
  }
};

/**
 * Gets the related lessons such as in the mobile app.
 * @param {string[]} ids Related lesson identifiers
 * @param {any} key Used by the relatedItemsGetSuccess
 */
export const getRelatedLessons = (ids, key = null) => async dispatch => {
  try {
    dispatch(relatedItemsGetInProgress());
    const results = await RelatedItems.getRelatedLessonsByIds(ids);
    dispatch(relatedItemsGetSuccess(results, key));
  } catch (err) {
    dispatch(relatedItemsGetError(err));
  }
};
