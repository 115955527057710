import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import { Focus } from '@fenderdigital/ui/utils/css';
import { SearchSelectionProvider } from '../../contexts/SearchSelectionContext';
import SearchSuggestions from './SearchSuggestions';
import SearchPanelResults from './SearchPanelResults';
import SearchPanelScreenReaderInstructions from './SearchPanelScreenReaderInstructions';
import SearchField from '../SearchField/SearchFieldContainer';
import messages from './messages';

export class SearchPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instrument: 'guitar',
      results: {
        hits: [],
      },
    };
    this.handleInstrument = this.handleInstrument.bind(this);
    this.setLocalResults = this.setLocalResults.bind(this);
  }

  handleInstrument(instrument) {
    this.setState({ instrument });
  }

  setLocalResults(results) {
    this.setState({ results });
  }

  renderLayout() {
    const { onCloseClick } = this.props;
    const { results: localResults, instrument } = this.state;
    const { hits, query } = localResults;
    return (
      <div>
        <div className="relative pa3 bg-near-white">
          <button
            type="button"
            className="outline-0 pa0 ma0 bn absolute top-1 right-1 bg-none pointer"
            data-id="search-panel-close"
            onClick={onCloseClick}
          >
            <span className="f4 icon-x mid-gray" />
          </button>
          <h2 className="pa0 mt0 mb2 f3" data-id="search-panel-title">
            Search
          </h2>
          <SearchField
            instrument={instrument}
            localResults={localResults}
            setLocalResults={this.setLocalResults}
            onSubmit={this.handleSubmit}
            hasLocalSearch
            onMountFocus
            instant
            small
          />
        </div>
        {query ? (
          <SearchPanelResults
            {...this.props}
            instrument={instrument}
            handleInstrument={this.handleInstrument}
            localResults={localResults}
            setLocalResults={this.setLocalResults}
            closeSearchPanel={onCloseClick}
          />
        ) : (
          <SearchSuggestions />
        )}
      </div>
    );
  }

  render() {
    const styles = 'relative z-3 shadow-3 bg-white ba b--moon-gray futura-heavy';
    return (
      <SearchSelectionProvider>
        <div className={`search-panel ${styles}`} style={{ width: '24rem' }}>
          {this.renderLayout()}
        </div>
      </SearchSelectionProvider>
    );
  }
}

SearchPanel.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.shape({})),
  intl: intlShape.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  query: PropTypes.string,
};

SearchPanel.defaultProps = {
  hits: null,
  query: '',
};

export default withRouter(injectIntl(SearchPanel));
