import { defineMessages } from 'react-intl';

const messages = defineMessages({
  instructions: {
    id: 'searchPanel.instructions',
    defaultMessage:
      'Begin typing to search, use arrow keys to navigate the results, Enter to select. Following the text field are additional filter buttons which can also be used to refine the results.',
  },
  noInstrumentResults: {
    id: 'searchPanel.noInstrumentResults',
    defaultMessage: 'No {instrument} search results.',
  },
  noResults: { id: 'searchPanel.noResults', defaultMessage: 'No search results.' },
  resultsFound: {
    id: 'searchPanel.resultsFound',
    defaultMessage: '{length} results found',
  },
});

export default messages;
