import React, { FC, useEffect, ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import isDomUsable from '@fenderdigital/utils/dom';
import Flex from '@fenderdigital/ui/Flex';
import Spinner from '@fenderdigital/ui/Spinner';
import Blocks from '../../../components/Blocks';
import Head from '../../../components/Head';
import Flag from '../../../components/Flag';
// TODO: consider adding to the design system.
import Toast from '../../../components/Toast';
import UnSubscribedLayout from '../../../components/UnSubscribedLayout';
import { getFlag } from '../../../lib/feature-flags';
import { Page as PageDataState } from '../../../types/pages';
import { getPagesBySlug } from '../../../lib/pages';

const PagesContainer = styled.section`
  width: 100%;
  height: 100%;
`;

const PagesIframeStyled = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  overflow: auto;
  margin-bottom: 2rem;
`;

const getIframeSrc = (): string => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  const queryParam = '?hide-nav-footer=true';
  // qa.fendergarage.com has an X-Frame `sameorign` policy and
  // will preven us from iframe'ing the what's new page in local dev.
  // using this as a workaround for now, but will bring up with ops.
  if (env === 'development') {
    return `https://fenderpublic.gtsb.io/whats-new${queryParam}`;
  }
  return `${process.env.REACT_APP_CLIENT_FENDER_COM}/whats-new${queryParam}`;
};

const Pages: FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [error, setError] = useState<string | null>(null);
  const [pageData, setPageData] = useState<PageDataState | null>(null);
  const isAnnouncementsPage = slug === 'announcements';
  const isWhatsNewUrlEnabled = getFlag('isWhatsNewUrlEnabled');
  useEffect(() => {
    if (isAnnouncementsPage && isWhatsNewUrlEnabled) return;
    const fetchData = async (): Promise<void> => {
      try {
        const data = await getPagesBySlug(slug);
        setPageData(data);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        }
      }
    };
    fetchData();
  }, [isAnnouncementsPage, isWhatsNewUrlEnabled, slug]);

  const renderBlocks = (): ReactElement => {
    const blocksPresent = pageData?.blocks && pageData.blocks.length;
    const shouldRenderBlocks = isDomUsable() && blocksPresent;
    const blocksMarkup = shouldRenderBlocks ? <Blocks blocks={pageData?.blocks} /> : null;
    const spinnerMarkup = !shouldRenderBlocks ? (
      <Spinner fullPage strokeBaseColor="black" strokeColor="transparent" />
    ) : null;
    return (
      <>
        {blocksMarkup}
        {spinnerMarkup}
      </>
    );
  };

  const parsedSearch = isDomUsable() ? window.location.search : '';

  const renderContent = (): ReactElement => {
    const pageTitle =
      pageData?.['html-meta-title'] ?? pageData?.title ?? 'Fender Play Guitar Lessons';
    const pageDescription = pageData?.['html-meta-description'] ?? pageData?.description;
    const canonicalURL = pageData?.['canonical-url'] ?? `pages/${slug}${parsedSearch}`;
    const metaData = Head.generateMetaData(
      pageTitle,
      pageDescription,
      `pages/${slug}${parsedSearch}`,
      canonicalURL,
      null,
      pageData?.['social-title'],
      pageData?.['social-description'],
      pageData?.['social-image'],
    );
    return (
      <div>
        <Head {...metaData} />
        {renderBlocks()}
      </div>
    );
  };

  const renderIframeContent = (): ReactElement => {
    const pageTitle = "What's New on Fender Play®";
    const pageDescription = "What's New on Fender Play®";
    const metaData = Head.generateMetaData(
      pageTitle,
      pageDescription,
      `pages/${slug}${parsedSearch}`,
      `pages/${slug}${parsedSearch}`,
      null,
    );
    return (
      <>
        <Head {...metaData} />
        <PagesIframeStyled
          data-id="pages-iframe-content"
          src={getIframeSrc()}
          title="Whats New on Fender Play"
        />
      </>
    );
  };

  return (
    <UnSubscribedLayout>
      <Flex height={isAnnouncementsPage ? '100vh' : 'auto'}>
        <PagesContainer data-id="pages">
          <Toast
            visible={!!error}
            message={`Oops! \nAn unknown \nerror occurred.`}
            icon="Alert"
            dataId="toast-pages-err"
          />
          {isAnnouncementsPage ? (
            <Flag name="isWhatsNewUrlEnabled" fallBack={renderContent()}>
              {renderIframeContent()}
            </Flag>
          ) : (
            renderContent()
          )}
        </PagesContainer>
      </Flex>
    </UnSubscribedLayout>
  );
};

export default Pages;
