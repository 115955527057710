import React, { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { InjectedIntl, injectIntl, Messages } from 'react-intl';
import Media from 'react-media';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AuthIndicator from '@fenderdigital/react-ui-kit/auth-indicator';
import isDomUsable from '@fenderdigital/utils/dom';
import useFenderConnect from '@fenderdigital/custom-hooks/hooks/useFenderConnect';
import Header from '../Header/Header';
import { CTA } from '../Header/CTAButton';
import { NavigationItem } from '../Header/NavTypes';
import Footer from '../Footer';
import megaNav from '../../constants/mega-nav';
import messages from './messages';
import config from '../../config';
import { getSignInUrlWithSession } from '../../lib/cognito';
import { RootState } from '../../redux-types/root.redux-types';

interface UnSubscribedLayoutProps extends RouteComponentProps {
  children: ReactNode;
  intl: InjectedIntl;
  messages: Messages;
  searchQuery: string;
}

const UnSubscribedLayout: FC<UnSubscribedLayoutProps> = ({
  children,
  intl,
  messages,
  location,
}) => {
  const { formatMessage } = intl;
  const { firstName, sessionCheckComplete } = useFenderConnect();
  const params = new URLSearchParams(window.location.search);
  const shouldHideNavFooter = params.get('hide-nav-footer') === 'true';

  const getHeaderCta = (pathname: string): CTA | null => {
    const isCtaActive = pathname.indexOf('/pages') === 0 || pathname === '/';
    const ctaLink = `${process.env.REACT_APP_CLIENT_SSO_URL}/sign-up?ref=${process.env.REACT_APP_CLIENT_FENDER_COM}/play/plans?session-started=true`;
    if (isCtaActive) {
      return {
        text: formatMessage(messages.cta),
        onClick: (): void => {
          window.location.href = ctaLink;
        },
        wrapperClassName: 'dn db-l',
        className: 'bn',
        dataId: 'free-trial-button',
      };
    }
    return null;
  };

  const getSignOutUrl = (location: string): string => {
    const isOnReactivationCta = location.toLowerCase().trim().includes('campaign-reactivation');
    let targetUrl = isDomUsable() ? location : '';
    if (isOnReactivationCta && isDomUsable())
      targetUrl = `${process.env.REACT_APP_CLIENT_FENDER_COM}/play`;
    return `${process.env.REACT_APP_CLIENT_SSO_URL}/sign-out?ref=${targetUrl}`;
  };

  const renderHeader = (match: boolean): JSX.Element => {
    const { navSkinny: globalNavData } = config;
    const cta = match ? getHeaderCta(location.pathname) : null;
    const filteredNavItems = megaNav.filter(({ id }) => {
      if (!cta && id === '7-day-free-trial') return false;
      if (id === 'my-path') return false;
      return true;
    });
    const signInURL = getSignInUrlWithSession();

    return (
      <Header
        currentNav="play"
        fenderConnect={
          <AuthIndicator
            name={firstName}
            profileUrl={`${process.env.REACT_APP_CLIENT_FENDER_COM}/profile/info`}
            signedIn={!!firstName}
            signInUrl={signInURL}
            signOutUrl={getSignOutUrl(window.location.href)}
          />
        }
        globalNavData={globalNavData}
        navItems={filteredNavItems as NavigationItem[]}
        promoColor="near-white"
        titles={{
          site: formatMessage(messages.site),
          connect: formatMessage(messages.connect),
          apps: formatMessage(messages.apps),
        }}
        cta={cta}
      />
    );
  };

  return (
    <div className="un-subscribed-layout" data-id="un-subscribed-layout">
      {sessionCheckComplete && !shouldHideNavFooter ? (
        <Media query={config.breakpoints.large}>{renderHeader}</Media>
      ) : null}
      <div className="min-vh-100">{children}</div>
      {sessionCheckComplete && !shouldHideNavFooter ? (
        <div className="w-100">
          <Footer />
        </div>
      ) : null}
    </div>
  );
};

const mapState = (state: RootState): { messages: Messages; searchQuery: string } => {
  const { search } = state;
  return {
    messages,
    searchQuery: search.results.query || '',
  };
};

export default injectIntl(withRouter(connect(mapState)(UnSubscribedLayout)));
