import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Spinner from '@fenderdigital/ui/Spinner';
import { actions as CollectionActions } from '@fenderdigital/business/collections';
import CollectionDetailHero from '../../components/LandingHeros/CollectionDetailHero';
import CollectionCourses from '../../components/CollectionCourses';
import UnSubscribedLayout from '../../components/UnSubscribedLayout';
import { RootState } from '../../redux-types/root.redux-types';
import Library from '../../lib/library';
import useScrollToTop from '../../hooks/useScrollToTop';
import { EventNames, trackPageEvents, getAlgoliaTracking } from '../../lib/analytics';

const { getCollectionWithID } = CollectionActions;

interface CollectionsDetailProps {
  slug: string;
}

const selector = (state: RootState) => ({
  collection: state.collections.collection,
  loadingCollection: state.collections.loadingCollection,
});

const CollectionsDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: ${({ theme: { colors } }): string => colors.lightGray};
`;

const CollectionsDetail: React.FC<CollectionsDetailProps> = ({ slug }) => {
  const dispatch = useDispatch();
  const { instrument } = useParams<{ instrument: string }>();
  const { collection, loadingCollection } = useSelector(selector);
  const [trackingEventFired, setTrackingEventFired] = useState(false);
  useScrollToTop();

  useEffect(() => {
    dispatch(getCollectionWithID(slug, () => Library.getCollectionBySlug(slug, instrument)));
  }, [dispatch, instrument, slug]);

  useEffect(() => {
    if (collection && !trackingEventFired) {
      trackPageEvents(EventNames.COLLECTION_DETAIL, getAlgoliaTracking('view', collection?.id));
      setTrackingEventFired(true);
    }
  }, [collection, trackingEventFired]);

  if (collection === null || loadingCollection) {
    return <Spinner fullPage />;
  }

  return (
    <UnSubscribedLayout>
      <CollectionsDetailContainer className="collections-detail">
        <CollectionDetailHero collection={collection} />
        <CollectionCourses collection={collection} />
      </CollectionsDetailContainer>
    </UnSubscribedLayout>
  );
};

export default CollectionsDetail;
