import { actionTypes } from '../actions/skills';
import { skills as defaultState } from './default.json';

const handlers = {
  [actionTypes.SKILLS_GET_ERROR]: (state, action) => ({
    ...state,
    error: action.error.message,
    inProgress: false,
  }),
  [actionTypes.SKILLS_GET_IN_PROGRESS]: state => ({
    ...state,
    error: null,
    inProgress: true,
  }),
  [actionTypes.SKILLS_GET_SUCCESS]: (state, action) => ({
    ...state,
    error: null,
    inProgress: false,
    results: {
      ...action.results,
    },
  }),
};

export default (state = defaultState, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};
