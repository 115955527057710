import React from 'react';
import PropTypes from 'prop-types';
import { Link as RRLink } from 'react-router-dom';
import filterByEmptyProps from './LinkUtils';
import segment from '../../core/segment';
import { trackEvent as avoTrackEvent, EventNames } from '../../lib/analytics';

const { event } = segment;

function isLeftClickEvent(e) {
  return e.button === 0;
}

function isModifiedEvent(e) {
  return !!(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey);
}

/**
 * Straight port from react components.
 * We should consider refactoring this component if needed.
 */
class Link extends React.Component {
  handleClick = e => {
    if (isModifiedEvent(e) || !isLeftClickEvent(e)) {
      return;
    }
    const { trackEvent, onClick } = this.props;

    if (trackEvent) {
      const { eventType, properties } = trackEvent;
      avoTrackEvent(eventType, properties);

      if (eventType === EventNames.SEARCH_RESULT_CLICKED) {
        if (properties?.objectType === 'lesson') {
          avoTrackEvent(EventNames.LESSON_CLICKED, properties);
        }
      }
    }
    if (onClick) onClick(e);
  };

  render() {
    const {
      dataId,
      className,
      children,
      id,
      target,
      to,
      tabIndex,
      role,
      ariaCurrent,
      ariaHidden,
      ariaLabel,
    } = this.props;
    const conditionalProps = { id, target };
    const linkProps = filterByEmptyProps(conditionalProps);
    if (/^https?:\/\//.test(to)) {
      return (
        <a
          className={`link ${className}`}
          data-id={dataId}
          href={to}
          onClick={this.handleClick}
          aria-current={ariaCurrent}
          aria-hidden={ariaHidden}
          aria-label={ariaLabel}
          {...linkProps}
        >
          {children}
        </a>
      );
    }
    return (
      <RRLink
        className={`link ${className}`}
        data-id={dataId}
        to={to}
        onClick={this.handleClick}
        role={role}
        tabIndex={tabIndex}
        aria-current={ariaCurrent}
        aria-hidden={ariaHidden}
        aria-label={ariaLabel}
        {...linkProps}
      >
        {children}
      </RRLink>
    );
  }
}

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  dataId: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  target: PropTypes.string,
  trackEvent: PropTypes.shape({
    eventType: PropTypes.string.isRequired,
    properties: PropTypes.object,
    options: PropTypes.object,
    callback: PropTypes.func,
  }),
  tabIndex: PropTypes.string,
  role: PropTypes.string,
  ariaCurrent: PropTypes.string,
  ariaHidden: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

Link.contextTypes = {
  history: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  analytics: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Link.defaultProps = {
  dataId: 'link',
  onClick: null,
  id: '',
  className: '',
  trackEvent: null,
  target: '',
  tabIndex: undefined,
  to: '',
  role: undefined,
  ariaCurrent: undefined,
  ariaHidden: undefined,
  ariaLabel: undefined,
};

export default Link;
