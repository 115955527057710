import React, { ReactNode, useEffect, useState, FC } from 'react';
import styled, { SimpleInterpolation, css } from 'styled-components';
import isDomUsable from '@fenderdigital/utils/dom';
import Spacer from '@fenderdigital/ui/Spacer';
import { AuthIndicatorProps } from '@fenderdigital/react-ui-kit/auth-indicator';
import { UnSubscribedMobileMenu, FenderConnectProfile, Titles } from '../UnSubscribedMenus';
import DotComHeader from './DotComHeader';
import CTAButton, { CTA } from './CTAButton';
import SearchButton from './SearchButton';
import DesktopMenu from './DesktopMenu';
import { NavigationItem } from './NavTypes';
import useIsHome from '../../hooks/useIsHome';

interface HeaderProps {
  currentNav?: string;
  fenderConnect?: FC<AuthIndicatorProps> | React.ReactElement;
  fenderConnectProfile?: FenderConnectProfile | null;
  globalNavData: any;
  navItems?: NavigationItem[] | null;
  onMobileSearchClick?: null | (() => void);
  onToggleMobileMenu?: (toggleState: boolean) => void;
  promoContent?: ReactNode;
  promoColor?: string;
  titles?: Titles;
  cta?: CTA | null;
}

export function isHash(str: string | null): boolean | null {
  if (typeof str === 'string') {
    return str.charAt(0) === '#';
  }
  return null;
}

const HeaderContainer = styled.div`
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
`;

interface StyledHeaderStyleProps {
  isFixedHeader?: boolean;
  isMobileMenuVisible?: boolean;
}

const StyledHeader = styled.header<StyledHeaderStyleProps>`
  position: relative;
  background-color: ${({ theme: { colors } }): string => colors.white};
  border-top: 1px solid ${({ theme: { colors } }): string => colors.lightGray};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-family: ${({ theme: { fonts } }): string => fonts.futuraHeavy};
  width: 100%;
  height: ${({ theme: { spacing } }): string => spacing.lg3};

  ${({ isFixedHeader }): SimpleInterpolation =>
    isFixedHeader &&
    css`
      top: 0;
      z-index: 5;
    `};
  ${({ isFixedHeader, isMobileMenuVisible }): SimpleInterpolation =>
    isFixedHeader &&
    !isMobileMenuVisible &&
    css`
      position: fixed;
    `};
`;

const Header: React.FC<HeaderProps> = ({
  currentNav = '',
  fenderConnect,
  fenderConnectProfile = null,
  globalNavData,
  navItems = null,
  onToggleMobileMenu = (): void => {},
  promoContent = null,
  promoColor = 'red',
  titles = {
    site: 'Fender',
    connect: 'Fender Connect',
    apps: 'Fender Apps',
  },
  cta = null,
}) => {
  const [isBannerOpen, setBannerOpen] = useState(true);
  const [isFixedHeader, setFixedHeader] = useState(false);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [selectedSubMenu, setSelectedSubMenu] = useState<string | null>(null);

  const selectSubMenu = (id: string | null): void =>
    setSelectedSubMenu(selectedSubMenu !== id ? id : null);

  const handleScroll = (): void => {
    const topOffset = document.getElementById('above-header')?.clientHeight ?? 0;
    const windowY = window.pageYOffset;
    if (windowY > topOffset && !isFixedHeader) {
      setFixedHeader(true);
    }
    if (windowY < topOffset && isFixedHeader) {
      setFixedHeader(false);
    }
  };

  const toggleMobileMenu = (): void => {
    setMobileMenuVisible(!isMobileMenuVisible);
    onToggleMobileMenu(isMobileMenuVisible);
  };

  const closeMenu = (): void => {
    setMobileMenuVisible(false);
    onToggleMobileMenu(false);
  };

  const closeBanner = (): void => {
    setBannerOpen(false);
  };

  useEffect(() => {
    if (isDomUsable()) {
      window.addEventListener('scroll', handleScroll);
    }
    return (): void => window.removeEventListener('scroll', handleScroll);
  });

  const filteredNavItems = Array.isArray(navItems)
    ? navItems.filter(navItem => navItem.id !== 'home')
    : null;
  const isHome = useIsHome();
  const userEmail = fenderConnectProfile?.email ?? '';
  return (
    <HeaderContainer onScroll={handleScroll}>
      <DotComHeader
        bannerOpen={isBannerOpen}
        isHome={isHome}
        promoOnClick={closeBanner}
        promoColor={promoColor}
        promoContent={promoContent}
      />
      {!isHome && (
        <>
          <Spacer spacing={isFixedHeader && !isMobileMenuVisible ? 'lg3' : ['sp0', 'sp0', 'sm1']} />
          <StyledHeader
            data-id="mega-nav"
            isFixedHeader={isFixedHeader}
            isMobileMenuVisible={isMobileMenuVisible}
          >
            <div
              className={`center h-100 flex justify-between z-5 relative bg-white${
                cta ? ' w-100' : ''
              }`}
            >
              <DesktopMenu
                navItems={filteredNavItems}
                selectedSubMenu={selectedSubMenu}
                userEmail={userEmail}
                setSelectedSubMenu={setSelectedSubMenu}
              />
              <div className="flex items-center">
                <SearchButton cta={cta} />
                <button
                  type="button"
                  className="db dn-l dark-gray outline-0 button-reset bn bg-transparent pointer pl0 pr3"
                  data-id="header-menu-btn-mobile"
                  onClick={toggleMobileMenu}
                >
                  <span className="f3 icon-menu" style={{ transition: 'opacity .15s ease-in' }} />
                </button>
                <UnSubscribedMobileMenu
                  currentNav={currentNav}
                  fenderConnect={fenderConnect}
                  fenderConnectProfile={fenderConnectProfile}
                  globalNavData={globalNavData}
                  isVisible={isMobileMenuVisible}
                  onCloseMenu={closeMenu}
                  onSelectSubMenu={selectSubMenu}
                  navItems={navItems}
                  selectedSubMenu={selectedSubMenu}
                  titles={titles}
                />
                <CTAButton cta={cta} />
              </div>
            </div>
          </StyledHeader>
        </>
      )}
    </HeaderContainer>
  );
};

export default Header;
