import PropTypes from 'prop-types';
import React from 'react';
import '../styles/icon-library.css';
import '../styles/web-fonts.css';
import '../styles/utilities.css';
import '../styles/global.css';

class Styles extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default Styles;
