import React, { useState } from 'react';
import Button from '@fenderdigital/ui/Button';
import { HStack, VStack } from '@fenderdigital/ui/Stacks';
import Spacer from '@fenderdigital/ui/Spacer';
import flags from '../../flags';
import {
  getFlagState,
  setOverrideFlag,
  clearOverrideFlags,
  AvailableFeatureFlags,
  getDefaultFlag,
  getOverrideFlag,
} from '../../lib/feature-flags';

interface FeatureFlagLabelProps {
  label: string;
  enabled: boolean;
  onCheck: (enabled: boolean) => void;
}

const FeatureFlagControl: React.FC<FeatureFlagLabelProps> = ({ label, enabled, onCheck }) => {
  return (
    <label htmlFor={`feature-flag-${label}`} className="flex items-center pointer ttc dib">
      <span className="pr2 source-sans-b">{label}</span>
      <input
        checked={enabled}
        id={`feature-flag-${label}`}
        name={`feature-flag-${label}`}
        onChange={(e): void => onCheck(e.target.checked)}
        type="checkbox"
      />
    </label>
  );
};

const FeatureFlags: React.FC = () => {
  const nonFeatureFlags = ['console', 'env'];
  const flagNames = Object.keys(flags).filter(
    item => nonFeatureFlags.indexOf(item) === -1,
  ) as AvailableFeatureFlags[];

  const [flagState, setFlagState] = useState<typeof flags>(getFlagState());

  const handleApplyClick = (): void => {
    for (const flag of flagNames) {
      const defaultFlag = getDefaultFlag(flag);
      const overrideFlag = getOverrideFlag(flag);
      const uiFlagState = flagState[flag];

      if ((overrideFlag !== null && uiFlagState !== overrideFlag) || uiFlagState !== defaultFlag) {
        setOverrideFlag(flag, uiFlagState);
      }
    }
    window.location.reload();
  };

  const handleResetClick = (): void => {
    clearOverrideFlags();
    setFlagState(getFlagState());
    window.location.reload();
  };

  return (
    <div>
      <VStack spacing="sm2">
        {flagNames.map(flagName =>
          nonFeatureFlags.indexOf(flagName) === -1 ? (
            <FeatureFlagControl
              enabled={flagState[flagName]}
              label={flagName}
              key={flagName}
              onCheck={() => {
                const newEnableState = !flagState[flagName];
                const newState = {
                  ...flagState,
                  [flagName]: newEnableState,
                };
                setFlagState(newState);
              }}
            />
          ) : null,
        )}
      </VStack>
      <Spacer />
      <HStack>
        <Button id="developer-console-apply-flags" onClick={handleApplyClick} size="small">
          Apply Flags
        </Button>
        <Button
          colorScheme="white"
          id="developer-console-reset-flags"
          onClick={handleResetClick}
          size="small"
          variant="outline"
          variantBgColor="transparent"
        >
          Reset Flags
        </Button>
      </HStack>
    </div>
  );
};

export default FeatureFlags;
