import keyBy from 'lodash/keyBy';
import { lessons as defaultState } from './default.json';
import { actionTypes } from '../actions/lessons';

const handlers = {
  [actionTypes.CLEAR_WATCHING]: state => ({
    ...state,
    watching: {
      ...defaultState.watching,
    },
  }),
  [actionTypes.FC_SIGNOUT]: () => defaultState,
  [actionTypes.LESSONS_GET_ERROR]: (state, action) => ({
    ...state,
    inProgress: false,
    error: action.error,
  }),
  [actionTypes.LESSONS_GET_IN_PROGRESS]: state => ({
    ...state,
    inProgress: true,
  }),
  [actionTypes.LESSONS_GET_SUCCESS]: (state, action) => ({
    ...state,
    inProgress: false,
    results: {
      ...action.results.items,
    },
    meta: action.results.meta,
    slugs: keyBy(action.results.items, 'slug'),
  }),
};

export default (state = defaultState, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};
