import isDomUsable from '@fenderdigital/utils/dom';
import config from '../config';

export function computedWidth(element) {
  if (!window || !element) return 0;
  const computedStyle = window.getComputedStyle(element);
  const ph =
    parseFloat(computedStyle.getPropertyValue('padding-left')) +
    parseFloat(computedStyle.getPropertyValue('padding-right'));

  return element.clientWidth - ph;
}

export function computedHeight(element) {
  if (!window || !element) return 0;
  const computedStyle = window.getComputedStyle(element);
  const pv =
    parseFloat(computedStyle.getPropertyValue('padding-top')) +
    parseFloat(computedStyle.getPropertyValue('padding-bottom'));

  return element.clientHeight - pv;
}

export function computedXPos(element) {
  const computedStyle = window.getComputedStyle(element);
  const pl = parseFloat(computedStyle.getPropertyValue('padding-left'));

  return element.offsetLeft + pl;
}

export function computedYPos(element, yPos = 0) {
  return element.offsetTop - yPos;
}

export function isBottomOffset(element, windowY, offset = 0) {
  const selectedElement = document.querySelector(element);
  if (selectedElement) {
    const bodyScrollHeight = document.querySelector('body').scrollHeight;
    const elementHeight = selectedElement.clientHeight;
    const waypoint = bodyScrollHeight - elementHeight - offset;
    return windowY >= waypoint;
  }
  return false;
}

export function isValidScreenSize(width = 0) {
  const parsedBreakpoint = config.breakpoints.large.replace(/[^0-9]/g, '');
  const largeBreakpoint = parseFloat(parsedBreakpoint);

  return width >= largeBreakpoint;
}

export function isElementVisible(el) {
  const rect = el.getBoundingClientRect();
  const vWidth = window.innerWidth || document.documentElement.clientWidth;
  const vHeight = window.innerHeight || document.documentElement.clientHeight;
  const efp = (x, y) => {
    return document.elementFromPoint(x, y);
  };

  // Return false if it's not in the viewport
  if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) return false;

  // Return true if any of its four corners are visible
  return (
    el.contains(efp(rect.left, rect.top)) ||
    el.contains(efp(rect.right, rect.top)) ||
    el.contains(efp(rect.right, rect.bottom)) ||
    el.contains(efp(rect.left, rect.bottom))
  );
}

// https://www.w3.org/blog/CSS/2012/06/14/unprefix-webkit-device-pixel-ratio/
export const isHighDensity = () => {
  return (
    isDomUsable() &&
    window.matchMedia(
      'only screen and (min-resolution: 144dpi), only screen and (-webkit-min-device-pixel-ratio: 1.5)',
    ).matches
  );
};
