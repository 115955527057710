/* eslint-disable max-classes-per-file */
import { useEffect, useState } from 'react';
import AuthorizationHeader from './authorization-header';
import bugsnagClient from '../core/bugsnag';
import screenToSkuMap from '../constants/zuora-skus';
import { getFlag } from './feature-flags';

// LEGACY
// TODO remove, along with unused plans-utils related to 2.x.x
const productPlans = {
  PLAN_MONTHLY: 'Monthlyretail',
  PLAN_12MONTH: 'Play-12Month',
};
export default productPlans;
// LEGACY

// polyfill for node 10
if (Array.prototype.flat === undefined)
  /* eslint-disable func-names */
  // eslint-disable-next-line no-extend-native
  Array.prototype.flat = function (): any[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.reduce((acc, val) => acc.concat(val), []);
  };
/* eslint-enable func-names */

const getAuthenticationHeader = async (): Promise<string> =>
  (await AuthorizationHeader.getCognitoHeader()).headers.Authorization ?? '';

export enum PLAN_SKU {
  PLAN_MONTHLY = 'Monthlyretail',
  PLAN_12MONTH = 'Play-12Month',
}
export class ProductRateRequestError extends Error {
  constructor(
    public status: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public payload: any,
    message = 'ProductRates request unsuccessful',
  ) {
    super(message);
    this.name = 'ProductRateRequestError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ProductRateRequestError);
    }
  }
}

export class InvalidPromoCode extends Error {
  constructor(
    public promoCode: string,
    public status: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public payload: any,
    message = 'The Promocode supplied was not valid',
  ) {
    super(message);
    this.name = 'InvalidPromoCode';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidPromoCode);
    }
  }
}
interface Pricing {
  currency: string;
  price: string;
  symbol: string;
}

export interface ProductRate {
  benefit: string;
  feature?: string;
  'monthly-cost': {
    currency: string;
    price: string;
    symbol: string;
  };
  name: string;
  pricing: Pricing;
  'free-trial-days': number;
  'product-rate-plan-sku': string;
  'one-time-fee'?: {
    price: string;
    currency: string;
    symbol: string;
  };
  'pricing-discount'?: {
    'discount-amount': string;
  };
  value: string;
}

export interface PlaySKUs {
  bundlesPlan: MaybeProductRate;
  monthlyPlan: MaybeProductRate;
  annualPlan: MaybeProductRate;
}

// This is a Maybe type, in the event we receive empty response
export type MaybeProductRate = ProductRate | undefined;
export interface ProductRatesResponse {
  items: ProductRate[];
  meta: unknown;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isProductRatesResponse = (payload: any): payload is ProductRatesResponse => {
  return (
    typeof payload === 'object' &&
    payload !== null &&
    'items' in payload &&
    Array.isArray(payload.items)
  );
};

export type PlanLabel = 'annually' | 'monthly';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPlanLabel = (label: unknown): label is PlanLabel =>
  label === 'annually' || label === 'monthly';

export interface Plan {
  hasDiscount: boolean;
  label: PlanLabel;
  secondaryLabel: 'annual' | '';
  ratePlan: ProductRate;
  prices: {
    annually: string;
    monthly: string;
  };
  symbol: string;
  theme: 'red' | 'blue';
}

interface PromoCodeResponse {
  'discount-percentage': number;
  message: string;
  'product-rate-plan-id': string;
  'product-rate-plan-sku': PLAN_SKU;
  'product-rate-plans': {
    [SKU: string]: string;
  };
  'wholesale-price': unknown;
  status: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const verifyPromoResponseSku = (associatedSkus: string[], plans: any): boolean =>
  associatedSkus.some(sku => Object.values(plans).concat().flat().includes(sku));
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPromoCodeResponse = (response: any): response is PromoCodeResponse =>
  typeof response === 'object' &&
  response !== null &&
  'discount-percentage' in response &&
  'message' in response &&
  'product-rate-plan-sku' in response &&
  verifyPromoResponseSku(Object.keys(response['product-rate-plans']), screenToSkuMap);

type PlanDefault = Pick<Plan, 'hasDiscount' | 'label' | 'secondaryLabel' | 'theme'>;
const plansMap: { annually: PlanDefault; monthly: PlanDefault } = {
  annually: {
    hasDiscount: true,
    label: 'annually',
    secondaryLabel: 'annual',
    theme: 'red',
  },
  monthly: {
    hasDiscount: false,
    label: 'monthly',
    secondaryLabel: '',
    theme: 'blue',
  },
};

export const findPlanFromList = (sku: string, plansList: Plan[]): Plan | undefined => {
  // eslint-disable-next-line consistent-return
  return plansList.find(plan => plan.ratePlan['product-rate-plan-sku'] === sku);
};

export const transformPricingResponseToPlans = (pricing: ProductRate[]): Plan[] => {
  return pricing.map(item => {
    return {
      ...(item.value === 'Annually' ? plansMap.annually : plansMap.monthly),
      ratePlan: item,
      prices: {
        annually: item.pricing.price,
        monthly: item['monthly-cost'].price,
      },
      symbol: item.pricing.symbol,
    };
  });
};

const verifyPromoWithPlan = (promoResponse: PromoCodeResponse, queryPlan: string): boolean => {
  const validPromoPlans = Object.keys(promoResponse['product-rate-plans']);
  return validPromoPlans.includes(queryPlan);
};

const clientName = process.env.REACT_APP_CLIENT_SITE_ID || 'play';

/**
 * Determines validity of a promocode.
 *
 * If the optional `sku` is provided,
 * this function will verify that it is eligible for the given `promoCode`.
 *
 * @param promoCode - target promocode
 * @param sku - optional sku to test promocode against
 * @returns
 */

export async function validatePromoCode(
  promoCode: string,
  sku?: string,
): Promise<PromoCodeResponse> {
  const parsedCode = promoCode.toLowerCase();
  const Authorization = await getAuthenticationHeader();
  const response = await fetch(
    `${process.env.REACT_APP_CLIENT_FC_SUBSCRIPTIONS_API}/promos/${parsedCode}/validate`,
    {
      method: 'GET',
      headers: {
        Authorization,
        'Content-Type': 'application/json',
        'X-Client-Name': clientName,
      },
    },
  );
  const { status } = response;
  const responseJson: PromoCodeResponse = await response.json();
  if (status && status >= 200 && status < 300 && isPromoCodeResponse(responseJson)) {
    console.log('responseJson', responseJson);
    if (sku) {
      const isValidPromoPlan = verifyPromoWithPlan(responseJson, sku);
      if (!isValidPromoPlan)
        throw new InvalidPromoCode(
          promoCode,
          response.status,
          '',
          `Promocode could not be associated with SKU: ${sku}`,
        );
    }
    if (responseJson.message === 'valid') {
      return responseJson;
    }
  }
  // segment event/bugsnag notify at info level here
  throw new InvalidPromoCode(promoCode, status, responseJson);
}

/**
 * @param country User's country code eg. US
 * @param subscriptionType eg. monthly, annual
 * @returns the specific price, plan sku, currency symbol for given country
 * @throws ProductRateRequestError when the given subscription type is not valid
 * or the API returned a non-200
 */
export const getProductRates = async (
  country: string,
  subscriptionType = 'multi-month-v4',
): Promise<ProductRatesResponse> => {
  const url =
    `${process.env.REACT_APP_CLIENT_FC_SUBSCRIPTIONS_API}/public/subscription-types/` +
    `${process.env.REACT_APP_CLIENT_PLAY_PRODUCT_SKU}?type=${subscriptionType}&country=${country}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json; version=3.1.0',
      'X-Client-Name': clientName,
    },
  });
  const payload = await response.json();
  if (response.status > 199 && response.status < 300 && isProductRatesResponse(payload)) {
    return payload;
  }
  throw new ProductRateRequestError(response.status, payload);
};

const removeFromList = (sku: string, list: MaybeProductRate[]): MaybeProductRate[] | null => {
  if (!list.length) return null;
  return list.filter(element => element?.['product-rate-plan-sku'] !== sku);
};

export const useProductRates = (
  country?: string,
  subscriptionType?: string,
): ProductRatesResponse | null => {
  const [productRates, setProductRates] = useState<ProductRatesResponse | null>(null);
  useEffect(() => {
    if (!country) {
      bugsnagClient.notify(new Error('country code cookie was not found'));
    } else {
      getProductRates(country, subscriptionType).then(
        productList => {
          const productListCopy = JSON.parse(JSON.stringify(productList));
          if (productListCopy?.items && !getFlag('isSquierBundle'))
            productListCopy.items = removeFromList(
              'play_web_allaccess_12mo_acoustic01',
              productListCopy?.items,
            );
          if (productListCopy?.items && !getFlag('isFA15Bundle'))
            productListCopy.items = removeFromList(
              'play_web_allaccess_12mo_bundle01',
              productListCopy?.items,
            );
          if (productListCopy?.items && !getFlag('isSquierElectricBundle'))
            productListCopy.items = removeFromList(
              'play_web_allaccess_12mo_bundle02',
              productListCopy?.items,
            );
          setProductRates(productListCopy);
        },
        e => {
          bugsnagClient.notify(e, { metaData: { country } });
          setProductRates(null);
        },
      );
    }
  }, [country, subscriptionType]);
  return productRates;
};
