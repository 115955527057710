const play5Skus = ['play_web_allaccess_1mo', 'play_web_allaccess_12mo'];

const screenToSkuMap = {
  'special-offer': play5Skus, // ['Zuora_SpecialOfferMonthly_Web', 'Zuora_SpecialOfferAnnual_Exp2_Web']
  'last-chance': play5Skus, // ['Zuora_LastChanceMonthly_Web', 'Zuora_LastChanceAnnual_Exp2_Web'],
  upgrade: [...play5Skus, 'Zuora_UpgradeMonthly_Exp_Web'], // ['Zuora_UpgradeMonthly_Exp2_Web', 'Zuora_UpgradeAnnual_Exp2_Web'],
  're-subscribe': play5Skus, // ['Zuora_ResubMonthly_Exp2_Web', 'Zuora_ResubAnnual_Exp2_Web']
  bundles: [
    'Zuora_UpgradeMonthly_Exp2_Web',
    'Zuora_UpgradeAnnual_Exp2_Web',
    'Zuora_Micro01Annual_Web',
  ],
};
export default screenToSkuMap;
