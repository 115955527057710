import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CTA } from './CTAButton';
import SearchPanel from '../SearchPanel';

interface SearchButtonProps {
  cta: CTA | null;
}

const SearchButton: React.FC<SearchButtonProps> = ({ cta }) => {
  const searchPanelRootRef = useRef<HTMLElement | null>(null);
  const searchButtonWrapperRef = useRef<HTMLDivElement | null>(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const history = useHistory();

  const handleSetIsSearchActive = (): void => {
    setIsSearchActive(isActive => !isActive);
  };

  const handleMobileClick = (): void => {
    history.push('/search/');
  };

  useEffect(() => {
    searchPanelRootRef.current = document.getElementById('search-panel-root');
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent): void => {
      if (
        searchButtonWrapperRef.current &&
        !searchButtonWrapperRef.current.contains(event.target as Node)
      ) {
        setIsSearchActive(false);
      }
    };
    document.addEventListener('mouseup', handleOutsideClick);
    return (): void => {
      document.removeEventListener('mouseup', handleOutsideClick);
    };
  }, [isSearchActive]);

  return (
    <div ref={searchButtonWrapperRef} className="on-search-click h-100">
      <button
        type="button"
        data-id="header-search-btn"
        className="dn db-l bn outline-0 h-100 pa0 bg-transparent"
        onClick={handleSetIsSearchActive}
      >
        <div className={`b--black-10 h-100${!cta ? ' bl' : ''}`}>
          <div className="bt bw2 b--white h-100">
            <div
              className={`flex items-center pv0 ph3 h-100 bw2 pointer${!cta ? ' bb' : ''} ${
                isSearchActive ? 'b--blue' : 'b--white'
              }`}
            >
              <span className="icon-search dark-gray f3 ph1" />
            </div>
          </div>
        </div>
      </button>
      <button
        type="button"
        className="bn outline-0 dn-l h-100 bg-transparent"
        data-id="header-search-btn-mobile"
        onClick={handleMobileClick}
      >
        <span className="icon-search dark-gray f2 ph3" />
      </button>
      {isSearchActive && (
        <div id="search-panel-root" className="absolute right-0 z-3">
          <SearchPanel eventTypes={['click']} onCloseClick={(): void => setIsSearchActive(false)} />
        </div>
      )}
    </div>
  );
};

export default SearchButton;
