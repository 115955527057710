import { connect } from 'react-redux';
import SearchSummary, { Summary } from './SearchSummary';
import { formatSummary } from '../../utils/search-utils';

import { Facets, Hit, InstrumentGroup } from '../../models/search';
import { RootState } from '../../redux-types/root.redux-types';

type InstantResult = {
  facets: Facets;
  hits: Hit[];
  query: string;
  queryID?: string;
};

interface MapProps {
  localResults: InstantResult;
  instrument: InstrumentGroup;
}

interface ReturnType {
  localResults: InstantResult;
  instrument: InstrumentGroup;
  query: string;
  summaryArr: Summary[];
}

const mapState = (state: RootState, props: MapProps): ReturnType => {
  const { search } = state;
  const { localResults, instrument } = props;
  const { instantResults, filters } = search;
  const results = instantResults;
  const { facets, hits, query, queryID } = localResults || results;
  return {
    ...props,
    query,
    summaryArr: formatSummary(hits, facets, query, instrument, queryID, filters),
  };
};

export default connect(mapState)(SearchSummary);
