import styled from 'styled-components';
import { Focus } from '@fenderdigital/ui/utils/css';

// eslint-disable-next-line import/prefer-default-export
export const VideoPlayerContainer = styled.div`
  height: 100%;

  .video-js {
    button:focus {
      outline: 0px;
      box-shadow: inset rgb(185, 205, 241) 0px 0px 0px 3px;
      transition: box-shadow 0.15s ease;
    }

    .vjs-big-play-button {
      ${Focus};
    }

    .vjs-volume-bar:focus,
    .vjs-progress-holder:focus {
      outline: 0px;
      box-shadow: rgb(185, 205, 241) 0px 0px 0px 1px;
      transition: box-shadow 0.15s ease;
    }

    .vjs-seek-button {
      img {
        height: 2em;
      }
    }
  }
`;
