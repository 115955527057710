import React, { FC, memo } from 'react';
import times from 'lodash/times';
import styled from 'styled-components';
import ScreenReaderOnly from '@fenderdigital/ui/ScreenReaderOnly';

interface LevelIndicatorProps {
  completeColor?: string;
  completeIcon?: string;
  incompleteColor?: string;
  incompleteIcon?: string;
  currentLevel?: string | number;
  maxLevel?: number;
  levelFontSize?: string;
  screenReaderTitle?: string;
}

const Icon = styled.i`
  padding-right: 2px;
`;

const LevelIndicator: FC<LevelIndicatorProps> = ({
  completeColor = 'red',
  completeIcon = 'pick',
  incompleteColor = 'moon-gray',
  incompleteIcon = 'pick',
  currentLevel = 0,
  maxLevel = 3,
  levelFontSize = '',
  screenReaderTitle,
}) => {
  return (
    <div className={`level-indicator di ${levelFontSize}`}>
      <ScreenReaderOnly as="span">
        {screenReaderTitle || `Difficulty level of ${currentLevel} out of ${maxLevel}`}
      </ScreenReaderOnly>
      {times(maxLevel).map(index => {
        const colorFill: string = index + 1 <= currentLevel ? completeColor : incompleteColor;
        const shapeIcon: string = index + 1 <= currentLevel ? completeIcon : incompleteIcon;
        return <Icon className={`icon-${shapeIcon} ${colorFill}`} key={index} aria-hidden />;
      })}
    </div>
  );
};

const MemoLevelIndicator = memo(LevelIndicator);

export default MemoLevelIndicator;
