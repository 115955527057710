import mirror from 'keymirror';
import Library from '../lib/library';

export const actionTypes = mirror({
  SONG_CLEAR: null,
  SONG_GET_ERROR: null,
  SONG_GET_IN_PROGRESS: null,
  SONG_GET_SUCCESS: null,
});

export const songClear = () => ({
  type: actionTypes.SONG_CLEAR,
});

const songGetError = error => ({
  error,
  type: actionTypes.SONG_GET_ERROR,
});

const songGetInProgress = () => ({
  type: actionTypes.SONG_GET_IN_PROGRESS,
});

const songGetSuccess = results => ({
  results,
  type: actionTypes.SONG_GET_SUCCESS,
});

export const getSongByID = songID => async dispatch => {
  dispatch(songGetInProgress());
  if (songID) {
    try {
      const results = await Library.getSongByID(songID);
      dispatch(songGetSuccess(results));
    } catch (err) {
      dispatch(songGetError(err));
    }
  } else {
    dispatch(songClear());
  }
};
