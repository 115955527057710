import mirror from 'keymirror';
import Skills from '../lib/skills';

export const actionTypes = mirror({
  SKILLS_GET_ERROR: null,
  SKILLS_GET_IN_PROGRESS: null,
  SKILLS_GET_SUCCESS: null,
});

const skillsGetError = error => ({
  error,
  type: actionTypes.SKILLS_GET_ERROR,
});

const skillsGetInProgress = () => ({
  type: actionTypes.SKILLS_GET_IN_PROGRESS,
});

const skillsGetSuccess = results => ({
  results,
  type: actionTypes.SKILLS_GET_SUCCESS,
});

export const getSkills = () => async dispatch => {
  dispatch(skillsGetInProgress());
  try {
    const skills = await Skills.getSkills();
    dispatch(skillsGetSuccess(skills));
  } catch (err) {
    dispatch(skillsGetError(err));
  }
};
