import fetch from '../core/fetch';
import { parseResponse } from '../core/fetch/response';
import AuthorizationHeader from './authorization-header';

export default class RelatedItems {
  static async getRelatedItems(itemType, type, limit, exclude, instrument) {
    const headers = await AuthorizationHeader.getCognitoHeader();
    const url = `${process.env.REACT_APP_CLIENT_LIBRARY_API}/public-${itemType}?type=${type}&limit=${limit}&excludes=${exclude}&sortField=updated-at&instrument=${instrument}`;
    let params = {};
    params = {
      method: 'GET',
      ...headers,
    };
    const response = await fetch(url, params);
    return parseResponse(response);
  }

  /**
   * This method replicates the behaviour from the mobile play app,
   * which is correct according to product.
   * @param {string[]} ids Unique identifiers of the lessons
   * @returns {Promise<any[]>} An array with the requested lessons by id
   */
  static async getRelatedLessonsByIds(ids) {
    const headers = await AuthorizationHeader.getCognitoHeader();
    const url =
      `${process.env.REACT_APP_CLIENT_LIBRARY_API}/lessons` +
      `?ids=${ids.join(',')}` +
      `&limit=${ids.length}`;
    const params = { method: 'GET', ...headers };
    const response = await fetch(url, params);
    return parseResponse(response);
  }
}
