import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * This hook is mainly used on the un-subscribed home page.
 * It's used to hide certain navigation elements in the header, and
 * render a hero that was popular with users.
 * This was determined from a google optimize A/B test.
 *
 * @return {Boolean} - true / false if user is on the home page.
 */
const useIsHome = (): boolean => {
  const [isHome, setIsHome] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    const home = location.pathname === '/';
    setIsHome(home);
  }, [location.pathname]);
  return isHome;
};

export default useIsHome;
