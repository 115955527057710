export interface FeedbackModeCarouselData {
  headline: string;
  text: string;
  tip?: string;
  background: {
    default: string;
    'tablet-portrait': string;
    'tablet-landscape'?: string;
    laptop?: string;
  };
  artwork?: string;
  specialArt: boolean;
}

const feedbackModeCarouselData: FeedbackModeCarouselData[] = [
  {
    headline: 'Welcome to\nFeedback Mode!',
    text: 'Be the first to try our beta. Play along with a song and get immediate feedback.',
    background: {
      default: 'https://stuff.fendergarage.com/images/y/I/S/woman-bg-gradient-phone.jpg',
      'tablet-portrait':
        'https://stuff.fendergarage.com/images/4/w/7/woman-bg-gradient-tablet-portrait.jpg',
      'tablet-landscape':
        'https://stuff.fendergarage.com/images/J/S/S/woman-bg-gradient-tablet-landscape.jpg',
      laptop: 'https://stuff.fendergarage.com/images/4/G/H/woman-bg-gradient-desktop.jpg',
    },
    specialArt: false,
  },
  {
    headline: 'Tap ”Feedback”\nto Start',
    text: "Play into your device's microphone. Once you've finished, you'll get your score.",
    tip: 'Tip: Plug in headphones for the best results.',
    background: {
      default: 'https://stuff.fendergarage.com/images/Z/t/E/First_Run_Modal-Slide_2-4_BG_Phone.png',
      'tablet-portrait':
        'https://stuff.fendergarage.com/images/h/7/L/First_Run_Modal-Slide_2-4_BG_Tablet.png',
    },
    artwork:
      'https://stuff.fendergarage.com/images/x/a/n/Feedback_Mode_Slide_2-feedback_icon@4x.png',
    specialArt: false,
  },
  {
    headline: 'Need to Review?',
    text: 'Detailed feedback shows you exactly what and where to practice.',
    tip: `Tip: Use ”loop” and ”tempo” to hone in on troublesome spots.`,
    background: {
      default: 'https://stuff.fendergarage.com/images/Z/t/E/First_Run_Modal-Slide_2-4_BG_Phone.png',
      'tablet-portrait':
        'https://stuff.fendergarage.com/images/h/7/L/First_Run_Modal-Slide_2-4_BG_Tablet.png',
    },
    artwork: 'https://stuff.fendergarage.com/images/m/1/3/Feedback_Mode_Slide_3-score_chart@4x.png',
    specialArt: false,
  },
];

export default feedbackModeCarouselData;
