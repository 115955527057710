import React, { FC, memo, MouseEvent } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Focus } from '@fenderdigital/ui/utils/css';
import useFenderConnect from '@fenderdigital/custom-hooks/hooks/useFenderConnect';
import Scroller from '../Scroller';
import Link from '../Link';
import OverlayThumb from '../OverlayThumb';
import LevelIndicator from '../LevelIndicator';
import {
  getPracticeTrackEvent,
  trackEvent as avoTrackEvent,
  EventNames,
} from '../../lib/analytics';
import { getCurriculumImage } from '../../utils/curriculum-utils';
import { toTitleCase } from '../../utils/string-utils';
import { getContentText, getStatsMessage } from '../CurriculumTable/CurriculumContent';
import { Item, ThumbListProps, TrackEvent } from './types';

function getArtistSongsQueryUrl(item: Item, instrument: string): string {
  const artist = encodeURIComponent(item.name || '');
  return `/search/results?query=${artist}&instrument=${instrument}`;
}

function getLabelUrl(itemIsLocked: boolean, toUrl: string): string {
  if (itemIsLocked) {
    return '/plans/upgrade';
  }
  return toUrl;
}
const LinkWrapper = styled.div<{ textAlign: string }>`
  padding-top: 8px;
  padding-bottom: 4px;
  text-align: ${({ textAlign }): string => textAlign};
  font-family: ${({ theme: { fonts } }): string => fonts.sourceSans};
  a {
    ${Focus};
    color: ${({ theme: { colors } }): string => colors.black};
    font-size: ${({ theme: { fontSizes } }): string => fontSizes.f3};
    line-height: 1.5;
  }
`;

export const formatThumbUrl = (item: Item, instrument: string, isPracticeMode: boolean): string => {
  const { 'activity-lesson-video': activityVideo, objectType, slug, type } = item || {};
  const lessonPrefix = 'lessons';
  const coursePrefix = 'courses';

  if (isPracticeMode) return `/practice/${item.slug}`;
  if (activityVideo || type === 'chord' || type === 'lesson' || objectType === 'lesson') {
    return `/${lessonPrefix}/${slug}`;
  }
  if (type === 'artist') return getArtistSongsQueryUrl(item, instrument);
  if (objectType === 'collection') {
    const instrumentPath =
      item.instrument && item.instrument.indexOf('guitar') > -1 ? 'guitar' : item.instrument;
    return `/${instrumentPath}/collections/${slug}`;
  }
  return `/${coursePrefix}/${slug}`;
};

const ThumbList: FC<ThumbListProps> = ({
  alphaSort = false,
  aspectRatio = 'square',
  containerLayout,
  instrument = 'guitar',
  isCollections = false,
  isScrollerEnabled = true,
  isSkills = false,
  itemArr,
  itemClasses = 'mr3',
  pathColor = 'blue',
  showDescription = false,
  sortBy,
  variantType = null,
}) => {
  const { userID } = useFenderConnect();
  const history = useHistory();
  const itemAlignStyle = containerLayout === 'column' ? 'db' : 'dib v-top';
  const parsedItemStyles = `${itemAlignStyle} mb4 ${itemClasses}`;
  const width = isCollections ? '15rem' : '10rem';
  const itemStyle = { width, minWidth: width };

  if (alphaSort) {
    if (sortBy === 'artist') {
      itemArr.sort((a, b) => {
        if (a.artists && a.artists[0].name && b.artists && b.artists[0].name) {
          if (a.artists[0].name.toLowerCase() > b.artists[0].name.toLowerCase()) {
            return 1;
          }
          if (b.artists[0].name.toLowerCase() > a.artists[0].name.toLowerCase()) {
            return -1;
          }
        }
        return 0;
      });
    } else if (itemArr[0] && itemArr[0].type === 'chord') {
      itemArr.sort((a, b) => {
        const titleA = a.overlayTitle || a.title;
        const titleB = b.overlayTitle || b.title;
        if (titleA && titleB) {
          if (titleA.length > titleB.length) {
            return 1;
          }
          if (titleB.length > titleA.length) {
            return -1;
          }
        }
        return 0;
      });
    } else {
      itemArr.sort((a, b) => {
        if (a.title && b.title) {
          if (a.title.replace(/[^\w\s]/gi, '') > b.title.replace(/[^\w\s]/gi, '')) {
            return 1;
          }
          if (b.title.replace(/[^\w\s]/gi, '') > a.title.replace(/[^\w\s]/gi, '')) {
            return -1;
          }
        }
        return 0;
      });
    }
  }

  const handleOnClick = (
    itemIsLocked: boolean,
    url: string,
    trackObj: TrackEvent,
  ): void | false => {
    if (trackObj) {
      const { eventType, properties } = trackObj;
      avoTrackEvent(eventType, properties);
      if (properties.objectType === 'lesson') {
        avoTrackEvent(EventNames.LESSON_CLICKED, {
          ...properties,
          lesson_slug: properties?.slug,
          lesson_type1: properties?.lesson_type,
          is_locked: itemIsLocked,
        });
      }
    }

    return history.push(url);
  };

  return (
    <Scroller enableMaxWidthClass={false} isScrollerEnabled={isScrollerEnabled}>
      <ul className="flex content-start">
        {Object.keys(itemArr).map((key: any) => {
          const itemObj: Item = itemArr[key];
          const {
            overlayColor,
            overlayTitle,
            title,
            trackEvent,
            type,
            description,
            'activity-lesson-video': activitylessonVideo,
            stats,
            url,
          } = itemObj;

          if (trackEvent) {
            trackEvent.properties.lesson_id = itemObj.objectID;
            trackEvent.properties.lesson_name = itemObj.title;
            trackEvent.properties.fc_id = userID ?? '';
          }
          const isPracticeMode = itemObj['activity-type'] === 'practice-sheet';
          const practiceModeDescription =
            isPracticeMode && activitylessonVideo && activitylessonVideo.artists
              ? activitylessonVideo.artists[0].name
              : toTitleCase(type);
          const toUrl = url || formatThumbUrl(itemObj, instrument, isPracticeMode) || '#';
          const trackObj = isPracticeMode
            ? getPracticeTrackEvent('Practice Mode Launched', itemObj, window.location.href)
            : trackEvent ?? null;

          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <li
              key={uuid()}
              className={`${parsedItemStyles} thumb`}
              style={itemStyle}
              onClick={(): void | false => handleOnClick(false, toUrl, trackObj)}
            >
              <OverlayThumb
                aspectRatio={aspectRatio}
                backgroundColor={overlayColor}
                circle={type === 'chord' || type === 'circle' || type === 'artist'}
                image={getCurriculumImage(itemObj)}
                label={overlayTitle}
                overlay={type === 'chord' || type === 'circle' || type === 'artist'}
                variantType={variantType}
                itemStyle={itemStyle}
                labelUrl={!title ? getLabelUrl(false, toUrl) : ''}
              />
              {title ? (
                <LinkWrapper
                  textAlign={
                    itemObj.type === 'artist' || itemObj.type === 'chord' ? 'center' : 'left'
                  }
                >
                  <Link
                    to={toUrl}
                    trackEvent={trackObj}
                    onClick={(e: MouseEvent<HTMLLinkElement>): void => {
                      e.stopPropagation();
                    }}
                  >
                    {title}
                  </Link>
                </LinkWrapper>
              ) : null}
              {showDescription && description ? (
                <div className="ttn source-sans mid-gray">{description}</div>
              ) : null}
              {isPracticeMode ? (
                <div className="ttn source-sans mid-gray">{practiceModeDescription}</div>
              ) : null}
              {!isPracticeMode && itemObj.artists ? (
                <div className={`source-sans mid-gray f6 ${itemObj.level ? 'mb1' : ''}`}>
                  {itemObj.artists.map(artist => (
                    <div key={uuid()}>{artist.name}</div>
                  ))}
                </div>
              ) : null}
              {!isPracticeMode && !isSkills && itemObj.level ? (
                <LevelIndicator
                  currentLevel={parseInt(itemObj.level, 10)}
                  levelFontSize="f5 mid-gray"
                  completeColor={pathColor}
                />
              ) : null}
              {getContentText(
                isCollections,
                getStatsMessage(stats),
                `ttc flex source-sans f6 mid-gray`,
              )}
            </li>
          );
        })}
      </ul>
    </Scroller>
  );
};

const MemoizedThumbList = memo(ThumbList);

export default MemoizedThumbList;
