import React, { FC, SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';
import Flex from '@fenderdigital/ui/Flex';
import BodyText from '@fenderdigital/ui/BodyText';
import Spacer from '@fenderdigital/ui/Spacer';
import usePlayUser from '@fenderdigital/custom-hooks/hooks/usePlayUser';
import { trackEvent as avoTrackEvent, EventNames } from '../../lib/analytics';
import {
  getChords,
  getContentImage,
  getContentText,
  getLessonsMessage,
  getLevelDisplay,
  getTitle,
  getContentTitle,
  getStatsMessage,
} from './CurriculumContent';
import { stylesLarge, CurriculumStyle } from './CurriculumStyles';
import { CurriculumTableProps, RowItem, RowDisplayInfo } from './types';
import * as S from './CurriculumTable-styled';

interface RowContentProps {
  display: RowDisplayInfo;
  height: string;
  isSearch: boolean;
  item: RowItem;
  itemHeightStyle: string;
}

const RowContent: FC<RowContentProps> = ({ display, height, isSearch, item, itemHeightStyle }) => {
  return (
    <Flex data-id={`curriculum-row-${item.title}`}>
      {item.title && (
        <Flex width="40%">
          <BodyText
            textColor="midGray"
            textSize="f3"
            textTransform="capitalize"
            className={itemHeightStyle}
          >
            {getContentTitle(item.title, isSearch)}
          </BodyText>
          <Spacer direction="vertical" spacing="sm3" />
        </Flex>
      )}
      <Flex justify="space-between" width={display.title ? '100%' : '60%'}>
        {getChords(
          display.chords,
          item.chords,
          `db tl w-60 ${itemHeightStyle}`,
          'flex flex-columns',
        )}
        {getContentText(display.type, item.type, `${itemHeightStyle} mid-gray ttc pl5`)}
        {getContentText(display.description, item.description, `${itemHeightStyle} source-sans`)}
        {getContentText(display.lessons, getLessonsMessage(item.lessons), `${itemHeightStyle} pl5`)}
        {getContentText(
          display.stats,
          getStatsMessage(item.stats),
          `ttc mid-gray flex ${itemHeightStyle}`,
        )}
        {getContentText(
          display.minutes && item.minutes,
          `${item.minutes} minutes`,
          `gray ${itemHeightStyle} w-30`,
        )}
        {getLevelDisplay(display.level, item.level, `${height} flex items-center gray`)}
      </Flex>
    </Flex>
  );
};

type CurriculumLargeProps = Pick<CurriculumTableProps, 'content' | 'display' | 'isFullSearchView'>;

const CurriculumLarge: FC<CurriculumLargeProps> = ({ content, display, isFullSearchView }) => {
  const history = useHistory();
  const { user } = usePlayUser();

  return (
    <>
      {content.map(rowContent => {
        const { objectID, objectType, items = [], title, url, trackEvent, isSearch } = rowContent;
        const { type: skillType } = items[0];
        const styleObj = ((): CurriculumStyle => {
          switch (objectType) {
            case 'collection':
              return stylesLarge.wide;
            default:
              return stylesLarge.square;
          }
        })();
        const { height } = styleObj;
        const itemHeightStyle = `${height} flex items-center`;
        const style = `w-40 ${itemHeightStyle}`;
        const isCollection = objectType === 'collection';
        const collectionDisplay = { level: false, type: false, stats: true };
        const displayInfo = {
          ...display,
          ...(isCollection ? collectionDisplay : { level: skillType !== 'Skill' }),
        };
        const itemIsLocked = false;

        const handleOnClick = (): void | boolean => {
          if (trackEvent) {
            const { eventType, properties } = trackEvent;
            properties.fc_id = user?.userID ?? '';
            avoTrackEvent(eventType, properties);
            if (properties.objectType === 'lesson') {
              properties.is_locked = itemIsLocked;
              avoTrackEvent(EventNames.LESSON_CLICKED, properties);
            }
          }

          return history.push(url);
        };

        const handleTitleClick = (e: SyntheticEvent): void => {
          e.stopPropagation();
        };

        return (
          <S.RowContainer
            className="curriculum-large"
            key={`curriculum-large-${objectID}`}
            onClick={handleOnClick}
          >
            <Flex width="100%">
              <div className="w-50 flex-auto flex items-start">
                <div
                  className="flex justify-center"
                  style={{ minWidth: isFullSearchView ? '150px' : '' }}
                >
                  {getContentImage(rowContent, styleObj)}
                </div>
                {getTitle({
                  title,
                  url,
                  trackEvent,
                  style,
                  isSearch,
                  isLocked: itemIsLocked,
                  onClick: handleTitleClick,
                })}
              </div>
              <div className="w-50 f6">
                {items?.map(
                  (item: RowItem): JSX.Element => (
                    <RowContent
                      key={`curriculum-large-row-${item.objectID}`}
                      item={item}
                      display={displayInfo}
                      height={height}
                      itemHeightStyle={itemHeightStyle}
                      isSearch={isSearch}
                    />
                  ),
                )}
              </div>
            </Flex>
          </S.RowContainer>
        );
      })}
    </>
  );
};
export default CurriculumLarge;
