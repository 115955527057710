import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

const bugsnagClient = bugsnag({
  appType: 'client',
  appVersion: `${process.env.REACT_APP_GITSHA}`,
  apiKey: `${process.env.REACT_APP_BUGSNAG_KEY}`,
  releaseStage: process.env.REACT_APP_ENVIRONMENT,
  notifyReleaseStages: ['prod', 'qa'],
  collectUserIp: false,
  filters: [/token/i, /password/i, /firstname/i, /lastname/i, /email/i, /phone/i],
  beforeSend: report => {
    if (/Extension context invalidated/i.test(report.errorMessage)) report.ignore();
  },
});
bugsnagClient.use(bugsnagReact, React);

// if (typeof document.querySelectorAll('body').forEach === 'undefined') {
//   bugsnagClient.notify(new Error('qsa foreach'));
// }

// // browsers which do not support these features are not supported
// if (
//   typeof window !== 'undefined' && // makes sure we aren't in server code
//   (typeof Proxy === 'undefined' ||
//     typeof Promise === 'undefined' ||
//     typeof Promise.prototype.finally === 'undefined' ||
//     typeof document.querySelectorAll === 'undefined')
// ) {
//   if (typeof Promise === 'undefined') {
//     bugsnagClient.notify(new Error('Promise not present'));
//   } else if (typeof Proxy === 'undefined') {
//     bugsnagClient.notify(new Error('Proxy not present'));
//   } else if (typeof Promise.prototype.finally === 'undefined') {
//     bugsnagClient.notify(new Error('Promise.finally not present'));
//   } else if (typeof document.querySelectorAll === 'undefined') {
//     bugsnagClient.notify(new Error('qsa not present'));
//   }
//   window.location.href = 'https://browsehappy.com/';
// }
export default bugsnagClient;
