import cloneDeep from 'lodash/cloneDeep';

export function filterPath(paths, pathSlug, playlistSlug) {
  const path = paths[pathSlug] ? paths[pathSlug] : null;
  let playlist = null;
  if (path && path.playlists && playlistSlug) {
    const filteredPlaylist = paths[pathSlug].playlists.filter(p => p.slug === playlistSlug);
    playlist = filteredPlaylist.length ? filteredPlaylist[0] : null;
  }
  return {
    path,
    playlist,
  };
}

export function getUpcomingCourses(state, pathSlug, playlistSlug, courseSlug) {
  let currentCourse = null;
  let nextCourse = null;
  const path = state.results[pathSlug];
  if (path && path.playlists) {
    const filteredPlaylist = path.playlists.filter(playlist => playlist.slug === playlistSlug);
    if (Array.isArray(filteredPlaylist) && filteredPlaylist.length === 1) {
      const playlist = filteredPlaylist[0];
      let courseIndex = null;
      playlist.courses.forEach((course, i) => {
        if (course.slug === courseSlug) {
          courseIndex = i;
          currentCourse = cloneDeep(course);
          currentCourse.rank = courseIndex + 1; // Rank start at 1 instead of 0 as index
        }
      });
      if (courseIndex !== null && playlist.courses[courseIndex + 1]) {
        nextCourse = cloneDeep(playlist.courses[courseIndex + 1]);
        nextCourse.rank = courseIndex + 2; // Rank start at 1 instead of 0 as index
      }
    }
  }

  return {
    currentCourse,
    nextCourse,
  };
}

export function getLesson(course, lessonSlug = null, lessonID = null) {
  let currentLesson = course && Array.isArray(course.lessons) ? course.lessons[0] : null;
  let nextLesson = currentLesson && course.lessons.length > 1 ? course.lessons[1] : null;
  if (!currentLesson) {
    return {
      currentLesson,
      nextLesson,
    };
  }
  if (lessonID || lessonSlug) {
    let currentLessonIndex = 0;
    course.lessons.forEach((l, i) => {
      if (l.id === lessonID || l.slug === lessonSlug) {
        currentLessonIndex = i;
      }
    });
    currentLesson = course.lessons[currentLessonIndex];
    nextLesson = course.lessons[currentLessonIndex + 1] || null;
  }

  return {
    currentLesson,
    nextLesson,
  };
}
