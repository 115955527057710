import fetch from '../core/fetch';
import { parseResponse } from '../core/fetch/response';
import { Page } from '../types/pages';

const getPagesBySlug = async (slug?: string, application = 'play'): Promise<Page> => {
  const response = await fetch(
    `${process.env.REACT_APP_CLIENT_PAGES_API}/${slug}?fender-application=${application}`,
  );
  return parseResponse(response);
};

export default getPagesBySlug;
export { getPagesBySlug };
