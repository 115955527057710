import { actionTypes } from '../actions/search';
import { search as defaultState } from './default.json';

const handlers = {
  [actionTypes.SEARCH_GET_ERROR]: (state, action) => ({
    ...state,
    error: action.error.message,
    inProgress: false,
  }),
  [actionTypes.SEARCH_GET_IN_PROGRESS]: state => ({
    ...state,
    error: null,
    inProgress: true,
  }),
  [actionTypes.SEARCH_GET_SUCCESS]: (state, action) => ({
    ...state,
    error: null,
    inProgress: false,
    results: {
      ...action.results,
    },
  }),
  [actionTypes.SEARCH_INSTANT_GET_ERROR]: (state, action) => ({
    ...state,
    error: action.error.message,
    instantInProgress: false,
  }),
  [actionTypes.SEARCH_INSTANT_GET_IN_PROGRESS]: state => ({
    ...state,
    error: null,
    instantInProgress: true,
  }),
  [actionTypes.SEARCH_INSTANT_GET_SUCCESS]: (state, action) => ({
    ...state,
    error: null,
    instantInProgress: false,
    instantResults: {
      ...action.results,
    },
  }),
  [actionTypes.SEARCH_UPDATE_FILTER_SUCCESS]: (state, action) => ({
    ...state,
    error: null,
    filters: { ...action.filters },
  }),
};

export default (state = defaultState, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};
