import React, { FC, SyntheticEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Course } from '@fenderdigital/api-definitions/play';
import Flex from '@fenderdigital/ui/Flex';
import ProgressBar from '@fenderdigital/ui/ProgressBar';
import Spacer from '@fenderdigital/ui/Spacer';
import Text from '@fenderdigital/ui/BodyText';
import usePlayUser from '@fenderdigital/custom-hooks/hooks/usePlayUser';
import { types as CourseCardImageTypes, getCourseMetadata } from '../../CourseCardImage';
import { toTitleCase } from '../../../utils/string-utils';
import { getFlag } from '../../../lib/feature-flags';
import { FeedbackBadge, LastWatchedBadge } from '../Labels';
import { isLocked } from '../../../utils/access-utils';
import CardImage from './CardImage';
import LevelIndicator from '../../LevelIndicator';
import Link from '../../Link';
import { trackEvent, EventNames } from '../../../lib/analytics';
import * as S from './PathCard-styled';

interface CourseData extends Course {
  'desktop-background-image'?: string;
  'mobile-background-image'?: string;
}

interface CardProps {
  collectionId?: string;
  collectionTitle?: string;
  courseData: CourseData;
  courseNumber: number;
  recentlyViewedCourse: boolean;
  percentCompleted: number;
  toURL: string;
  markCompleteData?: any;
}

const PathCard: FC<CardProps> = ({
  collectionId,
  collectionTitle,
  courseData,
  courseNumber,
  recentlyViewedCourse,
  percentCompleted,
  toURL,
}) => {
  const {
    title,
    image,
    type,
    lessons,
    level,
    artists,
    'mms-id': mmsId,
    'access-levels': courseAccessLevel,
    'desktop-background-image': desktopImage,
    'mobile-background-image': mobileImage,
  } = courseData ?? {};
  const location = useLocation();
  const history = useHistory();
  const isSong = type === 'song' || type === 'riff';
  const hasBackgroundImage = !!(desktopImage && mobileImage);
  const isSongWBackground = isSong && hasBackgroundImage;
  const showFeebackBadge = mmsId && getFlag('isMMS');
  const activityCount = lessons?.length;
  const percentage = Math.round(percentCompleted * 100);
  const { accessLevel: userAccessLevel, user } = usePlayUser();

  const transformBaseImageUrl = (url: string): string => {
    const base = url?.split('.jpg')[0];
    const resolution = '@2x';
    return encodeURI(`${base}${resolution}.jpg`);
  };

  const newMobileImage = mobileImage ? transformBaseImageUrl(mobileImage) : '';

  const getPercentComplete = (percent: number): number => {
    if (percent === 0 || percent === 1 || recentlyViewedCourse) {
      if (recentlyViewedCourse) {
        return Math.round(0.99 * 100);
      }
      return Math.round(percent * 100);
    }
    return 0;
  };

  const lessonsCompletedPercentage = getPercentComplete(percentCompleted);
  const { courseStatus } = getCourseMetadata(lessonsCompletedPercentage, courseNumber);
  const isInProgress = CourseCardImageTypes.CourseStatus.IN_PROGRESS === courseStatus;
  const isAvailable = !isLocked(courseAccessLevel, userAccessLevel);

  const DotDivider = <S.Dot>&#8226;</S.Dot>;

  const isCollectionsCourse = /collections/.test(location.pathname);

  const handleClick = (): void | boolean => {
    const courseProperties = {
      fc_id: user?.userID,
      course_id: courseData?.id,
      course_slug: courseData?.slug,
      course_name: courseData?.title,
      is_locked: !isAvailable,
      collection_name: collectionTitle,
      collection_id: collectionId,
    };

    const songProperties = {
      song_name: courseData?.title,
      artist_name: courseData?.artists?.[0]?.name,
    };

    const trackingProperties = {
      ...courseProperties,
      ...(isSong && songProperties),
    };
    trackEvent(EventNames.COURSE_CLICKED, trackingProperties);

    if (isCollectionsCourse) {
      trackEvent(EventNames.COLLECTION_COURSE_CLICKED, courseProperties);
    }

    history.push(toURL, { courseNumber });
  };

  return (
    <S.Container
      className="course-card-container"
      onClick={handleClick}
      desktopImage={desktopImage}
      mobileImage={newMobileImage}
      data-id="course-card-container"
    >
      <S.SubContainer>
        <S.ImageContainer>
          <CardImage
            image={image}
            courseNumber={courseNumber}
            isSong={isSong}
            hasBackgroundImage={hasBackgroundImage}
          />
        </S.ImageContainer>
        <S.MetadataContainer>
          {(showFeebackBadge || isInProgress) && (
            <Flex height="22px">
              {showFeebackBadge && (
                <>
                  <FeedbackBadge />
                  <Spacer direction="vertical" spacing="md1" />
                </>
              )}
              {isInProgress && <LastWatchedBadge />}
            </Flex>
          )}
          <Spacer spacing="sm4" />
          <S.Title data-id="course-card-title" isSongWBackground={hasBackgroundImage}>
            <Link
              to={isAvailable ? { pathname: toURL, state: { courseNumber } } : '/plans'}
              onClick={(e: SyntheticEvent): void => e.preventDefault()}
              ariaLabel={`${title} ${isAvailable ? '' : '. Locked. Upgrade to unlock content.'}`}
            >
              {title}
            </Link>
          </S.Title>
          <Spacer spacing={[null, 'sm3', 'sm4']} />
          {artists && (
            <>
              <Text
                textColor={isSongWBackground ? 'white' : 'midGray'}
                textSize="f3"
                dataId="artist"
              >
                {artists[0]?.name}
              </Text>
              <Spacer spacing={[null, 'sm3', 'sm4']} />
            </>
          )}

          <Text
            textColor={isSongWBackground ? 'white' : 'midGray'}
            textSize="f3"
            dataId="activity-count"
          >
            {`${toTitleCase(type)} Course`}
            {DotDivider}
            {`${activityCount} ${activityCount > 1 ? 'Activities' : 'Activity'}`}
            {level && isSong && (
              <>
                {DotDivider}
                <LevelIndicator
                  currentLevel={parseInt(level, 10)}
                  levelFontSize="f6"
                  completeColor={isSongWBackground ? 'white' : '#666666'}
                  incompleteColor={isSongWBackground ? 'fade-white' : 'moon-gray'}
                />
              </>
            )}
          </Text>
          <Spacer spacing="md1" />
          <S.ProgressBarContainer isSongWBackground={isSongWBackground}>
            <ProgressBar
              dataId="course-card-progress-fill"
              percentComplete={percentage}
              size="small"
              barColor={isSongWBackground ? 'white' : 'black'}
              screenReaderDescription={`You have completed ${Math.round(
                percentage,
              )}% of the course.`}
            />
          </S.ProgressBarContainer>
        </S.MetadataContainer>
      </S.SubContainer>
    </S.Container>
  );
};

export default PathCard;
