import { VideoJsPlayer } from 'video.js';
import { EventNames, trackEvent } from '../../lib/analytics';
import { VideoPlayerTech } from './VideoJSPlayer';

export enum VideoEvent {
  BUFFER_COMPLETE = 'Buffer Complete',
  COMPLETE = 'Complete',
  PAUSE = 'Pause',
  PICTURE_IN_PICTURE = 'Picture in Picture',
  PLAY = 'Play',
  QUALITY_CHANGE = 'Quality Change',
  SCRUB_BACK = 'Scrub Back',
  SCRUB_FORWARD = 'Scrub Forward',
  SEEK_BACK = 'Seek Back',
  SEEK_BAR_CLICK = 'Seek Bar Click',
  SEEK_FORWARD = 'Seek Forward',
  SPEED = 'Speed',
}

interface VideoProperties {
  watched_to_seconds: number;
  watched_to_percentage: number;
  position: number;
  total_length: number;
  bitrate: number;
  framerate: number;
  video_player: string;
  sound: number;
  full_screen: boolean;
  quality?: string;
  livestream: boolean;
}

export interface VideoSpeedTrackingProperties {
  from_speed: string;
  to_speed: string;
}

export const getVideoProperties = (player: VideoJsPlayer): VideoProperties => {
  const duration = Math.floor(player.duration());
  const currentTime = Math.floor(player.currentTime());
  const percentWatched = Math.round((currentTime / duration) * 100);
  const media = (player.tech(true) as VideoPlayerTech).vhs.playlists.media();
  const framerate = Number(media.attributes['FRAME-RATE']);
  const bitrate = media.attributes.BANDWIDTH;
  // Resolution is in uri
  const uriResolution = media.uri.match(/\d{1,4}p/g) || [];
  const quality = uriResolution.length ? uriResolution[0] : '';
  const isLive = player.liveTracker.isLive();

  return {
    watched_to_seconds: currentTime,
    watched_to_percentage: percentWatched,
    position: currentTime,
    total_length: duration,
    bitrate,
    framerate,
    video_player: 'videojs',
    sound: Math.floor(player.volume() * 100),
    full_screen: player.isFullscreen(),
    quality,
    livestream: isLive,
  };
};

export const trackVideoEvent = (type: EventNames, player: VideoJsPlayer, trackObj = {}): void => {
  const videoProperties = getVideoProperties(player);
  const trackData = { ...videoProperties, ...trackObj };
  return trackEvent(type, trackData);
};

export const trackWatchEvent = (type: EventNames, trackObj = {}): void => {
  const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();
  const trackData = {
    ...trackObj,
    locale,
    timeZone,
  };
  return trackEvent(type, trackData);
};

// Map of timestamp: percentage pairs
// These properties are taken from the Watched Video tracking event spec
export const getTimeTrackingTargets = (duration: number): Record<number, number> => {
  return {
    0: 0,
    20: Math.floor((20 / duration) * 100),
    30: Math.floor((30 / duration) * 100),
    [Math.floor(duration * 0.25) - 1]: 25,
    [Math.floor(duration * 0.5) - 1]: 50,
    [Math.floor(duration * 0.75) - 1]: 75,
    [Math.floor(duration) - 1]: 100,
  };
};
