import isDomUsable from '@fenderdigital/utils/dom';
import { avoConfig } from './avoConfig';
import Cookie from '../lib/cookie';

const rawSiteId = process.env.REACT_APP_CLIENT_SITE_ID;
const event = (type, properties, options, callback) => {
  if (isDomUsable() && window.analytics) {
    window.analytics.track(type, { ...properties }, options, callback);
  }
  if (avoConfig.inspector) {
    avoConfig.inspector.trackSchemaFromEvent(type, { ...properties });
  }
};

const updateUser = (userID, values) => {
  if (isDomUsable() && window.analytics) {
    window.analytics.identify(userID, values, false);
  }
};

const pageTrack = (category, name, properties, options, callback) => {
  const updatedProperties = {
    country_code: Cookie.getCountry(),
    ...properties,
  };

  if (window && window.analytics) {
    window.analytics.page(category, name, updatedProperties, options, callback);
  }
  if (avoConfig.inspector) {
    avoConfig.inspector.trackSchemaFromEvent('Page Track', { name, ...updatedProperties });
  }
};

export function decorateWindow() {
  // eslint-disable-next-line no-multi-assign
  const analytics = (window.analytics = window.analytics || []);
  if (analytics.initialize) return;

  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error('Segment snippet included twice.');
    }
    return;
  }
  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;
  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = method => {
    return () => {
      // eslint-disable-next-line prefer-rest-params
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  analytics.load = (key, options) => {
    // Create an async script element based on your key.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'segment_analytics';
    script.async = true;
    script.src = `${
      document.location.protocol === 'https:' ? 'https://' : 'http://'
    }cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;

    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
    // eslint-disable-next-line no-underscore-dangle
    analytics._loadOptions = options;
  };
  analytics.SNIPPET_VERSION = '4.1.0';
}

export function loadSegment() {
  if (!window.analytics) {
    console.error('decorateWindow for segment should be called first');
    return;
  }
  window.analytics.load(`${process.env.REACT_APP_SEGMENT_KEY}`);
  console.log('sgmnt loaded');
}

export default { pageTrack, event, updateUser };
