import React, { FC, ComponentType } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import Spinner from '@fenderdigital/ui/Spinner';
import usePlayUser from '@fenderdigital/custom-hooks/hooks/usePlayUser';
import { PLAY_AUTH_URL } from '../../lib/cognito';
import isRouteMatch from '../Layout/layout-utils';

interface PlayAuthRedirectRouteProps extends RouteProps {
  Component?: ComponentType<any>;
}

const explicitRedirectRoutes = [
  'bundles-ineligible',
  'checkout',
  'chord-challenge',
  'course',
  'favorites',
  'lesson',
  'my-progress',
  'path',
  'plans',
  'practice',
  'quiz',
  'redeem',
  'tab-viewer',
  'up-next',
  'welcome-screen',
];

export const handleRedirect = (url: string): void => {
  window.location.href = url;
};

const parseCoursesAndLessonsPathname = (pathname: string): string => {
  if (pathname.includes('/courses/')) return pathname.replace('/courses/', '/course/');
  if (pathname.includes('/lessons/')) return pathname.replace('/lessons/', '/lesson/');
  return pathname;
};

const PlayAuthRedirectRoute: FC<PlayAuthRedirectRouteProps> = ({
  Component,
  children,
  location,
  ...rest
}) => {
  const { hasCheckedSession, hasSubscription, user } = usePlayUser();
  const isFakeRedirect = process.env.REACT_APP_FAKE_REDIRECT === 'true';

  if (!hasCheckedSession) return <Spinner fullPage />;

  const isLoggedIn = user && hasCheckedSession;
  const isExplicitRedirectRoute = isRouteMatch(location?.pathname, explicitRedirectRoutes);

  // do the direct, if a user has a valid sub and auth'd, or if a match is
  // returned from the explicit redirect list. we want to ensure
  // users are redirected even if they aren't auth'd, but land on a specific route.
  if ((hasSubscription && isLoggedIn) || isExplicitRedirectRoute) {
    // check for courses and lessons and singualrize them
    const pathname = parseCoursesAndLessonsPathname(location?.pathname ?? '');
    // construct the url to play auth including pathname and any query params
    const redirectUrl = `${PLAY_AUTH_URL}${pathname}${location?.search}`;
    // this fake redirect is set up, for dev environment to test redirects in cypress
    if (isFakeRedirect) return <div data-id="fake-redirect">{redirectUrl}</div>;
    handleRedirect(redirectUrl);
    return <Spinner fullPage />;
  }

  return (
    <Route
      {...rest}
      render={({ location: loc, history, match }): JSX.Element => {
        return Component ? <Component location={loc} history={history} match={match} /> : <></>;
      }}
    />
  );
};

export default PlayAuthRedirectRoute;
