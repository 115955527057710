import React, {
  FC,
  useCallback,
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  Ref,
} from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.min.css';
import './plugins/seekButtons/seek-buttons';
import { SeekButtonOptions } from './plugins/seekButtons/seek-buttons.types';
import './plugins/videoThumbnails/video-thumbnails';
import { VideoThumbnailOptions } from './plugins/videoThumbnails/video-thumbnails.types';
import { getPlayBackRateButtons } from './video-utils';
import { getTimeTrackingTargets } from './VideoJSPlayerAnalytics';
import * as S from './VideoJSPlayer-styled';

export type Kind = 'subtitles' | 'captions' | 'descriptions' | 'chapters' | 'metadata';

interface Caption {
  kind: Kind;
  label: string;
  src: string;
  srcLang: string;
}
interface CustomVideoJsPlayer extends VideoJsPlayer {
  seekButtons: (options: SeekButtonOptions) => void;
  videoThumbnails: (options: VideoThumbnailOptions) => void;
}

export type VideoJSPlayerHandle = {
  getPlayer: () => VideoJsPlayer | null;
  pause: () => void;
  play: () => void;
  resetVideoState: () => void;
};

export interface VideoPlayerTech extends videojs.Tech {
  vhs: {
    playlists: {
      media: () => {
        attributes: {
          'FRAME-RATE': string;
          BANDWIDTH: number;
        };
        uri: string;
      };
    };
  };
}

interface VideoPlayerProps {
  captions?: Caption[];
  onBufferComplete?: () => void;
  onComplete?: () => void;
  onCompleteThreshold?: number;
  onFullscreen?: (isFullscreen: boolean) => void;
  onPause?: () => void;
  onPictureInPicture?: (isPictureInPicture: boolean) => void;
  onPlay?: () => void;
  onPlaybackRateChange?: (fromSpeed: number, toSpeed: number) => void;
  onPlaying?: (currentTime: number) => void;
  onQualityChange?: () => void;
  onScrub?: (timestampFrom: number, timestampTo: number) => void;
  onSeekBack?: (timestampFrom: number, timestampTo: number) => void;
  onSeekBarClick?: (timestampFrom: number, timestampTo: number) => void;
  onSeekForward?: (timestampFrom: number, timestampTo: number) => void;
  onStart?: () => void;
  onWatching?: (watchedToSeconds: number, watchedToPercentage: number) => void;
  poster?: string;
  ref?: Ref<VideoJSPlayerHandle>;
  thumbnailBaseUrl?: string;
  videoSrc: string;
  videoType: string;
  options?: VideoJsPlayerOptions;
}

const VideoPlayer: FC<VideoPlayerProps> = forwardRef(
  (
    {
      captions,
      onBufferComplete,
      onComplete,
      onCompleteThreshold = 0.95,
      onFullscreen,
      onPause,
      onPictureInPicture,
      onPlay,
      onPlaybackRateChange,
      onPlaying,
      onQualityChange,
      onScrub,
      onSeekBack,
      onSeekBarClick,
      onSeekForward,
      onStart,
      onWatching,
      poster,
      thumbnailBaseUrl,
      videoSrc,
      videoType,
      options,
    },
    ref,
  ) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [player, setPlayer] = useState<VideoJsPlayer | null>(null);
    const [hasStarted, setHasStarted] = useState(false);
    const [hasCompleted, setHasCompleted] = useState(false);
    const [playWhenReady, setPlayWhenReady] = useState(false);
    const currentTime = useRef(0);
    const previousTime = useRef(0);
    const currentBitrate = useRef(0);
    const timeTrackingTargets = useRef<Record<number, number>>({});

    useImperativeHandle(ref, () => ({
      getPlayer(): VideoJsPlayer | null {
        return player;
      },
      pause(): void {
        if (player) {
          player.pause();
        }
      },
      play(): void {
        if (player) {
          player.play();
        } else {
          setPlayWhenReady(true);
        }
      },
      resetVideoState(): void {
        setHasStarted(false);
        if (player) {
          // Rewind to beginning
          player.currentTime(0);
          // Remove the .vjs-has-started class
          player.hasStarted(false);
        }
      },
    }));

    const onReady = useCallback((): void => {
      const customPlayer = player as CustomVideoJsPlayer;

      if (customPlayer) {
        customPlayer.src({
          src: videoSrc,
          type: videoType,
        });
      }

      if (captions?.length) {
        captions.forEach(caption => {
          customPlayer.addRemoteTextTrack(
            {
              kind: caption.kind,
              label: caption.label,
              language: caption.srcLang,
              src: caption.src,
            },
            false,
          );
        });
      }

      customPlayer.seekButtons({
        forward: 10,
        back: 10,
        onSeekBack,
        onSeekForward,
      });

      if (thumbnailBaseUrl) {
        customPlayer.videoThumbnails({
          interval: 6,
          thumbnailBaseUrl,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [player, videoSrc, videoType, captions, thumbnailBaseUrl]);

    useEffect(() => {
      const defaultOptions: VideoJsPlayerOptions = {
        controls: true,
        controlBar: {
          volumePanel: {
            inline: false,
            volumeControl: {
              vertical: true,
            },
          },
        },
        fluid: true,
        playbackRates: [0.5, 0.75, 1, 1.25, 1.5],
        preload: 'auto',
        poster,
        ...options,
      };

      const videoPlayer = videojs(videoRef.current as HTMLVideoElement, defaultOptions);
      setPlayer(videoPlayer);

      return (): void => videoPlayer.dispose();
    }, [options, poster]);

    useEffect(() => {
      if (player !== null) {
        player.ready(onReady);
        player.on('play', () => {
          if (onPlay) {
            onPlay();
          }
          setHasStarted(true);
        });
        player.on('pause', () => {
          if (onPause) {
            onPause();
          }
        });
        player.on('loadedmetadata', () => {
          if (playWhenReady) {
            player.play();
            setPlayWhenReady(false);
          }
          const duration = player.duration();
          let time = 0;
          let thresholdReached = false;
          timeTrackingTargets.current = getTimeTrackingTargets(duration);

          if (duration) {
            player.on('timeupdate', () => {
              const threshold = Math.round(duration * onCompleteThreshold);
              const exactTime = player.currentTime();
              const current = Math.floor(exactTime);

              if (onSeekBarClick) {
                setTimeout(() => {
                  previousTime.current = current;
                }, 1000);
              }

              if (onScrub) {
                if (player.scrubbing()) {
                  onScrub(currentTime.current, Math.floor(player.currentTime()));
                } else {
                  currentTime.current = current;
                }
              }

              if (onQualityChange) {
                const media = (player.tech(true) as VideoPlayerTech).vhs.playlists.media();
                const bitrate = media.attributes.BANDWIDTH;

                if (bitrate !== currentBitrate.current) {
                  onQualityChange();
                  currentBitrate.current = bitrate;
                }
              }

              if (onWatching) {
                if (timeTrackingTargets.current[current] !== undefined) {
                  onWatching(current, timeTrackingTargets.current[current]);
                }
              }

              if (!thresholdReached && exactTime > threshold) {
                thresholdReached = true;
                setHasCompleted(true);
              }

              if (onPlaying) {
                // ensure this fires only once every 10 seconds
                if (current === time) return;
                time = current;
                if (time % 10 === 0) {
                  onPlaying(current);
                }
              }
            });
          } else {
            player.on('ended', () => {
              setHasCompleted(true);
            });
          }
        });
        player.on('canplaythrough', () => {
          if (onBufferComplete) {
            onBufferComplete();
          }
        });
        player.on('fullscreenchange', () => {
          if (onFullscreen) {
            onFullscreen(player.isFullscreen());
          }
        });
        player.on('leavepictureinpicture', () => {
          if (onPictureInPicture) {
            onPictureInPicture(false);
          }
        });

        // Playback Rate Listeners
        if (onPlaybackRateChange) {
          const playbackRateButtons = getPlayBackRateButtons(player);
          playbackRateButtons.forEach(el => {
            el.on('click', () => {
              onPlaybackRateChange(player.playbackRate(), el.rate);
            });
          });
        }

        // PIP (Picture in Picture) Listener
        if (onPictureInPicture) {
          const pipButton = player.controlBar.getChild('PictureInPictureToggle');

          if (pipButton) {
            pipButton.on('click', () => {
              // PIP exit tracked in 'leavepictureinpicture' event
              onPictureInPicture(true);
            });
          }
        }

        // SeekBar Listener
        if (onSeekBarClick) {
          const progressControl = player.controlBar.getChild('ProgressControl');
          const seekBar = progressControl?.getChild('SeekBar');

          if (progressControl) {
            progressControl.on('click', () => {
              onSeekBarClick(previousTime.current, Math.floor(player.currentTime()));
            });
          }

          if (seekBar) {
            seekBar.on('click', () => {
              onSeekBarClick(previousTime.current, Math.floor(player.currentTime()));
            });
          }
        }
      }

      return (): void => {
        if (player !== null) {
          player.off([
            'play',
            'pause',
            'loadedmetadata',
            'timeupdate',
            'ended',
            'canplaythrough',
            'fullscreenchange',
            'leavepictureinpicture',
          ]);

          // Playback Rate Listeners
          if (onPlaybackRateChange) {
            const playbackRateButtons = getPlayBackRateButtons(player);
            playbackRateButtons.forEach(el => {
              el.off('click');
            });
          }

          // PIP (Picture in Picture) Listener
          if (onPictureInPicture) {
            const pipButton = player.controlBar.getChild('PictureInPictureToggle');

            if (pipButton) {
              pipButton.off('click');
            }
          }

          // SeekBar Listener
          if (onSeekBarClick) {
            const progressControl = player.controlBar.getChild('ProgressControl');
            const seekBar = progressControl?.getChild('SeekBar');

            if (progressControl) {
              progressControl.off('click');
            }

            if (seekBar) {
              seekBar.off('click');
            }
          }
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onReady, player]);

    useEffect(() => {
      if (onStart && hasStarted) {
        onStart();
      }
    }, [hasStarted, onStart]);

    useEffect(() => {
      if (onComplete && hasCompleted) {
        onComplete();
      }
    }, [hasCompleted, onComplete]);

    return (
      <S.VideoPlayerContainer>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video ref={videoRef} className="video-js" id="videojs" />
      </S.VideoPlayerContainer>
    );
  },
);

export default VideoPlayer;
