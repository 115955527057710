import { actionTypes } from '../actions/related-items';
import { relatedItems as defaultState } from './default.json';

const handlers = {
  [actionTypes.RELATED_ITEMS_RESET]: state => {
    return {
      ...state,
      results: {},
    };
  },
  [actionTypes.RELATED_ITEMS_CLEAR]: (state, action) => {
    const updatedState = {
      ...state,
      results: {
        ...state.results,
      },
    };
    delete updatedState[action.key];
    return updatedState;
  },
  [actionTypes.RELATED_ITEMS_GET_ERROR]: (state, action) => ({
    ...state,
    error: action.error.message,
    inProgress: false,
  }),
  [actionTypes.RELATED_ITEMS_GET_IN_PROGRESS]: state => ({
    ...state,
    error: null,
    inProgress: true,
  }),
  [actionTypes.RELATED_ITEMS_GET_SUCCESS]: (state, action) => ({
    ...state,
    inProgress: false,
    results: action.results,
  }),
};

export default (state = defaultState, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};
