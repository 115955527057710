export default function filterByEmptyProps(props) {
  const filteredObj = {};
  if (props) {
    Object.keys(props).forEach(prop => {
      if (props[prop] !== '') {
        filteredObj[prop] = props[prop];
      }
    });
  }
  return filteredObj;
}
