import React, { FC } from 'react';

const IconPointerRight: FC = () => {
  return (
    <svg width={9} height={16} viewBox="0 0 9 16">
      <path
        d="M0 15.11a.9.9 0 01.23-.59L6 8 .22 1.48A.88.88 0 01.33.25.9.9 0 011.57.3L8.4 8l-6.83 7.7A.9.9 0 010 15.11z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default IconPointerRight;
