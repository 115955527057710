import videojs from 'video.js';
import { Thumbnail, Thumbnails, ControlBar } from './video-thumbnails.types';

function createThumbnailImage(thumbnails: Thumbnails): HTMLImageElement {
  const thumbnailImg = document.createElement('img');

  thumbnailImg.src = thumbnails[0].src;
  thumbnailImg.className = 'vjs-thumbnail-img';
  thumbnailImg.alt = '';
  return thumbnailImg;
}

function createThumbnailImageContainer(): HTMLDivElement {
  const container = document.createElement('div');

  container.className = 'vjs-thumbnail-image-container';
  return container;
}

function mergeThumbnailElements(
  thumbnailImageContainer: HTMLDivElement,
  thumbnailImage: HTMLImageElement,
): HTMLDivElement {
  thumbnailImageContainer.appendChild(thumbnailImage);
  return thumbnailImageContainer;
}

function addThumbnailToPlayer(controlBar: ControlBar, thumbnailContainer: HTMLDivElement): void {
  const { mouseTimeDisplay } = controlBar?.progressControl?.seekBar;
  mouseTimeDisplay.el().appendChild(thumbnailContainer);
}

function updateOnHover(
  controlBar: ControlBar,
  thumbnails: Thumbnails,
  thumbnailImage: HTMLImageElement,
  player: videojs.Player,
): void {
  controlBar.on('mousemove', event => {
    moveListener(event, controlBar, thumbnails, thumbnailImage, player);
  });
}

function moveListener(
  event: MouseEvent,
  controlBar: ControlBar,
  thumbnails: Thumbnails,
  thumbnailImage: HTMLImageElement,
  player: videojs.Player,
): void {
  const duration = getVideoDuration(player);

  const seekBarPosition = controlBar.progressControl.seekBar.el().getBoundingClientRect();
  const seekBarLeftOffset = seekBarPosition.x;
  const seekBarWidth = seekBarPosition.width;

  const pageMousePositionX = event.pageX;

  const mouseTime = getMouseVideoTime(
    pageMousePositionX,
    seekBarLeftOffset,
    seekBarWidth,
    duration,
  );
  const activeThumbnail = getActiveThumbnail(thumbnails, mouseTime);
  updateThumbnailSrc(activeThumbnail, thumbnailImage);
}

function getVideoDuration(player: videojs.Player): number {
  let duration = player.duration();

  player.on('durationchange', () => {
    duration = player.duration();
  });
  return duration;
}

function getMouseVideoTime(
  pageMousePositionX: number,
  seekBarLeftOffset: number,
  seekBarWidth: number,
  duration: number,
): number {
  if (pageMousePositionX > seekBarLeftOffset) {
    if (pageMousePositionX > seekBarWidth) {
      return duration;
    }
    return Math.floor(((pageMousePositionX - seekBarLeftOffset) / seekBarWidth) * duration);
  }
  return 0;
}

function getActiveThumbnail(thumbnails: Thumbnails, mouseTime: number): Thumbnail {
  let activeClip = 0;

  for (const time in thumbnails) {
    if (mouseTime > +time) {
      activeClip = Math.max(activeClip, +time);
    }
  }
  return thumbnails[activeClip];
}

function updateThumbnailSrc(activeThumbnail: Thumbnail, thumbnailImage: HTMLImageElement): void {
  if (activeThumbnail.src && thumbnailImage.src !== activeThumbnail.src) {
    thumbnailImage.src = activeThumbnail.src;
  }
}

export {
  createThumbnailImage,
  createThumbnailImageContainer,
  mergeThumbnailElements,
  addThumbnailToPlayer,
  updateOnHover,
};
