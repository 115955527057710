import React, { cloneElement, ReactElement, ReactNode } from 'react';

interface ContextsProps {
  providers: JSX.Element[];
  children: ReactNode;
}

const ProviderComposer = ({ providers, children }: ContextsProps): JSX.Element => (
  <>
    {providers.reduceRight(
      (kids: ReactNode, parent: ReactElement) => cloneElement(parent, { children: kids }),
      children,
    )}
  </>
);

const ContextsProvider = ({ providers, children }: ContextsProps) => {
  return <ProviderComposer providers={providers}>{children}</ProviderComposer>;
};

export default ContextsProvider;
