import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const HeroContainer = styled.div`
  position: relative;
  background: black;
  color: white;
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroContentContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 704px;
`;

export const HeroTitle = styled.h1`
  font-family: FuturaPT-Demi;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin: 0;
  ${up('tablet-landscape')} {
    font-size: 40px;
    line-height: 48px;
  }
  ${up('laptop')} {
    font-size: 50px;
    line-height: 64px;
  }
`;

interface HeroDescriptionProps {
  textAlign: 'left' | 'center';
}

export const HeroDescription = styled.div<HeroDescriptionProps>`
  font-family: FuturaPT-Book;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  text-align: ${(props): string => props.textAlign};

  ${up('tablet-landscape')} {
    font-size: 22px;
  }
`;
