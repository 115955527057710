import React from 'react';
import { defineMessages, InjectedIntl, injectIntl } from 'react-intl';
import Container from '@fenderdigital/ui/Container';
import Button from '@fenderdigital/ui/Button';
import Heading from '@fenderdigital/ui/Heading';
import BodyText from '@fenderdigital/ui/BodyText';
import Link from '../../components/Link';
import UnSubscribedLayout from '../../components/UnSubscribedLayout';

interface NotFoundPropType {
  title: string;
  intl: InjectedIntl;
  staticContext: {
    status: number;
    notFound: boolean;
  };
}

const messages = defineMessages({
  notFoundGoToHome: {
    id: 'not-found.go-to-home',
    defaultMessage: 'Take me home',
  },
  notFoundDescription: {
    id: 'not-found.description',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Sorry, that link may not be valid. You can check out the feed for some of the recommended stuff below.',
  },
});

function NotFound({
  intl,
  title = "We still haven't found what you're looking for...",
  staticContext,
}: NotFoundPropType): JSX.Element {
  const { formatMessage }: InjectedIntl = intl;
  if (staticContext) {
    staticContext.status = 404;
    staticContext.notFound = true;
  }
  return (
    <>
      <UnSubscribedLayout>
        <Container
          maxWidth="86rem"
          paddingTop="md1"
          paddingLeft={[null, null, 'md1', 'md4']}
          paddingRight={[null, null, 'md1', 'md4']}
        >
          <Heading>{title}</Heading>
          <BodyText>{formatMessage(messages.notFoundDescription)}</BodyText>
          <Button as={Link} to="/">
            {formatMessage(messages.notFoundGoToHome)}
          </Button>
        </Container>
      </UnSubscribedLayout>
    </>
  );
}

export default injectIntl(NotFound);
