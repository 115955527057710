import { getAuthenticationHeader } from '@fenderdigital/custom-hooks/lib/cognito';

export type PlayStatus = 'active' | 'cancelled' | 'pending';

/**
 * Checks the subscription status response for a cancelled value.
 * @param {String} - status
 * @return {Boolean} - is false when the value is cancelled.
 */
export const hasValidSubscription = (status?: PlayStatus): boolean => status !== 'cancelled';

export interface StatusResponse {
  play?: {
    status?: PlayStatus;
    free?: boolean;
    'is-iap': boolean;
    'payment-source'?: string;
    'product-family': string;
    'product-rate-plan-sku': string;
    'created-at': string;
    'expiration-date': string;
    'access-level': string[];
  };
}

const clientName = process.env.REACT_APP_CLIENT_SITE_ID || 'play';

class SubscriptionStatusRequestError extends Error {
  constructor(public status: number, public payload: unknown, message = 'Profile request error') {
    super(message);
    this.name = 'SubscriptionStatusRequestError';
    this.status = status;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SubscriptionStatusRequestError);
    }
  }
}

/**
 * Gets the a user's subscription status by userId.
 * @param {String} userId
 * @return {Object} Returns an object with the user's subscripton data.
 */
export const getSubscriptionStatus = async (userId?: string): Promise<StatusResponse> => {
  const token = await getAuthenticationHeader();
  const response = await fetch(
    `${process.env.REACT_APP_CLIENT_FC_SUBSCRIPTIONS_API}/subscriptions/status?user-id=${userId}`,
    {
      headers: {
        Authorization: token,
        'X-Client-Name': clientName,
      },
    },
  );
  const payload = await response.json();
  if (response.status >= 200 && response.status < 300) {
    return payload;
  }
  throw new SubscriptionStatusRequestError(response.status, payload, payload.message);
};

export default getSubscriptionStatus;
