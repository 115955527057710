import React, { FC } from 'react';
import isEmpty from 'lodash/isEmpty';
import BlockFlag from './BlockFlag';

interface BlockListProps {
  columns: string;
  containerStyles?: string;
  items?: any[];
  isFirstBlock: boolean;
}

const BlockList: FC<BlockListProps> = ({
  columns,
  items,
  containerStyles = 'pl0 ma0 mb4-ns nr3 nl3 ',
  isFirstBlock = false,
}) => {
  const baseStyles = `list flex flex-column flex-row-ns ${containerStyles}`;
  const aspectRatio = `${columns === '2' ? 'wideScreen' : 'square'}`;
  const spacingStyles = 'ph3 mb0-ns mb4';

  const filteredItems: any[] = [];
  items &&
    items.forEach(item => {
      if (!isEmpty(item)) {
        filteredItems.push(item);
      }
    });

  let columnWidth: string | null = null;

  if (columns === '2') {
    columnWidth = 'w-50-ns';
  } else if (columns === '3') {
    columnWidth = 'w-third-ns';
  } else if (columns === '4') {
    columnWidth = 'w-25-ns';
  }

  return (
    <ul className={`block-list ${baseStyles}`}>
      {Array.isArray(filteredItems) &&
        filteredItems.length > 0 &&
        filteredItems.map(item => (
          <li key={item['image-url']} className={`${spacingStyles} ${columnWidth} w-100`}>
            <BlockFlag
              image={item['image-url']}
              columns={columns}
              aspectRatio={aspectRatio}
              headline={item.headline}
              description={item.description}
              cta1={item.cta1}
              cta1Url={item['cta1-url']}
              cta2={item.cta2}
              cta2Url={item['cta2-url']}
              isFirstBlock={isFirstBlock}
            />
          </li>
        ))}
    </ul>
  );
};

export default BlockList;
