import mirror from 'keymirror';
import SkillsLists from '../lib/skills-lists';

export const actionTypes = mirror({
  SKILLS_LISTS_GET_ERROR: null,
  SKILLS_LISTS_GET_IN_PROGRESS: null,
  SKILLS_LISTS_GET_SUCCESS: null,
});

const skillsListGetError = error => ({
  error,
  type: actionTypes.SKILLS_LISTS_ERROR,
});

const skillsListGetInProgress = () => ({
  type: actionTypes.SKILLS_LISTS_GET_IN_PROGRESS,
});

const skillsListGetSuccess = results => ({
  results,
  type: actionTypes.SKILLS_LISTS_GET_SUCCESS,
});

export const getSkillsLists = () => async dispatch => {
  dispatch(skillsListGetInProgress());
  try {
    const list = await SkillsLists.getSkillsLists();
    dispatch(skillsListGetSuccess(list));
  } catch (error) {
    dispatch(skillsListGetError(error));
  }
};
