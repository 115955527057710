import React, { FC, SyntheticEvent, forwardRef, Ref } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import Link from '../Link/Link';
import { NavigationItem } from '../Header/NavTypes';

interface MobileMenuHeaderProps {
  heading?: keyof JSX.IntrinsicElements;
  title?: string;
  isBackButtonVisible?: boolean;
  onBackButtonClick?: () => void;
  onCloseButtonClick?: () => void;
  ref?: Ref<HTMLButtonElement>;
}

export const MobileMenuHeader: FC<MobileMenuHeaderProps> = forwardRef(
  (
    {
      heading = 'h1',
      title,
      isBackButtonVisible = false,
      onBackButtonClick = (): void => {},
      onCloseButtonClick = (): void => {},
    },
    ref,
  ) => {
    const Heading = heading;
    if (title) {
      return (
        <header className="flex items-center pa3 bb b--black-10 z-5">
          {isBackButtonVisible ? (
            <button
              type="button"
              className="ph0 mid-gray relative button-reset bn bg-transparent pointer"
              onClick={onBackButtonClick}
              data-id="mobile-menu-back-button"
            >
              <i className="f4 icon-pointer-left-light" aria-hidden />
              <span
                className="absolute futura-heavy ttu f7"
                style={{ top: '0.4375rem', left: '1rem' }}
              >
                Back
              </span>
            </button>
          ) : null}
          <Heading className="ttu f6 tc ma0 ttu flex-auto">{title}</Heading>
          <button
            type="button"
            className="mid-gray button-reset bn bg-transparent pointer ph0"
            onClick={onCloseButtonClick}
            aria-label="Close"
            data-id="mobile-menu-close-button"
            ref={ref}
          >
            <i className="f4 icon-x" aria-hidden />
          </button>
        </header>
      );
    }
    return null;
  },
);

const MenuListContainer = styled.ul`
  li > a {
    &:focus,
    &:active {
      outline: 0px;
      box-shadow: inset rgb(185, 205, 241) 0px 0px 0px 3px;
    }
  }
`;
interface MenuListProps {
  obj: any;
  isMobile?: boolean;
  onClick?: (event: SyntheticEvent) => void;
}

export const MenuList: FC<MenuListProps> = ({
  obj,
  isMobile = false,
  onClick = (): void => {},
}) => {
  const { list, gutterSpacing } = obj;

  if (list && Array.isArray(list)) {
    const filteredList = list.filter(listItem => listItem.url);

    return (
      <MenuListContainer>
        {filteredList.map(listItem => (
          <li className={`${!isMobile ? 'mb2' : ''}`} key={`${listItem.label}-${listItem.url}`}>
            <Link
              className={`
              ${
                isMobile
                  ? `${
                      gutterSpacing || 'ph3'
                    } db pv3 bg-near-white bb bt-0 bl-0 br-0 b--light-gray b--dotted`
                  : ''
              }
              dark-gray source-sans f7`}
              onClick={
                !listItem.target
                  ? (event: SyntheticEvent): void => {
                      onClick(event);
                    }
                  : null
              }
              to={listItem.url || ''}
              target={listItem.target || ''}
            >
              {listItem['label-prefix'] ? (
                <span className="blue pr1">{listItem['label-prefix']}</span>
              ) : null}
              <span className={listItem['class-names'] || ''}>{listItem.label}</span>
            </Link>
          </li>
        ))}
      </MenuListContainer>
    );
  }

  return null;
};

interface MenuTitleProps {
  obj: any;
  isSubTitle?: boolean;
  isMobile?: boolean;
}

export const MenuTitle: FC<MenuTitleProps> = ({ obj, isSubTitle = false, isMobile = false }) => {
  const { entity, label, secondaryLabel } = obj;

  const setStyles = (): string => {
    const baseStyles = 'ttu ma0 futura-heavy mid-gray';
    if (!isMobile) {
      const subTitleStyles = `${isSubTitle ? 'f8 b--dotted mb2' : 'f7 mb3'}`;
      return `${baseStyles} ${subTitleStyles} pb2 bb bt-0 bl-0 br-0 b--light-gray`;
    }
    return `${isMobile ? 'f7' : ''} ${baseStyles}`;
  };

  return (
    <h2 className={`${setStyles()}`} data-id={`mega-nav-sub-menu-title-${label}`}>
      <div className={`${secondaryLabel ? 'mb1' : ''}`}>
        {label}
        {entity ? (
          <sup
            style={{
              fontSize: '.625rem',
              paddingLeft: '.15rem',
              top: '-.125rem',
            }}
          >
            {entity}
          </sup>
        ) : null}
      </div>
      {secondaryLabel ? <span className="f7 ttn source-sans">{secondaryLabel}</span> : null}
    </h2>
  );
};

interface CollapsibleContentProps {
  obj: any;
  onClick?: () => void;
}

export const CollapsibleContent: FC<CollapsibleContentProps> = ({ obj, onClick }) => {
  const { email, list, styles } = obj;
  if (list && Array.isArray(list)) {
    const filteredList = list.filter(listItem => listItem.items);
    return (
      <>
        {filteredList.map(listItem => (
          <div key={`collapsible-content-list-item-${listItem.label}`}>
            <h4 className={`${styles}`} data-id={`collapsible-content-list-item-${listItem.label}`}>
              {listItem.label}
            </h4>
            <MenuList
              obj={{
                email,
                list: listItem.items,
                gutterSpacing: 'pl4 pr3',
              }}
              isMobile
              onClick={onClick}
            />
          </div>
        ))}
      </>
    );
  }
  return null;
};

interface MobileMenuItemContentsProps {
  navItem: NavigationItem;
}

export const MobileMenuItemContents: FC<MobileMenuItemContentsProps> = ({ navItem }) => {
  return (
    <>
      <span className={classnames(navItem['class-names'])}>{navItem.label}</span>
      {navItem.items ? (
        <span
          className={classnames(navItem['class-names'], 'absolute right-0 pr3 f4')}
          style={{ top: '0.75rem' }}
        >
          <i className="icon-pointer-right-light" aria-hidden />
        </span>
      ) : null}
    </>
  );
};
