const loadMouseFlow = (): void => {
  // eslint-disable-next-line no-underscore-dangle
  window._mfq = window._mfq || [];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'playapp-mf';
  script.defer = true;
  script.src = `https://cdn.mouseflow.com/projects/${process.env.REACT_APP_MOUSE_FLOW_KEY}.js`;
  document.getElementsByTagName('head')[0].appendChild(script);
};

export default loadMouseFlow;
