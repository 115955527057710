import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { useFocusTrap } from '@fenderdigital/custom-hooks';
import { Alert } from '@fenderdigital/ui/Icons';
import { up } from 'styled-breakpoints';

export interface ToastProps {
  message?: string;
  icon?: string;
  dataId?: string;
  visible?: boolean;
  handleManualClose?: () => void;
}

export const ToastStyled = styled.div<ToastProps>`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0.5rem 1rem;
  position: fixed;
  left: 50%;
  bottom: 50%;
  text-align: center;
  white-space: pre;
  ${up('tablet-portrait')} {
    flex-direction: row;
    padding: 1rem;
    text-align: auto;
    white-space: normal;
    bottom: 20%;
  }
  border-radius: 0.25rem;
  color: #fff;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.25);
  background-color: #000;
  margin: 0;

  z-index: ${({ visible }): string => (visible ? '999' : '-1')};
  transition: 0.5s ease 0.2s all;
  transform: translate3d(-50%, ${({ visible }): string => (visible ? '16px' : '100%')}, 0);
  opacity: ${({ visible }): string => (visible ? '1' : '0')};

  .icon {
    padding: 0;
    ${up('tablet-portrait')} {
      padding-right: 0.5rem;
    }
    margin: 0;
  }
`;

const Message = styled.div`
  line-height: 1.5rem;
  padding-bottom: 1rem;
  ${up('tablet-portrait')} {
    padding-bottom: 0;
  }
`;

const Dismiss = styled.button`
  background: transparent;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  padding-top: 0.5rem;
  line-height: 1.5rem;
  border: none;
  border-top: 2px solid #33393b;
  margin-left: -1rem;
  margin-right: -1rem;
  ${up('tablet-portrait')} {
    border: none;
    margin: 0;
    padding: 0;
    margin-left: 0.5rem;
  }
  &:focus,
  &:active {
    outline-width: 1px;
    outline-style: solid;
    outline-color: #fff;
  }
  transition: outline 0.15s ease-in 0s;
`;

const Toast: FC<ToastProps> = ({
  message = '',
  icon = 'alert',
  visible = false,
  dataId = 'toast',
  handleManualClose = null,
}) => {
  const showIcon = (): ReactNode => {
    switch (icon.toLowerCase()) {
      case 'alert':
        return <Alert data-id="alert" fill="#fff" fontSize="1.5rem" />;
      default:
        return null;
    }
  };
  const ref = useFocusTrap();
  return (
    <ToastStyled visible={visible} data-id={dataId} role="alert">
      <div className="icon">{showIcon()}</div>
      <Message>{message}</Message>
      {handleManualClose && (
        <Dismiss data-id="toast-dismiss" onClick={handleManualClose} type="button" ref={ref}>
          Dismiss
        </Dismiss>
      )}
    </ToastStyled>
  );
};

export default Toast;
