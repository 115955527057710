import { dateToRFC3339 } from '@fenderdigital/utils/date';
import { postActivity } from '@fenderdigital/digital-services/services/activity-stream';
import { getSessionData } from '@fenderdigital/utils/sessions';
import { getUserProperties } from '@fenderdigital/custom-hooks/lib/cognito';
import Auth from '@aws-amplify/auth';
import bugsnagClient from '../core/bugsnag';

// Direct call to activity stream without the client.
// Token and api host is called each time.
const postDirectlyToActivityStream = async (
  activityName: string,
  properties: Record<any, string>,
): Promise<void> => {
  const user = await Auth.currentAuthenticatedUser();
  const apiHost = `${process.env.REACT_APP_CLIENT_ACTIVITY_STREAM_HOST}`;
  const date = new Date();
  const localTime = dateToRFC3339(date);
  const publishedAt = date.toISOString();
  const { fcSessionId } = getSessionData() || {};

  const { token, userID } = getUserProperties(user.signInUserSession);

  const activityStreamData = {
    activity: activityName,
    publishedAt,
    context: {
      applicationName: 'play',
      applicationPlatform: 'web',
      userAgent: window.navigator.userAgent,
      locale: window.navigator.language,
      localTime,
    },
    actor: {
      type: 'person',
      id: userID || '',
      sessionID: fcSessionId,
    },
    properties,
  };

  try {
    postActivity(apiHost, token || '', activityStreamData);
  } catch {
    bugsnagClient.notify(new Error('Activity Stream Post: Error posting to stream'));
  }
};

export default postDirectlyToActivityStream;
