import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { Focus } from '@fenderdigital/ui/utils/css';

interface ContainerProps {
  desktopImage?: string;
  mobileImage?: string;
}

interface ProgressBarContainerProps {
  isSongWBackground?: boolean;
}

const getGradient = (image: string) => {
  return css`
    background: url("${image}");
  `;
};

export const Container = styled.article<ContainerProps>`
  display: flex
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  background-color: white;
  margin: auto;
  overflow: hidden;
  ${({ desktopImage, mobileImage }) =>
    desktopImage || mobileImage
      ? css`
          color: white;
        `
      : ''}

  ${({ mobileImage }) => (mobileImage ? getGradient(mobileImage) : '')}

  ${up('tablet-portrait')} {
    border-radius: 10px;
    ${({ desktopImage }) => (desktopImage ? getGradient(desktopImage) : '')}
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${up('tablet-portrait')} {
    padding: 32px;
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;

  ${up('tablet-portrait')} {
    max-width: 368px;
  }
`;

export const Title = styled.h1<ProgressBarContainerProps>`
  margin-bottom: 0px;
  ${Focus}
  font-size: ${({ theme }): string => theme.fontSizes.f6};
  font-family: ${({ theme }): string => theme.fonts.futuraDemi};
  color: ${({ theme }): string => theme.colors.black};

  ${up('tablet-portrait')} {
    font-size: ${({ theme }): string => theme.fontSizes.f8};
  }
`;

export const MetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  justify-content: center;

  ${up('tablet-portrait')} {
    padding: 0 0 0 2rem;
  }

  .fade-white {
    color: rgba(255, 255, 255, 0.3);
  }
`;

export const CardMenuContainer = styled.div`
  max-height: 36px;
  min-height: 20px;
`;

export const Dot = styled.span`
  margin: 0 6px;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;

  ${up('tablet-portrait')} {
    max-width: 300px;
  }
`;

export const LockContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
